<template>
  <ModuleStep :prev-step="previous" :index="2">
    <AdiForm
      v-slot="{ loading }"
      :disabled="!isComplete"
      @submit.prevent="onSubmit"
    >
      <template v-if="askAttorneyDecisions">
        <Headline :level="1">
          {{ $t('pages.poa.medical.primaryDecisions.headline') }}
        </Headline>
        <p class="mb-4">
          {{ $t('pages.poa.medical.primaryDecisions.jointly') }}
        </p>
        <p class="mb-4">
          {{ $t('pages.poa.medical.primaryDecisions.severally') }}
        </p>
        <p v-if="['qld', 'act', 'sa'].includes(userState)" class="mb-4">
          {{ $t('pages.poa.medical.primaryDecisions.affectOtherAttorneys') }}
        </p>
        <Tip>
          {{ $t('pages.poa.medical.primaryDecisions.prosCons') }}
        </Tip>
        <LargeRadioButtons
          id="medical_attorney_decisions"
          v-model="poaMedicalMeta.attorney_decisions"
          layout="column"
          :options="[
            {
              label: $t(
                'pages.poa.medical.primaryDecisions.medicalAttorneyDecisions.jointly'
              ),
              value: 'jointly',
            },
            {
              label: $t(
                'pages.poa.medical.primaryDecisions.medicalAttorneyDecisions.severally'
              ),
              value: 'jointly and severally',
            },
          ]"
        />
      </template>
      <template v-if="askAttorneyDecisionsOther">
        <Headline :level="2">
          {{
            $t(
              'pages.poa.medical.primaryDecisions.jointDecisionMakerUnableToAct'
            )
          }}
        </Headline>
        <Tip>
          {{ $t('pages.poa.medical.primaryDecisions.exampleUnwellOrDead') }}
        </Tip>
        <LargeRadioButtons
          id="medical_attorney_decisions_other"
          v-model="poaMedicalMeta.attorney_decisions_other"
          layout="column"
          :options="[
            {
              label: $t(
                'pages.poa.medical.primaryDecisions.medicalAttorneyDecisionsOther.other'
              ),
              value: 'other',
            },
            {
              label: $t(
                'pages.poa.medical.primaryDecisions.medicalAttorneyDecisionsOther.none'
              ),
              value: 'none',
            },
          ]"
        />
      </template>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';
import Tip from '@/components/molecules/Tip';
import AdiForm from '@/components/Form';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalPrimaryDecisions',
  components: {
    AdiForm,
    LargeRadioButtons,
    ModuleStep,
    Tip,
    Headline,
  },
  mixins: [user, will],
  apollo: {
    poaMedicalMeta: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaMedicalMeta: {
        attorney_decisions: undefined,
        attorney_decisions_other: undefined,
        has_substitute: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        poaMedicalMeta: this.poaMedicalMeta,
        addressState: this.userState,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      const completedMainQuestion =
        !this.askAttorneyDecisions || this.poaMedicalMeta.attorney_decisions;
      const completedOtherQuestion =
        !this.askAttorneyDecisionsOther ||
        this.poaMedicalMeta.attorney_decisions_other;
      return completedMainQuestion && completedOtherQuestion;
    },
    askAttorneyDecisions() {
      return ['nsw', 'qld', 'act', 'sa', 'nt'].includes(this.userState);
    },
    askAttorneyDecisionsOther() {
      return (
        ['tas', 'wa'].includes(this.userState) ||
        (['nsw', 'nt', 'tas'].includes(this.userState) &&
          this.poaMedicalMeta.attorney_decisions === 'jointly')
      );
    },
  },
  methods: {
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'attorney_decisions',
            value: this.poaMedicalMeta.attorney_decisions,
            category: 'MEDICAL',
          },
          {
            key: 'attorney_decisions_other',
            value: this.poaMedicalMeta.attorney_decisions_other,
            category: 'MEDICAL',
          },
        ])
      );

      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
</script>
