<template>
  <ModuleStep :index="3" prev-step="/will/guardians/primary">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="submit"
    >
      <Headline :level="1">
        {{ $t('pages.will.guardians.appointBackupGuardian') }}
      </Headline>
      <p>
        {{ $t('pages.will.guardians.backupGuardianExplanation') }}
      </p>
      <Tip>
        {{ $t('pages.will.guardians.addExtraProtection') }}
      </Tip>
      <LargeRadioButtons
        id="hasBackupGuardians"
        v-model="willMeta.has_backup_guardian"
        :options="[
          {
            label: $t('forms.labels.yes'),
            value: true,
          },
          {
            label: $t('forms.labels.no'),
            value: false,
          },
        ]"
      />
      <template v-if="willMeta.has_backup_guardian">
        <Headline :level="2">
          {{ $t('pages.will.guardians.appointBackupGuardianPrompt') }}
        </Headline>
        <Tip>
          {{ $t('pages.will.guardians.backupGuardianRequirements') }}
        </Tip>
        <PersonSelector
          v-model="selectedBackupGuardian"
          :loading="loading"
          :max="1"
          :people="filteredPeople"
          type="guardian"
        />
      </template>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';

import AdiForm from '@/components/Form';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { guardians, people, user, will } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliateWillGuardiansBackup',
  components: {
    AdiForm,
    LargeRadioButtons,
    ModuleStep,
    PersonSelector,
    Tip,
    Headline,
  },
  mixins: [guardians, people, user, will],
  data() {
    return {
      selectedBackupGuardian: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        this.willMeta.has_backup_guardian === false ||
        this.selectedBackupGuardian.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        const personIsAlreadySelected = this.selectedBackupGuardian.includes(
          person.id
        );
        return (
          personIsAlreadySelected ||
          (meta.is_over_18 && !this.isPrimaryGuardian(person))
        );
      });
    },
    variables() {
      return {
        id: this.willId,
        meta: this.willMeta.toArray(),
      };
    },
  },
  watch: {
    backupGuardians(guardians) {
      if (guardians && !this.selectedBackupGuardian.length) {
        this.selectedBackupGuardian = this.people
          .filter((person) => {
            return guardians.find(
              (guardian) => guardian.person.id === person.id
            );
          })
          .map((person) => person.id);
      }
    },
  },
  methods: {
    ...mapActions('person', ['addPerson']),
    async submit() {
      for (const person of this.people) {
        const isBackupGuardian = this.isBackupGuardian(person);
        const isSelected = this.selectedBackupGuardian.includes(person.id);

        if (this.willMeta.has_backup_guardian && isSelected) {
          if (!isBackupGuardian) {
            await this.addGuardian(person, 'backup');
          }
        } else if (isBackupGuardian) {
          await this.removeGuardian(person);
        }
      }

      this.$router.push({
        path: this.localePath('/will/guardians/your-pets'),
      });
    },
  },
};
</script>
