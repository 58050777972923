<template>
  <Modal size="large" @closeModal="closeModal">
    <template #header>
      <Headline flush :level="2">
        {{ $t('components.checkoutModal.title') }}
      </Headline>
    </template>
    <template #body>
      <Alert level="medium" theme="warning" icon="warning">
        {{ $t('components.checkoutModal.alertMessage') }}
      </Alert>
      <p>
        {{ $t('components.checkoutModal.body.line1') }}
      </p>
      <p>
        {{ $t('components.checkoutModal.body.line2') }}
      </p>
      <Buttons layout="column">
        <RuButton level="secondary" data-test="back-button" @click="closeModal">
          {{ $t('components.checkoutModal.buttons.back') }}
        </RuButton>
        <RuButton theme="danger" data-test="exit-button" @click="exitCheckout">
          {{ $t('components.checkoutModal.buttons.exit') }}
        </RuButton>
      </Buttons>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/molecules/Modal';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Alert from '@/components/molecules/Alert';

export default {
  name: 'ComponentsOrganismsCheckoutModal',
  components: {
    Modal,
    Headline,
    Alert,
    Buttons,
    RuButton,
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'checkout_exit_modal_continue',
      });
    },
    exitCheckout() {
      this.$emit('confirmLeave');
      this.$emit('closeModal');
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'checkout_exit_modal_exit',
      });
      this.$router.push({ path: this.localePath('/') });
    },
  },
};
</script>
