<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      :auto-submit="!willMeta.has_gifts"
      @done="done"
    >
      <Headline :level="1">
        {{ $t('pages.will.gifts.leaveGift') }}
        <GlossaryTooltip term="gift">
          {{ $t('texts.words.gift') }}
        </GlossaryTooltip>
        ? 🎁
      </Headline>
      <p class="mb-8 sm:mb-12 text-2xl">
        {{ $t('pages.will.gifts.giftInfo') }}
      </p>
      <Tip>
        {{ $t('pages.will.gifts.specificGifts') }}
      </Tip>
      <LargeRadioButtons
        id="has_gifts"
        v-model="willMeta.has_gifts"
        :options="[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ]"
      />
      <template v-if="willMeta.has_gifts">
        <Headline :level="2">
          {{ $t('pages.will.gifts.giftType') }}
        </Headline>
        <Tip>
          {{ $t('pages.will.gifts.proportionalGifts') }}
        </Tip>
        <MetaSlot
          v-for="(gift, index) in gifts"
          :key="index"
          v-slot="{ giftMeta, personMeta, charityMeta }"
          :gift-meta="gift.meta"
          :person-meta="gift.person && gift.person.meta"
          :charity-meta="gift.charity && gift.charity.meta"
        >
          <DataCard
            :title="
              giftMeta.type === 'money'
                ? `$${giftMeta.amount}`
                : giftMeta.description
            "
            :description="
              personMeta.full_name ||
              charityMeta.displayName ||
              charityMeta.name
            "
            :icon="giftMeta.type === 'money' ? 'wallet' : 'gift'"
            edit
            remove
            type="gift"
            @edit="editGift(gift)"
            @remove="removeGift(gift)"
          >
            <Box v-if="giftMeta.note" shadow>
              <Headline flush :level="3">
                <em>{{ $t('texts.sentences.youWrote') }}</em>
              </Headline>
              <p>
                {{ giftMeta.note }}
              </p>
            </Box>
          </DataCard>
        </MetaSlot>
        <Buttons layout="stretch">
          <RuButton size="large" level="secondary" @click="addGift">
            + {{ $t('forms.labels.addAGift') }}
          </RuButton>
        </Buttons>
        <GiftWizard v-if="showGiftWizard" />
      </template>
    </AdiForm>
    <GlossaryPanel />
  </ModuleStep>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Box from '@/components/atoms/Box';
import DataCard from '@/components/DataCard';
import AdiForm from '@/components/Form';
import GlossaryTooltip from '@/components/GlossaryTooltip';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import MetaSlot from '@/components/MetaSlot';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import GiftWizard from '@/components/GiftWizard';
import GlossaryPanel from '@/components/GlossaryPanel';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { gifts, user, will } from '@/mixins/apollo';
import { giftsToGiftsAnalyticsParams } from '@/utilities/charity';

export default {
  name: 'PagesAffiliateWillGiftsIndex',
  components: {
    Box,
    DataCard,
    AdiForm,
    GlossaryTooltip,
    LargeRadioButtons,
    MetaSlot,
    ModuleStep,
    Tip,
    Headline,
    GiftWizard,
    GlossaryPanel,
    Buttons,
    RuButton,
  },
  mixins: [gifts, user, will],
  computed: {
    ...mapGetters('ruach', ['shouldShowCharityConsentPage']),
    ...mapGetters('gift', ['showGiftWizard']),
    isComplete() {
      return !!(this.willMeta.has_gifts === false || this.gifts.length);
    },
  },
  methods: {
    ...mapActions('gift', ['addGift', 'editGift']),
    async done() {
      if (this.willMeta.has_gifts === false && this.gifts.length) {
        await this.removeAllGifts();
      }

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 5 (Gifts)',
      });

      const noCharity = !this.gifts.find((g) => g.charity !== null);

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift: !noCharity,
      });

      if (noCharity) {
        this.$router.push({
          path: this.localePath('/will/gifts/include-charity'),
        });
      } else {
        const analyticsGiftsParams = giftsToGiftsAnalyticsParams(this.gifts);
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'gifts_added',
          props: {
            gifts: analyticsGiftsParams,
          },
        });
        this.willMeta.charity_in_gifts = 'true';
        await this.updateWillMeta();
        await this.refetchWill();
        await this.$updateShouldShowCharityConsent();
        const path = this.localePath(
          this.shouldShowCharityConsentPage ? '/will/charity-consent' : '/will'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
</script>
