<template>
  <div v-if="endOfLifeCategory" class="grid">
    <div class="cell cell__8/12">
      <AdminBackLink to="/admin/end-of-life-categories">
        End-of-Life Categories
      </AdminBackLink>
      <AdminCard :title="`Category: ${endOfLifeCategory.name}`">
        <div class="admin-card__buttons">
          <BaseButton
            class="admin-card__button admin-card__button--save"
            :disabled="loading"
            @click="updateEndOfLifeCategoryData"
          >
            <div class="admin-card__button-content">
              <BaseIcon id="tick" size="small" classes="mr-2" />
              Save
            </div>
          </BaseButton>
        </div>
        <ValidationObserver ref="observer">
          <AdminCardRow
            id="name"
            v-model="endOfLifeCategory.name"
            type="text"
            label="Name"
            rules="required"
            :disabled="loading"
          />
        </ValidationObserver>
        <ValidationObserver ref="observer">
          <AdminCardRow
            id="slug"
            v-model="endOfLifeCategory.slug"
            type="text"
            label="Slug"
            rules="required"
            disabled
          />
        </ValidationObserver>
        <AdminCardRow
          id="parentCategoryId"
          v-model="endOfLifeCategory.parentCategoryId"
          type="select"
          label="Parent category"
          :options="parentCategoryOptions"
          :disabled="loading"
        />
        <AdminCardRow
          id="description"
          v-model="endOfLifeCategory.description"
          type="text"
          label="Description"
          :disabled="loading"
        />
        <AdminCardRow
          id="explanation"
          v-model="endOfLifeCategory.explanation"
          type="textarea"
          label="Explanation"
          :disabled="loading"
        />
      </AdminCard>
    </div>
    <div class="cell cell__4/12">
      <AdminCard>
        <AdminCardSection title="History">
          <div class="text-sm">
            Updated at:
            {{ $formatDate(endOfLifeCategory.updatedAt) }}
          </div>
          <div class="text-sm">
            Created at:
            {{ $formatDate(endOfLifeCategory.createdAt) }}
          </div>
        </AdminCardSection>
      </AdminCard>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import AdminBackLink from '@/components/admin/BackLink';
import AdminCard from '@/components/admin/Card';
import BaseButton from '@/components/BaseButton';
import AdminCardRow from '@/components/admin/CardRow';
import BaseIcon from '@/components/BaseIcon';
import AdminCardSection from '@/components/admin/CardSection';
import endOfLife from '@/mixins/end-of-life';
import endOfLifeAdmin from '@/mixins/end-of-life-admin';
import { recursiveRemoveKey } from '@/utilities';
export default {
  name: 'PagesAffiliateAdminEndOfLifeCategoriesId',
  components: {
    AdminBackLink,
    AdminCard,
    BaseButton,
    BaseIcon,
    AdminCardRow,
    AdminCardSection,
    ValidationObserver,
  },
  mixins: [endOfLife, endOfLifeAdmin],
  layout: 'admin',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('end-of-life-categories', ['endOfLifeCategories']),
    parentCategoryOptions() {
      return (
        Object.keys(this.endOfLifeCategories)
          .map((category) => ({
            text: this.endOfLifeCategories[category].name,
            value: this.endOfLifeCategories[category].id,
          }))
          .sort((a, b) => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          }) || []
      );
    },
  },
  created() {
    this.getEndOfLifeCategories();
  },
  methods: {
    ...mapActions('end-of-life-categories', ['getEndOfLifeCategories']),
    async updateEndOfLifeCategoryData() {
      this.loading = true;
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());
      if (!isValid) {
        this.loading = false;
        return;
      }
      const newEndOfLifeCategory = { ...this.endOfLifeCategory };
      recursiveRemoveKey(newEndOfLifeCategory, '__typename');
      recursiveRemoveKey(newEndOfLifeCategory, 'createdAt');
      recursiveRemoveKey(newEndOfLifeCategory, 'updatedAt');
      await this.updateEndOfLifeCategory(newEndOfLifeCategory);
      this.loading = false;
      this.getEndOfLifeCategories(true);
    },
  },
};
</script>
