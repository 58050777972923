<template>
  <Panel
    v-if="showPetPanel"
    class="pet-panel"
    :title="$t('components.petPanel.title')"
    @close="close"
  >
    <AdiForm
      v-slot="{ loading }"
      :disabled="!name || !type"
      :mutation="mutation"
      :variables="variables"
      :submit-label="$t('components.petPanel.save')"
      @update="update"
      @done="close"
    >
      <FormControl
        id="pet_name"
        v-model="name"
        :disabled="loading"
        :text="$t('components.petPanel.petNameInput.label')"
        :placeholder="$t('components.petPanel.petNameInput.placeholder')"
        rules="required|max:50"
      />
      <FormControl
        id="pet_type"
        v-model="type"
        :disabled="loading"
        :text="$t('components.petPanel.petTypeInput.label')"
        :placeholder="$t('components.petPanel.petTypeInput.placeholder')"
        rules="required|max:50"
      />
    </AdiForm>
  </Panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import Panel from '@/components/organisms/Panel';
import AdiForm from '@/components/Form';
import FormControl from '@/components/molecules/FormControl';

import { pets, user } from '@/mixins/apollo';
import { objectToMetaArray } from '@/utilities';

export default {
  name: 'ComponentsPetPanel',
  components: {
    Panel,
    AdiForm,
    FormControl,
  },
  mixins: [pets, user],
  computed: {
    ...mapFields('pet', ['fields.name', 'fields.type']),
    ...mapGetters('pet', ['id', 'fields', 'showPetPanel']),
    mutation() {
      return this.id ? this.UPDATE_PET_MUTATION : this.ADD_PET_MUTATION;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
        willId: this.willId,
      };

      if (this.id) {
        variables.id = this.id;
      }

      return variables;
    },
  },
  methods: {
    ...mapActions('pet', ['setShowPetPanel']),
    close() {
      this.setShowPetPanel(false);
    },
    update(store, { data: { addPet } }) {
      if (addPet) {
        const data = store.readQuery(this.getPetsQuery);
        data.getPets.pets.push(addPet.pet);

        store.writeQuery({
          ...this.getPetsQuery,
          data,
        });
      }
    },
  },
};
</script>
