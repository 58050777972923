<template>
  <div class="ru:admin-card__row" :class="`${rowClass} ${changeClass}`">
    <div v-if="type !== 'button'" :class="labelClass">{{ label }}</div>
    <div :class="fieldClass">
      <slot v-if="type === 'custom'" />
      <template v-else>
        <SelectInput
          v-if="type === 'select'"
          :id="id"
          v-model="modelValue"
          class="rounded"
          :disabled="disabled"
          :options="selectOptions"
          :required="rules === 'required'"
          compact
          @change="update"
        />
        <BaseRadio
          v-else-if="type === 'radio'"
          :id="id"
          v-model="modelValue"
          :options="options"
          compact
          @change="update"
        />
        <BaseSwitch
          v-else-if="type === 'switch'"
          :id="id"
          v-model="modelValue"
          @change="update"
        />
        <button
          v-else-if="type === 'button'"
          class="flex text-slate-100 underline hover:no-underline text-left"
          @click="$emit('click')"
        >
          <BaseIcon
            :id="icon"
            classes="text-blue-200 mt-1 mr-2 flex-shrink-0"
            size="small"
          />
          {{ label }}
        </button>
        <TextInput
          v-else
          :id="id"
          v-model="modelValue"
          :type="type"
          class="rounded"
          :rules="rules"
          :disabled="disabled"
          compact
          @input="update"
        />
        <div v-if="!inputIsValid" :class="errorClass">{{ title }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';

import BaseIcon from '@/components/BaseIcon';
import BaseRadio from '@/components/BaseRadio';
import TextInput from '@/components/TextInput';
import SelectInput from '@/components/SelectInput';
import BaseSwitch from '@/components/BaseSwitch';

import { isValidDateString } from '@/utilities';

export default {
  name: 'ComponentsAdminCardRow',
  components: {
    BaseIcon,
    BaseRadio,
    TextInput,
    SelectInput,
    BaseSwitch,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    options: {
      default: () => [],
      type: Array,
    },
    rules: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    value: {
      default: '',
      type: [String, Boolean, Number],
    },
    vertical: {
      default: false,
      type: Boolean,
    },
    pattern: {
      default: null,
      type: String,
    },
    title: {
      default: null,
      type: String,
    },
    format: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      modelValue: this.formatValue(this.value),
    };
  },
  computed: {
    inputIsValid() {
      if (this.pattern && this.modelValue) {
        const regex = new RegExp(this.pattern);
        return regex.test(this.modelValue);
      }
      return true;
    },
    errorClass() {
      return this.inputIsValid ? '' : '--invalid';
    },
    isWide() {
      return this.vertical || this.type === 'button';
    },
    labelClass() {
      return !this.isWide ? 'p-2 w-2/5 text-right' : 'w-full px-2 py-1';
    },
    fieldClass() {
      return !this.isWide ? 'p-2 w-3/5' : 'w-full px-2 py-1';
    },
    rowClass() {
      return !this.isWide ? 'flex items-center' : 'py-2';
    },
    changeClass() {
      return this.originalValue !== this.value
        ? '--updated shadow-inset text-orange-400'
        : '';
    },
    selectOptions() {
      let options = [];
      if (typeof this.options[0] === 'string') {
        options = this.options.map((option) => {
          return {
            value: option,
            text: option,
          };
        });
      } else {
        options = this.options;
      }

      if (options[0]?.value && this.rules !== 'required') {
        options.unshift({
          value: null,
          text: null,
        });
      }
      return options;
    },
  },
  created() {
    this.originalValue = this.value;
  },
  methods: {
    update() {
      if ([this.type, this.format].includes('number')) {
        this.$emit('input', parseInt(this.modelValue));
      } else if (this.type === 'date') {
        this.$emit('input', this.modelValue || null);
      } else {
        this.$emit('input', this.modelValue);
      }
    },
    getDateValue(value) {
      if (isValidDateString(value)) {
        return value;
      }
      return format(new Date(parseInt(value)), 'yyyy-MM-dd');
    },
    formatValue(value) {
      switch (this.type) {
        case 'date':
          return value ? this.getDateValue(value) : value;
        default:
          return value;
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &admin-card {
    &__row {
      border-top: 1px solid --rgba(iron);
      margin: 0 #{calc(var(--base-margin-small) * -1)};

      &:nth-child(2n-1) {
        background: --rgba(iron, 0.2);
        border-radius: 0 0 var(--border-radius);
      }

      &:is(#{$mf}updated) {
        background: linear-gradient(
          -45deg,
          #ffca89 30px,
          #fff 30px,
          #fff calc(100% - 10px),
          #ffca89 calc(100% - 10px)
        );
      }

      &:is(#{$mf}invalid) {
        color: --rgba(red);
      }
    }
  }
}
</style>
