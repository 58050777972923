<template>
  <ModuleStep :index="3" :prev-step="prevStep">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :submit-label="buttonText"
      @done="onDone"
    >
      <Headline :level="1">
        {{ $t('pages.will.funeral.otherRequests') }}
      </Headline>
      <p>
        {{ $t('pages.will.funeral.dayWishes') }}
      </p>
      <Tip>
        {{ $t('pages.will.funeral.suggestions') }}
      </Tip>
      <FormControl
        id="funeral_note"
        v-model="willMeta.funeral_note"
        :disabled="loading"
        rules="max:3000"
        :placeholder="$t('forms.placeholders.iWouldLike')"
        type="textarea"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillFuneralOtherRequests',
  components: {
    AdiForm,
    ModuleStep,
    FormControl,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    buttonText() {
      return this.willMeta.funeral_note
        ? this.$t('forms.labels.next')
        : this.$t('forms.labels.skip');
    },
    prevStep() {
      return ['cremation', 'burial'].includes(this.willMeta.funeral_type)
        ? '/will/funeral/location'
        : '/will/funeral';
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 7 (Funeral)',
      });
      this.$router.push({
        path: this.localePath('/will'),
      });
    },
  },
};
</script>
