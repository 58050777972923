var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"prev-step":_vm.previous,"index":2}},[_c('AdiForm',{attrs:{"disabled":!_vm.isComplete},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [(_vm.askAttorneyDecisions)?[_c('Headline',{attrs:{"level":1}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.primaryDecisions.headline'))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.primaryDecisions.jointly'))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.primaryDecisions.severally'))+"\n      ")]),_vm._v(" "),(['qld', 'act', 'sa'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.primaryDecisions.affectOtherAttorneys'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.primaryDecisions.prosCons'))+"\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"medical_attorney_decisions","layout":"column","options":[
          {
            label: _vm.$t(
              'pages.poa.medical.primaryDecisions.medicalAttorneyDecisions.jointly'
            ),
            value: 'jointly',
          },
          {
            label: _vm.$t(
              'pages.poa.medical.primaryDecisions.medicalAttorneyDecisions.severally'
            ),
            value: 'jointly and severally',
          },
        ]},model:{value:(_vm.poaMedicalMeta.attorney_decisions),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "attorney_decisions", $$v)},expression:"poaMedicalMeta.attorney_decisions"}})]:_vm._e(),_vm._v(" "),(_vm.askAttorneyDecisionsOther)?[_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t(
            'pages.poa.medical.primaryDecisions.jointDecisionMakerUnableToAct'
          ))+"\n      ")]),_vm._v(" "),_c('Tip',[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.primaryDecisions.exampleUnwellOrDead'))+"\n      ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"medical_attorney_decisions_other","layout":"column","options":[
          {
            label: _vm.$t(
              'pages.poa.medical.primaryDecisions.medicalAttorneyDecisionsOther.other'
            ),
            value: 'other',
          },
          {
            label: _vm.$t(
              'pages.poa.medical.primaryDecisions.medicalAttorneyDecisionsOther.none'
            ),
            value: 'none',
          },
        ]},model:{value:(_vm.poaMedicalMeta.attorney_decisions_other),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "attorney_decisions_other", $$v)},expression:"poaMedicalMeta.attorney_decisions_other"}})]:_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }