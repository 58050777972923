<template>
  <div
    class="ru:tooltip"
    :class="{
      '--inverted': inverted,
    }"
    @mouseenter="updateTipPlacement"
  >
    <slot />
    <div class="ru:tooltip__text" :class="placementClass">{{ tooltip }}</div>
  </div>
</template>

<script>
export default {
  name: 'ComponentsAtomsTooltip',
  props: {
    tooltip: {
      type: String,
      required: true,
    },
    inverted: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      placement: 'left',
      closestScrollableElement: null,
    };
  },
  computed: {
    placementClass() {
      return `--${this.placement}`;
    },
  },
  mounted() {
    let parentElement = this.$el.parentElement;
    while (parentElement) {
      const { overflow } = window.getComputedStyle(parentElement);
      if (
        overflow
          .split(' ')
          .every((overflow) => overflow === 'auto' || overflow === 'scroll')
      ) {
        break;
      }
      parentElement = parentElement.parentElement;
    }
    this.closestScrollableElement = parentElement || document.body;
  },
  methods: {
    updateTipPlacement() {
      const windowCenter = this.closestScrollableElement.offsetWidth / 2;
      const tipContainerPosition =
        this.$el.offsetLeft + this.$el.scrollWidth / 2;
      this.placement = tipContainerPosition < windowCenter ? 'left' : 'right';
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &tooltip {
    display: inline-block;
    position: relative;

    &__text {
      background: --rgba(white);
      color: --rgba(basalt);
      transition: --out();
      font-size: 0.875rem;
      line-height: 1.125rem;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      z-index: $z-stack;
      bottom: #{calc(100% + 1rem)};
      padding: 1rem;
      width: 15rem;
      border-radius: 0.25rem 0.25rem 0;
      transform: translateY(1rem);

      &:is(#{$mf}left) {
        left: 0.75rem;

        &::after {
          background-image: linear-gradient(
            to bottom right,
            --rgba(white) 49%,
            transparent 51%
          );
          left: 0;
        }
      }
      &:is(#{$mf}right) {
        right: 0.75rem;

        &::after {
          background-image: linear-gradient(
            to bottom left,
            --rgba(white) 49%,
            transparent 51%
          );
          right: 0;
        }
      }

      &::before,
      &::after {
        display: block;
        content: '';
      }

      &::before {
        background: --rgba(white);
        position: absolute;
        inset: 0;
        border-radius: 0.25rem 0.25rem 0;
        box-shadow: var(--base-box-shadow);
        z-index: -1;
      }

      &::after {
        height: 0.75rem;
        width: 1.5rem;
        position: absolute;
        top: 100%;
        z-index: -1;
        filter: drop-shadow(--base-box-shadow);
      }
    }

    &:is(:hover, :active, :focus) &__text {
      transition: --in();
      opacity: 1;
      transform: none;
    }

    &:is(#{$mf}inverted) &__text {
      background: --rgba(basalt);
      color: --rgba(white);

      &:is(#{$mf}left) {
        &::after {
          background-image: linear-gradient(
            to bottom right,
            --rgba(basalt) 49%,
            transparent 51%
          );
        }
      }

      &:is(#{$mf}right) {
        &::after {
          background-image: linear-gradient(
            to bottom left,
            --rgba(basalt) 49%,
            transparent 51%
          );
        }
      }

      &::before {
        background: --rgba(basalt);
      }
    }
  }
}
</style>
