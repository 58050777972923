<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.mortuaryCare') }}
    </Headline>
    <p>
      {{ $t('pages.cremations.deceasedBodyContents') }}
    </p>
    <FormWrapper @submit="saveUpdatedDetails('initial')">
      <Headline :level="3">
        {{ $t('pages.cremations.medicalImplants') }}
      </Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
          <FormControl
            id="guestHasPacemaker"
            v-model="formData.guestHasPacemaker"
            type="select"
            :text="$t('forms.labels.cardiacPacemaker')"
            :options="YES_NO_UNSURE_OPTIONS"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
          <FormControl
            id="guestHasDefibrillator"
            v-model="formData.guestHasDefibrillator"
            type="select"
            :text="$t('forms.labels.cardiacDefibrillator')"
            :options="YES_NO_UNSURE_OPTIONS"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
          <FormControl
            id="guestHasDefibrillatorDrugInfusion"
            v-model="formData.guestHasDefibrillatorDrugInfusion"
            type="select"
            :text="$t('forms.labels.drugInfusionPump')"
            :options="YES_NO_UNSURE_OPTIONS"
          />
        </div>
      </div>
      <Headline :level="3">
        {{ $t('texts.sentences.authorisation') }}
      </Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12">
          {{ $t('pages.cremations.authoriseHygienicPreparation') }}
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
          <FormControl
            id="isAuthorizedForPrep"
            v-model="formData.isAuthorizedForPrep"
            type="select"
            :text="$t('forms.labels.authorisePreparation')"
            :options="YES_NO_OPTIONS"
            format="boolean"
          />
        </div>
        <div class="cell cell__12/12">
          {{ $t('pages.cremations.informRelatives') }}
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__4/12--lg">
          <FormControl
            id="isRelativeInformed"
            v-model="formData.isRelativeInformed"
            type="select"
            :text="$t('forms.labels.relativesInformed')"
            :options="YES_NO_OPTIONS"
            format="boolean"
          />
        </div>
        <div
          v-if="showUnauthorisedUninformedWarning"
          class="cell cell__12/12 flex items-center"
        >
          <Badge color="white" background="orange">
            <BaseIcon id="alert" size="small" />
          </Badge>
          <span class="text-warning ml-2">
            {{ $t('pages.cremations.contactFuneralArranger') }}
          </span>
        </div>
        <div class="cell cell__12/12">
          {{ $t('pages.cremations.objectionToCremation') }}
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="hasRelativeExpressedObjection"
            v-model="formData.hasRelativeExpressedObjection"
            type="select"
            :text="$t('forms.labels.relativesObjection')"
            :options="YES_NO_OPTIONS"
            format="boolean"
          />
        </div>
        <div
          v-if="showRelativeReasonForObjection"
          class="cell cell__12/12 cell__8/12--lg"
        >
          <FormControl
            id="relativeReasonForObjection"
            v-model="formData.relativeReasonForObjection"
            type="text"
            :text="$t('forms.labels.reasonForObjection')"
          />
        </div>
      </div>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="toggleConfirmModal">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton
          background="wine"
          color="white"
          @click="saveUpdatedDetails('initial')"
        >
          {{ $t('forms.labels.accept') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import BaseIcon from '@/components/BaseIcon';
import Badge from '@/components/atoms/Badge';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';

export default {
  name: 'PagesAffiliateCremationsAtNeedCareId',
  components: {
    BaseModal,
    BaseIcon,
    Badge,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    FormControl,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      formData: {
        guestHasPacemaker: null,
        guestHasDefibrillator: null,
        guestHasDefibrillatorDrugInfusion: null,
        isAuthorizedForPrep: null,
        isRelativeInformed: null,
        hasRelativeExpressedObjection: null,
        relativeReasonForObjection: null,
      },
    };
  },
  computed: {
    nextPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
    prevPage() {
      return this.localePath(
        `/cremations/at-need/passing/${this.cremationRequestId}`
      );
    },
  },
};
</script>
