<template>
  <div class="ru:container">
    <div v-if="willMeta && willStatus" class="relative z-10 mb-8">
      <SubscriptionStatusAlert product-page="will" />
      <Alert
        v-if="willStatus === 'IN_PROGRESS' && allModulesCompleted && verified"
        theme="info"
        level="medium"
      >
        <div class="flex items-baseline lg:items-center w-full">
          <div class="mr-2 opacity-50">
            <BaseGlyph id="circle-tick-large" :large="true" />
          </div>
          <div class="lg:flex flex-grow">
            <span>
              {{ $t('pages.will.willReadyToSubmit') }}
            </span>
            <BaseButton
              class="mt-2 lg:mt-0 lg:ml-auto font-normal underline text-left"
              @click="setShowSubmitHelperModal(true)"
            >
              {{ $t('pages.will.whySubmitFirstUpdateLater') }}
            </BaseButton>
          </div>
        </div>
      </Alert>

      <Alert v-if="showVerifyReminder" theme="warning" level="medium">
        <div class="w-full">
          <span>
            {{ $t('pages.will.verifyEmailToDownloadOrUpload') }}
          </span>
          <nuxt-link
            :to="localePath('/dashboard/account-settings')"
            class="underline"
          >
            {{ $t('texts.sentences.goToAccountSettings') }}
          </nuxt-link>
        </div>
      </Alert>
      <div
        class="flex flex-col md:flex-row justify-between items-start md:items-center"
      >
        <Headline big :level="1">
          <small>{{ fullName }}</small>
          {{ $t('texts.sentences.yourWill') }}
          <WillStatus :ready="allModulesCompleted" />.
        </Headline>
        <div
          v-if="willStatus"
          id="submit-btn"
          class="fixed md:static left-0 right-0 bottom-0 flex-shrink-0 py-2 pl-5 zendesk-offset md:p-0 bg-white md:bg-transparent shadow-md md:shadow-none"
        >
          <div class="w-full">
            <WillSubmitButton
              v-if="showSubmitButton"
              :ready="allModulesCompleted"
              :has-subscription-expired="hasExpiredSubscription"
            />
            <WillDownloadComboButton v-else />
          </div>
        </div>
      </div>
      <Tip v-if="tipText" large>
        {{ tipText }}
      </Tip>
    </div>
    <Slider v-if="willMeta" :slide="currentSlide">
      <SliderItem v-for="(module, moduleIndex) in modules" :key="moduleIndex">
        <ModuleCard
          :title="module.displayName || module.name"
          :description="module.description"
          :duration="module.duration"
          :issues="willStatus === 'FLAGGED' && !!willIssues[module.name]"
          :number="moduleIndex + 1"
          :loading="isLoading[module.name]"
          :progress="progress[module.name]"
          :ready="isReady[module.name]"
          :to="to[module.name]"
          :locked="hasExpiredSubscription"
        >
          <template v-if="module.name === 'About Yourself'">
            <ModuleCardText
              :text="`${willMeta.name_first} ${willMeta.name_last}`"
            />
            <MetaSlot
              v-if="partners.length"
              v-slot="{ meta }"
              :meta="partners[0].meta"
            >
              <ModuleCardText :text="meta.full_name" />
            </MetaSlot>
          </template>
          <template v-if="module.name === 'Guardians'">
            <template v-if="children.length && guardians.length">
              <ModuleCardText glyph="child" light text="Children" />
              <ModuleCardText :text="guardianNames" />
            </template>
            <MetaSlot
              v-for="(pet, index) in petsWithGuardians.slice(0, maxPets)"
              :key="index"
              v-slot="{ petMeta, personMeta }"
              :pet-meta="pet.meta"
              :person-meta="pet.person.meta"
            >
              <ModuleCardText glyph="paw" light :text="petMeta.name" />
              <ModuleCardText :text="personMeta.full_name" />
            </MetaSlot>
            <ModuleCardText
              v-if="petsWithGuardians.length > maxPets"
              light
              :text="`+ ${petsWithGuardians.length - maxPets} ${$t(
                'texts.words.more'
              )}`"
            />
            <template
              v-if="!(guardians.length && petsWithGuardians) && children.length"
            >
              <ModuleCardText
                light
                :text="$upperFirst($t('texts.words.children'))"
              />
              <MetaSlot
                v-for="(child, index) in children.slice(0, maxChildren)"
                :key="index"
                v-slot="{ childMeta }"
                :child-meta="child.meta"
              >
                <ModuleCardText :text="childMeta.full_name" />
              </MetaSlot>
              <ModuleCardText
                v-if="children.length > maxChildren"
                :text="`+ ${children.length - maxChildren} ${$t(
                  'texts.words.more'
                )}`"
              />
            </template>
            <template v-else>
              <ModuleCardText
                light
                :text="$t('texts.sentences.noneSpecified')"
              />
            </template>
          </template>
          <template v-if="module.name === 'Executors'">
            <template v-if="willMeta.executors_option !== 'friendsFamily'">
              <ModuleCardText
                :truncate="false"
                :text="$t('titles.safewillProfessionalExecutors')"
              />
            </template>
            <template v-if="primaryExecutors.length">
              <ModuleCardText
                :text="
                  willMeta.executors_option === 'friendsFamilyAndProfessional'
                    ? $t('texts.sentences.primaryContact')
                    : $upperFirst($t('texts.words.primary'))
                "
                light
              />
              <ModuleCardText :text="primaryExecutorNames" />
            </template>
            <template v-if="backupExecutors.length">
              <ModuleCardText
                light
                :text="$upperFirst($t('texts.words.backup'))"
              />
              <ModuleCardText :text="backupExecutorNames" />
            </template>
          </template>
          <template v-if="module.name === 'Estate'">
            <template
              v-if="willMeta.estate_split !== null && beneficiaries.length"
            >
              <div
                v-if="will && will.isPrimaryEstateSplitEvenly"
                class="md:text-lg text-black"
              >
                Split evenly to
              </div>
              <div
                v-for="(beneficiary, index) in beneficiaries.slice(0, maxItems)"
                :key="index"
              >
                <ModuleCardText
                  v-if="beneficiary.person"
                  :percentage="
                    will && will.isPrimaryEstateSplitEvenly
                      ? null
                      : `${beneficiary.distribution}%`
                  "
                  :text="beneficiary.person.meta.full_name"
                />
                <ModuleCardText
                  v-else-if="beneficiary.charity"
                  :percentage="
                    will && will.isPrimaryEstateSplitEvenly
                      ? null
                      : `${beneficiary.distribution}%`
                  "
                  :text="
                    beneficiary.charity.meta.displayName ||
                    beneficiary.charity.meta.name
                  "
                />
              </div>
              <ModuleCardText
                v-if="beneficiaries.length > maxItems"
                light
                :text="`+ ${beneficiaries.length - maxItems} ${$t(
                  'texts.words.more'
                )}`"
              />
            </template>
            <template v-else>
              {{ $t('texts.sentences.noBeneficiaries') }}
            </template>
          </template>
          <template v-if="module.name === 'Gifts'">
            <MetaSlot
              v-for="(gift, index) in gifts.slice(0, maxPeople)"
              :key="index"
              v-slot="{ giftMeta, personMeta, charityMeta }"
              :gift-meta="gift.meta"
              :person-meta="gift.person && gift.person.meta"
              :charity-meta="gift.charity && gift.charity.meta"
            >
              <ModuleCardText
                light
                :text="
                  giftMeta.type === 'money'
                    ? `$${giftMeta.amount}`
                    : giftMeta.description
                "
              />
              <ModuleCardText v-if="gift.person" :text="personMeta.full_name" />
              <ModuleCardText
                v-if="gift.charity"
                :text="charityMeta.displayName || charityMeta.name"
              />
            </MetaSlot>
            <ModuleCardText
              v-if="gifts.length > maxPeople"
              light
              :text="`+ ${gifts.length - maxPeople} more`"
            />
            <ModuleCardText
              v-else-if="gifts.length === 0"
              :text="$t('texts.sentences.noGifts')"
            />
          </template>
          <template v-if="module.name === 'Assets'">
            <div v-if="assets.length">
              <MetaSlot
                v-for="(asset, index) in assets.slice(0, maxItems / 2)"
                :key="index"
                v-slot="{ meta }"
                :meta="asset && asset.meta"
              >
                <ModuleCardText :text="meta.description" />
              </MetaSlot>
              <ModuleCardText
                v-if="assets.length > maxItems"
                light
                :text="`+ ${assets.length - maxItems / 2} ${$t(
                  'texts.words.more'
                )}`"
              />
            </div>
            <div v-else>{{ $t('texts.sentences.noAssets') }}</div>
            <div v-if="liabilities.length">
              <MetaSlot
                v-for="(liability, index) in liabilities.slice(0, maxItems / 2)"
                :key="index"
                v-slot="{ meta }"
                :meta="liability && liability.meta"
              >
                <ModuleCardText :text="meta.description" />
              </MetaSlot>
              <ModuleCardText
                v-if="liabilities.length > maxItems"
                light
                :text="`+ ${liabilities.length - maxItems / 2} ${$t(
                  'texts.words.more'
                )}`"
              />
            </div>
            <div v-else>{{ $t('texts.sentences.noLiabilities') }}</div>
          </template>
          <template v-if="module.name === 'Liabilities'">
            <template v-if="liabilities.length">
              <MetaSlot
                v-for="(liability, index) in liabilities.slice(0, maxItems)"
                :key="index"
                v-slot="{ meta }"
                :meta="liability && liability.meta"
              >
                <ModuleCardText :text="meta.description" />
              </MetaSlot>
              <ModuleCardText
                v-if="liabilities.length > maxItems"
                light
                :text="`+ ${liabilities.length - maxItems} ${$t(
                  'texts.words.more'
                )}`"
              />
            </template>
            <template v-else>
              {{ $t('texts.sentences.noLiabilities') }}
            </template>
          </template>
          <template v-if="module.name === 'Funeral'">
            <ModuleCardText
              light
              :text="$t('texts.sentences.youWouldLikeTo')"
            />
            <ModuleCardText :text="funeralText" />
          </template>
        </ModuleCard>
      </SliderItem>
    </Slider>
    <WelcomeModal />
    <FlaggedWillAttentionModal />
    <UnlockWillModal />
    <SubmitHelperModal />
    <SubscriptionExpiredModal />
  </div>
</template>

<script>
import kebabCase from 'lodash/kebabCase';
import detectIt from 'detect-it';
import { mapActions, mapGetters } from 'vuex';
import cookies from 'js-cookie';

import Alert from '@/components/molecules/Alert';
import BaseButton from '@/components/BaseButton';
import BaseGlyph from '@/components/BaseGlyph';
import MetaSlot from '@/components/MetaSlot';
import ModuleCard from '@/components/ModuleCard';
import ModuleCardText from '@/components/ModuleCardText';
import Slider from '@/components/Slider';
import SliderItem from '@/components/SliderItem';
import WillStatus from '@/components/organisms/WillStatus';
import Tip from '@/components/molecules/Tip';
import WillDownloadComboButton from '@/components/WillDownloadComboButton';
import WillSubmitButton from '@/components/WillSubmitButton';
import WelcomeModal from '@/components/WelcomeModal';
import FlaggedWillAttentionModal from '@/components/FlaggedWillAttentionModal';
import UnlockWillModal from '@/components/UnlockWillModal';
import SubmitHelperModal from '@/components/SubmitHelperModal';
import SubscriptionExpiredModal from '@/components/SubscriptionExpiredModal';
import SubscriptionStatusAlert from '@/components/organisms/SubscriptionStatusAlert';
import Headline from '@/components/atoms/Headline';

import willProgress from '@/mixins/will-progress';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliateWillIndex',
  components: {
    Alert,
    BaseButton,
    BaseGlyph,
    MetaSlot,
    ModuleCard,
    ModuleCardText,
    Slider,
    SliderItem,
    WillStatus,
    Tip,
    WillDownloadComboButton,
    WillSubmitButton,
    WelcomeModal,
    FlaggedWillAttentionModal,
    UnlockWillModal,
    SubmitHelperModal,
    SubscriptionExpiredModal,
    SubscriptionStatusAlert,
    Headline,
  },
  mixins: [willProgress],
  data() {
    return {
      maxItems: 4,
      maxPeople: 2,
      maxChildren: 2,
      tour: null,
    };
  },
  computed: {
    ...mapGetters(['verified', 'willStatus']),
    ...mapGetters('will', {
      showCompletionPage: 'showCompletionPage',
      progress: 'modulesProgress',
    }),
    currentSlide() {
      const incompleteIndex = Object.values(this.progress).findIndex(
        (progress) => progress !== 1
      );

      return incompleteIndex > -1 ? Math.max(0, incompleteIndex - 1) : 0;
    },
    isLoading() {
      const {
        assets,
        beneficiaries,
        executors,
        gifts,
        guardians,
        people,
        pets,
        will,
      } = this.$apollo.queries;
      return {
        'About Yourself': people.loading && will.loading,
        Guardians: guardians.loading && pets.loading && will.loading,
        Executors: executors.loading && will.loading,
        Estate: beneficiaries.loading && will.loading,
        Gifts: gifts.loading && will.loading,
        Assets: assets.loading && will.loading,
        Funeral: will.loading,
      };
    },
    isReady() {
      const isReady = {};
      this.modules.forEach((module, index) => {
        isReady[module.name] = this.modules.slice(0, index).every((mod) => {
          return this.progress[mod.name] === 1;
        });
      });
      return isReady;
    },
    to() {
      const to = {};
      this.modules.forEach((module) => {
        let uri = `/will/${kebabCase(module.name)}`;
        const step = module.steps[0];
        if (step?.length) {
          uri += `/${step}`;
        }
        to[module.name] = this.localePath(uri);
      });
      return to;
    },
    guardianNames() {
      const names = this.guardians.map((guardian) => {
        const meta = metaArrayToObject(guardian.person.meta);
        return meta.full_name;
      });
      return names.join(', ');
    },
    maxPets() {
      let maxPets = this.maxPeople;
      if (this.children.length) {
        maxPets--;
      }
      return maxPets;
    },
    petsWithGuardians() {
      return this.pets.filter((pet) => {
        return pet.person;
      });
    },
    primaryExecutorNames() {
      const names = this.primaryExecutors.map((executor) => {
        const meta = metaArrayToObject(executor.person.meta);
        return meta.full_name;
      });
      return names.join(' & ');
    },
    backupExecutorNames() {
      const names = this.backupExecutors.map((executor) => {
        const meta = metaArrayToObject(executor.person.meta);
        return meta.full_name;
      });
      return names.join(' & ');
    },
    funeralText() {
      let text = '';
      switch (this.willMeta.funeral_type) {
        case 'burial':
          text = this.$t('pages.will.beBuried');
          break;
        case 'cremation':
          text = this.$t('pages.will.beCremated');
          break;
        case 'donate':
          text = this.$t('pages.will.donateBodyToScience');
          break;
        case 'executor':
          text = this.$t('pages.will.letExecutorDecide');
          break;
        default:
          break;
      }
      return text;
    },
    tipText() {
      let text;
      switch (this.willStatus) {
        case 'AWAITING_APPROVAL':
          text = this.$t('pages.will.emailWillReadyCanChangeLater');
          break;
        case 'APPROVED':
          text = this.$t('pages.will.signAndWitnessInstructions');
          break;
        default:
          break;
      }
      return text;
    },
    showSubmitButton() {
      return ['IN_PROGRESS', 'FLAGGED'].includes(this.willStatus);
    },
    showVerifyReminder() {
      return (
        !this.verified &&
        (this.willStatus === 'AWAITING_APPROVAL' ||
          this.willStatus === 'APPROVED')
      );
    },
    isTouchDevice() {
      return (
        (typeof navigator !== 'undefined' && navigator.webdriver) ||
        detectIt.deviceType !== 'mouseOnly' ||
        ['base', 'sm'].includes(this.$mq)
      );
    },
    fullName() {
      let name = '';

      if ('name_first' in this.willMeta) {
        name = this.willMeta.name_first.trim();

        if (this.willMeta.name_middle) {
          name = `${name} ${this.willMeta.name_middle.trim()}`;
        }

        name = `${name} ${this.willMeta.name_last.trim()}`;
      }

      return name;
    },
    shouldRedirectToCompletionPage() {
      return (
        this.showCompletionPage &&
        this.$ff.willCompletionPageEnabled() &&
        this.willStatus === 'IN_PROGRESS' &&
        this.allModulesCompleted
      );
    },
  },
  watch: {
    async will() {
      if (
        this.will.status === 'APPROVED' &&
        this.willMeta.show_download_tour === undefined &&
        !this.tour
      ) {
        this.showDownloadTour();
      }

      if (!this.willMeta.referral_charity && !this.masquerading) {
        const referralCharity = cookies.get('referral_charity');

        if (referralCharity) {
          this.willMeta.referral_charity = referralCharity;
          await this.updateWillMeta();
          await this.addPartnerCharityWithRelatedCharities(referralCharity);
        }
      }
    },
    shouldRedirectToCompletionPage(newValue) {
      if (newValue) {
        this.redirectToWillCompletionPage();
      }
    },
  },
  mounted() {
    if (this.shouldRedirectToCompletionPage) {
      this.redirectToWillCompletionPage();
    }
  },
  methods: {
    ...mapActions('ui', ['setShowSubmitHelperModal']),
    updateCardDetails() {
      this.$router.push({ path: this.localePath(`/checkout/update-card`) });
    },
    unlockAccount() {
      this.$router.push({ path: this.localePath(`/checkout/unlock`) });
    },
    showDownloadTour() {
      this.tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
          },
          classes: 'class-1 class-2',
          scrollTo: {
            behavior: 'smooth',
            block: 'center',
          },
          modalOverlayOpeningPadding: 8,
          modalOverlayOpeningRadius: 4,
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 35] } }],
          },
          when: {
            show: () => {
              const currentStepElement = this.tour.currentStep.el;
              const header =
                currentStepElement.querySelector('.shepherd-header');
              const progress = document.createElement('span');
              const current =
                this.tour.steps.indexOf(this.tour.currentStep) + 1;
              progress.classList.add('shepherd-progress');
              progress.textContent = `Tip ${current} of ${this.tour.steps.length}`;
              header.insertBefore(
                progress,
                currentStepElement.querySelector('.shepherd-title')
              );
            },
          },
        },
      });
      this.tour.addStep({
        title: this.$t('texts.sentences.welcomeBack'),
        attachTo: !this.isTouchDevice && {
          element: '#submit-btn',
          on: 'left-start',
        },
        text: this.$t('pages.will.downloadWillAfterVerification', {
          where: this.isTouchDevice
            ? this.$t('texts.words.below')
            : this.$t('texts.words.here'),
        }),
        classes: 'step-1',
        buttons: [
          {
            action() {
              return this.next();
            },
            text: this.$t('forms.labels.next'),
          },
        ],
      });
      this.tour.addStep({
        attachTo: !this.isTouchDevice && {
          element: '#invite',
          on: 'left-start',
        },
        classes: 'step-2',
        title: this.$t('texts.sentences.referFriendsAndFamily'),
        text: this.$t('pages.will.discountForReferral'),
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: this.$t('forms.labels.back'),
          },
          {
            action() {
              return this.next();
            },
            text: this.$t('forms.labels.next'),
          },
        ],
      });
      this.tour.addStep({
        attachTo: !this.isTouchDevice && {
          element: '#upload',
          on: 'left-start',
        },
        classes: 'step-3',
        title: this.$t('texts.sentences.uploadSignedWill'),
        text: this.$t('pages.will.executorAccessToWill', {
          action: this.isTouchDevice
            ? this.$t('texts.sentences.clickOnMenuToLearnMore')
            : '',
        }),
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back',
          },
          {
            action() {
              return this.hide();
            },
            text: 'Finish',
          },
        ],
      });
      this.tour.start();
      this.willMeta.show_download_tour = false;
      this.updateWillMeta();
    },
    redirectToWillCompletionPage() {
      this.$router.push({
        path: this.localePath('/will/completion'),
        replace: true,
      });
    },
  },
};
</script>
