<template>
  <div class="ru:container ru:container--tight">
    <Checkout
      v-if="loaded"
      ref="checkoutComponent"
      :checkout-items="checkoutItems"
      @orderCreated="ordersCreationSuccessfulHandler"
    >
      <template #PRODUCTS_GIFT>
        <Headline :level="1">
          {{ $t('pages.checkout.giveThePerfectGift', { firstName }) }}
        </Headline>
        <Tip>
          {{ $t('pages.checkout.lastingGift') }}
        </Tip>
      </template>
    </Checkout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Checkout from '@/components/templates/Checkout';
import Headline from '@/components/atoms/Headline';
import Tip from '@/components/molecules/Tip';

import { user, will } from '@/mixins/apollo';
import { toggleObjectInArray } from '@/utilities';

export default {
  name: 'PagesAffiliateCheckoutGiftsIndex',
  components: {
    Checkout,
    Headline,
    Tip,
  },
  mixins: [user, will],
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('directory-person', ['userIsAustralian']),
    firstName() {
      return this.willMeta.name_first;
    },
    checkoutItems() {
      return [
        {
          product: 'WILL_GIFT',
          type: 'GIFT',
          numerable: true,
          description: this.$t('pages.checkout.purchaseWill'),
        },
        ...(this.userIsAustralian
          ? [
              {
                product: 'POA_GIFT',
                type: 'GIFT',
                numerable: true,
                description: this.$t(
                  'pages.checkout.purchaseFinancialMedicalAttorney'
                ),
              },
            ]
          : []),
      ];
    },
  },
  mounted() {
    if (this.isFreeWillWeek) {
      this.checkoutItems = toggleObjectInArray(
        { product: 'WILL_GIFT' },
        [...this.checkoutItems],
        'product'
      );
    }
    this.loaded = true;
  },
  methods: {
    ordersCreationSuccessfulHandler() {
      this.$router.push({
        path: this.localePath(
          `/checkout/gifts/${
            this.$ff.isShowingGiftsInCheckout() ? 'thank-you' : 'thanks'
          }`
        ),
      });
    },
  },
};
</script>
