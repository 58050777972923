<template>
  <BaseWizard
    :disabled="!complete"
    :headline="$t('components.giftWizard.title')"
    @close="close"
  >
    <AdiForm
      v-slot="{ loading }"
      :disabled="!complete"
      :submit-label="$t('components.giftWizard.saveGift')"
      @submit.prevent="submit"
    >
      <Headline :level="3">
        {{ $t('components.giftWizard.whatTypeOfGift') }}
      </Headline>
      <Buttons layout="stretch">
        <RuButton
          id="gift-type-possession"
          v-bind="tabAttributes('possession')"
          level="secondary"
          @click="setGiftType('possession')"
        >
          <Icon id="gift" />
          {{ $t('components.giftWizard.aPossession') }}
        </RuButton>
        <RuButton
          id="gift-type-money"
          v-bind="tabAttributes('money')"
          level="secondary"
          @click="setGiftType('money')"
        >
          <Icon id="wallet" />
          {{ $t('components.giftWizard.someMoney') }}
        </RuButton>
      </Buttons>
      <template v-if="type === 'possession'">
        <Headline :level="3">
          {{ $t('components.giftWizard.describeGift') }}
        </Headline>
        <Tip>
          {{ $t('components.giftWizard.describeGiftTooltip') }}
        </Tip>
        <FormControl
          id="description"
          v-model="description"
          :placeholder="$t('components.giftWizard.giftExample')"
          rules="required|max:1000"
        />
      </template>
      <template v-else-if="type === 'money'">
        <Headline :level="3">
          {{ $t('components.giftWizard.howMuchMoney') }}
        </Headline>
        <Tip>
          {{ $t('components.giftWizard.howMuchMoneyTooltip') }}
        </Tip>
        <FormControl
          id="amount"
          v-model="amount"
          rules="numeric|max_value:9999999999|max:10"
          placeholder="$"
        />
      </template>
      <template v-if="type">
        <Headline :level="2">
          {{ $t('components.giftWizard.whoAreYouGivingTo') }}
        </Headline>
        <Tip v-if="type === 'possession'">
          {{
            $t('components.giftWizard.whoAreYouGivingToTooltipPossessionType')
          }}
        </Tip>
        <Tip v-else-if="type === 'money'">
          {{ $t('components.giftWizard.whoAreYouGivingToTooltipMoneyType') }}
        </Tip>
        <Headline :level="3"> People </Headline>
        <PersonSelector
          v-model="recipients"
          label
          :loading="loading"
          :max="1"
          :people="people"
          type="beneficiary"
        />
        <Headline :level="3">
          {{ $t('components.giftWizard.charity') }}
        </Headline>
        <CharitySuggester
          v-if="showSuggestedCharities"
          v-model="recipients"
          :loading="loading"
          :selected-suggested-charities="recipients"
          :max="1"
        />
        <CharitySelector v-model="recipients" :loading="loading" :max="1" />
        <Buttons layout="stretch">
          <RuButton size="large" level="secondary" @click="addCharity">
            + {{ $t('components.giftWizard.addCharity') }}
          </RuButton>
        </Buttons>
        <CharityPanel />
      </template>
      <template v-if="type && (!isFreeWillWeek || note)">
        <Headline :level="2"> {{ $t('components.giftWizard.note') }} </Headline>
        <Tip> {{ $t('components.giftWizard.noteTooltip') }} </Tip>
        <FormControl
          id="note"
          v-model="note"
          :placeholder="$t('components.giftWizard.notePlaceholder')"
          type="textarea"
          rules="max:3000"
        />
      </template>
    </AdiForm>
  </BaseWizard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import BaseWizard from '@/components/BaseWizard';
import CharitySelector from '@/components/CharitySelector';
import CharitySuggester from '@/components/CharitySuggester';
import AdiForm from '@/components/Form';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import CharityPanel from '@/components/CharityPanel';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import FormControl from '@/components/molecules/FormControl';
import Icon from '@/components/atoms/Icon';

import { charities, gifts, people, user, will } from '@/mixins/apollo';
import { objectToMetaArray, metaArrayToObject } from '@/utilities';

export default {
  name: 'ComponentsGiftWizard',
  components: {
    BaseWizard,
    CharitySelector,
    CharitySuggester,
    AdiForm,
    PersonSelector,
    Tip,
    CharityPanel,
    Headline,
    Buttons,
    RuButton,
    FormControl,
    Icon,
  },
  mixins: [charities, gifts, people, user, will],
  data() {
    return {
      recipients: [],
      selectedSuggestedCharities: [],
    };
  },
  computed: {
    ...mapFields('gift', [
      'fields.type',
      'fields.description',
      'fields.amount',
      'fields.note',
    ]),
    ...mapGetters('gift', ['id', 'fields', 'person', 'charity']),
    ...mapGetters(['isFreeWillWeek']),
    mutation() {
      return this.id ? this.UPDATE_GIFT_MUTATION : this.ADD_GIFT_MUTATION;
    },
    showSuggestedCharities() {
      const hasReferral = !!this.willMeta.referral_charity;
      const hasAddedSuggestedCharity = this.charities.some((charity) => {
        const charityMeta = metaArrayToObject(charity.meta);
        return !!charityMeta.suggestionLocation;
      });
      return !hasReferral && !hasAddedSuggestedCharity;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
        person: null,
        charity: null,
        willId: this.willId,
      };

      if (this.id && !this.note) {
        variables.meta.push({ key: 'note', value: null });
      }

      if (this.id) {
        variables.id = this.id;
      } else {
        variables.willId = this.willId;
      }

      const recipient = this.recipients[0];

      if (recipient) {
        if (this.isPerson(recipient)) {
          variables.person = recipient;
        } else if (this.isCharity(recipient)) {
          variables.charity = recipient;
        }
      }

      return variables;
    },
    complete() {
      return (
        this.type &&
        ((this.type === 'money' && this.amount) ||
          (this.type === 'possession' && this.description)) &&
        this.recipients.length
      );
    },
  },
  mounted() {
    if (this.person) {
      this.recipients = [this.person.id];
    } else if (this.charity) {
      this.recipients = [this.charity.id];
    }
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    ...mapActions('gift', ['setShowGiftWizard']),
    ...mapActions('person', ['addPerson']),
    setGiftType(giftType) {
      this.type = giftType;
    },
    tabAttributes(giftType) {
      return {
        active: giftType === this.type,
      };
    },
    async submit() {
      const noRecipientID = !this.variables.person && !this.variables.charity;
      if (noRecipientID) {
        const newRecipientABN = this.recipients[0].abn;
        await this.addPartnerCharity(this.recipients[0]);
        await this.refetchCharities();
        const newRecipient = this.charities.find((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          return charityMeta.abn === newRecipientABN;
        });
        this.recipients = [newRecipient.id];
      }
      await this.$apollo.mutate({
        mutation: this.mutation,
        variables: this.variables,
        update: (store, res) => {
          this.update(store, res);
          this.close();
        },
      });
    },
    close() {
      this.setShowGiftWizard(false);
    },
    update(store, { data: { addGift } }) {
      if (addGift) {
        const data = store.readQuery(this.getGiftsQuery);
        data.getGifts.gifts.push(addGift.gift);

        store.writeQuery({
          ...this.getGiftsQuery,
          data,
        });
      }
    },
  },
};
</script>
