<template>
  <div class="grid">
    <div class="cell cell__12/12">
      <div class="grid grid--middle">
        <div class="cell cell__fill">
          <Headline flush :level="1"> Users </Headline>
        </div>
        <div class="cell">
          <RuButton
            v-if="showAddUserButton"
            size="small"
            level="primary"
            @click="setShowAddUserModal(true)"
          >
            + Add User
          </RuButton>
        </div>
        <div class="cell cell__12/12">
          <input
            id="search"
            class="input input-compact mr-8"
            :v-model="searchQuery"
            placeholder="Search..."
            :compact="true"
            :value="searchValue"
            @keydown="search"
          />
        </div>
      </div>
      <AdminCard tight>
        <AdminDataTable
          v-if="users"
          :columns="columns"
          :loading="$apollo.loading"
          :row-count="users.length"
          :current-sorting-column="currentSortingColumn"
          :sortable-columns="sortableColumns"
          @sortColumnClicked="changeSortColumn"
        >
          <template #filters>
            <AdminDataTableFilter
              v-for="(filter, index) in filters"
              :key="index"
              :active="filter.value === userRoleFilter"
              :label="filter.label"
              @click="setUserRoleFilter(filter.value)"
            />
          </template>
          <template #rows>
            <AdminDataTableRow
              v-for="(user, index) in users"
              :key="index"
              class="group"
              :to="localePath(`/admin/users/${user.id}`)"
            >
              <AdminDataTableCell full>
                {{ user.id }}
              </AdminDataTableCell>
              <AdminDataTableCell full>
                {{ user.email }}
              </AdminDataTableCell>
              <AdminDataTableCell>
                {{ user.role }}
              </AdminDataTableCell>
              <AdminDataTableCell>
                {{ $formatDate(user.createdAt) }}
              </AdminDataTableCell>
            </AdminDataTableRow>
          </template>
          <template #pagination>
            <AdminDataTableButton
              :disabled="usersPageIndex === 0"
              :loading="$apollo.loading"
              @click="prevPage"
            >
              <BaseIcon id="arrow-left" />
            </AdminDataTableButton>
            <AdminDataTableButton
              :disabled="users.length !== pageSize"
              :loading="$apollo.loading"
              @click="nextPage"
            >
              <BaseIcon id="arrow-right" />
            </AdminDataTableButton>
          </template>
        </AdminDataTable>
      </AdminCard>
    </div>
    <AdminUserModal
      :show-add-user-modal="showAddUserModal"
      @setShowAddUserModal="setShowAddUserModal"
      @done="showUserPage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

import GET_USERS_QUERY from '@/graphql/queries/GetUsers';
import AdminCard from '@/components/admin/Card';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';
import AdminDataTableFilter from '@/components/admin/DataTableFilter';
import AdminUserModal from '~/components/admin/UserModal';
import BaseIcon from '@/components/BaseIcon';
import AdminDataTableButton from '@/components/admin/DataTableButton';
import RuButton from '@/components/atoms/Button';
import Headline from '@/components/atoms/Headline';

import sorting from '@/mixins/sorting';
import { isPartnershipsAdminOrHigher } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminUsersIndex',
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
    AdminDataTableFilter,
    AdminUserModal,
    BaseIcon,
    AdminDataTableButton,
    RuButton,
    Headline,
  },
  mixins: [sorting],
  apollo: {
    users: {
      query: GET_USERS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          limit: this.pageSize,
          offset: this.usersPageIndex * this.pageSize,
          searchQuery: this.usersPageSearch,
          customOrder: this.customOrder,
          ...(this.userRoleFilter && { roles: [this.userRoleFilter] }),
        };
      },
      update: (data) => data.getUsers,
    },
  },
  data() {
    return {
      searchQuery: '',
      columns: ['ID', 'Email', 'Role', 'Created At'],
      pageSize: 10,
      filters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Super Admin',
          value: 'SUPER_ADMIN',
        },
        {
          label: 'Cremations Admin',
          value: 'CREMATIONS_ADMIN',
        },
        {
          label: 'Partnerships Admin',
          value: 'PARTNERSHIPS_ADMIN',
        },
        {
          label: 'Admin',
          value: 'ADMIN',
        },
        {
          label: 'Moderator',
          value: 'MODERATOR',
        },
        {
          label: 'Partnership',
          value: 'PARTNERSHIP',
        },
        {
          label: 'Consumer',
          value: 'CONSUMER',
        },
      ],
      users: [],
      showAddUserButton: false,
      showAddUserModal: false,
      sortableFields: [
        {
          field: 'id',
          columnName: 'ID',
          defaultDirection: 'ASC',
        },
        {
          field: 'email',
          columnName: 'Email',
          defaultDirection: 'ASC',
        },
        {
          field: 'role',
          columnName: 'Role',
          defaultDirection: 'ASC',
        },
        {
          field: 'createdAt',
          columnName: 'Created At',
          defaultDirection: 'DESC',
        },
      ],
      currentSortingColumn: ['Created At', 'DESC'],
    };
  },
  computed: {
    ...mapGetters(['role']),
    ...mapGetters('admin', [
      'usersPageIndex',
      'usersPageSearch',
      'userRoleFilter',
    ]),
    searchValue() {
      return this.usersPageSearch;
    },
  },
  watch: {
    userRoleFilter() {
      this.resetUsersPageIndex();
    },
  },
  created() {
    this.showAddUserButton = isPartnershipsAdminOrHigher(this.role);
  },
  methods: {
    ...mapActions('admin', [
      'nextUsersPage',
      'prevUsersPage',
      'resetUsersPageIndex',
      'setUsersPageSearch',
      'setUserRoleFilter',
    ]),
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextUsersPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevUsersPage();
      }
    },
    search: debounce(300, function (event) {
      this.resetUsersPageIndex();
      this.setUsersPageSearch(event.target.value.trim());
    }),
    setShowAddUserModal(show) {
      this.showAddUserModal = show;
    },
    showUserPage(userId) {
      this.$router.push({
        path: `/admin/users/${userId}`,
      });
    },
  },
};
</script>
