<template>
  <BaseModal @close="$emit('close')">
    <h2 class="mb-4 text-4xl leading-tight">
      {{ $t('components.endOfLifeCategoryModal.title') }}
    </h2>
    <AdiForm
      :mutation="CREATE_END_OF_LIFE_CATEGORY_MUTATION"
      :variables="variables"
      submit-wide
      submit-label="Add End-of-Life Category"
      @update="update"
      @done="$emit('close')"
    >
      <FormRow>
        <TextInput
          id="name"
          v-model="formData.name"
          :label="$t('components.endOfLifeCategoryModal.name')"
          rules="required"
          compact
        />
      </FormRow>
      <TextInput
        id="name"
        v-model="formData.slug"
        :label="$t('components.endOfLifeCategoryModal.slug')"
        rules="required"
        compact
      />
    </AdiForm>
  </BaseModal>
</template>

<script>
import CREATE_END_OF_LIFE_CATEGORY_MUTATION from '@/graphql/mutations/CreateEndOfLifeCategory.gql';
import BaseModal from '@/components/BaseModal';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import TextInput from '@/components/TextInput';
export default {
  name: 'ComponentsAdminNewEndOfLifeCategoryModal',
  components: {
    BaseModal,
    AdiForm,
    FormRow,
    TextInput,
  },
  props: {
    showNewEndOfLifeCategoryModal: {
      type: Boolean,
    },
  },
  data: () => ({
    CREATE_END_OF_LIFE_CATEGORY_MUTATION,
    formData: {
      name: null,
      description: null,
      parentCategoryId: null,
      slug: null,
      explanation: null,
    },
  }),
  computed: {
    variables() {
      return {
        ...this.formData,
      };
    },
  },
  methods: {
    async update(store, res) {
      await this.$router.push(
        this.localePath(
          `/admin/end-of-life-categories/${res.data.createEndOfLifeCategory.endOfLifeCategory.id}`
        )
      );
    },
  },
};
</script>
