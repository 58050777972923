<template>
  <button
    type="button"
    class="flex items-center cursor-pointer focus:outline-none font-medium"
    @click="$emit('click', column)"
  >
    {{ column }}
    <template v-if="column === currentSortingColumn[0]">
      <span v-if="currentSortingColumn[1] === 'ASC'" class="ml-4">
        <BaseIcon id="arrow-up" size="small" />
      </span>
      <span v-else-if="currentSortingColumn[1] === 'DESC'" class="ml-4">
        <BaseIcon id="arrow-down" size="small" />
      </span>
    </template>
  </button>
</template>

<script>
import BaseIcon from '@/components/BaseIcon';

export default {
  name: 'ComponentsAdminDataTableSortableHeader',
  components: {
    BaseIcon,
  },
  props: {
    column: {
      type: String,
      required: true,
    },
    currentSortingColumn: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>
