var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":7,"prev-step":_vm.previous}},[_c('AdiForm',{attrs:{"disabled":!_vm.isComplete},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.medical.preferences.headline'))+"\n    ")]),_vm._v(" "),(['nsw', 'vic', 'tas'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.preferences.preferencesImportant'))+"\n      ")])]:_vm._e(),_vm._v(" "),(['qld', 'nt'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.preferences.preferencesNotBinding'))+"\n      ")])]:_vm._e(),_vm._v(" "),(['wa'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.preferences.attorneyPower'))+"\n      ")])]:_vm._e(),_vm._v(" "),(['sa'].includes(_vm.userState))?[_c('p',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.preferences.decisionMakerInfo1'))+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('pages.poa.medical.preferences.decisionMakerInfo2'))+"\n      ")])]:_vm._e(),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.medical.preferences.exampleOfPreferences'))+"\n    ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"preferences","layout":"column","options":[
        {
          label: _vm.$t('forms.labels.yes'),
          value: true,
        },
        {
          label: _vm.$t('forms.labels.no'),
          value: false,
        },
      ]},on:{"input":_vm.onChange},model:{value:(_vm.poaMedicalMeta.has_preferences),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "has_preferences", $$v)},expression:"poaMedicalMeta.has_preferences"}}),_vm._v(" "),(_vm.poaMedicalMeta.has_preferences)?_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell cell__12/12"},[_c('FormControl',{attrs:{"id":"preferences","disabled":loading,"type":"textarea","rules":"required|max:3000","placeholder":_vm.$t('forms.placeholders.itIsMyPreference')},model:{value:(_vm.poaMedicalMeta.preferences),callback:function ($$v) {_vm.$set(_vm.poaMedicalMeta, "preferences", $$v)},expression:"poaMedicalMeta.preferences"}})],1)]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }