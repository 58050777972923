<template>
  <div
    v-if="item.numerable"
    class="ru:item"
    :class="{ 'ru:item--selected': isSelected }"
  >
    <div class="ru:item__counter">
      <button
        class="ru:item__icon"
        :disabled="!selectionCount"
        @click="$emit('removeItem')"
      >
        <Icon id="minus" size="large" />
      </button>
      <span class="ru:item__count">{{ selectionCount }}</span>
      <button class="ru:item__icon" @click="$emit('addItem')">
        <Icon id="add" size="large" />
      </button>
    </div>
    <div class="ru:item__description">
      <div class="ru:item__title">
        {{ productToDisplayNameMapping[item.product] }}
      </div>
      <div class="ru:item__copy">
        {{ item.description }}
      </div>
    </div>
    <div class="ru:item__price">{{ formatPrice(item.finalPrice) }}</div>
  </div>
  <button
    v-else
    type="button"
    class="ru:item"
    :class="{ 'ru:item--selected': isSelected }"
    :disabled="item.isGifted"
    @click="$emit('toggleItem')"
  >
    <div class="ru:item__icon">
      <Icon :id="isSelected ? 'check' : 'add'" size="huge" />
    </div>
    <div class="ru:item__description">
      <div class="ru:item__title">
        {{ productToDisplayNameMapping[item.product] }}
      </div>
      <div class="ru:item__copy">
        {{ item.description }}
      </div>
    </div>
    <div v-if="item.isGifted" class="ru:item__gift-icon">🎁</div>
    <div v-else class="ru:item__price">{{ formatPrice(item.finalPrice) }}</div>
  </button>
</template>

<script>
import { formatPrice, productToDisplayNameMapping } from '@/utilities';
import Icon from '@/components/atoms/Icon';
export default {
  name: 'ComponentsMoleculesItem',
  components: {
    Icon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    selectionCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      productToDisplayNameMapping,
    };
  },
  computed: {
    isSelected() {
      return !!this.selectionCount;
    },
  },
  methods: {
    formatPrice,
  },
};
</script>

<style lang="scss">
#{$ru} {
  &item {
    border: 2px solid --rgba(theme);
    border-radius: var(--border-radius);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    text-align: left;
    padding: var(--base-margin);
    transition: --out();
    @include mq('min-md') {
      flex-wrap: initial;
    }

    &:is(button):not([disabled]) {
      cursor: pointer;
    }

    &--selected {
      background-color: --rgba(lighter);
      transition: --in();
    }

    &:is([disabled]) {
      background-color: --rgba(iron);
      border: 2px solid --rgba(aluminium);
      cursor: auto;
      pointer-events: none;
    }

    &:is([disabled]) &__icon {
      background-color: --rgba(silver);
    }

    &:not(:last-child) {
      margin-bottom: var(--base-margin);
    }

    &__icon {
      background-color: --rgba(theme);
      border-radius: var(--border-radius-small);
      color: --rgba(white);
      padding: 0.5rem;
      margin-right: 1rem;
      transition: --out();
      @include mq('min-md') {
        padding: 1rem;
      }

      &:hover {
        background-color: --rgba(darker);
        transition: --in();
      }
    }

    &__counter {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      margin-right: auto;
      @include mq('min-md') {
        margin-right: 1rem;
      }

      button {
        display: block;
      }
    }

    &__counter &__icon {
      margin-right: 0;
      padding: 0.25rem;
    }

    &__count {
      font-size: 1.75rem;
      width: 2rem;
      text-align: center;
    }

    &__description {
      flex-grow: 1;
      color: --rgba(basalt);
      margin-bottom: var(--base-margin-small);
      order: -1;
      width: 100%;
      @include mq('min-md') {
        order: initial;
        width: auto;
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: 1.125rem;
      margin-bottom: var(--base-margin-small);
    }

    &__copy {
      color: --rgba(gravel);
    }

    &__price {
      font-size: 1.25rem;
      margin-left: 1rem;
      width: 2.5rem;
      flex-shrink: 0;
      text-align: right;
    }

    &__gift-icon {
      margin-left: 1rem;
      font-size: 2rem;
    }
  }
}
</style>
