<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid }"
    tag="form"
    class="adi:form"
    @submit.prevent="submit"
  >
    <slot :loading="loading" />
    <Alert v-if="$nuxt.isOffline" icon="warning" theme="warning" level="medium">
      {{ $t('components.form.networkError') }}
    </Alert>
    <Alert v-else-if="error" icon="error" theme="error" level="medium">
      {{ customError || error }}
    </Alert>
    <Buttons v-if="!hideSubmit" layout="start">
      <RuButton
        id="adiform-submit"
        :disabled="invalid || disabled || loading || $nuxt.isOffline"
        button-type="submit"
        level="primary"
        size="large"
        width="33%"
      >
        {{ submitLabel }}
        <Loading v-if="loading" />
        <Icon v-else :id="submitIcon" />
      </RuButton>
    </Buttons>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { formatError } from '@/utilities';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import Loading from '@/components/atoms/Loading';
import Alert from '@/components/molecules/Alert';

export default {
  name: 'ComponentsForm',
  components: {
    ValidationObserver,
    Buttons,
    RuButton,
    Icon,
    Loading,
    Alert,
  },
  props: {
    autoFocus: {
      default: true,
      type: Boolean,
    },
    customError: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    autoSubmit: {
      default: false,
      type: Boolean,
    },
    mutation: {
      default: null,
      type: Object,
    },
    submitIcon: {
      default: 'arrow-right',
      type: String,
    },
    submitCompact: {
      default: false,
      type: Boolean,
    },
    submitClasses: {
      default: '',
      type: String,
    },
    submitInline: {
      default: false,
      type: Boolean,
    },
    submitLabel: {
      default: 'Next',
      type: String,
    },
    submitWide: {
      default: false,
      type: Boolean,
    },
    variables: {
      default: null,
      type: Object,
    },
    hideSubmit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isDone: false,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    apollo() {
      return this.mutation && this.variables;
    },
    loading() {
      return this.isDone || this.isLoading;
    },
  },
  mounted() {
    this.$nuxt.$on('unlockForm', () => {
      this.isDone = false;
      this.isLoading = false;
    });
    this.$nuxt.$on('clearErrors', () => {
      this.$refs.observer && this.$refs.observer.reset();
    });
    if (this.autoFocus) {
      this.$el.querySelector('input,select,textarea')?.focus();
    }
  },
  methods: {
    async submit($event) {
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());
      if (isValid) {
        this.isLoading = true;
        this.error = null;
        if (this.apollo) {
          this.$emit('requestStarted');
          await this.$apollo
            .mutate({
              mutation: this.mutation,
              variables: this.variables,
              update: (store, res) => {
                this.$emit('update', store, res);
                this.isDone = true;
                this.$emit('done', res);
              },
            })
            .then(({ errors }) => {
              if (errors) {
                this.isLoading = false;
                this.error = formatError(errors[0].message);
              }
            })
            .catch((e) => {
              this.isLoading = false;
              this.error = formatError(e.message);
            });
        } else {
          this.$emit('submit', $event);
          this.isLoading = true;
        }
      } else {
        this.error = this.$t('components.form.missingRequiredFieldsError');
        this.$refs.observer.$el.parentElement
          .querySelector('.--invalid')
          ?.scrollIntoView();
      }
    },
  },
};
</script>

<style lang="scss">
#{$adi}form > #{$ru}buttons {
  padding-top: var(--base-margin);
}
</style>
