<template>
  <Modal size="large" @closeModal="closeModal">
    <template #header>
      <Headline flush :level="1">
        {{ $t('components.prepaidCremationModal.title') }}
      </Headline>
    </template>
    <template #body>
      <p>
        {{ $t('components.prepaidCremationModal.description') }}
      </p>

      <div
        class="ru:prepaid-cremation__info-container grid grid--stretch grid--gutterless grid-nowrap"
      >
        <Box
          v-for="(infoBox, index) in infoBoxes"
          :id="`box_${infoBox.key}`"
          :key="index"
          background="white"
          border="lighter"
        >
          <RuImage
            :src="
              require(`~/assets/svg/prepaid-cremation__${infoBox.iconId}.svg`)
            "
            :height="60"
            :width="infoBox.iconWidth"
            align="left"
          />
          <Headline :level="2">
            {{ infoBox.title }}
          </Headline>
          <p>
            {{ infoBox.description }}
          </p>
        </Box>
      </div>

      <Headline :level="2">
        {{ $t('texts.sentences.faq') }}
      </Headline>
      <AccordionPanel>
        <template #title>{{
          $t('components.prepaidCremationModal.accordionTitle1')
        }}</template>
        <template #content>
          <p>
            {{ $t('components.prepaidCremationModal.accordionText1') }}
          </p>
        </template>
      </AccordionPanel>
      <AccordionPanel>
        <template #title>{{
          $t('components.prepaidCremationModal.accordionTitle2')
        }}</template>
        <template #content>
          <p>
            {{ $t('components.prepaidCremationModal.accordionText2') }}
          </p>
        </template>
      </AccordionPanel>
      <AccordionPanel>
        <template #title>{{
          $t('components.prepaidCremationModal.accordionTitle3')
        }}</template>
        <template #content>
          <p>
            {{ $t('components.prepaidCremationModal.accordionText3') }}
          </p>
        </template>
      </AccordionPanel>
      <AccordionPanel>
        <template #title>{{
          $t('components.prepaidCremationModal.accordionTitle4')
        }}</template>
        <template #content>
          <p>
            {{ $t('components.prepaidCremationModal.accordionText4') }}
          </p>
        </template>
      </AccordionPanel>
      <Buttons layout="stretch">
        <RuButton
          level="primary"
          data-test="request-button"
          @click="requestQuote"
        >
          {{ requestQuoteOrInterestText }}
        </RuButton>
      </Buttons>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/molecules/Modal';
import AccordionPanel from '@/components/molecules/AccordionPanel';
import Headline from '@/components/atoms/Headline';
import Box from '@/components/atoms/Box';
import RuImage from '@/components/atoms/Image';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsOrganismsPrepaidCremationModal',
  components: {
    Modal,
    AccordionPanel,
    Headline,
    Box,
    RuImage,
    Buttons,
    RuButton,
  },
  props: {
    requestQuoteOrInterestText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      infoBoxes: [
        {
          key: 'fixedPrice',
          iconId: 'price',
          iconWidth: '102',
          title: this.$t('components.prepaidCremationModal.fixedPrice.title'),
          description: this.$t(
            'components.prepaidCremationModal.fixedPrice.description'
          ),
        },
        {
          key: 'fittingFarewells',
          iconId: 'farewell',
          iconWidth: '60',
          title: this.$t(
            'components.prepaidCremationModal.fittingFarewells.title'
          ),
          description: this.$t(
            'components.prepaidCremationModal.fittingFarewells.description'
          ),
        },
        {
          key: 'easierGoodbyes',
          iconId: 'lightbulb',
          iconWidth: '60',
          title: this.$t(
            'components.prepaidCremationModal.easierGoodbyes.title'
          ),
          description: this.$t(
            'components.prepaidCremationModal.easierGoodbyes.description'
          ),
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    requestQuote() {
      this.$emit('requestQuote');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &prepaid-cremation {
    &__info-container {
      --margin-y: 0;
      margin-bottom: var(--base-margin-huge);
      gap: var(--base-margin-big);

      @include mq('min-lg') {
        flex-flow: row;
      }
    }
  }
}
</style>
