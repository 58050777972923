<template>
  <div class="grid">
    <div class="cell cell__12/12 cell__5/12--md">
      <FormControl
        :id="`${id}_type`"
        v-model="type"
        :disabled="disabled"
        :options="options"
        type="select"
        rules="required"
      />
    </div>
    <div class="cell cell__11/12 cell__6/12--md">
      <FormControl
        :id="`${id}_description`"
        v-model="description"
        :name="`${id}_description`"
        :disabled="disabled || !type"
        :placeholder="type ? placeholders[type] : null"
        rules="required|max:3000"
      />
    </div>
    <div class="cell cell__1/12">
      <RuButton level="plain" theme="error" round icon @click="$emit('remove')">
        <Icon id="cancel" />
      </RuButton>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import RuButton from '@/components/atoms/Button';
import FormControl from '@/components/molecules/FormControl';

export default {
  name: 'ComponentsAssetInputs',
  components: {
    Icon,
    RuButton,
    FormControl,
  },
  props: {
    value: {
      default: () => ({ type: '', description: '' }),
      type: Object,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [
        this.$t('components.assetInputs.property.label'),
        this.$t('components.assetInputs.bankAccount.label'),
        this.$t('components.assetInputs.financial.label'),
        this.$t('components.assetInputs.superannuationFund.label'),
        this.$t('components.assetInputs.lifeInsurance.label'),
        this.$t('components.assetInputs.valuable.label'),
        this.$t('components.assetInputs.other.label'),
      ],
      placeholders: {
        Property: this.$t('components.assetInputs.property.placeholder'),
        'Bank Account': this.$t(
          'components.assetInputs.bankAccount.placeholder'
        ),
        'Financial Assets (e.g. Bonds, Stocks)': this.$t(
          'components.assetInputs.financial.placeholder'
        ),
        'Superannuation Fund': this.$t(
          'components.assetInputs.superannuationFund.placeholder'
        ),
        'Life Insurance Policy': this.$t(
          'components.assetInputs.lifeInsurance.placeholder'
        ),
        Valuable: this.$t('components.assetInputs.valuable.placeholder'),
        'Other (e.g. Legal interests)': this.$t(
          'components.assetInputs.other.placeholder'
        ),
      },
    };
  },
  computed: {
    type: {
      get() {
        return this.value.type;
      },
      set(newValue) {
        this.$emit('input', { ...this.value, type: newValue });
      },
    },
    description: {
      get() {
        return this.value.description;
      },
      set(newValue) {
        this.$emit('input', { ...this.value, description: newValue });
      },
    },
  },
};
</script>
