<template>
  <div class="ru:container ru:container--tight">
    <Checkout
      v-if="checkoutItems.length"
      is-unlock-account
      :checkout-items="checkoutItems"
      @orderCreated="ordersCreationSuccessfulHandler"
    >
      <template #PAYMENT>
        <Headline :level="1">
          {{ $t('pages.checkout.unlockAccount') }}
        </Headline>
        <p>
          {{ $t('pages.checkout.unlockAccountDesc') }}
        </p>
      </template>
    </Checkout>
  </div>
</template>

<script>
import Checkout from '@/components/templates/Checkout';
import Headline from '@/components/atoms/Headline';

import { user, subscription } from '@/mixins/apollo';

import { testUntilTrue } from '@/utilities';

export default {
  name: 'PagesAffiliateCheckoutUnlockIndex',
  components: {
    Checkout,
    Headline,
  },
  mixins: [user, subscription],
  data() {
    return {
      success: false,
    };
  },
  computed: {
    checkoutItems() {
      if (this.subscription) {
        const currentPeriodEnd = new Date(this.subscription.currentPeriodEnd);
        const recentSubscriptionPeriodEnd = new Date(currentPeriodEnd).setMonth(
          currentPeriodEnd.getMonth() + 1
        );
        if (Date.now() <= recentSubscriptionPeriodEnd) {
          return [{ product: 'RECENT_UNLOCK', type: 'DEFAULT' }];
        } else {
          return [{ product: 'UNLOCK', type: 'DEFAULT' }];
        }
      }
      console.error('User does not have a subscription.');
      return [];
    },
  },
  methods: {
    waitUntilSubscriptionUpdates() {
      const isSubscriptionUpdated = async () => {
        await this.refetchSubscription();
        return this.hasValidSubscription;
      };
      return testUntilTrue(isSubscriptionUpdated, 5, 2000);
    },
    async ordersCreationSuccessfulHandler() {
      await this.waitUntilSubscriptionUpdates();
      await this.$router.push({
        path: this.localePath('/checkout/unlock/thanks'),
      });
    },
  },
};
</script>
