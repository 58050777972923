<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm @submit="onSubmit">
      <Headline :level="1">
        {{ $t('titles.funeral') }}
      </Headline>
      <hr />
      <Headline :level="2">
        {{ $t('pages.will.funeral.funeralType') }}
      </Headline>
      <p>
        {{ $t('pages.will.funeral.executorInstructions') }}
      </p>
      <Tip>
        {{ $t('pages.will.funeral.funeralDescription') }}
      </Tip>
      <LargeRadioButtons
        id="funeral_type"
        v-model="willMeta.funeral_type"
        layout="column"
        :options="options[0]"
        @input="onCremationClick"
      />
      <PrepaidCremationModal
        v-if="showPrepaidCremationModal"
        :request-quote-or-interest-text="requestQuoteOrInterestText"
        @closeModal="closePrepaidCremationModal"
        @requestQuote="requestPrepaidCremationQuote"
      />
      <Alert
        v-if="userHasPrepaidCremationInterest && userHasSelectedCremation"
        level="medium"
        theme="success"
        icon="check"
      >
        <div class="ru:addon__success">{{ successStateText }}</div>
      </Alert>
      <Box v-else-if="addonVisible" background="white" border="aluminium">
        <div class="ru:addon__close">
          <Buttons theme="default" layout="end">
            <RuButton level="plain" @click="hideAddon">
              <Icon id="close" />
            </RuButton>
          </Buttons>
        </div>
        <Headline big :level="3" row class="ru:addon__headline">
          <span>{{
            $t('pages.will.funeral.components.addon.titles.prepaid')
          }}</span>
        </Headline>
        <div class="ru:addon__content">
          <p>
            {{
              $t(
                'pages.will.funeral.components.addon.text.descriptionFirstParagraph'
              )
            }}
          </p>
          <p>
            {{
              $t(
                'pages.will.funeral.components.addon.text.descriptionSecondParagraph'
              )
            }}
          </p>
          <p>
            {{
              $t(
                'pages.will.funeral.components.addon.text.descriptionThirdParagraph'
              )
            }}
          </p>
        </div>
        <div class="ru:addon__explanation">
          <Buttons layout="start">
            <RuButton
              level="plain"
              theme="slate"
              underline
              @click="openPrepaidCremationModal"
            >
              {{
                $t('pages.will.funeral.components.addon.buttons.explanation')
              }}
            </RuButton>
          </Buttons>
        </div>
        <Tooltip
          :disabled="hasCorrectDataForPrepaidCremation"
          class-name="ru:addon__tooltip"
          max-width-class="max-w-2xl"
          :content="
            $t('pages.will.funeral.components.addon.tips.actionDisabled')
          "
        >
          <Buttons layout="start">
            <RuButton
              level="secondary"
              theme="slate"
              wide
              :disabled="!hasCorrectDataForPrepaidCremation || addonLoading"
              @click="requestPrepaidCremationQuote"
            >
              <Loading v-if="addonLoading" />
              {{ requestQuoteOrInterestText }}
            </RuButton>
          </Buttons>
        </Tooltip>
      </Box>

      <LargeRadioButtons
        id="funeral_type"
        v-model="willMeta.funeral_type"
        layout="column"
        :options="options[1]"
        @input="onSubmit"
      />
    </AdiForm>
    <Snackbar
      v-if="snackbarIsVisible"
      :type="snackbarType"
      :text="snackbarText"
      @closeSnackbar="closeSnackbar"
    />
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Alert from '@/components/molecules/Alert';
import Box from '@/components/atoms/Box';
import Icon from '@/components/atoms/Icon';
import Tooltip from '@/components/Tooltip';
import Loading from '@/components/atoms/Loading';
import PrepaidCremationModal from '@/components/organisms/PrepaidCremationModal';
import Snackbar from '@/components/molecules/Snackbar';

import { error, user, will } from '@/mixins/apollo';

import GET_ACCOUNT_INTERESTS_BY_USER_ID from '@/graphql/queries/GetAccountInterestsByUserId';
import REQUEST_PREPAID_CREMATION_QUOTE from '@/graphql/mutations/RequestPrepaidCremationQuote';

export default {
  name: 'PagesAffiliateWillFuneralIndex',
  components: {
    Alert,
    AdiForm,
    LargeRadioButtons,
    ModuleStep,
    Tip,
    Headline,
    RuButton,
    Buttons,
    Box,
    Icon,
    Snackbar,
    Tooltip,
    Loading,
    PrepaidCremationModal,
  },
  mixins: [user, will],
  apollo: {
    accountInterests: {
      query: GET_ACCOUNT_INTERESTS_BY_USER_ID,
      variables() {
        return {
          userId: this.userId,
        };
      },
      update: (data) => data.getAccountInterestsByUserId,
      skip() {
        return !this.userId;
      },
      error,
    },
  },
  data() {
    return {
      snackbarIsVisible: false,
      showPrepaidCremationModal: false,
      addonVisible: false,
      addonLoading: false,
      accountInterests: [],
      options: [
        [
          {
            label: this.$t('titles.cremation'),
            value: 'cremation',
          },
        ],
        [
          {
            label: this.$t('titles.burial'),
            value: 'burial',
          },
          {
            label: this.$t('pages.will.funeral.bodyDonation'),
            value: 'donate',
          },
          {
            label: this.$t('pages.will.funeral.executorDecision'),
            value: 'executor',
          },
          {
            label: this.$t('pages.will.funeral.skip'),
            value: null,
            level: 'tertiary',
          },
        ],
      ],
    };
  },
  computed: {
    isPrepaidCremationStateSupported() {
      return ['NSW', 'ACT'].includes(this.willMeta.address_state);
    },
    isPrepaidCremationSupported() {
      return (
        this.isPrepaidCremationStateSupported &&
        !!this.willMeta.address_postcode
      );
    },
    isPrepaidAddonSupported() {
      if (
        !this.$ff.addonFlowEnabled() ||
        this.willMeta.address_country !== 'AU'
      ) {
        return false;
      }
      return (
        this.isPrepaidCremationStateSupported ||
        ['QLD', 'VIC'].includes(this.willMeta.address_state)
      );
    },
    userHasSelectedCremation() {
      return this.willMeta?.funeral_type === 'cremation';
    },
    shouldShowAddonAtLoad() {
      return (
        this.willMeta?.funeral_type === 'cremation' &&
        this.isPrepaidAddonSupported
      );
    },
    hasCorrectDataForPrepaidCremation() {
      return (
        !!this.willMeta.address_postcode &&
        !!this.willMeta.address_state &&
        !!this.willMeta.name_first &&
        !!this.willMeta.name_last
      );
    },
    userHasPrepaidCremationInterest() {
      return !!this.accountInterests.find(
        (interest) => interest.product === 'PREPAID_CREMATION'
      );
    },
    successStateText() {
      if (this.isPrepaidCremationStateSupported) {
        return this.$t(
          'pages.will.funeral.components.addon.hints.success.prepaidEnabled'
        );
      } else {
        return this.$t(
          'pages.will.funeral.components.addon.hints.success.accountInterested'
        );
      }
    },
    requestQuoteOrInterestText() {
      return this.isPrepaidCremationSupported
        ? this.$t('pages.will.funeral.components.addon.buttons.requestQuote')
        : this.$t(
            'pages.will.funeral.components.addon.buttons.registerInterest'
          );
    },
  },
  created() {
    if (this.shouldShowAddonAtLoad) {
      this.showAddon();
    }
  },
  methods: {
    openPrepaidCremationModal() {
      this.showPrepaidCremationModal = true;
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'funeral_cremation_safewill_information_requested',
      });
    },
    closePrepaidCremationModal() {
      this.showPrepaidCremationModal = false;
    },
    hideAddon() {
      this.addonVisible = false;
    },
    showAddon() {
      if (this.$ff.addonFlowEnabled()) {
        this.addonVisible = true;
      }
    },
    async requestPrepaidCremationQuote() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'funeral_cremation_safewill_quote_requested',
      });

      this.addonLoading = true;
      // Call requestPrepaidCremationQuote
      let success = false;
      try {
        success = await this.$apollo.mutate({
          mutation: REQUEST_PREPAID_CREMATION_QUOTE,
          variables: {
            userId: this.userId,
          },
        });
      } catch (err) {
        success = false;
        console.error(err);
      }
      if (!success) {
        this.snackbarText = this.$t(
          'pages.will.funeral.components.addon.hints.failure.requestQuote'
        );
        this.openSnackbar('error');
        this.addonLoading = false;
        return;
      }
      // Refetch account interests
      await this.$apollo.queries.accountInterests.refetch();
      this.addonLoading = false;
    },
    nextStep() {
      if (this.willMeta.funeral_skip) {
        return '/will';
      }

      return ['cremation', 'burial'].includes(this.willMeta.funeral_type)
        ? '/will/funeral/location'
        : '/will/funeral/other-requests';
    },
    assignFuneralMeta() {
      this.willMeta.funeral_skip = !this.willMeta.funeral_type;
      this.willMeta.funeral_location = this.willMeta.funeral_skip
        ? null
        : this.willMeta.funeral_location;
      this.willMeta.funeral_note = this.willMeta.funeral_skip
        ? null
        : this.willMeta.funeral_note;

      if (this.willMeta.funeral_skip) {
        this.willMeta.funeral_type = 'executor';
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 7 (Funeral)',
        });
      }

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'funeral_type_updated',
        props: {
          type: this.willMeta.funeral_type,
        },
      });
    },
    async onSubmit() {
      this.assignFuneralMeta();
      await this.updateWillMeta();
      const path = this.localePath(this.nextStep());
      this.$router.push({
        path,
      });
    },
    async onCremationClick() {
      if (this.isPrepaidAddonSupported) {
        this.assignFuneralMeta();
        await this.updateWillMeta();
        this.showAddon();
      } else {
        await this.onSubmit();
      }
    },
    openSnackbar(type) {
      this.snackbarType = type;
      this.snackbarIsVisible = true;
    },
    closeSnackbar() {
      this.snackbarIsVisible = false;
    },
  },
};
</script>
<style lang="scss">
#{$ru} {
  &addon {
    &__content {
      margin-bottom: var(--base-margin);
    }

    &__explanation {
      margin-bottom: var(--base-margin-huge);
    }

    &__close {
      min-height: var(--app-height);
      display: flex;
      position: absolute;
      align-items: center;
      top: 0;
      right: var(--base-margin);
    }

    &__success {
      white-space: pre;
    }

    &__headline {
      color: --rgba(basalt);
    }
  }
}
</style>
