<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.children') }}
    </Headline>
    <CremationsFormInstructions />
    <div class="grid ru:form__fieldset">
      <div class="cell cell__12/12 cell__4/12--lg">
        <FormControl
          id="guestHasChildren"
          v-model="totalGuestChildrenCount"
          type="number"
          :text="$t('forms.labels.howManyChildrenDidTheDeceasedHave')"
          :required="false"
        />
      </div>
    </div>
    <FormWrapper @submit="submitChildren">
      <div v-if="showChildren">
        <p>
          {{ $t('pages.cremations.enterChildrenDetails') }}
        </p>
        <div v-if="showForm">
          <div class="grid ru:form__fieldset">
            <div class="cell cell__12/12 cell__4/12--lg">
              <FormControl
                id="firstName"
                v-model="tempData.firstName"
                type="text"
                :text="$t('forms.labels.firstName')"
                :disabled="loading"
                :required="false"
                :pattern="FIRST_NAME_PATTERN.regex"
                :title="$t(FIRST_NAME_PATTERN.description)"
              />
            </div>
            <div class="cell cell__12/12 cell__4/12--lg">
              <FormControl
                id="otherGivenNames"
                v-model="tempData.otherGivenNames"
                type="text"
                :text="$t('forms.labels.otherGivenNames')"
                :disabled="loading"
                :required="false"
              />
            </div>
            <div class="cell cell__12/12 cell__4/12--lg">
              <FormControl
                id="lastName"
                v-model="tempData.lastName"
                type="text"
                :text="$t('forms.labels.lastName')"
                :disabled="loading"
                :required="false"
              />
            </div>
            <div class="cell cell__12/12 cell__4/12--lg">
              <FormControlWithUnknown
                id="dateOfBirth"
                v-model="tempData.dateOfBirth"
                type="date"
                format="date"
                :text="$t('forms.labels.dateOfBirth')"
                :required="false"
              />
            </div>
            <div class="cell cell__12/12 cell__4/12--lg">
              <FormControlWithUnknown
                id="age"
                v-model="tempData.age"
                type="number"
                format="number"
                :text="$t('forms.labels.age')"
                :disabled="loading"
                :required="false"
              />
            </div>
            <div class="cell cell__12/12 cell__4/12--lg">
              <FormControl
                id="sex"
                v-model="tempData.sex"
                type="select"
                :text="$t('forms.labels.sex')"
                :disabled="loading"
                :required="false"
                :options="SEX_OPTIONS"
              />
            </div>
            <div class="cell cell__12/12 cell__4/12--lg">
              <FormControl
                id="livingStatus"
                v-model="tempData.livingStatus"
                type="select"
                :text="$t('forms.labels.livingStatus')"
                :disabled="loading"
                :required="false"
                :options="LIVING_STATUS_OPTIONS"
              />
            </div>
          </div>
          <Alert v-if="formDataIncomplete" type="error">
            {{ $t('pages.cremations.unknownChildrenDetails') }}
          </Alert>
          <RuButton
            :aria-label="$t('forms.labels.add')"
            button-type="button"
            color="white"
            border="wine"
            background="wine"
            size="small"
            rounded
            @click="save"
          >
            <BaseIcon id="tick" classes="icon" />
            <span>{{ $t('forms.labels.save') }}</span>
          </RuButton>
          <RuButton
            :aria-label="$t('forms.labels.cancel')"
            button-type="button"
            color="wine"
            border="wine"
            background="white"
            size="small"
            rounded
            @click="cancel"
          >
            <BaseIcon id="cross" classes="icon" />
            <span>{{ $t('forms.labels.cancel') }}</span>
          </RuButton>
        </div>
        <div v-else>
          <DataTable
            v-if="formData.guestChildren.length"
            accent="lilac"
            theme="wine"
            :description="$t('texts.sentences.childrenDetails')"
            :columns="dataTableColumns"
            :rows="formData.guestChildren"
            delete-button
            edit-button
            @delete="deleteItem"
            @edit="edit"
          />
          <p>
            <RuButton
              aria-label="Add Child"
              button-type="button"
              color="white"
              border="wine"
              background="wine"
              size="small"
              rounded
              @click="add"
            >
              <BaseIcon id="plus" classes="icon" />
              <span>{{ $t('forms.labels.addChild') }}</span>
            </RuButton>
          </p>
        </div>
      </div>
      <Alert
        v-if="numberOfChildrenMismatch"
        level="major"
        theme="error"
        icon="error"
      >
        {{ $t('pages.cremations.incorrectNumberOfChildren') }}
      </Alert>
      <div v-if="!showForm" class="ru:form__buttons">
        <RuButton
          :href="prevPage"
          color="wine"
          border="wine"
          background="white"
        >
          <span>{{ $t('forms.labels.back') }}</span>
        </RuButton>
        <RuButton
          aria-label="Submit"
          button-type="submit"
          background="wine"
          color="white"
        >
          <span>{{ $t('forms.labels.next') }}</span>
        </RuButton>
      </div>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="toggleConfirmModal">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton background="wine" color="white" @click="submitChildren">
          {{ $t('forms.labels.accept') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import BaseIcon from '@/components/BaseIcon';
import RuButton from '@/components/atoms/Button';
import DataTable from '@/components/atoms/DataTable';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import FormControlWithUnknown from '@/components/organisms/FormControlWithUnknown';
import Alert from '@/components/molecules/Alert';
import CremationsFormInstructions from '@/components/snowflakes/CremationsFormInstructions';

import { FIRST_NAME_PATTERN } from '@/utilities/cremations/form-constants';

export default {
  name: 'PagesAffiliateCremationsAtNeedChildrenId',
  components: {
    BaseModal,
    BaseIcon,
    RuButton,
    DataTable,
    Headline,
    FormWrapper,
    FormControl,
    FormControlWithUnknown,
    Alert,
    CremationsFormInstructions,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      loading: true,
      editIndex: null,
      tempData: null,
      totalGuestChildrenCount: 0,
      formData: {
        guestPartners: [],
        guestChildren: [],
      },
      dataTableColumns: [
        { key: 'firstName', text: this.$t('forms.labels.firstName') },
        {
          key: 'otherGivenNames',
          text: this.$t('forms.labels.otherGivenNames'),
        },
        { key: 'lastName', text: this.$t('forms.labels.lastName') },
        { key: 'age', text: this.$t('forms.labels.age') },
        { key: 'dateOfBirth', text: this.$t('forms.labels.dateOfBirth') },
        { key: 'sex', text: this.$t('forms.labels.sex') },
        { key: 'livingStatus', text: this.$t('forms.labels.livingStatus') },
      ],
      numberOfChildrenMismatch: false,
      formDataIncomplete: false,
      FIRST_NAME_PATTERN,
    };
  },
  computed: {
    nextPage() {
      return this.localePath(
        `/cremations/at-need/address/${this.cremationRequestId}`
      );
    },
    prevPage() {
      return this.localePath(
        `/cremations/at-need/${
          this.showRelationships ? 'relationships' : 'parents'
        }/${this.cremationRequestId}`
      );
    },
    showChildren() {
      return (
        this.formData.guestChildren.length ||
        Number(this.totalGuestChildrenCount)
      );
    },
    showForm() {
      return !!this.tempData;
    },
  },
  watch: {
    'formData.guestChildren.length'(newValue) {
      if (newValue > 0 && this.totalGuestChildrenCount === 0) {
        this.totalGuestChildrenCount = newValue;
      }
      this.numberOfChildrenMismatch = false;
    },
    totalGuestChildrenCount() {
      this.numberOfChildrenMismatch = false;
    },
  },
  methods: {
    edit(rowIndex) {
      this.editIndex = rowIndex;
      this.tempData = { ...this.formData.guestChildren[rowIndex] };
    },
    add() {
      this.editIndex = this.formData.guestChildren.length;
      this.tempData = { ...this.formFields.guestChildren };
    },
    deleteItem(rowIndex) {
      this.formData.guestChildren = [
        ...this.formData.guestChildren.slice(0, rowIndex),
        ...this.formData.guestChildren.slice(rowIndex + 1),
      ];
    },
    save() {
      if (this.tempData.dateOfBirth === null || this.tempData.age === null) {
        this.formDataIncomplete = true;
        return;
      }

      this.formData.guestChildren[this.editIndex] = { ...this.tempData };
      this.tempData = null;
      this.formDataIncomplete = false;
    },
    cancel() {
      this.tempData = null;
    },
    submitChildren() {
      if (
        Number(this.totalGuestChildrenCount) !==
        this.formData.guestChildren.length
      ) {
        this.numberOfChildrenMismatch = true;
        return;
      }
      this.saveUpdatedDetails('final');
    },
  },
};
</script>
