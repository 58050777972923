<template functional>
  <div class="ru:arrow">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 135 40"
      class="ru:arrow__horizontal"
    >
      <path
        d="M104.255 14.519c-2.217-.813-4.747-1.865-8.22-3.61-2.496-1.244-1.94-4.912-1.065-6.992.525-1.245 1.59-2.588 2.736-3.292.906-.54 2.163-.884 3.165-.377 5.431 2.729 11.102 4.857 16.774 6.986 5.255 1.972 10.511 3.944 15.575 6.394 1.718.835 1.956 3.128 1.686 4.815-.366 2.325-1.575 4.094-3.356 5.486-2.417 1.884-5.089 3.423-7.857 4.733-7.505 3.586-15.416 5.836-23.339 8.089-3.51.998-7.024 1.997-10.505 3.113-1.082.344-2.131.016-2.927-.754-.89-.851-1.001-2.292-.97-3.455.064-2.735 1.972-5.88 4.565-6.715a188.752 188.752 0 0 1 6.568-1.965c1.674-.478 3.218-.885 4.681-1.258a450.723 450.723 0 0 0-12.485-.542c-11.264-.333-22.538-.306-33.814-.278 0 0-6.268.016-9.398.016-14.44.016-28.882-.164-43.291-1.245-2.815-.213-3.07-3.603-2.577-5.78.302-1.393 1.193-2.965 2.211-3.948.811-.77 2.036-1.539 3.197-1.457 8.08.606 16.174.933 24.27 1.097 9.504.194 19.008.168 28.512.142 4.905-.014 9.81-.028 14.716-.01 10.384.034 20.776.214 31.148.807Z"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 80"
      class="ru:arrow__vertical"
    >
      <path
        d="M14.519 49.307c-.813-2.213-1.865-4.74-3.61-8.205-1.244-2.493-4.912-1.937-6.992-1.064-1.245.524-2.588 1.588-3.292 2.731-.54.905-.884 2.16-.377 3.16 2.729 5.422 4.857 11.083 6.986 16.745 1.972 5.247 3.944 10.493 6.394 15.549.835 1.715 3.128 1.953 4.815 1.683 2.325-.365 4.094-1.572 5.486-3.35 1.884-2.413 3.423-5.081 4.733-7.844 3.586-7.493 5.836-15.39 8.089-23.3.998-3.504 1.997-7.01 3.113-10.486.344-1.08.016-2.128-.754-2.922-.851-.889-2.292-1-3.455-.968-2.735.063-5.88 1.968-6.715 4.556a188.235 188.235 0 0 0-1.965 6.558c-.478 1.67-.885 3.212-1.258 4.673 0 0-.007-6.692-.007-9.816.016-14.417-.164-19.849-1.245-34.234-.212-2.81-3.602-3.064-5.78-2.572-1.393.302-2.965 1.19-3.947 2.207-.77.81-1.54 2.032-1.458 3.191.606 8.066.934 7.163 1.097 15.244.195 9.488.168 18.976.142 28.464Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ComponentsAtomsArrow',
};
</script>

<style lang="scss">
#{$ru} {
  &arrow {
    stroke: none;
    fill: --rgba(lighter);

    svg {
      max-width: 100%;
    }

    &__vertical {
      width: 2.5rem;
      height: 5rem;
    }

    &__horizontal {
      display: none;
    }

    @include mq('min-md') {
      display: flex;
      height: 100%;

      svg {
        max-width: 100%;
        margin: auto;
      }

      &__horizontal {
        display: block;
        width: 8.5rem;
        height: 2.5rem;
      }

      &__vertical {
        display: none;
      }
    }
  }
}
</style>
