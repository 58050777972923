<template>
  <component
    :is="element"
    :id="id"
    :to="disabled ? '' : localePath(to)"
    :href="disabled ? null : href ? localePath(href) : null"
    :target="href ? '_blank' : null"
    :rel="href ? 'noopener' : null"
    class="ru:navigation__item"
    :class="`${activeClass} ${disabledClass}`"
    @click.stop="
      if (!to) {
        $emit('click');
      }
    "
  >
    <Icon v-if="icon" :id="icon" :theme="iconTheme" />
    <span class="ru:navigation__item-text">
      {{ text }}
    </span>
    <slot />
  </component>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/atoms/Icon';
export default {
  name: 'ComponentsMoleculesNavigationItem',
  components: {
    Icon,
  },
  props: {
    to: {
      default: null,
      type: String,
    },
    href: {
      default: null,
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    iconTheme: {
      default: null,
      type: String,
    },
    text: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      default: null,
      type: String,
    },
  },
  computed: {
    ...mapGetters('affiliate', ['slug']),
    element() {
      if (this.href) {
        return 'a';
      } else if (this.to) {
        return 'nuxt-link';
      } else {
        return 'button';
      }
    },
    activeClass() {
      const link = this.href || this.to;
      const linkWithAffiliateSlug = `/${this.slug}${link}`;

      let isActive = false;
      if (link === '/') {
        isActive = this.localePath(linkWithAffiliateSlug) === this.$route.path;
      } else {
        isActive = this.$route.path.includes(linkWithAffiliateSlug);
      }
      return isActive ? '--active' : '';
    },
    disabledClass() {
      return this.disabled ? '--disabled' : '';
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &navigation {
    &__item {
      align-items: center;
      border-radius: var(--border-radius);
      cursor: pointer;
      color: --rgba(gravel);
      display: flex;
      font-size: var(--base-font-size);
      font-weight: 500;
      transition: --out();
      padding: var(--link-padding, 1rem) 1.25rem;

      &:is(#{$mf}active),
      &:hover {
        background: --rgba(lighter);
        transition: --in();

        &,
        #{$ru}icon,
        #{$ru}icon#{$mf}themed {
          color: --rgba(basalt);
        }
      }

      &-text {
        margin-right: auto;
        margin-left: var(--navigation-text-indent, 0);

        @at-root #{$ru}navig {
          display: none;
        }
      }

      #{$ru}icon + &-text {
        margin-left: 0;
      }

      #{$ru}icon {
        flex-shrink: 0;
        width: 1.5rem;

        &:is(:first-child) {
          margin-right: calc(
            (1 - var(--is-desktop-menu-collapsed)) * var(--base-margin-small)
          );
        }
      }

      @include mq('min-lg') {
        @at-root #{$mf}desktop-menu-is-collapsed & :not(:first-child) {
          display: none;
        }
      }
    }
  }
}
</style>
