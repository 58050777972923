<template>
  <div v-if="formData.partnership" class="grid">
    <div class="cell cell__8/12">
      <AdminBackLink to="/admin/partnerships">Partnerships</AdminBackLink>
      <ValidationObserver ref="observer">
        <AdminCard :title="`Partner: ${formData.partnership.name}`">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updatePartnership"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="name"
            v-model="formData.partnership.name"
            type="text"
            label="Name"
            rules="required"
            :disabled="loading"
          />
          <AdminCardRow
            id="displayName"
            v-model="formData.partnership.displayName"
            type="text"
            label="Display Name"
            :disabled="loading"
          />
          <AdminCardRow
            id="slug"
            v-model="formData.partnership.slug"
            type="text"
            label="Slug"
            :disabled="loading"
          />
          <AdminCardRow
            id="abn"
            v-model="formData.partnership.abn"
            type="text"
            label="ABN"
            :disabled="loading"
          />
          <AdminCardRow
            id="address"
            v-model="formData.partnership.address"
            type="text"
            label="Address"
            :disabled="loading"
          />
          <AdminCardRow
            id="country"
            v-model="formData.partnership.country"
            type="select"
            label="Country"
            :disabled="loading"
            :options="['AU', 'NZ']"
            :rules="formData.partnership.active ? 'required' : ''"
          />
          <AdminCardRow id="categories" label="Categories" type="custom">
            <div
              v-for="(category, value) in availableCharityCategories"
              :key="category"
            >
              <label class="block w-full cursor-pointer">
                <input
                  v-model="formData.partnership.categories"
                  type="checkbox"
                  :value="value"
                />
                {{ category }}
              </label>
            </div>
          </AdminCardRow>
          <AdminCardRow
            id="logo"
            v-model="formData.partnership.logo"
            type="text"
            label="Logo"
            :disabled="loading"
          />
          <AdminCardRow
            id="description"
            v-model="formData.partnership.description"
            type="textarea"
            label="Description"
            :disabled="loading"
          />
          <AdminCardRow
            id="membershipFee"
            v-model="formData.partnership.membershipFee"
            type="number"
            label="Membership fee"
            :disabled="loading"
          />
          <AdminCardRow
            id="featuredFive"
            v-model="formData.partnership.featuredFive"
            type="switch"
            label="Featured five"
            :disabled="loading"
          />
          <AdminCardRow
            id="bequestEnabled"
            v-model="formData.partnership.bequestEnabled"
            type="switch"
            label="Bequest enabled"
            :disabled="loading"
          />
        </AdminCard>

        <AdminCard title="Affiliate">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updatePartnership"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="affiliateEnabled"
            v-model="formData.partnership.affiliateEnabled"
            type="switch"
            label="Affiliate enabled"
            :disabled="loading"
          />
          <template v-if="formData.partnership.affiliateEnabled">
            <AdminCardRow
              id="dashboardEnabled"
              v-model="formData.partnership.affiliateFeatures.dashboardEnabled"
              type="switch"
              label="Dashboard enabled"
              :disabled="loading"
            />
            <AdminCardRow
              id="willEnabled"
              v-model="formData.partnership.affiliateFeatures.willEnabled"
              type="switch"
              label="Will enabled"
              :disabled="loading"
            />
            <AdminCardRow
              id="powerOfAttorneyEnabled"
              v-model="
                formData.partnership.affiliateFeatures.powerOfAttorneyEnabled
              "
              type="switch"
              label="Power of attorney enabled"
              :disabled="loading"
            />
            <AdminCardRow
              id="endOfLifeEnabled"
              v-model="formData.partnership.affiliateFeatures.endOfLifeEnabled"
              type="switch"
              label="End of life enabled"
              :disabled="loading"
            />
            <AdminCardRow
              id="giftEnabled"
              v-model="formData.partnership.affiliateFeatures.giftEnabled"
              type="switch"
              label="Gift enabled"
              :disabled="loading"
            />
            <AdminCardRow
              id="checkoutLegalAdviceEnabled"
              v-model="
                formData.partnership.affiliateFeatures
                  .checkoutLegalAdviceEnabled
              "
              type="switch"
              label="Checkout Legal Advice enabled"
              :disabled="loading"
            />
            <AdminCardRow
              id="affiliateUserIdEnabled"
              v-model="
                formData.partnership.affiliateFeatures.affiliateUserIdEnabled
              "
              type="switch"
              label="Affiliate User ID enabled"
              :disabled="loading"
            />
            <template
              v-if="
                formData.partnership.affiliateFeatures.affiliateUserIdEnabled
              "
            >
              <AdminCardRow
                id="affiliateUserIdFieldName"
                v-model="
                  formData.partnership.affiliateFeatures
                    .affiliateUserIdFieldName
                "
                type="text"
                label="User ID Field Name"
                rules="required"
                :disabled="loading"
              />
            </template>
            <AdminCardRow
              id="couponCodeEnabled"
              v-model="formData.partnership.affiliateFeatures.couponCodeEnabled"
              type="switch"
              label="Coupon Code enabled"
              :disabled="loading"
            />
            <template
              v-if="formData.partnership.affiliateFeatures.couponCodeEnabled"
            >
              <AdminCardRow
                id="couponCodeFieldName"
                v-model="
                  formData.partnership.affiliateFeatures.couponCodeFieldName
                "
                type="text"
                label="Coupon Code Field Name"
                rules="required"
                :disabled="loading"
              />
            </template>
          </template>
        </AdminCard>

        <AdminCard title="Branding">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updatePartnership"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="brandingTheme"
            v-model="formData.partnership.branding.theme"
            type="text"
            label="Theme"
            :disabled="loading"
          />
          <AdminCardRow
            id="brandingDarker"
            v-model="formData.partnership.branding.darker"
            type="text"
            label="Darker"
            :disabled="loading"
          />
          <AdminCardRow
            id="brandingLighter"
            v-model="formData.partnership.branding.lighter"
            type="text"
            label="Lighter"
            :disabled="loading"
          />
          <AdminCardRow
            id="brandingLandingImage"
            v-model="formData.partnership.branding.landingImage"
            type="text"
            label="Landing image"
            :disabled="loading"
          />
        </AdminCard>

        <AdminCard
          v-if="formData.partnership.parentPartnershipId"
          title="National Partnership"
        >
          <AdminCardRow
            id="name"
            v-model="formData.partnership.parentPartnershipId"
            type="select"
            label="Name"
            :disabled="true"
            :options="partnershipsSelectList"
          />
        </AdminCard>

        <AdminCard v-else title="Regions">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--add"
              @click="addRegionalPartnership"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="plus" size="small" classes="mr-2" />
                Add partnership
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updatePartnership"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
          </div>

          <AdminCard
            v-for="(partnership, index) of formData.partnership
              .regionalPartnerships"
            :key="partnership.id"
            :title="`Partnership ${index + 1}`"
          >
            <div class="admin-card__buttons">
              <BaseButton
                class="admin-card__button admin-card__button--remove"
                @click="removeRegionalPartnership(partnership)"
              >
                <div class="admin-card__button-content">
                  <BaseIcon id="minus" size="small" classes="mr-2" />
                  Remove
                </div>
              </BaseButton>
            </div>

            <AdminCardRow
              id="region"
              :key="'region-' + partnership.id"
              v-model="partnership.region"
              type="select"
              label="Region"
              :disabled="loading"
              :options="$auStates"
              rules="required"
            />
            <AdminCardRow
              id="name"
              :key="'regional-partnership-' + partnership.id"
              v-model="partnership.id"
              type="select"
              label="Name"
              :disabled="loading"
              :options="partnershipsSelectList"
              rules="required"
            />
          </AdminCard>
        </AdminCard>

        <AdminCard title="Related Partnerships">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--add"
              @click="addRelatedPartnership"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="plus" size="small" classes="mr-2" />
                Add partnership
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updatePartnership"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
          </div>
          <AdminCard
            v-for="(related, index) of formData.partnership.relatedPartnerships"
            :key="index"
            :title="`Partnership ${index + 1}`"
          >
            <div class="admin-card__buttons">
              <BaseButton
                class="admin-card__button admin-card__button--remove"
                @click="removeRelatedPartnership(related)"
              >
                <div class="admin-card__button-content">
                  <BaseIcon id="minus" size="small" classes="mr-2" />
                  Remove
                </div>
              </BaseButton>
            </div>

            <AdminCardRow
              id="name"
              :key="'related-' + related.id"
              v-model="related.id"
              type="select"
              label="Name"
              rules="required"
              :disabled="loading"
              :options="partnershipsSelectList"
            />
          </AdminCard>
        </AdminCard>

        <AdminCard title="Dashboards">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updatePartnership"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
          </div>
          <AdminCardRow
            id="dashboardEnabled"
            v-model="formData.partnership.dashboardEnabled"
            type="switch"
            label="Dashboard enabled"
            :disabled="loading"
          />
          <AdminCardRow
            id="altNames"
            v-model="altNames"
            type="textarea"
            label="Alternate names (one per line)"
            :disabled="loading"
          />
          <AdminCardRow
            id="landingPages"
            v-model="landingPages"
            type="textarea"
            label="Landing pages (one per line)"
            :disabled="loading"
          />
          <AdminCardRow
            id="utmPrefixes"
            v-model="utmPrefixes"
            type="textarea"
            label="UTM Prefixes (one per line)"
            :disabled="loading"
          />
        </AdminCard>

        <AdminCard title="Contacts">
          <div class="admin-card__buttons">
            <BaseButton
              class="admin-card__button admin-card__button--add"
              @click="addContact"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="plus" size="small" classes="mr-2" />
                Add Contact
              </div>
            </BaseButton>
            <BaseButton
              class="admin-card__button admin-card__button--save"
              @click="updatePartnership"
            >
              <div class="admin-card__button-content">
                <BaseIcon id="tick" size="small" classes="mr-2" />
                Save
              </div>
            </BaseButton>
          </div>

          <AdminCard
            v-for="(contact, index) of formData.partnership.contacts"
            :key="contact.id"
            :title="`Contact ${index + 1}`"
          >
            <div class="admin-card__buttons">
              <BaseButton
                class="admin-card__button admin-card__button--remove"
                @click="removeContact(index)"
              >
                <div class="admin-card__button-content">
                  <BaseIcon id="minus" size="small" classes="mr-2" />
                  Remove
                </div>
              </BaseButton>
            </div>
            <AdminCardRow
              v-if="contact.email"
              id="email"
              v-model="contact.email"
              type="email"
              label="Email"
              rules="required"
              :disabled="true"
            />
            <AdminCardRow
              v-if="!contact.email"
              id="emailSearch"
              key="emailSearch"
              v-model="emailSearch"
              type="text"
              label="Search email"
              :disabled="false"
            />
            <AdminCardRow
              v-if="!contact.email"
              id="email"
              key="contactEmail"
              v-model="contact.userId"
              type="select"
              label="Email"
              :options="users"
              :disabled="loading || $apollo.loading"
            />
            <AdminCardRow
              v-if="contact.email"
              id="hasDashboardAccess"
              v-model="contact.hasDashboardAccess"
              type="switch"
              label="Has dashboard access"
              :disabled="loading"
            />
            <AdminCardRow
              v-if="contact.email"
              id="isSubscribedToEmails"
              v-model="contact.isSubscribedToEmails"
              type="switch"
              label="Is subscribed to emails"
              :disabled="loading"
            />
          </AdminCard>
        </AdminCard>

        <AdminCard title="Coupons">
          <AdminCardSection>
            <p v-if="!formData.coupons || !formData.coupons.length">
              There are no coupons associated with this partnership
            </p>
            <table v-else class="w-full">
              <thead>
                <th class="text-left">Code</th>
                <th class="text-left">Description</th>
                <th class="text-left">Active</th>
              </thead>
              <tbody>
                <tr v-for="coupon of formData.coupons" :key="coupon.id">
                  <td class="pr-4">
                    <nuxt-link
                      :to="localePath(`/admin/coupons/${coupon.id}`)"
                      class="text-teal-300 underline"
                    >
                      {{ coupon.code }}
                    </nuxt-link>
                  </td>
                  <td class="pr-4">
                    {{ coupon.description }}
                  </td>
                  <td class="text-right">
                    <BaseSwitch :value="coupon.active" />
                  </td>
                </tr>
              </tbody>
            </table>
          </AdminCardSection>
        </AdminCard>
      </ValidationObserver>
    </div>
    <div class="cell cell__4/12">
      <AdminCard title="Status">
        <AdminCardSection>
          <label class="flex items-center">
            <BaseSwitch
              v-model="formData.partnership.active"
              @change="setPartnershipActive(formData.partnership)"
            />
            <p class="ml-4">
              {{ formData.partnership.active ? 'Active' : 'Inactive' }}
            </p>
          </label>
        </AdminCardSection>
      </AdminCard>
      <AdminCard title="Actions">
        <AdminCardSection>
          <AdminActionLink
            glyph="remove"
            :disabled="formData.partnership.archived"
            class="m-0 p-0 border-none bg-transparent text-red-300 underline"
            @click="showPartnershipArchivedModal"
          >
            Archive
          </AdminActionLink>

          <p v-if="formData.partnership.archived" class="mt-2">
            Partnership is already archived
          </p>
        </AdminCardSection>
      </AdminCard>
      <AdminCard title="History">
        <AdminCardSection>
          <div class="text-sm">
            Updated at:
            {{ $formatDate(formData.partnership.updatedAt || Date.now()) }}
          </div>
          <div class="text-sm">
            Created at:
            {{ $formatDate(formData.partnership.createdAt || Date.now()) }}
          </div>
        </AdminCardSection>
      </AdminCard>
    </div>
    <Toast />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { charityCategories } from '@/modules/charityData';

import UPDATE_PARTNERSHIP_MUTATION from '@/graphql/mutations/UpdatePartnership';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import GET_PARTNERSHIP_QUERY from '@/graphql/queries/GetPartnership';
import GET_COUPONS_BY_PARTNERSHIPS from '@/graphql/queries/GetCouponsForPartnership';
import GET_USERS_QUERY from '@/graphql/queries/GetUsers';

import dialog from '@/mixins/message-box';
import AdminBackLink from '@/components/admin/BackLink';
import AdminCard from '@/components/admin/Card';
import AdminCardSection from '@/components/admin/CardSection';
import AdminActionLink from '@/components/admin/ActionLink';
import AdminCardRow from '@/components/admin/CardRow';
import BaseSwitch from '@/components/BaseSwitch';
import BaseIcon from '@/components/BaseIcon';
import BaseButton from '@/components/BaseButton';
import Toast from '@/components/Toast';

import { partnerships } from '@/mixins/apollo';
import { recursiveRemoveKey } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminPartnershipsId',
  components: {
    AdminBackLink,
    AdminCard,
    AdminCardSection,
    AdminActionLink,
    BaseSwitch,
    BaseIcon,
    BaseButton,
    Toast,
    AdminCardRow,
    ValidationObserver,
  },
  mixins: [dialog, partnerships],
  layout: 'admin',
  data() {
    return {
      loading: false,
      availableCharityCategories: charityCategories,
      formData: {
        partnerships: [],
        partnership: null,
        coupons: [],
      },
      emailSearch: '',
    };
  },
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      update: (data) => data?.getPartnerships,
      result({ data }) {
        this.formData.partnerships = data.getPartnerships;
      },
    },
    partnership: {
      query: GET_PARTNERSHIP_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          id: this.partnershipId,
        };
      },
      update: (data) => {
        return data && data.getPartnership;
      },
      skip() {
        return !this.partnershipId;
      },
      result({ data }) {
        const partnership = data.getPartnership;

        this.formData.partnership = {
          ...partnership,
          affiliateFeatures: partnership.affiliateFeatures || {
            dashboardEnabled: false,
            willEnabled: false,
            powerOfAttorneyEnabled: false,
            endOfLifeEnabled: false,
            giftEnabled: false,
            checkoutLegalAdviceEnabled: false,
            affiliateUserIdEnabled: false,
            couponCodeEnabled: false,
          },
          branding: partnership.branding || {},
        };
      },
    },
    coupons: {
      query: GET_COUPONS_BY_PARTNERSHIPS,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.partnershipId,
        };
      },
      update: (data) => data && data.getCouponsForPartnership,
      result({ data }) {
        this.formData.coupons = data.getCouponsForPartnership;
      },
    },
    users: {
      query: GET_USERS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          searchQuery: this.emailSearch,
          roles: ['PARTNERSHIP'],
          customOrder: { field: 'email', direction: 'ASC' },
        };
      },
      update: (data) =>
        data?.getUsers?.map((user) => ({
          text: user.email,
          value: user.id,
        })),
    },
  },
  computed: {
    partnershipId() {
      return this.$route.params.id;
    },
    landingPages: {
      get() {
        return this.formData.partnership.landingPages?.join('\n');
      },
      set(value) {
        this.formData.partnership.landingPages = value
          .split('\n')
          .filter((item) => item);
      },
    },
    altNames: {
      get() {
        return this.formData.partnership.altNames?.join('\n');
      },
      set(value) {
        this.formData.partnership.altNames = value
          .split('\n')
          .filter((item) => item);
      },
    },
    utmPrefixes: {
      get() {
        return this.formData.partnership.utmPrefixes?.join('\n');
      },
      set(value) {
        this.formData.partnership.utmPrefixes = value
          .split('\n')
          .filter((item) => item);
      },
    },
    partnershipsSelectList() {
      return (this.formData.partnerships || [])
        .filter((partnership) => {
          return partnership.id !== this.partnership.id;
        })
        .map((partnership) => {
          return {
            text: partnership.name,
            value: partnership.id,
          };
        });
    },
  },
  methods: {
    setPartnershipActive(partnership) {
      const missingFields = this.getPartnershipMissingFields(partnership);

      if (missingFields.length) {
        partnership.active = !partnership.active;
        return this.$nuxt.$emit('toast', {
          type: 'error',
          message:
            'The following field(s) are missing: ' + missingFields.join(', '),
        });
      }

      const title = `${partnership.active ? 'Activate' : 'Deactivate'} ${
        partnership.name
      }?`;
      const activationText = `Are you sure you want to activate ${partnership.name}?
         Activating this partnership will display the partnership on the platform and activate their dashboard.`;

      const deactivationText = `Are you sure you want to deactivate ${partnership.name}?
         Deactivating this partnership will remove the partnership from the platform and deactivate their dashboard.`;

      this.$confirm(
        {
          title,
          message: partnership.active ? activationText : deactivationText,
        },
        (action) => {
          if (action) {
            this.updatePartnership(partnership);
          } else {
            partnership.active = !partnership.active;
          }
        }
      );
    },
    showPartnershipArchivedModal() {
      this.$confirm(
        {
          title: `Archive ${this.formData.partnership.name}?`,
          message: 'Are you sure you want to archive this Partnership?',
        },
        (action) => {
          if (action) {
            this.updatePartnership({
              ...this.formData.partnership,
              archived: !this.formData.partnership.archived,
            });
          }
        }
      );
    },
    async updatePartnership(props = {}) {
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());

      if (!isValid) {
        return;
      }

      const partnership = {
        ...this.formData.partnership,
        ...props,
      };

      partnership.relatedPartnershipIds = partnership.relatedPartnerships
        .filter((partnership) => partnership.id)
        .map((partnership) => partnership.id);

      if (!partnership.affiliateEnabled) {
        partnership.affiliateFeatures = {
          willEnabled: false,
          powerOfAttorneyEnabled: false,
          endOfLifeEnabled: false,
          affiliateUserIdEnabled: false,
          couponCodeEnabled: false,
        };
      }

      if (!partnership.affiliateFeatures.affiliateUserIdEnabled) {
        delete partnership.affiliateFeatures.affiliateUserIdFieldName;
      }

      if (!partnership.affiliateFeatures.couponCodeEnabled) {
        delete partnership.affiliateFeatures.couponCodeFieldName;
      }

      delete partnership.region;
      delete partnership.parentPartnershipId;
      delete partnership.relatedPartnerships;

      partnership.contacts = partnership.contacts.map((contact) => {
        delete contact.email;
        return contact;
      });

      recursiveRemoveKey(partnership, '__typename');
      recursiveRemoveKey(partnership, 'createdAt');
      recursiveRemoveKey(partnership, 'updatedAt');

      this.loading = true;

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_PARTNERSHIP_MUTATION,
          variables: {
            partnership,
          },
        });
      } catch (error) {
        this.$nuxt.$emit('toast', {
          type: 'error',
          message: error.message,
        });
      }

      this.formData.partnership.contacts = partnership.contacts.map(
        (contact) => {
          if (!contact.email) {
            return {
              ...contact,
              email: this.users.find((user) => user.value === contact.userId)
                .text,
            };
          }
          return contact;
        }
      );

      this.loading = false;
    },
    addContact() {
      this.formData.partnership.contacts.push({
        partnershipId: this.partnershipId,
        hasDashboardAccess: true,
        isSubscribedToEmails: true,
        email: null,
        userId: null,
      });
    },
    async removeContact(index) {
      const backupContacts = this.formData.partnership.contacts;
      this.formData.partnership.contacts = [];
      backupContacts.splice(index, 1);
      await this.$nextTick();
      this.formData.partnership.contacts = backupContacts;
    },
    addRelatedPartnership() {
      this.formData.partnership.relatedPartnerships.push({
        id: null,
      });
    },
    removeRelatedPartnership(relatedPartnership) {
      this.formData.partnership.relatedPartnerships =
        this.formData.partnership.relatedPartnerships.filter(
          (partnership) => partnership.id !== relatedPartnership.id
        );
    },
    addRegionalPartnership() {
      this.formData.partnership.regionalPartnerships.push({
        id: null,
      });
    },
    removeRegionalPartnership(regionalPartnership) {
      this.formData.partnership.regionalPartnerships =
        this.formData.partnership.regionalPartnerships.filter(
          (partnership) => partnership.id !== regionalPartnership.id
        );
    },
  },
};
</script>
