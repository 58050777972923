<template>
  <ModuleStep :index="2" prev-step="/will/guardians/your-children">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      :auto-submit="!willMeta.has_primary_guardian"
      @done="submit"
    >
      <Headline :level="1">
        <i18n path="pages.will.guardians.appointGuardianPrompt">
          <GlossaryTooltip term="guardian">
            {{ $t('texts.words.guardian') }}
          </GlossaryTooltip>
        </i18n>
      </Headline>
      <p>
        {{ $t('pages.will.guardians.childGuardianExplanation') }}
      </p>
      <Tip>
        {{ $t('pages.will.guardians.survivingParentGuardian') }}
      </Tip>
      <LargeRadioButtons
        id="hasPrimaryGuardians"
        v-model="willMeta.has_primary_guardian"
        :options="[
          {
            label: $t('forms.labels.yes'),
            value: true,
          },
          {
            label: $t('forms.labels.no'),
            value: false,
          },
        ]"
      />
      <template v-if="willMeta.has_primary_guardian">
        <Headline :level="2">
          {{ $t('pages.will.guardians.primaryGuardianPrompt') }}
        </Headline>
        <p>
          {{ $t('pages.will.guardians.primaryGuardianExplanation') }}
        </p>
        <Tip>
          <p>
            {{ $t('pages.will.guardians.dontAppointOtherParent') }}
          </p>
          <Buttons layout="start">
            <RuButton
              theme="secondary"
              size="small"
              href="https://safewill.com/blog/posts/how-do-i-choose-a-guardian"
            >
              <Icon id="info" />
              {{ $t('pages.will.guardians.howChooseGuardian') }}
            </RuButton>
          </Buttons>
        </Tip>
        <PersonSelector
          v-model="selectedPrimaryGuardian"
          :loading="loading"
          :max="1"
          :people="filteredPeople"
          type="guardian"
        />
      </template>
    </AdiForm>
    <GlossaryPanel />
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';

import AdiForm from '@/components/Form';
import GlossaryTooltip from '@/components/GlossaryTooltip';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import GlossaryPanel from '@/components/GlossaryPanel';
import Icon from '@/components/atoms/Icon';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { guardians, people, user, will } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliateWillGuardiansPrimary',
  components: {
    AdiForm,
    GlossaryTooltip,
    LargeRadioButtons,
    ModuleStep,
    PersonSelector,
    Tip,
    Headline,
    GlossaryPanel,
    Icon,
    Buttons,
    RuButton,
  },
  mixins: [guardians, people, user, will],
  data() {
    return {
      selectedPrimaryGuardian: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        this.willMeta.has_primary_guardian === false ||
        this.selectedPrimaryGuardian.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        const personIsAlreadySelected = this.selectedPrimaryGuardian.includes(
          person.id
        );
        return (
          personIsAlreadySelected ||
          (meta.is_over_18 && !this.isBackupGuardian(person))
        );
      });
    },
    nextStep() {
      return this.localePath(
        this.willMeta.has_primary_guardian
          ? '/will/guardians/backup'
          : '/will/guardians/your-pets'
      );
    },
    variables() {
      return {
        id: this.willId,
        meta: this.willMeta.toArray(),
      };
    },
  },
  watch: {
    primaryGuardians(guardians) {
      if (guardians && !this.selectedPrimaryGuardian.length) {
        this.selectedPrimaryGuardian = this.people
          .filter((person) => {
            return guardians.find(
              (guardian) => guardian.person.id === person.id
            );
          })
          .map((person) => person.id);
      }
    },
  },
  methods: {
    ...mapActions('person', ['addPerson']),
    async submit() {
      for (const person of this.people) {
        const isPrimaryGuardian = this.isPrimaryGuardian(person);
        const isSelected = this.selectedPrimaryGuardian.includes(person.id);

        if (this.willMeta.has_primary_guardian && isSelected) {
          if (!isPrimaryGuardian) {
            await this.addGuardian(person, 'primary');
          }
        } else if (isPrimaryGuardian) {
          await this.removeGuardian(person);
        }
      }

      this.$router.push({
        path: this.nextStep,
      });
    },
  },
};
</script>
