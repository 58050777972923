var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":4,"prev-step":_vm.previous}},[_c('AdiForm',{attrs:{"disabled":!_vm.isComplete},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.spending.title'))+"\n    ")]),_vm._v(" "),(['nsw', 'wa', 'sa'].includes(_vm.userState))?_c('Tip',[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.spending.tipNswWaSa'))+"\n    ")]):_c('Tip',[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.spending.tipOtherStates'))+"\n    ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_inclusions","options":[
        {
          label: _vm.$t('forms.labels.yes'),
          value: true,
        },
        {
          label: _vm.$t('forms.labels.no'),
          value: false,
        },
      ]},on:{"input":_vm.onChange},model:{value:(_vm.poaFinancialMeta.has_inclusions),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "has_inclusions", $$v)},expression:"poaFinancialMeta.has_inclusions"}}),_vm._v(" "),(_vm.poaFinancialMeta.has_inclusions)?[_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$upperFirst(_vm.$t('texts.words.people')))+"\n      ")]),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"people":_vm.people,"will-beneficiary-selector":false,"min":1},model:{value:(_vm.selectedInclusionPeopleIDs),callback:function ($$v) {_vm.selectedInclusionPeopleIDs=$$v},expression:"selectedInclusionPeopleIDs"}})]:_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }