<template>
  <div class="ru:container ru:container--snug ru:will-completion">
    <Box background="white" border="iron" class="ru:will-completion__box">
      <Headline :level="1">
        {{ $t(`pages.will.completion.${willState}.title`) }}
      </Headline>
      <Alert level="medium" theme="warning" icon="warning">
        {{ $t(`pages.will.completion.${willState}.alertMessage`) }}
      </Alert>
      <hr />
      <Headline :level="2">
        {{ $t(`pages.will.completion.${willState}.nextSteps.title`) }}
      </Headline>
      <div
        v-for="(step, key, index) in $t(
          `pages.will.completion.${willState}.nextSteps.steps`
        )"
        :key="key"
        class="grid"
      >
        <div class="cell">
          <div class="ru:will-completion__number">
            <BigNumber :number="`0${index + 1}`" />
          </div>
        </div>
        <div class="cell cell__fill">
          <div class="ru:will-completion__step">
            <Headline :level="2">
              {{ step.title }}
            </Headline>
            <p>
              {{ step.description }}
            </p>
          </div>
        </div>
      </div>
      <hr />
      <Buttons layout="column" class="ru:will-completion__buttons">
        <RuButton
          :href="localePath('/checkout/will')"
          size="large"
          level="primary"
          data-test="will-completion-cta"
        >
          {{ $t(`pages.will.completion.${willState}.cta`) }}
          <Icon id="arrow-right" />
        </RuButton>
        <RuButton
          level="tertiary"
          theme="slate"
          underline
          data-test="view-details-cta"
          @click="hideCompletionPage"
        >
          {{ $t('pages.will.completion.viewDetailsCTA') }}
        </RuButton>
      </Buttons>
    </Box>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Box from '@/components/atoms/Box';
import BigNumber from '@/components/atoms/BigNumber';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import Alert from '@/components/molecules/Alert';

import willProgress from '@/mixins/will-progress';

import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';

export default {
  name: 'PagesAffiliateWillCompletion',
  components: {
    Box,
    BigNumber,
    Headline,
    Buttons,
    RuButton,
    Icon,
    Alert,
  },
  mixins: [willProgress],
  async middleware({ redirect, store, app, localePath }) {
    const { data } = await app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_PURCHASED_PRODUCTS_QUERY,
      variables: {
        userId: store.getters.userId,
      },
    });

    if (data?.getPurchasedProducts?.products?.includes('WILL')) {
      return redirect(localePath('/will'));
    }
  },
  data() {
    return {
      willState: 'willNotPurchased',
    };
  },
  computed: {
    ...mapGetters(['willStatus']),
    isCompletedPageVisible() {
      return (
        this.$ff.willCompletionPageEnabled() &&
        this.willStatus === 'IN_PROGRESS' &&
        this.allModulesCompleted
      );
    },
  },
  mounted() {
    if (!this.isCompletedPageVisible) {
      this.$router.push({ path: this.localePath('/'), replace: true });
    }
  },
  methods: {
    ...mapActions('will', ['setShowCompletionPage']),
    hideCompletionPage() {
      this.setShowCompletionPage(false);
      this.$router.push({ path: this.localePath('/will') });
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &will-completion {
    &__box {
      padding: 2rem;
    }

    &__number {
      width: 5.5rem;
    }

    &__step {
      h2 {
        margin-bottom: var(--base-margin-small) !important;
      }

      p {
        color: --rgba(rock);
      }
    }
  }
}
</style>
