<template>
  <Portal to="stack">
    <transition
      appear
      mode="out-in"
      :duration="250"
      name="ru:wiggle-fade-squish"
      @after-leave="onAfterLeave"
    >
      <div class="ru:modal" :class="[size ? `--${size}` : '']">
        <div
          :key="'modal__' + id"
          v-on-dismiss="{
            watch: isDismissable,
            callback: closeModal,
          }"
          class="ru:modal__content"
        >
          <div class="ru:modal__close">
            <Buttons theme="default" layout="end">
              <RuButton
                v-if="isDismissable"
                level="plain"
                data-test="close-button"
                @click="closeModal"
              >
                <Icon id="close" />
              </RuButton>
            </Buttons>
          </div>
          <div v-if="showHeader" class="ru:modal__header">
            <slot name="header" />
          </div>
          <div class="ru:modal__body">
            <slot name="body" />
          </div>
        </div>
      </div>
    </transition>
  </Portal>
</template>

<script>
import VDismiss from 'vue-dismiss';
import { Portal } from 'portal-vue';

import Icon from '@/components/atoms/Icon';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsMoleculesModal',
  components: {
    Icon,
    Buttons,
    RuButton,
    Portal,
  },
  mixins: [VDismiss],
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    isDismissable: {
      type: Boolean,
      default: true,
    },
    watch: {
      type: Boolean,
      default: true,
    },
    size: {
      default: null,
      type: String,
      validator(value) {
        return ['default', 'large'].includes(value);
      },
    },
    id: {
      default: '',
      type: String,
    },
  },
  computed: {
    showHeader() {
      return !this.hideHeader;
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    onAfterLeave() {
      this.$emit('modalHasClosed');
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &modal {
    --margin: var(--base-margin);
    position: fixed;
    inset: 0;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--margin);

    &__close {
      min-height: var(--app-height);
      display: flex;
      position: absolute;
      align-items: center;
      top: 0;
      right: var(--margin);
    }

    &__header {
      background: --rgba(white);
      min-height: var(--app-height);
      font-weight: 500;
      padding: var(--margin) #{calc(var(--margin) * 4)} var(--margin) var(--margin);
      display: flex;
      align-items: center;
    }

    &__content {
      border-radius: var(--border-radius);
      max-height: #{calc(
          100vh - (var(--app-logo-height) + var(--app-padding-y) * 2)
        )};
      width: min(100%, 30rem);
      background: --rgba(body-background);
      box-shadow: var(--base-box-shadow);
      position: relative;
      overflow-y: auto;
    }

    &__body {
      padding: var(--margin);

      > :is(:last-child) {
        margin-bottom: 0;
      }
    }

    @include mq('min-lg') {
      &#{$mf}large {
        --margin: var(--base-margin-big);
      }

      &#{$mf}large &__close {
        min-height: calc(2rem + 2 * var(--margin));
      }

      &#{$mf}large &__content {
        width: min(100%, 56rem);
      }

      &#{$mf}large &__body {
        padding-top: var(--base-margin);
      }
    }
  }
}
</style>
