<template>
  <ModuleStep :index="5" prev-step="/will/guardians/your-pets">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="submit"
    >
      <Headline :level="1">
        {{ $t('pages.will.guardians.appointPetGuardian') }}
      </Headline>
      <LargeRadioButtons
        id="hasPetGuardians"
        v-model="willMeta.has_pet_guardians"
        :options="[
          {
            label: $t('forms.labels.yes'),
            value: true,
          },
          {
            label: $t('forms.labels.no'),
            value: false,
          },
        ]"
      />
      <template
        v-if="willMeta.has_pet_guardians && selectedPetGuardians.length"
      >
        <MetaSlot
          v-for="(pet, index) in pets"
          :key="index"
          v-slot="{ meta }"
          :meta="pet.meta"
        >
          <Headline :level="2">
            {{
              $t('pages.will.guardians.appointAGuardianFor', {
                name: meta.name,
              })
            }}
          </Headline>
          <p>
            {{ $t('pages.will.peopleSelectionInstructions') }}
          </p>
          <PersonSelector
            :id="pet.id"
            v-model="selectedPetGuardians[index]"
            :loading="loading"
            :max="1"
            :people="filteredPeople[index]"
            type="guardian"
          />
        </MetaSlot>
      </template>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import MetaSlot from '@/components/MetaSlot';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Headline from '@/components/atoms/Headline';

import { pets, people, user, will } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliateWillGuardiansPetGuardians',
  components: {
    AdiForm,
    LargeRadioButtons,
    MetaSlot,
    ModuleStep,
    PersonSelector,
    Headline,
  },
  mixins: [pets, people, user, will],
  data() {
    return {
      selectedPetGuardians: [],
    };
  },
  computed: {
    isComplete() {
      const assignedPets = this.selectedPetGuardians.filter((arr) => {
        return arr.length;
      });
      return !!(
        this.willMeta.has_pet_guardians === false ||
        assignedPets.length === this.pets.length
      );
    },
    filteredPeople() {
      return this.pets.map((pet, index) => {
        return this.people.filter((person) => {
          const meta = metaArrayToObject(person.meta);
          const personIsAlreadySelected = this.selectedPetGuardians[
            index
          ].includes(person.id);
          return personIsAlreadySelected || meta.is_over_18;
        });
      });
    },
  },
  watch: {
    pets() {
      this.updateSelectedPetGuardians();
    },
    people() {
      this.updateSelectedPetGuardians();
    },
  },
  methods: {
    updateSelectedPetGuardians() {
      this.selectedPetGuardians = this.pets.map((pet) => {
        const person = this.people.find(
          (person) => pet.person && pet.person.id === person.id
        );
        return person ? [person.id] : [];
      });
    },
    async submit() {
      await Promise.all(
        this.pets.map((pet, i) => {
          const selectedGuardian = this.selectedPetGuardians[i][0];
          if (this.willMeta.has_pet_guardians) {
            if (!pet.person || pet.person.id !== selectedGuardian) {
              return this.addPetGuardian(pet, selectedGuardian);
            }
          } else if (pet.person) {
            return this.removePetGuardian(pet);
          }
          return Promise.resolve();
        })
      );
      this.$router.push({
        path: this.localePath('/will/guardians/pet-care-fund'),
      });
    },
  },
};
</script>
