<template>
  <ModuleStep :index="3" prev-step="/will/estate/beneficiaries">
    <AdiForm :disabled="!isComplete" @submit="submit">
      <Headline :level="1">
        {{ $t('pages.will.estate.beneficiaryProportionsQuestion') }}
      </Headline>
      <Tip>
        {{ $t('pages.will.estate.beneficiaryProportionsNote') }}
      </Tip>
      <FormControl
        id="isPrimaryEstateSplitEvenly"
        v-model="isPrimaryEstateSplitEvenly"
        type="checkbox"
        format="boolean"
        :text="$t('pages.will.estate.splitEvenlyCheckbox')"
        :required="false"
      />
      <BeneficiaryInputs
        v-model="distributions"
        :beneficiaries="beneficiaries"
        :is-split-evenly="isPrimaryEstateSplitEvenly"
        @valid="checkValid"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';

import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import BeneficiaryInputs from '@/components/BeneficiaryInputs';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import FormControl from '@/components/molecules/FormControl';

import { will, beneficiaries, user } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillEstateDistribution',
  components: {
    FormControl,
    AdiForm,
    ModuleStep,
    BeneficiaryInputs,
    Tip,
    Headline,
  },
  mixins: [will, beneficiaries, user],
  data() {
    return {
      distributions: [],
      valid: false,
      isPrimaryEstateSplitEvenly: false,
    };
  },
  computed: {
    ...mapGetters('ruach', ['shouldShowCharityConsentPage']),
    isComplete() {
      return !!this.valid;
    },
  },
  watch: {
    will(newWillValue) {
      this.isPrimaryEstateSplitEvenly = newWillValue.isPrimaryEstateSplitEvenly;
    },
  },
  methods: {
    checkValid(valid) {
      this.valid = valid;
    },
    async submit() {
      await this.updateWillIsPrimaryEstateSplitEvenly(
        this.isPrimaryEstateSplitEvenly
      );
      await Promise.all(
        this.beneficiaries.map((beneficiary, i) => {
          return this.updateBeneficiaryDistribution(
            beneficiary,
            this.isPrimaryEstateSplitEvenly ? null : this.distributions[i]
          );
        })
      );

      const hasPeople = this.beneficiaries.find((b) => b.person !== null);

      if (hasPeople) {
        this.$router.push({
          path: this.localePath(
            `/will/estate/backup-beneficiaries/${this.backupQueue[0].id}`
          ),
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module 4 (Estate)',
        });
        await this.refetchWill();
        await this.$updateShouldShowCharityConsent();
        const path = this.localePath(
          this.shouldShowCharityConsentPage ? '/will/charity-consent' : '/will'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
</script>
