<template functional>
  <div class="ru:big-number">{{ props.number }}</div>
</template>

<script>
export default {
  name: 'ComponentsAtomsBigNumber',
  props: {
    number: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &big-number {
    color: --rgba(theme);
    font-weight: 500;
    margin-bottom: var(--base-margin);
    line-height: 1;
    font-size: 2rem;
    @include mq('min-md') {
      font-size: 3rem;
    }
    @include mq('min-lg') {
      margin-bottom: var(--base-margin-big);
      font-size: 4rem;
    }
  }
}
</style>
