<template>
  <ModuleStep :index="6" :prev-step="previous">
    <AdiForm
      v-slot="{ loading }"
      :disabled="!isComplete"
      @submit.prevent="onSubmit"
    >
      <Headline :level="1">
        {{ $t('pages.poa.medical.limitations.headline') }}
      </Headline>
      <template v-if="['nsw'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.nsw.decisionMakerScope') }}
        </p>
        <ul>
          <li>
            {{ $t('pages.poa.medical.limitations.nsw.livingArrangements') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.nsw.personalServices') }}
          </li>
          <li>{{ $t('pages.poa.medical.limitations.nsw.healthcare') }}</li>
        </ul>
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.nsw.laterQuestion') }}
        </p>
      </template>
      <template v-else-if="['vic'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.vic.decisionMakerScope') }}
        </p>
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.vic.limitations') }}
        </p>
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.vic.laterQuestion') }}
        </p>
      </template>
      <template v-else-if="['qld'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.qld.decisionMakerScope') }}
        </p>
        <ul>
          <li>
            {{ $t('pages.poa.medical.limitations.qld.personalAffairs') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.qld.supportServices') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.qld.employment') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.qld.healthcare') }}
          </li>
        </ul>
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.qld.laterQuestion') }}
        </p>
      </template>
      <template v-else-if="['act'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.act.decisionMakerScope') }}
        </p>
        <ul>
          <li>
            {{ $t('pages.poa.medical.limitations.act.personalAffairs') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.act.healthcare') }}
          </li>
        </ul>
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.act.withholdConsent') }}
        </p>
      </template>
      <template v-else-if="['wa'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.wa.decisionMakerScope') }}
        </p>
        <ul>
          <li>
            {{ $t('pages.poa.medical.limitations.wa.livingArrangements') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.wa.employment') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.wa.association') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.wa.healthcare') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.wa.medicalResearch') }}
          </li>
        </ul>
      </template>
      <template v-else-if="['sa'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.sa.bindingRefusals') }}
        </p>
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.sa.stateCircumstances') }}
        </p>
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.sa.laterQuestion') }}
        </p>
      </template>
      <template v-else-if="['tas'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.tas.generalDecisions') }}
        </p>
        <ul>
          <li>
            {{ $t('pages.poa.medical.limitations.tas.personalAffairs') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.tas.healthCare') }}
          </li>
        </ul>
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.tas.laterQuestion') }}
        </p>
      </template>
      <template v-else-if="['nt'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.nt.generalDecisions') }}
        </p>
        <ul>
          <li>
            {{ $t('pages.poa.medical.limitations.nt.accommodation') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.nt.dailyLivingIssues') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.nt.supportServices') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.nt.association') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.limitations.nt.healthCare') }}
          </li>
        </ul>
        <p class="mb-4">
          {{ $t('pages.poa.medical.limitations.nt.laterQuestion') }}
        </p>
      </template>
      <LargeRadioButtons
        id="has_limitations"
        v-model="poaMedicalMeta.has_limitations"
        :options="[
          {
            label: $t('forms.labels.yes'),
            value: true,
          },
          {
            label: $t('forms.labels.no'),
            value: false,
          },
        ]"
        @input="onChange"
      />
      <div v-if="poaMedicalMeta.has_limitations" class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="funeral_notes"
            v-model="poaMedicalMeta.limitations"
            :disabled="loading"
            type="textarea"
            rules="required|max:3000"
            :placeholder="$t('forms.placeholders.itIsMyInstruction')"
          />
        </div>
      </div>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  queries as metaQueries,
  mutations as metaMutations,
} from '@/modules/apollo-queries/poa-meta';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import FormControl from '@/components/molecules/FormControl';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalLimitations',
  components: {
    AdiForm,
    ModuleStep,
    LargeRadioButtons,
    FormControl,
    Headline,
  },
  mixins: [user, will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'MEDICAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    poaMedicalMeta: {
      ...metaQueries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      selectedPrimaryAttorneyPersonIDs: [],
      poaMedicalMeta: {
        has_limitations: undefined,
        limitations: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        poaMedicalMeta: this.poaMedicalMeta,
        numPrimaryAttorneysSelected:
          this.selectedPrimaryAttorneyPersonIDs.length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      return (
        this.poaMedicalMeta.has_limitations === false ||
        (this.poaMedicalMeta.has_limitations === true &&
          this.poaMedicalMeta.limitations?.length > 0)
      );
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length) {
        this.selectedPrimaryAttorneyPersonIDs = attorneys
          .filter((attorney) => attorney.role === 'PRIMARY')
          .map((attorney) => attorney.person.id);
      }
    },
  },
  methods: {
    onChange(value) {
      if (value === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await this.$apollo.mutate(
        metaMutations.updatePoaMetas(this.poaId, [
          {
            key: 'has_limitations',
            value: this.poaMedicalMeta.has_limitations ? 'true' : 'false',
            category: 'MEDICAL',
          },
          {
            key: 'limitations',
            value: this.poaMedicalMeta.has_limitations
              ? this.poaMedicalMeta.limitations
              : null,
            category: 'MEDICAL',
          },
        ])
      );
      const path = this.localePath(this.next);
      await this.$router.push({ path });
    },
  },
};
</script>
