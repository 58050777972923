var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/will"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"disabled":!_vm.isComplete},on:{"done":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_c('i18n',{attrs:{"path":"pages.will.guardians.doYouHaveAny"}},[_c('GlossaryTooltip',{attrs:{"term":"child"}},[_vm._v("\n          "+_vm._s(_vm.$t('texts.words.children'))+"\n        ")])],1)],1),_vm._v(" "),_c('Tip',[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.guardians.chooseGuardianTip'))+"\n    ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasChildren","options":[
        {
          label: _vm.$t('forms.labels.yes'),
          value: true,
        },
        {
          label: _vm.$t('forms.labels.no'),
          value: false,
        },
      ]},model:{value:(_vm.willMeta.has_children),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_children", $$v)},expression:"willMeta.has_children"}}),_vm._v(" "),(_vm.willMeta.has_children)?[_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.guardians.myChildrenAre'))+"\n      ")]),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"people":_vm.filteredPeople,"type":"child"},model:{value:(_vm.selectedChildren),callback:function ($$v) {_vm.selectedChildren=$$v},expression:"selectedChildren"}})]:_vm._e()]}}])}),_vm._v(" "),_c('GlossaryPanel')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }