<template>
  <div class="ru: ru:ach" :style="styles" @scroll="onScroll">
    <App />
    <Navigation v-if="showNavigation" :variant="navigationVariant" />
    <nuxt />
    <Notices />
    <Portal v-if="shouldShowCharityConsentModal" to="stack">
      <CharitiesConsentModal />
    </Portal>
    <PortalTarget ref="stack" class="ru:stack" name="stack" multiple />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { throttle } from 'throttle-debounce';
import { Portal, PortalTarget } from 'portal-vue';

import App from '@/components/organisms/App';
import Navigation from '@/components/organisms/Navigation';
import Notices from '@/components/organisms/Notices';
import CharitiesConsentModal from '@/components/organisms/CharitiesConsentModal';

import tracking from '@/mixins/tracking';

export default {
  name: 'RuAch',
  components: {
    App,
    Navigation,
    Notices,
    CharitiesConsentModal,
    Portal,
    PortalTarget,
  },
  mixins: [tracking],
  middleware: [
    'redirect-if-not-logged-in.middleware',
    'userOnboardingPageRequired',
  ],
  data() {
    return {
      scrollTop: 0,
      portalObserver: null,
    };
  },
  computed: {
    ...mapGetters('ruach', [
      'navigationVariant',
      'shouldShowCharityConsentModal',
    ]),
    showNavigation() {
      return this.navigationVariant !== 'none';
    },
    windowScrolled() {
      return this.scrollTop > 0;
    },
    styles() {
      return {
        '--window-scrolled': Number(this.windowScrolled),
        '--navigation-visible': Number(this.showNavigation),
      };
    },
  },
  mounted() {
    this.$updateShouldShowCharityConsent();
    this.observePortal();
  },
  beforeDestroy() {
    if (this.portalObserver) {
      this.portalObserver.disconnect();
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll: throttle(250, function () {
      this.scrollTop = window.scrollY;
    }),
    observePortal() {
      const callback = () => {
        this.$nextTick(() => {
          document.documentElement.classList.toggle(
            '--stack-has-content',
            this.$refs.stack.$el.childNodes.length > 1
          );
        });
      };
      const portalObserver = new MutationObserver(callback);
      portalObserver.observe(this.$refs.stack.$el, { childList: true });
      this.portalObserver = portalObserver;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &ach {
    --ruach-margin: var(--base-margin-small);
    padding: calc(var(--app-height) + var(--ruach-margin) * 3) 0;

    @include mq('min-lg') {
      --ruach-margin: var(--base-margin);
      padding-left: calc(
        var(--navigation-visible) *
          (
            var(--navigation-width-collapsed) +
              (
                (1 - var(--is-desktop-menu-collapsed)) *
                  (var(--navigation-width) - var(--navigation-width-collapsed))
              )
          )
      );
    }
  }

  &stack {
    position: fixed;
    inset: 0;
    background: --rgba(black, 0.25);
    transition: --in();
    z-index: $z-stack;

    &:empty {
      transition: --out();
      background: transparent;
      pointer-events: none;
    }
  }
}
</style>
