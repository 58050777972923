<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm
      @submit="$router.push({ path: localePath('/will/estate/beneficiaries') })"
    >
      <Headline :level="1">
        {{ $t('pages.will.estate.yourEstate') }}
      </Headline>
      <i18n path="pages.will.estate.instruction" tag="p">
        <GlossaryTooltip term="estateResidualEstate">
          {{ $t('texts.words.estate') }}
        </GlossaryTooltip>
      </i18n>
      <Tip>
        {{ $t('pages.will.estate.tip') }}
      </Tip>
    </AdiForm>
    <GlossaryPanel />
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import GlossaryTooltip from '@/components/GlossaryTooltip';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import GlossaryPanel from '@/components/GlossaryPanel';

export default {
  name: 'PagesAffiliateWillEstateIndex',
  components: {
    AdiForm,
    GlossaryTooltip,
    ModuleStep,
    Tip,
    Headline,
    GlossaryPanel,
  },
};
</script>
