<template>
  <div v-if="authenticated" class="ru: ru:admin">
    <NavigationAdmin />
    <div class="ru:admin-container">
      <nuxt />
    </div>
    <PortalTarget ref="stack" class="ru:stack" name="stack" multiple />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';

import NavigationAdmin from '@/components/organisms/NavigationAdmin';

import { isModeratorOrHigher } from '@/utilities';

export default {
  name: 'AdminLayout',
  components: {
    NavigationAdmin,
    PortalTarget,
  },
  computed: {
    ...mapGetters(['role', 'token']),
    authenticated() {
      return this.token && isModeratorOrHigher(this.role);
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &admin {
    @include mq('max-lg') {
      font-size: 14px;
    }

    th,
    td {
      padding: 0.875rem;
    }

    td {
      font-size: 0.9375rem;
    }

    &-container {
      display: flex;
      margin: 1.5rem 1.5rem 1.5rem 16.5rem;
      gap: 1.5rem;

      #{$ru}box {
        padding: var(--base-margin);
        overflow: hidden;
      }

      #{$ru}headline {
        padding-top: 0;
      }
    }

    &-content {
      flex-grow: 1;

      td {
        font-size: 1rem;
      }
    }

    &-sidebar {
      flex-shrink: 0;
      width: 23.75rem;
    }
  }
}
</style>
