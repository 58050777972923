<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="done"
    >
      <Headline :level="1">
        {{ $t('pages.will.assets.asset.title') }}
      </Headline>
      <p>
        {{ $t('pages.will.assets.asset.desc') }}
      </p>
      <Tip>
        <p>
          {{ $t('pages.will.assets.asset.tip.assetsGuide') }}
        </p>
        <template v-if="userIsAustralian">
          <p>
            {{ $t('pages.will.assets.asset.tip.superannuationInsurance') }}
          </p>
          <Buttons layout="start">
            <RuButton
              data-test="super-death-explanation"
              theme="secondary"
              size="small"
              href="https://safewill.com/blog/posts/what-happens-to-your-superannuation-when-you-die"
            >
              <Icon id="info" />
              {{ $t('pages.will.assets.asset.superDeathExplanation') }}
            </RuButton>
          </Buttons>
        </template>
      </Tip>
      <Headline :level="2">
        {{ $t('pages.will.assets.asset.question') }}
      </Headline>
      <LargeRadioButtons
        id="hasAssets"
        v-model="willMeta.has_assets"
        :options="[
          {
            label: $t('forms.labels.yes'),
            value: true,
          },
          {
            label: $t('forms.labels.no'),
            value: false,
          },
        ]"
      />
      <template v-if="willMeta.has_assets">
        <Headline :level="2"> {{ $t('texts.sentences.yourAssets') }} </Headline>
        <Box v-if="!selectedAssets.length" background="sand">
          {{ $t('pages.will.assets.asset.noAssets') }}
        </Box>
        <AssetInputs
          v-for="(asset, index) in selectedAssets"
          :id="`asset_${asset.id || index}`"
          :key="index"
          v-model="asset.meta"
          :disabled="loading"
          @add="addAssetInput"
          @remove="removeAssetInput(asset)"
        />
        <Buttons layout="end">
          <RuButton size="large" level="secondary" @click="addAssetInput">
            + {{ $t('forms.labels.add') }}
          </RuButton>
        </Buttons>
      </template>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import AssetInputs from '@/components/AssetInputs';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import AdiForm from '@/components/Form';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import Icon from '@/components/atoms/Icon';
import Box from '@/components/atoms/Box';

import { assets, user, will } from '@/mixins/apollo';
import { metaArrayToObject, objectToMetaArray } from '@/utilities';

export default {
  name: 'PagesAffiliateWillAssetsIndex',
  components: {
    AssetInputs,
    AdiForm,
    LargeRadioButtons,
    ModuleStep,
    Tip,
    Headline,
    Buttons,
    RuButton,
    Icon,
    Box,
  },
  mixins: [assets, user, will],
  data() {
    return {
      defaultAsset: {},
      selectedAssets: [],
    };
  },
  computed: {
    ...mapGetters('directory-person', ['userIsAustralian']),
    isComplete() {
      return !!(
        this.willMeta.has_assets === false || this.selectedAssets.length
      );
    },
  },
  watch: {
    assets(assets) {
      if (assets.length && !this.selectedAssets.length) {
        this.selectedAssets = assets.map(({ id, meta }) => {
          return {
            id,
            meta: metaArrayToObject(meta),
          };
        });
      }
    },
  },
  methods: {
    addAssetInput() {
      this.selectedAssets.push({
        meta: {
          description: '',
          type: '',
        },
      });
    },
    async removeAssetInput(asset) {
      if (this.isAsset(asset)) {
        await this.removeAsset(asset);
      }

      this.selectedAssets.splice(this.selectedAssets.indexOf(asset), 1);
    },
    async done() {
      for (const asset of this.selectedAssets) {
        const isAsset = this.isAsset(asset);
        const meta = objectToMetaArray(asset.meta);

        if (this.willMeta.has_assets) {
          if (isAsset) {
            await this.updateAsset(asset, meta);
          } else {
            await this.addAsset(meta);
          }
        } else if (isAsset) {
          await this.removeAsset(asset);
        }
      }

      this.$router.push({
        path: this.localePath('/will/assets/liabilities'),
      });
    },
  },
};
</script>
