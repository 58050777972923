<template>
  <ModuleStep :index="3" prev-step="/will/executors/options">
    <AdiForm v-slot="{ loading }" :disabled="!isComplete" @submit="submit">
      <Headline :level="1">
        {{ $t('pages.will.executors.primaryContact.headline') }}
      </Headline>
      <Tip>
        {{ $t('pages.will.executors.primaryContact.tip') }}
      </Tip>
      <p class="text-2xl">
        {{ $t('pages.will.executors.primaryContact.professionalExecutor') }}
      </p>
      <PersonSelector
        v-model="selectedPrimaryContact"
        :loading="loading"
        :max="2"
        :people="filteredPeople"
        type="executor"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { executors, people, user } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliateWillExecutorsPrimaryContact',
  components: {
    AdiForm,
    ModuleStep,
    PersonSelector,
    Tip,
    Headline,
  },
  mixins: [executors, people, user],
  data() {
    return {
      selectedPrimaryContact: [],
    };
  },
  computed: {
    isComplete() {
      return !!this.selectedPrimaryContact.length;
    },
    filteredPeople() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        const personIsAlreadySelected = this.selectedPrimaryContact.includes(
          person.id
        );
        return (
          personIsAlreadySelected ||
          (meta.is_over_18 && !this.isBackupExecutor(person))
        );
      });
    },
  },
  watch: {
    primaryExecutors(executors) {
      if (executors.length && !this.selectedPrimaryContact.length) {
        this.selectedPrimaryContact = this.people
          .filter((person) => {
            return executors.find(
              (executor) => person.id === executor.person.id
            );
          })
          .map((person) => person.id);
      }
    },
  },
  methods: {
    async submit() {
      await Promise.all(
        this.people.map((person) => {
          const isPrimaryExecutor = this.isPrimaryExecutor(person);
          const isSelected = this.selectedPrimaryContact.includes(person.id);
          if (isSelected) {
            if (!isPrimaryExecutor) {
              return this.addExecutor(person, 'primary');
            }
          } else if (isPrimaryExecutor) {
            return this.removeExecutor(person);
          }
          return Promise.resolve();
        })
      );

      this.$router.push({
        path: this.localePath('/will/executors/professional-terms'),
      });
    },
  },
};
</script>
