<template>
  <div class="person mb-8 last-child:mb-0">
    <MetaSlot
      v-for="(person, index) in people"
      :key="index"
      v-slot="{ meta }"
      :meta="person.meta"
    >
      <div class="mb-4">
        <LocalScope
          :id="`person_${person.id}__${id}`"
          v-slot="{ id, quickEdit, selected }"
          :quick-edit="index >= quickEditIndex"
          :selected="isSelected[index]"
        >
          <Selectable
            :id="id"
            :is-selected="selected"
            :is-selectable="selected || maxSelectedNotMet"
            :data="person"
            @selectionChange="selectionChange"
          >
            <template #content>
              <Headline big :level="3">
                {{ meta.full_name }}
              </Headline>
              <p>{{ buildIdentifier(meta) }}</p>
            </template>
            <template #actions>
              <Buttons layout="column">
                <RuButton
                  level="secondary"
                  :active="selected"
                  @click="selectionChange(!selected, person)"
                >
                  {{
                    selected
                      ? $t('components.personSelector.unselect')
                      : $t('components.personSelector.select')
                  }}
                </RuButton>
                <RuButton level="secondary" @click="onEditPerson(person)">
                  {{ $t('components.personSelector.edit') }}
                </RuButton>
              </Buttons>
            </template>
          </Selectable>
        </LocalScope>
      </div>
    </MetaSlot>
    <Buttons layout="stretch">
      <RuButton
        id="add-person"
        size="large"
        level="secondary"
        @click="showAddPersonPanel"
      >
        + {{ $t('components.personSelector.addPerson') }}
      </RuButton>
    </Buttons>
    <PersonPanel :will-beneficiary="willBeneficiarySelector" />
  </div>
</template>

<script>
import { LocalScope } from 'vue-local-scope';
import { mapActions } from 'vuex';

import PersonPanel from '@/components/PersonPanel';
import Selectable from '@/components/molecules/Selectable';
import MetaSlot from '@/components/MetaSlot';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsPersonSelector',
  components: {
    PersonPanel,
    Selectable,
    LocalScope,
    MetaSlot,
    Headline,
    Buttons,
    RuButton,
  },
  props: {
    id: {
      default: '',
      type: String,
    },
    label: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    max: {
      default: Infinity,
      type: Number,
    },
    people: {
      type: Array,
      default: () => [],
      required: true,
    },
    type: {
      default: 'person',
      type: String,
    },
    value: {
      type: Array,
      required: true,
    },
    identifierRequirements: {
      type: Array,
      default: null,
    },
    willBeneficiarySelector: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      quickEditIndex: Infinity,
      selected: this.value,
      personBeingEdited: undefined,
    };
  },
  computed: {
    isSelected() {
      return this.people.map((person) => {
        return this.selected.includes(person.id);
      });
    },
    maxSelectedNotMet() {
      return this.selected.length < this.max;
    },
  },
  watch: {
    value(newValue) {
      if (!this.loading) {
        this.selected = newValue;
      }
    },
  },
  mounted() {
    this.$nuxt.$on('addPerson', (person) => {
      if (this.quickEditIndex === Infinity) {
        this.quickEditIndex = this.people.length;
      }

      this.selectionChange(true, person);
    });
  },
  methods: {
    ...mapActions('person', [
      'addPerson',
      'setIdentifierRequirements',
      'editPerson',
    ]),
    closePersonPanel() {
      this.personBeingEdited = undefined;
    },
    onEditPerson(person) {
      this.editPerson({ person, type: this.type });
      if (this.identifierRequirements !== null) {
        this.setIdentifierRequirements(this.identifierRequirements);
      }
    },
    showAddPersonPanel() {
      this.addPerson(this.type);
      if (this.identifierRequirements !== null) {
        this.setIdentifierRequirements(this.identifierRequirements);
      }
    },
    buildIdentifier(meta) {
      switch (meta.identifier) {
        case 'email':
          return meta.email;
        case 'dob':
          return meta.date_of_birth;
        default:
          return meta.address_street
            ? this.buildAddress(meta)
            : meta.date_of_birth
            ? meta.date_of_birth
            : meta.email;
      }
    },
    buildAddress(meta) {
      return meta.address_state && meta.address_postcode
        ? `${meta.address_street}, ${meta.address_suburb} ${meta.address_state} ${meta.address_postcode}`
        : `${meta.address_street}, ${meta.address_suburb}`;
    },
    selectionChange(checked, person) {
      const index = this.selected.indexOf(person.id);
      if (!checked && index > -1) {
        this.selected.splice(index, 1);
      } else if (this.selected.length < this.max) {
        this.selected.push(person.id);
      } else if (this.max === 1) {
        this.selected = [person.id];
      }
      this.$emit('input', this.selected);
    },
  },
};
</script>
