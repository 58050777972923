<template>
  <ModuleStep :index="1" prev-step="/will/gifts">
    <AdiForm
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="done"
    >
      <Headline :level="1">
        {{ $t('pages.will.gifts.considerLeavingGift') }}
      </Headline>
      <p>
        {{ $t('pages.will.gifts.manyUsersChooseToLeaveAGift') }}
      </p>
      <LargeRadioButtons
        id="has_gifts"
        v-model="willMeta.charity_in_gifts"
        :options="[
          {
            label: $t('forms.labels.yes'),
            value: true,
          },
          {
            label: $t('forms.labels.no'),
            value: false,
          },
        ]"
      />
      <template v-if="willMeta.charity_in_gifts">
        <Headline :level="2"> Gifts </Headline>
        <MetaSlot
          v-for="(gift, index) in gifts"
          :key="index"
          v-slot="{ giftMeta, personMeta, charityMeta }"
          :gift-meta="gift.meta"
          :person-meta="gift.person && gift.person.meta"
          :charity-meta="gift.charity && gift.charity.meta"
        >
          <DataCard
            :title="
              giftMeta.type === 'money'
                ? `$${giftMeta.amount}`
                : giftMeta.description
            "
            :description="personMeta.full_name || charityMeta.name"
            :icon="giftMeta.type === 'money' ? 'wallet' : 'gift'"
            edit
            remove
            type="gift"
            @edit="editGift(gift)"
            @remove="removeGift(gift)"
          >
            <Box v-if="giftMeta.note" shadow>
              <Headline flush :level="3">
                <em>{{ $t('texts.sentences.youWrote') }}</em>
              </Headline>
              <p>
                {{ giftMeta.note }}
              </p>
            </Box>
          </DataCard>
        </MetaSlot>
        <Buttons layout="stretch">
          <RuButton size="large" level="secondary" @click="addGift">
            + {{ $t('forms.labels.addAGift') }}
          </RuButton>
        </Buttons>
        <GiftWizard v-if="showGiftWizard" />
      </template>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Box from '@/components/atoms/Box';
import DataCard from '@/components/DataCard';
import AdiForm from '@/components/Form';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import MetaSlot from '@/components/MetaSlot';
import ModuleStep from '@/components/templates/ModuleStep';
import GiftWizard from '@/components/GiftWizard';
import Headline from '@/components/atoms/Headline';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { gifts, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillGiftsIncludeCharity',
  components: {
    Box,
    DataCard,
    AdiForm,
    LargeRadioButtons,
    MetaSlot,
    ModuleStep,
    GiftWizard,
    Headline,
    Buttons,
    RuButton,
  },
  mixins: [gifts, user, will],
  computed: {
    ...mapGetters('ruach', ['shouldShowCharityConsentPage']),
    ...mapGetters('gift', ['showGiftWizard']),
    isComplete() {
      return !!(
        this.gifts.find((g) => g.charity !== null) ||
        !this.willMeta.charity_in_gifts
      );
    },
  },
  methods: {
    ...mapActions('gift', ['addGift', 'editGift']),
    async done() {
      if (this.willMeta.charity_in_gifts) {
        this.willMeta.has_gifts = true;
        await this.updateWillMeta();
      }
      if (this.willMeta.has_gifts === false && this.gifts.length) {
        await this.removeAllGifts();
      }
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 5 (Gifts)',
      });
      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift:
          this.willMeta.charity_in_gifts || this.willMeta.charity_in_estate,
      });
      await this.refetchWill();
      await this.$updateShouldShowCharityConsent();
      const path = this.localePath(
        this.shouldShowCharityConsentPage ? '/will/charity-consent' : '/will'
      );
      this.$router.push({
        path,
      });
    },
  },
};
</script>
