var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":5,"prev-step":_vm.previous}},[(_vm.errorMessage)?_c('Alert',{attrs:{"level":"medium","theme":"warning"}},[_vm._v("\n    "+_vm._s(_vm.errorMessage)+"\n  ")]):_vm._e(),_vm._v(" "),_c('AdiForm',{attrs:{"disabled":!_vm.isComplete},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.substituteDecisionMaker.whoShouldBeYour', {
          substitute: _vm.canHaveMultipleSubstitutes
            ? _vm.$t('texts.words.substitutes')
            : _vm.$t('texts.words.substitute'),
        }))+"\n    ")]),_vm._v(" "),(_vm.canHaveMultipleSubstitutes)?_c('p',{staticClass:"mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.substituteDecisionMaker.maxSubstitutes', {
          substitute: _vm.substitutesAllowed[_vm.userState],
        }))+"\n    ")]):_c('p',{staticClass:"mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.substituteDecisionMaker.oneSubstitute'))+"\n    ")]),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"people":_vm.filteredPeople,"max":_vm.substitutesAllowed[_vm.userState],"will-beneficiary-selector":false,"identifier-requirements":_vm.identifierRequirements,"type":"attorney"},model:{value:(_vm.selectedSecondaryAttorneyPersonIDs),callback:function ($$v) {_vm.selectedSecondaryAttorneyPersonIDs=$$v},expression:"selectedSecondaryAttorneyPersonIDs"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }