<template>
  <ModuleStep :index="2" prev-step="/will/executors">
    <AdiForm v-slot="{ loading }" hide-submit>
      <Headline :level="1">
        {{ $t('pages.will.executors.options.whoExecutor') }}
      </Headline>
      <Tip>
        {{ $t('pages.will.executors.options.tip') }}
      </Tip>
      <ExpandingCard
        v-for="(option, index) in executorOptions"
        :key="index"
        :open="option.value === willMeta.executors_option"
        class="mb-5"
        @click="willMeta.executors_option = option.value"
      >
        <template #title>
          {{ option.label }}
        </template>
        <div class="flex items-center my-6">
          <BaseIcon id="info" />
          <span
            class="ml-3 font-bold text-sm tracking-wide uppercase text-slate-100"
          >
            {{ $t('pages.will.executors.options.youShouldKnow') }}
          </span>
        </div>
        <div class="text-lg">
          <template v-if="option.value === 'friendsFamily'">
            <p class="mb-8">
              {{ $t('pages.will.executors.options.friendsFamily.content') }}
            </p>
            <ul>
              <li>
                {{
                  $t('pages.will.executors.options.friendsFamily.trustedByYou')
                }}
              </li>
              <li>
                {{
                  $t(
                    'pages.will.executors.options.friendsFamily.familiarWithLegal'
                  )
                }}
              </li>
              <li>
                {{
                  $t(
                    'pages.will.executors.options.friendsFamily.influencedByFamily'
                  )
                }}
              </li>
              <li>
                {{
                  $t(
                    'pages.will.executors.options.friendsFamily.willingAndAvailableToHelp'
                  )
                }}
              </li>
            </ul>
          </template>
          <template v-else-if="option.value === 'professional'">
            <p class="mb-8">
              {{ $t('pages.will.executors.options.professional.content') }}
            </p>
            <p>
              {{ $t('pages.will.executors.options.professional.content2') }}
            </p>
          </template>
          <template v-else-if="option.value === 'friendsFamilyAndProfessional'">
            <p class="mb-8">
              {{
                $t(
                  'pages.will.executors.options.friendsFamilyAndProfessional.content'
                )
              }}
            </p>
            <p>
              {{
                $t(
                  'pages.will.executors.options.friendsFamilyAndProfessional.content2'
                )
              }}
            </p>
          </template>
        </div>
        <ReviewsSummaryCard
          v-if="option.value !== 'friendsFamily'"
          class="mt-8"
        />
        <BaseButton
          class="btn-xl btn-slate w-full mt-5"
          :loading="isLoading"
          @click="onSubmit"
        >
          <div class="flex justify-between items-center">
            <span class="inline-block text-white">
              {{ $t('forms.labels.select') }}
            </span>
            <span class="ml-4">
              <BaseIcon id="arrow-right" />
            </span>
          </div>
        </BaseButton>
      </ExpandingCard>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import BaseIcon from '@/components/BaseIcon';
import ExpandingCard from '@/components/ExpandingCard';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import ReviewsSummaryCard from '@/components/ReviewsSummaryCard';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { executors, people, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillExecutorsOptions',
  components: {
    BaseButton,
    BaseIcon,
    ExpandingCard,
    AdiForm,
    ModuleStep,
    ReviewsSummaryCard,
    Tip,
    Headline,
  },
  mixins: [executors, people, user, will],
  middleware({ store, redirect }) {
    if (!store.getters['directory-person/userIsAustralian']) {
      return redirect('/will/executors');
    }
  },
  data() {
    return {
      executorOptions: [
        {
          label: this.$t('pages.will.executors.options.friendsFamily.label'),
          value: 'friendsFamily',
        },
        {
          label: this.$t('pages.will.executors.options.professional.label'),
          value: 'professional',
        },
        {
          label: this.$t(
            'pages.will.executors.options.friendsFamilyAndProfessional.label'
          ),
          value: 'friendsFamilyAndProfessional',
        },
      ],
      isLoading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;

      let path;
      this.willMeta.professional_executor_terms_check = false;
      this.willMeta.professional_executor_payment_check = false;
      this.willMeta.professional_executor_court_check = false;

      switch (this.willMeta.executors_option) {
        case 'friendsFamily':
          path = '/will/executors/primary';

          break;
        case 'professional':
          path = '/will/executors/professional-terms';
          this.willMeta.has_backup_executor = false;

          this.$nuxt.$emit('sendTrackingEvent', {
            event: '👨‍🎓 Safewill Professional Executor',
            props: { type: 'Professional' },
          });

          await Promise.all(
            this.people.map((person) => {
              if (
                this.isPrimaryExecutor(person) ||
                this.isBackupExecutor(person)
              ) {
                return this.removeExecutor(person);
              }
              return Promise.resolve();
            })
          );

          break;
        case 'friendsFamilyAndProfessional':
          path = '/will/executors/primary-contact';
          this.willMeta.has_backup_executor = false;

          this.$nuxt.$emit('sendTrackingEvent', {
            event: '👨‍🎓 Safewill Professional Executor',
            props: { type: 'Professional with Friends & Family' },
          });

          await Promise.all(
            this.people.map((person) => {
              if (this.isBackupExecutor(person)) {
                return this.removeExecutor(person);
              }
              return Promise.resolve();
            })
          );

          break;
        default:
          break;
      }

      await this.updateWillMeta();

      this.$router.push({ path: this.localePath(path) });
    },
  },
};
</script>
