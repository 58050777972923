<template>
  <ModuleStep :index="3" prev-step="/poa/about-yourself/address">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="onDone"
    >
      <Headline :level="1">
        {{ $t('pages.poa.questions.dateOfBirth') }}
      </Headline>
      <Tip>
        {{ $t('pages.poa.tips.above18') }}
      </Tip>
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="date_of_birth"
            v-model="willMeta.date_of_birth"
            :disabled="loading"
            rules="required|over18|after1900"
            type="date"
            format="legacy"
          />
        </div>
      </div>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';
import { dobToAge } from '@/utilities';

export default {
  name: 'PagesAffiliatePoaAboutYourselfDateOfBirth',
  components: {
    AdiForm,
    ModuleStep,
    FormControl,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    isComplete() {
      return this.willMeta && this.willMeta.date_of_birth;
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        date_of_birth: `${this.willMeta.date_of_birth}`,
        age: dobToAge(this.willMeta.date_of_birth),
      });

      this.$router.push({
        path: this.localePath('/poa/about-yourself/phone'),
      });
    },
  },
};
</script>
