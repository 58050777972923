<template>
  <div class="ru:alert" :class="classes">
    <svg v-if="icon" class="ru:alert__icon" viewBox="0 0 24 24">
      <use
        v-bind="{
          'xlink:href': `${require('~/assets/svg/ru-icons.svg')}#${icon}`,
        }"
      />
    </svg>
    <span class="ru:alert__text"><slot /></span>
  </div>
</template>

<script>
export default {
  name: 'ComponentsMoleculesAlert',
  props: {
    layout: {
      type: String,
      default: null,
    },
    level: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      const theme = this.theme ? `--${this.theme}` : '';
      const level = this.level ? `--${this.level}` : '';
      const layout = this.layout ? `--${this.layout}` : '';
      return [theme, level, layout];
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &alert {
    padding: 1rem;
    border-radius: var(--border-radius-small);
    border: 1px solid --rgba(theme);
    color: --rgba(darker);
    align-items: center;
    margin-bottom: var(--base-margin);
    display: flex;

    a {
      text-decoration: underline;
      font-weight: 500;
    }

    &__icon {
      margin-right: 1rem;
      display: inline-block;
      vertical-align: bottom;
      width: 1.5rem;
      height: 1.5rem;
    }

    &#{$mf}center {
      justify-content: center;
    }

    &#{$mf}minor {
      background: --rgba(white);
      border: 1px solid --rgba(theme);
    }

    &#{$mf}medium {
      background: --rgba(lighter);
      border-color: --rgba(lighter);
    }

    &#{$mf}major {
      background: --rgba(darker);
      border-color: --rgba(darker);
      color: --rgba(white);
    }
  }
}
</style>
