<template>
  <ModuleStep :index="5" prev-step="/will/about-yourself/address">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      :auto-submit="isComplete && !willMeta.has_partner"
      @done="done"
    >
      <Headline :level="1">
        {{ $t('pages.will.aboutYourSelf.partner.question') }}
      </Headline>
      <Tip>
        {{ $t('pages.will.aboutYourSelf.partner.tip') }}
        <GlossaryTooltip term="deFactoDomesticRelationship">
          {{ $t('pages.will.aboutYourSelf.partner.subTip') }}
        </GlossaryTooltip>
      </Tip>
      <LargeRadioButtons
        id="hasPartner"
        v-model="willMeta.has_partner"
        :options="[
          {
            label: $t('forms.labels.yes'),
            value: true,
          },
          {
            label: $t('forms.labels.no'),
            value: false,
          },
        ]"
      />
      <template v-if="willMeta.has_partner">
        <Box v-if="!hidePartnerDiscountTip" background="white" shadow>
          <Headline :level="3">
            <Icon id="tag" />
            {{ $t('texts.sentences.partnerDiscount') }}
          </Headline>
          <p>
            {{ $t('pages.will.aboutYourSelf.partner.discountNotice') }}
          </p>
        </Box>
        <Headline :level="2">
          {{ $t('pages.will.aboutYourSelf.partner.partnerSelect.headline') }}
        </Headline>
        <PersonSelector
          v-model="selectedPartners"
          :loading="loading"
          :max="1"
          :people="filteredPeople"
          type="partner"
        />
      </template>
    </AdiForm>
    <GlossaryPanel />
  </ModuleStep>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import AdiForm from '@/components/Form';
import GlossaryTooltip from '@/components/GlossaryTooltip';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import Box from '@/components/atoms/Box';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import Icon from '@/components/atoms/Icon';
import GlossaryPanel from '@/components/GlossaryPanel';

import { metaArrayToObject } from '@/utilities';

import { beneficiaries, people, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAboutYourselfPartner',
  components: {
    AdiForm,
    GlossaryTooltip,
    LargeRadioButtons,
    ModuleStep,
    Box,
    PersonSelector,
    Tip,
    Headline,
    Icon,
    GlossaryPanel,
  },
  mixins: [beneficiaries, people, user, will],
  data() {
    return {
      selectedPartners: [],
    };
  },
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('prices', ['pricePartnerWill']),
    isComplete() {
      return !!(
        (this.willMeta && this.willMeta.has_partner === false) ||
        this.selectedPartners.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        return person.category === 'none' || person.category === 'partner';
      });
    },
    partnerWillIsFree() {
      return this.pricePartnerWill === 0;
    },
    hidePartnerDiscountTip() {
      return this.isFreeWillWeek || this.partnerWillIsFree;
    },
  },
  watch: {
    partners(partners) {
      if (partners.length && !this.selectedPartners.length) {
        this.selectedPartners = partners.map((partner) => partner.id);
      }
    },
  },
  created() {
    this.getPrices();
  },
  methods: {
    ...mapActions('prices', ['getPrices']),
    async done() {
      let partnerUpdated = false;

      await Promise.all(
        this.people.map((person) => {
          const isPartner = person.category === 'partner';
          const isSelected = this.selectedPartners.includes(person.id);
          if (this.willMeta.has_partner && isSelected) {
            if (!isPartner) {
              partnerUpdated = true;
              this.$nuxt.$emit('sendTrackingAttributes', {
                partner_name: metaArrayToObject(person.meta).full_name,
              });
              return this.updatePerson(person, {
                category: 'partner',
              });
            }
          } else if (isPartner) {
            partnerUpdated = true;
            this.$nuxt.$emit('sendTrackingAttributes', {
              partner_name: null,
            });
            return this.updatePerson(person, {
              category: 'none',
            });
          }
          return Promise.resolve();
        })
      );

      if (
        this.willMeta.estate_split === 'partner' &&
        (this.willMeta.has_partner === false || partnerUpdated)
      ) {
        this.willMeta.estate_split = null;

        await this.removeAllBeneficiaries();
        await this.updateWillMeta();
      }

      this.$router.push({
        path: this.localePath('/will/about-yourself/phone'),
      });
    },
  },
};
</script>
