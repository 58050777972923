<template>
  <ModuleStep :index="0" :prev-step="previous">
    <AdiForm
      v-slot="{ loading }"
      :disabled="!isComplete"
      @submit.prevent="onSubmit"
    >
      <Headline :level="1">
        {{ $t('pages.poa.medical.index.appointAMedicalDecisionMaker') }}
      </Headline>
      <p class="mb-4">
        {{ $t('pages.poa.medical.index.appointPeopleTrust') }}
        <template v-if="['nsw'].includes(userState)">
          {{ $t('pages.poa.medical.index.nswLaw') }}
        </template>
        <template v-else-if="['act'].includes(userState)">
          {{ $t('pages.poa.medical.index.actLaw') }}
        </template>
        <template v-else-if="['vic'].includes(userState)">
          {{ $t('pages.poa.medical.index.vicLaw') }}
        </template>
        <template v-else-if="['nt'].includes(userState)">
          {{ $t('pages.poa.medical.index.ntLaw') }}
        </template>
        <template v-else-if="['sa'].includes(userState)">
          {{ $t('pages.poa.medical.index.saLaw') }}
        </template>
        <template v-else-if="['tas'].includes(userState)">
          {{ $t('pages.poa.medical.index.tasLaw') }}
        </template>
        <template v-else-if="['wa'].includes(userState)">
          {{ $t('pages.poa.medical.index.waLaw') }}
        </template>
        <template v-else-if="['qld'].includes(userState)">
          {{ $t('pages.poa.medical.index.qldLaw') }}
        </template>
      </p>
      <template v-if="['vic'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.medical.index.healthcareDecisionMakerWithExample') }}
        </p>
      </template>
      <template v-else>
        <p class="mb-4">
          {{ $t('pages.poa.medical.index.healthcareDecisionMaker') }}
        </p>
        <ul>
          <li>
            {{ $t('pages.poa.medical.index.decideMedicalTreatment') }}
          </li>
          <li>
            {{ $t('pages.poa.medical.index.decideWhereLive') }}
          </li>
        </ul>
      </template>
      <Tip>
        {{ $t('pages.poa.medical.index.limitsExclusions') }}
      </Tip>
      <LargeRadioButtons
        id="hasAttorney"
        v-model="poaMedicalMeta.has_attorney"
        layout="column"
        :options="[
          {
            label: $t('pages.poa.medical.index.yesAppointDecisionMaker'),
            value: true,
          },
          {
            label: $t('pages.poa.medical.index.noAppointDecisionMaker'),
            value: false,
          },
        ]"
        @input="onSubmit"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';
import Tip from '@/components/molecules/Tip';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalIndex',
  components: {
    Tip,
    AdiForm,
    ModuleStep,
    LargeRadioButtons,
    Headline,
  },
  mixins: [user, will],
  apollo: {
    poaMedicalMeta: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      poaMedicalMeta: {
        has_attorney: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        poaMedicalMeta: this.poaMedicalMeta,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      return (
        this.poaMedicalMeta &&
        (this.poaMedicalMeta.has_attorney === true ||
          this.poaMedicalMeta.has_attorney === false)
      );
    },
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      await this.$apollo.mutate(
        mutations.updatePoaMeta(this.poaId, {
          key: 'has_attorney',
          value: this.poaMedicalMeta.has_attorney ? 'true' : 'false',
          category: 'MEDICAL',
        })
      );

      if (!this.poaMedicalMeta.has_attorney) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module POA medical skipped',
        });
      }

      const path = this.localePath(this.next);
      await this.$router.push({ path });
    },
  },
};
</script>
