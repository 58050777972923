var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/will"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"disabled":!_vm.isComplete},on:{"done":_vm.onDone},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.aboutYourSelf.letsStartToWriteYourWill'))+"\n    ")]),_vm._v(" "),_c('Headline',{attrs:{"level":2}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.aboutYourSelf.questions.fullName'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell cell__12/12"},[_c('FormControl',{attrs:{"id":"name_first","disabled":loading,"text":_vm.$t('forms.placeholders.yourFirstName'),"rules":"required|max:50"},model:{value:(_vm.willMeta.name_first),callback:function ($$v) {_vm.$set(_vm.willMeta, "name_first", $$v)},expression:"willMeta.name_first"}})],1),_vm._v(" "),_c('div',{staticClass:"cell cell__12/12"},[_c('FormControl',{attrs:{"id":"name_middle","disabled":loading,"required":false,"text":_vm.$t('forms.placeholders.yourMiddleName'),"rules":"max:50"},model:{value:(_vm.willMeta.name_middle),callback:function ($$v) {_vm.$set(_vm.willMeta, "name_middle", $$v)},expression:"willMeta.name_middle"}})],1),_vm._v(" "),_c('div',{staticClass:"cell cell__12/12"},[_c('FormControl',{attrs:{"id":"name_last","disabled":loading,"text":_vm.$t('forms.placeholders.yourLastName'),"rules":"required|max:50"},model:{value:(_vm.willMeta.name_last),callback:function ($$v) {_vm.$set(_vm.willMeta, "name_last", $$v)},expression:"willMeta.name_last"}})],1)]),_vm._v(" "),_c('Headline',{attrs:{"level":2}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.aboutYourSelf.questions.knownByAnotherName'))+"\n    ")]),_vm._v(" "),_c('Tip',[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.aboutYourSelf.egAltName'))+"\n    ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_alt_name","options":[
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ]},model:{value:(_vm.willMeta.has_alt_name),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_alt_name", $$v)},expression:"willMeta.has_alt_name"}}),_vm._v(" "),(_vm.willMeta.has_alt_name)?[_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.aboutYourSelf.questions.otherName'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell cell__12/12"},[_c('FormControl',{attrs:{"id":"alt_name_first","disabled":loading,"text":_vm.$t('forms.placeholders.yourFirstName'),"rules":"required|max:50"},model:{value:(_vm.willMeta.alt_name_first),callback:function ($$v) {_vm.$set(_vm.willMeta, "alt_name_first", $$v)},expression:"willMeta.alt_name_first"}})],1),_vm._v(" "),_c('div',{staticClass:"cell cell__12/12"},[_c('FormControl',{attrs:{"id":"alt_name_middle","disabled":loading,"text":_vm.$t('forms.placeholders.yourMiddleName'),"rules":"max:50"},model:{value:(_vm.willMeta.alt_name_middle),callback:function ($$v) {_vm.$set(_vm.willMeta, "alt_name_middle", $$v)},expression:"willMeta.alt_name_middle"}})],1),_vm._v(" "),_c('div',{staticClass:"cell cell__12/12"},[_c('FormControl',{attrs:{"id":"alt_name_last","disabled":loading,"text":_vm.$t('forms.placeholders.yourLastName'),"rules":"required|max:50"},model:{value:(_vm.willMeta.alt_name_last),callback:function ($$v) {_vm.$set(_vm.willMeta, "alt_name_last", $$v)},expression:"willMeta.alt_name_last"}})],1)])]:_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }