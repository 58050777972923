<template>
  <div v-if="institution" class="grid">
    <div class="cell cell__8/12">
      <AdminBackLink to="/admin/institutions">Institutions</AdminBackLink>
      <AdminCard :title="`Institution: ${institution.name}`">
        <div class="admin-card__buttons">
          <BaseButton
            class="admin-card__button admin-card__button--save"
            :disabled="loading"
            @click="updateInstitutionData"
          >
            <div class="admin-card__button-content">
              <BaseIcon id="tick" size="small" classes="mr-2" />
              Save
            </div>
          </BaseButton>
        </div>
        <AdminCardRow
          id="name"
          v-model="institution.name"
          type="text"
          label="Name"
          rules="required"
          :disabled="loading"
        />
        <AdminCardRow
          id="type"
          v-model="institution.type"
          type="select"
          label="Type"
          rules="required"
          :options="types"
          :disabled="loading"
        />
        <AdminCardRow
          v-if="typeIsOther"
          id="typeOther"
          v-model="institution.typeOther"
          type="text"
          label="Other Type"
          rules="required"
          :disabled="loading"
        />
        <AdminCardRow
          id="abn"
          v-model="institution.abn"
          type="text"
          label="ABN"
          :disabled="loading"
        />
      </AdminCard>
    </div>
    <div class="cell cell__4/12">
      <AdminCard title="Actions">
        <AdminCardSection>
          <AdminActionLink
            glyph="remove"
            :disabled="institution.archived"
            class="m-0 p-0 border-none bg-transparent text-red-300 underline"
            @click="showArchiveInstitutionModal"
          >
            Archive
          </AdminActionLink>

          <p v-if="institution.archived" class="mt-2">
            Coupon already archived
          </p>
        </AdminCardSection>
      </AdminCard>
      <AdminCard>
        <AdminCardSection title="History">
          <div class="text-sm">
            Updated at:
            {{ $formatDate(institution.updatedAt) }}
          </div>
          <div class="text-sm">
            Created at:
            {{ $formatDate(institution.createdAt) }}
          </div>
        </AdminCardSection>
      </AdminCard>
    </div>
  </div>
</template>

<script>
import AdminBackLink from '@/components/admin/BackLink';
import AdminCard from '@/components/admin/Card';
import BaseButton from '@/components/BaseButton';
import AdminCardRow from '@/components/admin/CardRow';
import BaseIcon from '@/components/BaseIcon';
import AdminCardSection from '@/components/admin/CardSection';
import AdminActionLink from '@/components/admin/ActionLink';

import dialog from '@/mixins/message-box';
import institutionsAdmin from '@/mixins/institutions-admin';
import { recursiveRemoveKey } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminInstitutionsId',
  components: {
    AdminBackLink,
    AdminCard,
    BaseButton,
    BaseIcon,
    AdminCardRow,
    AdminCardSection,
    AdminActionLink,
  },
  mixins: [institutionsAdmin, dialog],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    typeIsOther() {
      return this.institution.type === 'Other';
    },
    type() {
      return this.typeIsOther
        ? this.institution.typeOther
        : this.institution.type;
    },
  },
  methods: {
    showArchiveInstitutionModal() {
      this.$confirm(
        {
          title: `Archive ${this.institution.name}?`,
          message: 'Are you sure you want to archive this institution?',
        },
        async (action) => {
          if (action) {
            await this.archiveInstitution();
            this.$router.push(this.localePath('/admin/institutions'));
          }
        }
      );
    },
    async updateInstitutionData() {
      this.loading = true;
      const newInstitution = { ...this.institution, type: this.type };
      recursiveRemoveKey(newInstitution, '__typename');
      recursiveRemoveKey(newInstitution, 'createdAt');
      recursiveRemoveKey(newInstitution, 'updatedAt');

      await this.updateInstitution(newInstitution);

      this.loading = false;
    },
  },
};
</script>
