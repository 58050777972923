<template>
  <div />
</template>

<script>
import cookies from 'js-cookie';
import { mapActions } from 'vuex';
import { removeLegacyCookie } from '@/modules/cookies';
import { sanitizeLogInRedirect } from '@/utilities/allowed-redirects';

export default {
  name: 'PagesAffiliateLogInIndex',
  middleware: ['redirect-if-logged-in.middleware'],
  mounted() {
    removeLegacyCookie();
    this.$apolloHelpers.onLogout();
    this.resetUserState();
    this.resetCheckoutState();
    this.$analytics.reset();
    this.resetSignup();

    if (Object.keys(this.$route.query).length) {
      if (this.$route.query.charity) {
        cookies.set('referral_charity', this.$route.query.charity);
      }

      if (this.$route.query.redirect) {
        const where = sanitizeLogInRedirect(String(this.$route.query.redirect));

        if (where) {
          cookies.set('redirect', where);
        }
      }
    }

    cookies.set('from_login', 'true');
    this.$auth.loginWith('auth0', { params: { prompt: 'login' } });
  },
  beforeDestroy() {
    this.resetUI();
  },
  methods: {
    ...mapActions('signup', ['resetSignup']),
    ...mapActions('ruach', ['resetUI']),
    ...mapActions(['resetUserState']),
    ...mapActions('checkout', ['resetCheckoutState']),
  },
};
</script>
