<template>
  <div class="ru:charity-suggester">
    <div class="ru:charity-suggester__intro">
      <Badge background="white" size="large">
        <BaseIcon id="charity" size="large" />
      </Badge>
      <Headline :level="3">
        {{ $t('components.charitySuggester.supportTheCauses') }}
      </Headline>
      <p>
        {{ $t('components.charitySuggester.description') }}
      </p>
    </div>
    <div class="ru:charity-suggester__suggestions grid">
      <div
        v-for="(charity, slot) in charityData"
        :key="charity.id"
        class="cell cell__12/12"
        :class="slot > 0 ? 'cell__6/12--md' : ''"
      >
        <Selectable
          :id="charity.id"
          :is-selected="isSelected(charity.id)"
          :data="charity"
          @selectionChange="selectionChange(charity.id)"
        >
          <template #content>
            <div v-if="charity.logo" class="ru:charity-suggester__logo">
              <img
                v-if="slot === 0 || !charity.description"
                :src="$makePartnerLogoUrl(charity.logo)"
                :alt="charity.displayName || charity.name"
              />
              <Tooltip v-else :tooltip="charity.description">
                <img
                  :src="$makePartnerLogoUrl(charity.logo)"
                  :alt="charity.displayName || charity.name"
                />
              </Tooltip>
            </div>
            <Headline v-else :level="3">
              {{ charity.displayName || charity.name }}
            </Headline>
            <p v-if="slot === 0">
              {{ charity.description }}
            </p>
          </template>
          <template #actions>
            <div class="d-flex-end">
              <Badge
                :color="isSelected(charity.id) ? 'white' : 'silver'"
                :background="isSelected(charity.id) ? 'theme' : 'iron'"
                :size="slot === 0 ? 'large' : null"
              >
                <Icon id="check" :size="slot === 0 ? 'large' : null" />
              </Badge>
            </div>
          </template>
        </Selectable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import shuffle from 'lodash/shuffle';

import BaseIcon from '@/components/BaseIcon';
import Headline from '@/components/atoms/Headline';
import Selectable from '@/components/molecules/Selectable';
import Icon from '@/components/atoms/Icon';
import Badge from '@/components/atoms/Badge';
import Tooltip from '@/components/atoms/Tooltip';

import { will } from '@/mixins/apollo';

export default {
  name: 'ComponentsCharitySuggester',
  components: {
    BaseIcon,
    Headline,
    Selectable,
    Icon,
    Badge,
    Tooltip,
  },
  mixins: [will],
  props: {
    label: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    max: {
      default: Infinity,
      type: Number,
    },
  },
  data() {
    return {
      layout: {
        primary: 'w-full',
        secondary: 'w-full lg:w-1/2',
      },
      selectedCharityIds: [],
    };
  },
  computed: {
    ...mapGetters(['featuredFives']),
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    charityData() {
      return shuffle(this.featuredFives).map((charity, index) => {
        return {
          ...charity,
          suggestionLocation: window.location.pathname,
          suggestionSlot: index,
        };
      });
    },
  },
  methods: {
    isSelected(charityId) {
      return this.selectedCharityIds.includes(charityId);
    },
    selectionChange(charityId) {
      const index = this.selectedCharityIds.indexOf(charityId);
      if (index > -1) {
        this.selectedCharityIds.splice(index, 1);
      } else if (this.selectedCharityIds.length < this.max) {
        this.selectedCharityIds.push(charityId);
      } else if (this.max === 1) {
        this.selectedCharityIds = [charityId];
      }
      this.$emit(
        'input',
        this.charityData.filter((charity) => {
          return this.selectedCharityIds.includes(charity.id);
        })
      );
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &charity-suggester {
    &__intro {
      background: --rgba(sky);
      border: 1px solid --rgba(azure);
      border-radius: var(--border-radius);
      padding: var(--base-margin);
      margin-bottom: var(--base-margin);
    }

    &__suggestions {
      --margin-y: var(--base-margin-small);
    }

    &__logo {
      margin-bottom: var(--base-margin);
      height: 4rem;
      display: flex;
      align-items: center;
      max-width: 100%;

      img {
        max-height: 4rem;
        border-radius: var(--border-radius-small);
      }
    }
  }
}
</style>
