<template>
  <ValidationProvider
    v-slot="{ errors, touched }"
    :vid="identifier"
    :disabled="!required"
    :rules="rules"
    slim
  >
    <div
      ref="formControl"
      :key="identifier"
      class="ru:form__control"
      :class="[
        classes,
        { '--required': isRequired },
        { '--invalid': errors.length },
        { '--touched': touched },
        { '--has-value': !!value },
      ]"
    >
      <select
        v-if="type === 'select'"
        :id="identifier"
        v-model="modelValue"
        :required="isRequired"
        :disabled="disabled"
        class="ru:form__select"
        @change="change"
      >
        <option
          v-for="(option, index) in selectOptions"
          :key="index"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
      <input
        v-else-if="type === 'checkbox'"
        :id="identifier"
        :required="isRequired"
        :type="type"
        :checked="modelValue === true"
        :disabled="disabled"
        :value="true"
        class="ru:form__input-checkbox"
        @change="change"
      />
      <input
        v-else-if="type === 'radio'"
        :id="identifier"
        :type="type"
        :name="id"
        :value="option"
        :checked="modelValue === option"
        class="ru:form__input-radio"
        @change="change"
      />
      <textarea
        v-else-if="type === 'textarea'"
        :id="identifier"
        v-model="modelValue"
        :name="id"
        rows="4"
        class="ru:form__input-text"
        :placeholder="placeholder"
        :readonly="readonly"
        @change="change"
      />
      <input
        v-else
        :id="identifier"
        v-model="modelValue"
        :required="isRequired"
        :type="type"
        :placeholder="placeholder"
        :inputmode="inputmode"
        :min="min"
        :max="max"
        :pattern="pattern"
        :disabled="disabled"
        :readonly="readonly"
        :title="title"
        class="ru:form__input-text"
        @change="change"
      />
      <label v-if="text" :for="identifier" class="ru:form__label">
        <span class="ru:form__label-text">
          {{ text }}
        </span>
        <span v-if="isRequired" class="ru:form__label-indicator">*</span>
        <span class="ru:form__label-slot">
          <slot name="label" />
        </span>
      </label>
      <div v-if="errors.length" class="ru:form__invalid-message">
        {{ getErrorMessage(errors) }}
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import {
  isDateLegacyFormat,
  convertLegacyDateToIsoFormat,
  convertIsoDateToLegacyDate,
  isDateIsoDateTimeFormat,
  convertIsoDateTimeToIsoDate,
  isDateEpochFormat,
  convertEpochDateToIsoFormat,
  isDateIsoFormat,
} from '@/utilities';

export default {
  name: 'ComponentsMoleculesFormControl',
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      default: '',
      type: [String, Boolean, Number],
    },
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputmode: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    option: {
      type: [String, Boolean],
      default: '',
    },
    options: {
      type: Array,
      default: null,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    allowOther: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      legacyInLegacyOut: false,
    };
  },
  computed: {
    modelValue: {
      get() {
        if (this.type === 'date' && this.value) {
          if (isDateIsoFormat(this.value)) {
            return this.value;
          } else if (isDateLegacyFormat(this.value)) {
            return convertLegacyDateToIsoFormat(this.value);
          } else if (isDateIsoDateTimeFormat(this.value)) {
            return convertIsoDateTimeToIsoDate(this.value);
          } else if (isDateEpochFormat(this.value)) {
            return convertEpochDateToIsoFormat(this.value);
          }
        }
        return this.value;
      },
      set(value) {
        if (this.legacyInLegacyOut) {
          value = convertIsoDateToLegacyDate(value);
        }
        this.$emit('input', value);
      },
    },
    isRequired() {
      if (this.rules) {
        return this.rules.includes('required');
      }
      return this.required;
    },
    selectOptions() {
      let options = [];
      if (typeof this.options[0] === 'string') {
        options = this.options.map((option) => {
          return {
            value: option,
            text: option,
          };
        });
      } else {
        options = this.options.map((option) => {
          return {
            value: option.value,
            text: this.$t(option.text),
          };
        });
      }
      if (!this.required && options[0] && options[0].value) {
        options.unshift({
          value: null,
          text: null,
        });
      }
      if (this.allowOther) {
        options.push({
          value: 'OTHER',
          text: this.$t('components.formControl.other'),
        });
      }
      return options;
    },
  },
  created() {
    this.classes = ['radio', 'checkbox'].includes(this.type)
      ? '--inline'
      : null;
    this.identifier = this.id + '__' + Math.random().toString(36).slice(2);
    this.legacyInLegacyOut =
      this.format === 'legacy' || isDateLegacyFormat(this.modelValue);
  },
  methods: {
    change($event) {
      switch (this.format) {
        case 'number':
          this.modelValue = parseInt($event.target.value);
          break;
        case 'float':
          this.modelValue = Number($event.target.value);
          break;
        case 'boolean':
          if (this.type === 'checkbox') {
            this.modelValue = $event.target.checked;
            break;
          }
          this.modelValue = $event.target.value === 'true';
          break;
        case 'date':
          this.modelValue = $event.target.value || null;
          break;
        default:
          this.modelValue = $event.target.value.trim();
      }
    },
    reset() {
      this.modelValue = '';
    },
    getErrorMessage(errors) {
      const lastErrorKey = errors[errors.length - 1];
      if (lastErrorKey.split(':').length > 1) {
        const [key, args] = lastErrorKey.split(':');
        return this.$t(key, { args });
      } else {
        return this.$t(lastErrorKey);
      }
    },
  },
};
</script>

<style lang="scss">
$this: '.ru\\:form';
#{$ru} {
  &form {
    &__fieldset,
    &__buttons {
      margin-bottom: var(--base-margin);
    }

    &__buttons {
      text-align: right;
    }

    &__control {
      --input-background: var(--ivory);
      --input-background-focus: var(--white);
      --input-border-width: 2px;
      --input-border: var(--iron);
      --input-border-hover: var(--dust);
      --input-border-focus: var(--teal);
      --input-padding-y: 0.875rem;
      --input-padding-x: 1rem;
      --input-radius: 0.375rem;
      --input-padding: var(--input-padding-y) var(--input-padding-x);
      --font-size: var(--base-font-size);
      position: relative;
      display: flex;
      flex-direction: column;
      transition: --out();
      margin-bottom: var(--base-margin);

      @at-root .cell > &:only-child {
        margin-bottom: 0;
      }

      @include mq('min-md') {
        --font-size: 1.125rem;
      }

      &:hover,
      &:focus-within {
        transition: --in();
      }

      &:hover {
        --input-border: var(--input-border-hover);
      }

      &:focus-within {
        --input-border: var(--input-border-focus);
        --input-background: var(--input-background-focus);
      }

      &#{$mf}inline {
        --square: var(--base-line-height);
        display: flex;
        align-items: center;
        flex-direction: row;

        input {
          display: inline-block;
          flex-shrink: 0;
          margin-right: #{calc(var(--square) / 2)};
          height: #{calc(var(--square) + 5px)};
          width: var(--square);
          opacity: 0;
        }
      }

      &#{$mf}invalid#{$mf}touched {
        --input-border: var(--red);
        --input-background: var(--pink);
      }
    }

    &__select,
    &__input-text {
      outline: 0;
      font-size: var(--font-size);
      line-height: var(--base-line-height);
      display: block;
      background: --rgba(input-background);
      border: var(--input-border-width) solid --rgba(input-border);
      padding: var(--input-padding);
      border-radius: var(--input-radius);
      width: 100%;
      transition: inherit;

      &[disabled] {
        border-color: --rgba(silver);
        background: --rgba(concrete);
        color: --rgba(rock);
        cursor: not-allowed;
      }
    }

    &__select {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      height: #{calc(
          var(--base-line-height) + (var(--input-padding-y) * 2) +
            (var(--input-border-width) * 2)
        )};
    }

    &__label,
    &__invalid-message {
      font-size: var(--base-font-size);
    }

    &__label-indicator {
      color: --rgba(red);
      padding-left: var(--base-margin-small);
    }

    #{$mf}required#{$mf}has-value:not(#{$mf}invalid) &__label-indicator {
      color: --rgba(turquoise);
    }

    &__label {
      display: flex;
      @at-root [disabled] {
        + &::after {
          color: --rgba(red);
        }
      }

      &-slot {
        padding-left: var(--base-margin-small);
        margin-left: auto;
        #{$ru}buttons,
        #{$ru}button {
          margin-right: 0;
          padding-right: 0;
        }
        #{$ru}tooltip {
          top: 2px;
        }
      }

      @at-root :is(#{$this}__select, #{$this}__input-text) + & {
        margin-bottom: var(--base-margin-tiny);
        color: --rgba(basalt);
        line-height: var(--base-line-height);
        order: -1;
      }

      @at-root :is(#{$this}__input-text) + & {
        cursor: text;
      }

      @at-root :is(
            :focus:not(#{$this}__input-radio, #{$this}__input-checkbox),
            #{$this}__input-text:not(:placeholder-shown),
            #{$this}__select:valid
          )
          + & {
        cursor: pointer;
        line-height: var(--base-line-height);
      }
    }

    &__invalid-message {
      color: --rgba(red);
      margin-top: var(--base-margin-small);
      display: none;
    }

    &__control#{$mf}touched &__invalid-message {
      display: block;
    }

    &__input-radio {
      + #{$this}__label::before,
      + #{$this}__label::after {
        display: block;
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: var(--square);
        height: var(--square);
        border-radius: 99rem;
        border: var(--input-border-width) solid --rgba(checkbox-border);
      }

      + #{$this}__label::after {
        border-color: --rgba(checkbox-border-focus);
        background-color: --rgba(input-background);
        opacity: 0;
        transition: var(--transition);
        @include background-svg(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6.5 9.086 4 6.586 2.586 8 6.5 11.914 13.414 5 12 3.586z" fill="#123"/></svg>'
        );
      }

      &:checked + #{$this}__label::after {
        background-color: --rgba(lighter);
        opacity: 1;
      }
    }

    &__input-checkbox {
      &[disabled] + label {
        opacity: 0.5;
        cursor: not-allowed;
      }

      & + #{$this}__label {
        padding-top: var(--input-border-width);
      }

      + #{$this}__label::before,
      + #{$this}__label::after {
        display: block;
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: var(--square);
        height: var(--square);
        border-radius: var(--input-radius);
        border: var(--input-border-width) solid --rgba(checkbox-border);
      }

      + #{$this}__label::before {
        background-color: --rgba(input-background);
      }

      + #{$this}__label::after {
        border-color: --rgba(checkbox-border-focus);
        background-color: --rgba(input-background);
        opacity: 0;
        transition: var(--transition);
        @include background-svg(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6.5 9.086 4 6.586 2.586 8 6.5 11.914 13.414 5 12 3.586z" fill="#123"/></svg>'
        );
      }

      &:checked + #{$this}__label::after {
        background-color: --rgba(lighter);
        opacity: 1;
      }
    }
  }
}
</style>
