<template>
  <div class="ru:send-invite">
    <FormWrapper v-model="isInvalid" @submit="sendInvite">
      <div class="grid">
        <div class="cell cell__12/12 cell__9/12--md">
          <FormControl
            id="inviteEmail"
            ref="formControl"
            v-model="email"
            inputmode="email"
            placeholder="name@example.com"
            rules="required|email"
            :disabled="isBusy"
            :text="label ? label : null"
          />
        </div>
        <div class="cell cell__12/12 cell__3/12--md">
          <Buttons layout="stretch" :label-sibling="!!label">
            <RuButton
              level="secondary"
              :aria-label="$t('components.sendInvite.send')"
              button-type="submit"
              :disabled="isInvalid || isBusy || isIncomplete"
            >
              <template v-if="isBusy">
                <LoadingRing :size="24" />
                {{ $t('components.sendInvite.sending') }}
              </template>
              <template v-else>
                <Icon id="email" />
                {{ $t('components.sendInvite.send') }}
              </template>
            </RuButton>
          </Buttons>
        </div>
      </div>
      <Alert v-if="alertMessage" :theme="alertTheme" level="medium">
        {{ alertMessage }}
      </Alert>
    </FormWrapper>
    <Snackbar
      v-if="snackbarIsVisible"
      :type="snackbarType"
      :text="snackbarText"
      @closeSnackbar="closeSnackbar"
    />
  </div>
</template>

<script>
import { invites, user } from '@/mixins/apollo';
import { formatError } from '@/utilities';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import Alert from '@/components/molecules/Alert';
import LoadingRing from '@/components/LoadingRing';
import Icon from '@/components/atoms/Icon';
import Snackbar from '@/components/molecules/Snackbar';

import CREATE_INVITE_MUTATION from '@/graphql/mutations/CreateInvite';

export default {
  name: 'ComponentsOrganismsSendInvite',
  components: {
    Buttons,
    RuButton,
    Icon,
    FormWrapper,
    FormControl,
    Alert,
    LoadingRing,
    Snackbar,
  },
  mixins: [invites, user],
  props: {
    type: {
      default: 'DEFAULT',
      type: String,
    },
    isIncomplete: {
      default: false,
      type: Boolean,
    },
    products: {
      type: Array,
      default: () => ['WILL'],
    },
    prefill: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      email: this.prefill,
      error: null,
      isBusy: false,
      done: false,
      showAlert: null,
      alertTheme: null,
      alertMessage: null,
      snackbarIsVisible: false,
      snackbarType: null,
      snackbarText: null,
      isInvalid: null,
    };
  },
  computed: {
    hide() {
      return this.prefill && this.done;
    },
  },
  watch: {
    prefill() {
      this.email = this.prefill;
    },
  },
  methods: {
    async sendInvite() {
      this.isBusy = true;
      this.alertMessage = null;
      try {
        let inviteId;
        await this.$apollo.mutate({
          mutation: CREATE_INVITE_MUTATION,
          variables: {
            userId: this.userId,
            type: this.type,
            email: this.email,
            products: this.products,
          },
          update: (store, { data: { createInvite } }) => {
            const data = store.readQuery(this.getInvitesQuery);
            data.getInvites.invites.push(createInvite);
            inviteId = createInvite.id;
            store.writeQuery({
              ...this.getInvitesQuery,
              data,
            });
          },
        });

        // legacy tracking
        this.$nuxt.$emit('sendTrackingEvent', {
          event:
            this.type === 'PARTNER'
              ? '💌 Referred Partner'
              : '📧 Referred Friend',
          props: { referred_email: this.email },
        });

        this.$nuxt.$emit('sendTrackingAttributes', {
          partner_invite_created_frontend: this.invites.some(
            (i) => i.type === 'PARTNER'
          ),
          friend_invite_created_frontend: this.invites.some(
            (i) => i.type === 'DEFAULT'
          ),
          number_of_invitations: this.invites.filter((i) =>
            ['PARTNER', 'DEFAULT'].includes(i.type)
          ).length,
        });

        // new tracking
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'invite_sent',
          props: {
            type: this.type,
            recipient: this.email,
            products: this.products,
            inviteId,
          },
        });

        this.error = null;
        this.done = true;
        this.snackbarText = this.$t(
          'components.sendInvite.successfullySentInvite'
        );
        this.openSnackbar('success');
        this.$refs.formControl.reset();
        this.$emit('complete');
      } catch (error) {
        this.$nuxt.$emit('done');
        this.alertTheme = 'error';
        this.alertMessage = formatError(error.message);
      }
      this.isBusy = false;
    },
    openSnackbar(type) {
      this.snackbarType = type;
      this.snackbarIsVisible = true;
    },
    closeSnackbar() {
      this.snackbarIsVisible = false;
    },
  },
};
</script>
