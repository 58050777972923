<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm @submit="nextStep">
      <Headline :level="1">
        {{ $upperFirst($t('texts.words.executors')) }}
      </Headline>
      <Tip>
        <i18n path="pages.will.executors.index.carryingOutYourWishes">
          <GlossaryTooltip term="estateAdministration">
            {{ $t('texts.sentences.estateAdministration') }}
          </GlossaryTooltip>
        </i18n>
      </Tip>
      <i18n path="pages.will.executors.index.instruction" tag="p" class="mb-5">
        <GlossaryTooltip term="executor">
          {{ $t('texts.words.executor') }}
        </GlossaryTooltip>
      </i18n>
      <ul>
        <li>{{ $t('pages.will.executors.index.assetsDebts') }}</li>
        <li>{{ $t('pages.will.executors.index.distribute') }}</li>
        <li>{{ $t('pages.will.executors.index.accounting') }}</li>
        <li>{{ $t('pages.will.executors.index.paperwork') }}</li>
        <li>{{ $t('pages.will.executors.index.informed') }}</li>
      </ul>
    </AdiForm>
    <GlossaryPanel />
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';

import AdiForm from '@/components/Form';
import GlossaryTooltip from '@/components/GlossaryTooltip';
import ModuleStep from '@/components/templates/ModuleStep';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import GlossaryPanel from '@/components/GlossaryPanel';
import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillExecutorsIndex',
  components: {
    AdiForm,
    GlossaryTooltip,
    ModuleStep,
    Tip,
    Headline,
    GlossaryPanel,
  },
  mixins: [will],
  computed: {
    ...mapGetters('directory-person', ['userIsAustralian']),
  },
  methods: {
    async nextStep() {
      const redirectPath = this.localePath(
        this.userIsAustralian
          ? '/will/executors/options'
          : '/will/executors/primary'
      );
      if (!this.userIsAustralian) {
        this.willMeta.executors_option = 'friendsFamily';
        await this.updateWillMeta();
      }
      this.$router.push({ path: redirectPath });
    },
  },
};
</script>
