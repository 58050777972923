<template>
  <ModuleStep :index="2" prev-step="/will/assets/liabilities">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      :submit-label="buttonText"
      @done="onDone"
    >
      <Headline :level="1">
        {{ $t('pages.will.assets.notes.headline') }}
      </Headline>
      <p>
        {{ $t('pages.will.assets.notes.assetsNoteInstruction') }}
      </p>
      <Tip>
        {{ $t('pages.will.assets.notes.assetsNoteOptional') }}
      </Tip>
      <FormControl
        id="asset_notes"
        v-model="willMeta.asset_note"
        rules="max:3000"
        :placeholder="$t('forms.placeholders.optional')"
        type="textarea"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAssetsNotes',
  components: {
    AdiForm,
    ModuleStep,
    FormControl,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    buttonText() {
      return this.willMeta.asset_note ? 'Next' : 'Skip';
    },
    isComplete() {
      return !!(
        !this.willMeta.asset_note || this.willMeta.asset_note.length <= 3000
      );
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 6 (Assets)',
      });
      this.$router.push({
        path: this.localePath('/will'),
      });
    },
  },
};
</script>
