<template>
  <Portal to="stack">
    <transition appear mode="out-in" name="ru:wiggle-onscreen">
      <div
        v-on-dismiss="{
          callback: close,
          watch: isDismissable,
        }"
        class="ru:panel"
      >
        <div class="ru:panel__header">
          <div class="ru:panel__header-content">
            {{ title }}
            <RuButton level="plain" @click="close">
              <Icon id="close" />
            </RuButton>
          </div>
        </div>
        <div class="ru:panel__body">
          <slot />
        </div>
      </div>
    </transition>
  </Portal>
</template>

<script>
import { Portal } from 'portal-vue';
import VDismiss from 'vue-dismiss';

import Icon from '@/components/atoms/Icon';
import RuButton from '@/components/atoms/Button';

export default {
  name: 'ComponentsOrganismsPanel',
  components: {
    Icon,
    RuButton,
    Portal,
  },
  mixins: [VDismiss],
  props: {
    isDismissable: {
      default: false,
      type: Boolean,
    },
    title: {
      default: null,
      type: String,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    keyHandler(e) {
      switch (e.keyCode) {
        case 27: // ESC
          e.preventDefault();
          this.close();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &panel {
    position: fixed;
    width: min(100%, 30rem);
    top: 0;
    right: 0;
    bottom: 0;
    background: --rgba(water);
    padding-top: #{calc(var(--app-height) + 2.5rem)};
    box-shadow: var(--base-box-shadow);

    &__header {
      background: --rgba(white);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;

      &-content {
        height: var(--app-height);
        font-weight: 500;
        padding: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__body {
      padding: 2.5rem 1.5rem;
      transition: var(--transition);
      position: absolute;
      inset: var(--app-height) 0 0 0;
      overflow: auto;
    }

    &__footer {
      position: fixed;
      width: min(100%, 30rem);
    }
  }
}
</style>
