<template>
  <div class="grid">
    <div class="cell cell__12/12 cell__5/12--md">
      <FormControl
        :id="`${id}_type`"
        v-model="type"
        :disabled="disabled"
        :options="options"
        type="select"
        rules="required"
      />
    </div>
    <div class="cell cell__11/12 cell__6/12--md">
      <FormControl
        :id="`${id}_description`"
        v-model="description"
        :name="`${id}_description`"
        :disabled="disabled || !type"
        :placeholder="type ? placeholders[type] : null"
        rules="required|max:3000"
      />
    </div>
    <div class="cell cell__1/12">
      <RuButton level="plain" theme="error" round icon @click="$emit('remove')">
        <Icon id="cancel" />
      </RuButton>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon';
import RuButton from '@/components/atoms/Button';
import FormControl from '@/components/molecules/FormControl';

export default {
  name: 'ComponentsLiabilityInputs',
  components: {
    Icon,
    RuButton,
    FormControl,
  },
  props: {
    value: {
      default: () => ({ type: '', description: '' }),
      type: Object,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [
        this.$t('components.liabilityInputs.mortgage.label'),
        this.$t('components.liabilityInputs.creditCardDebt.label'),
        this.$t('components.liabilityInputs.personalLoans.label'),
        this.$t('components.liabilityInputs.other.label'),
      ],
      placeholders: {
        Mortgage: this.$t('components.liabilityInputs.mortgage.placeholder'),
        'Credit Card Debt': this.$t(
          'components.liabilityInputs.creditCardDebt.placeholder'
        ),
        'Personal Loans': this.$t(
          'components.liabilityInputs.personalLoans.placeholder'
        ),
        Other: this.$t('components.liabilityInputs.other.placeholder'),
      },
    };
  },
  computed: {
    type: {
      get() {
        return this.value.type;
      },
      set(newValue) {
        this.$emit('input', { ...this.value, type: newValue });
      },
    },
    description: {
      get() {
        return this.value.description;
      },
      set(newValue) {
        this.$emit('input', { ...this.value, description: newValue });
      },
    },
  },
};
</script>
