<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.relationshipsOfDeceased') }}
    </Headline>
    <CremationsFormInstructions />
    <FormWrapper @submit="saveUpdatedDetails('final')">
      <Headline :level="3">
        {{ $t('pages.cremations.marriageOrRelationshipDetails') }}
      </Headline>
      <p>
        {{ $t('pages.cremations.relationshipDetailsOrder') }}
      </p>
      <div v-if="showForm">
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="maritalStatus"
              v-model="tempData.maritalStatus"
              type="select"
              :text="$t('forms.labels.maritalStatus')"
              :disabled="loading"
              :required="false"
              :options="CURRENT_MARITAL_STATUS_OPTIONS"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="partnerFirstName"
              v-model="tempData.partnerFirstName"
              type="text"
              :text="$t('forms.labels.partnerFirstName')"
              :disabled="loading"
              :required="false"
              :pattern="FIRST_NAME_PATTERN.regex"
              :title="$t(FIRST_NAME_PATTERN.description)"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="partnerOtherGivenNames"
              v-model="tempData.partnerOtherGivenNames"
              type="text"
              :text="$t('forms.labels.partnerOtherGivenNames')"
              :disabled="loading"
              :required="false"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="partnerLastName"
              v-model="tempData.partnerLastName"
              type="text"
              :text="$t('forms.labels.partnerLastName')"
              :disabled="loading"
              :required="false"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="partnerSex"
              v-model="tempData.partnerSex"
              type="select"
              :text="$t('forms.labels.partnerSex')"
              :disabled="loading"
              :required="false"
              :options="SEX_OPTIONS"
            />
          </div>
        </div>
        <div
          v-if="['MARRIAGE', 'DIVORCE'].includes(tempData.maritalStatus)"
          class="grid ru:form__fieldset"
        >
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="placeOfMarriageSuburb"
              v-model="tempData.placeOfMarriageSuburb"
              type="text"
              :text="$t('forms.labels.placeOfMarriageSuburb')"
              :disabled="loading"
              :required="false"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControlWithUnknown
              v-if="showPlaceOfMarriageRegion"
              id="placeOfMarriageState"
              v-model="tempData.placeOfMarriageState"
              :required="false"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.placeOfMarriageRegion')"
            />
            <FormControlWithUnknown
              v-else
              id="placeOfMarriageState"
              v-model="tempData.placeOfMarriageState"
              :required="false"
              :disabled="loading"
              type="select"
              :text="$t('forms.labels.placeOfMarriageAustralianState')"
              :options="$auStatesOptions"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControlWithUnknown
              id="placeOfMarriageCountry"
              v-model="tempData.placeOfMarriageCountry"
              type="select"
              :text="$t('forms.labels.placeOfMarriageCountry')"
              :disabled="loading"
              :required="false"
              :options="LIFELINK_COUNTRIES"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControlWithUnknown
              id="guestAgeAtDateOfMarriage"
              v-model="tempData.guestAgeAtDateOfMarriage"
              type="number"
              format="number"
              :text="$t('forms.labels.ageOfDeceasedAtMarriage')"
              :disabled="loading"
              :required="false"
            />
          </div>
        </div>
        <RuButton
          aria-label="Add"
          button-type="button"
          color="white"
          border="wine"
          background="wine"
          size="small"
          rounded
          @click="save"
        >
          <BaseIcon id="tick" classes="icon" />
          <span>{{ $t('forms.labels.save') }}</span>
        </RuButton>
        <RuButton
          aria-label="Cancel"
          button-type="button"
          color="wine"
          border="wine"
          background="white"
          size="small"
          rounded
          @click="cancel"
        >
          <BaseIcon id="cross" classes="icon" />
          <span>{{ $t('forms.labels.cancel') }}</span>
        </RuButton>
      </div>
      <div v-else>
        <DataTable
          v-if="formData.guestPartners.length"
          accent="lilac"
          theme="wine"
          :description="$t('texts.sentences.spousePartnerDetails')"
          :columns="dataTableColumns"
          :rows="formData.guestPartners"
          delete-button
          edit-button
          @delete="deleteItem"
          @edit="edit"
        />
        <RuButton
          :description="$t('forms.labels.addRelationship')"
          button-type="button"
          color="white"
          border="wine"
          background="wine"
          size="small"
          rounded
          @click="add"
        >
          <BaseIcon id="plus" classes="icon" />
          <span>{{ $t('forms.labels.addRelationship') }}</span>
        </RuButton>
      </div>
      <Buttons v-if="!showForm">
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="toggleConfirmModal">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton
          background="wine"
          color="white"
          @click="saveUpdatedDetails('final')"
        >
          {{ $t('forms.labels.accept') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import BaseIcon from '@/components/BaseIcon';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import DataTable from '@/components/atoms/DataTable';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import FormControlWithUnknown from '@/components/organisms/FormControlWithUnknown';
import CremationsFormInstructions from '@/components/snowflakes/CremationsFormInstructions';

import { FIRST_NAME_PATTERN } from '@/utilities/cremations/form-constants';

export default {
  name: 'PagesAffiliateCremationsAtNeedRelationshipsId',
  components: {
    BaseModal,
    BaseIcon,
    RuButton,
    Buttons,
    DataTable,
    Headline,
    FormWrapper,
    FormControl,
    FormControlWithUnknown,
    CremationsFormInstructions,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      loading: true,
      editIndex: null,
      tempData: null,
      formData: {
        guestPartners: [],
      },
      dataTableColumns: [
        { key: 'maritalStatus', text: this.$t('forms.labels.maritalStatus') },
        {
          key: 'partnerFirstName',
          text: this.$t('forms.labels.partnerFirstName'),
        },
        {
          key: 'partnerOtherGivenNames',
          text: this.$t('forms.labels.partnerOtherGivenNames'),
        },
        {
          key: 'partnerLastName',
          text: this.$t('forms.labels.partnerLastName'),
        },
        { key: 'partnerSex', text: this.$t('forms.labels.partnerSex') },
        {
          key: 'placeOfMarriageSuburb',
          text: this.$t('forms.labels.placeOfMarriageSuburb'),
        },
        {
          key: 'placeOfMarriageState',
          text: this.$t('forms.labels.placeOfMarriageAustralianState'),
        },
        {
          key: 'placeOfMarriageCountry',
          text: this.$t('forms.labels.placeOfMarriageCountry'),
        },
        {
          key: 'guestAgeAtDateOfMarriage',
          text: this.$t('forms.labels.ageOfDeceasedAtMarriage'),
        },
      ],
      FIRST_NAME_PATTERN,
    };
  },
  computed: {
    nextPage() {
      return this.localePath(
        `/cremations/at-need/children/${this.cremationRequestId}`
      );
    },
    prevPage() {
      return this.localePath(
        `/cremations/at-need/parents/${this.cremationRequestId}`
      );
    },
    showForm() {
      return !!this.tempData;
    },
  },
  methods: {
    edit(rowIndex) {
      this.editIndex = rowIndex;
      this.tempData = { ...this.formData.guestPartners[rowIndex] };
    },
    add() {
      this.editIndex = this.formData.guestPartners.length;
      this.tempData = { ...this.formFields.guestPartners };
    },
    deleteItem(rowIndex) {
      this.formData.guestPartners = [
        ...this.formData.guestPartners.slice(0, rowIndex),
        ...this.formData.guestPartners.slice(rowIndex + 1),
      ];
    },
    save() {
      this.formData.guestPartners[this.editIndex] = { ...this.tempData };
      this.tempData = null;
    },
    cancel() {
      this.tempData = null;
    },
  },
};
</script>
