<template>
  <ModuleStep :index="2" prev-step="/will/funeral">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :submit-label="submitLabel"
      @done="onDone"
    >
      <Headline :level="1">
        {{ getHeadingText }}
      </Headline>
      <Tip>
        {{ $t('pages.will.funeral.executorLocation') }}
      </Tip>
      <FormControl
        id="funeral_location"
        v-model="willMeta.funeral_location"
        :disabled="loading"
        rules="max:3000"
        :placeholder="$t('forms.placeholders.enterLocation')"
        type="textarea"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillFuneralLocation',
  components: {
    AdiForm,
    ModuleStep,
    FormControl,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    submitLabel() {
      return this.willMeta.funeral_location
        ? this.$t('forms.labels.next')
        : this.$t('forms.labels.skip');
    },
    getHeadingText() {
      return this.willMeta.funeral_type === 'cremation'
        ? this.$t('pages.will.funeral.chooseAshesLocation')
        : this.$t('pages.will.funeral.chooseBurialLocation');
    },
  },
  methods: {
    onDone() {
      this.$router.push({
        path: this.localePath('/will/funeral/other-requests'),
      });
    },
  },
};
</script>
