<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="onDone"
    >
      <Headline :level="1">
        {{ $t('pages.will.aboutYourSelf.letsStartToWriteYourWill') }}
      </Headline>
      <Headline :level="2">
        {{ $t('pages.will.aboutYourSelf.questions.fullName') }}
      </Headline>
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="name_first"
            v-model="willMeta.name_first"
            :disabled="loading"
            :text="$t('forms.placeholders.yourFirstName')"
            rules="required|max:50"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="name_middle"
            v-model="willMeta.name_middle"
            :disabled="loading"
            :required="false"
            :text="$t('forms.placeholders.yourMiddleName')"
            rules="max:50"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="name_last"
            v-model="willMeta.name_last"
            :disabled="loading"
            :text="$t('forms.placeholders.yourLastName')"
            rules="required|max:50"
          />
        </div>
      </div>
      <Headline :level="2">
        {{ $t('pages.will.aboutYourSelf.questions.knownByAnotherName') }}
      </Headline>
      <Tip>
        {{ $t('pages.will.aboutYourSelf.egAltName') }}
      </Tip>
      <LargeRadioButtons
        id="has_alt_name"
        v-model="willMeta.has_alt_name"
        :options="[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ]"
      />
      <template v-if="willMeta.has_alt_name">
        <Headline :level="2">
          {{ $t('pages.will.aboutYourSelf.questions.otherName') }}
        </Headline>
        <div class="grid">
          <div class="cell cell__12/12">
            <FormControl
              id="alt_name_first"
              v-model="willMeta.alt_name_first"
              :disabled="loading"
              :text="$t('forms.placeholders.yourFirstName')"
              rules="required|max:50"
            />
          </div>
          <div class="cell cell__12/12">
            <FormControl
              id="alt_name_middle"
              v-model="willMeta.alt_name_middle"
              :disabled="loading"
              :text="$t('forms.placeholders.yourMiddleName')"
              rules="max:50"
            />
          </div>
          <div class="cell cell__12/12">
            <FormControl
              id="alt_name_last"
              v-model="willMeta.alt_name_last"
              :disabled="loading"
              :text="$t('forms.placeholders.yourLastName')"
              rules="required|max:50"
            />
          </div>
        </div>
      </template>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAboutYourselfName',
  components: {
    AdiForm,
    ModuleStep,
    FormControl,
    Tip,
    LargeRadioButtons,
    Headline,
  },
  mixins: [user, will],
  computed: {
    isComplete() {
      return !!(
        this.willMeta.name_first &&
        this.willMeta.name_last &&
        (this.willMeta.has_alt_name === false ||
          (this.willMeta.alt_name_first && this.willMeta.alt_name_last))
      );
    },
  },
  methods: {
    onDone() {
      const willMeta = this.willMeta;

      this.$nuxt.$emit('sendTrackingAttributes', {
        name: `${willMeta.name_first} ${willMeta.name_middle} ${willMeta.name_last}`,
      });

      if (willMeta.has_alt_name) {
        this.$nuxt.$emit('sendTrackingAttributes', {
          alternative_name: `${willMeta.alt_name_first} ${willMeta.alt_name_middle} ${willMeta.alt_name_last}`,
        });
      }

      this.$router.push({
        path: this.localePath('/will/about-yourself/date-of-birth'),
      });
    },
  },
};
</script>
