<template>
  <ModuleStep :index="1" prev-step="/will">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="submit"
    >
      <Headline :level="1">
        <i18n path="pages.will.guardians.doYouHaveAny">
          <GlossaryTooltip term="child">
            {{ $t('texts.words.children') }}
          </GlossaryTooltip>
        </i18n>
      </Headline>
      <Tip>
        {{ $t('pages.will.guardians.chooseGuardianTip') }}
      </Tip>
      <LargeRadioButtons
        id="hasChildren"
        v-model="willMeta.has_children"
        :options="[
          {
            label: $t('forms.labels.yes'),
            value: true,
          },
          {
            label: $t('forms.labels.no'),
            value: false,
          },
        ]"
      />
      <template v-if="willMeta.has_children">
        <Headline :level="2">
          {{ $t('pages.will.guardians.myChildrenAre') }}
        </Headline>
        <PersonSelector
          v-model="selectedChildren"
          :loading="loading"
          :people="filteredPeople"
          type="child"
        />
      </template>
    </AdiForm>
    <GlossaryPanel />
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';

import AdiForm from '@/components/Form';
import GlossaryTooltip from '@/components/GlossaryTooltip';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import GlossaryPanel from '@/components/GlossaryPanel';

import { beneficiaries, guardians, people, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillGuardiansYourChildren',
  components: {
    AdiForm,
    GlossaryTooltip,
    LargeRadioButtons,
    ModuleStep,
    PersonSelector,
    Tip,
    Headline,
    GlossaryPanel,
  },
  mixins: [beneficiaries, guardians, people, user, will],
  data() {
    return {
      selectedChildren: [],
    };
  },
  computed: {
    isComplete() {
      return !!(
        this.willMeta.has_children === false || this.selectedChildren.length
      );
    },
    filteredPeople() {
      return this.people.filter((person) => {
        return person.category === 'none' || person.category === 'child';
      });
    },
    nextStep() {
      return this.localePath(
        this.willMeta.has_children && this.minors.length
          ? '/will/guardians/primary'
          : '/will/guardians/your-pets'
      );
    },
  },
  watch: {
    children(children) {
      if (children.length && !this.selectedChildren.length) {
        this.selectedChildren = children.map((child) => child.id);
      }
    },
  },
  methods: {
    ...mapActions('person', ['addPerson']),
    async submit() {
      let childrenUpdated = false;

      const hasChildren = this.willMeta.has_children;

      await Promise.all(
        this.people.map((person) => {
          const isChild = person.category === 'child';
          const isSelected = this.selectedChildren.includes(person.id);
          if (hasChildren && isSelected) {
            if (!isChild) {
              childrenUpdated = true;
              return this.updatePerson(person, {
                category: 'child',
              });
            }
          } else if (isChild) {
            childrenUpdated = true;
            return this.updatePerson(person, {
              category: 'none',
            });
          }
          return Promise.resolve();
        })
      );

      if (
        this.willMeta.estate_split === 'children' &&
        (hasChildren === false || childrenUpdated)
      ) {
        this.willMeta.estate_split = null;

        await this.removeAllBeneficiaries();
        await this.updateWillMeta();
      }

      if (!hasChildren) {
        this.willMeta.has_primary_guardian = null;
        this.willMeta.has_backup_guardian = null;
        await this.updateWillMeta();
        await this.removeAllGuardians();
      }

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_children: hasChildren,
      });

      this.$router.push({
        path: this.nextStep,
      });
    },
  },
};
</script>
