<template>
  <table class="ru:admin-simple-table">
    <caption v-if="description">
      {{
        description
      }}
    </caption>
    <tbody>
      <tr class="d-none">
        <th scope="col">{{ $t('components.adminSimpleTable.item') }}</th>
        <th scope="col">{{ $t('components.adminSimpleTable.value') }}</th>
      </tr>
      <tr v-for="(row, index) in rows" :key="index">
        <td>{{ row[0] }}</td>
        <td v-if="row[2]">
          <a :href="row[2]" class="link link-dark whitespace-no-wrap">
            {{ row[1] }}
          </a>
        </td>
        <td v-else>
          {{ row[1] }}
          <span
            v-if="copyCellContent && row[1]"
            class="copy"
            @click="copyContent(row[1])"
          >
            <Icon id="copy" size="small" />
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import copy from 'copy-to-clipboard';
import Icon from '@/components/atoms/Icon';

export default {
  name: 'ComponentsAdminSimpleTable',
  components: {
    Icon,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    copyCellContent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copyContent(content) {
      copy(content);
      this.$nuxt.$emit('toast', {
        type: 'success',
        message: 'Copied to clipboard',
      });
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &admin-simple-table {
    @at-root #{$ru}box & {
      td,
      th {
        padding: var(--base-margin-small) var(--base-margin);
      }
    }

    tr {
      &:nth-child(2n) {
        background: --rgba(ivory);
      }
    }

    td:first-child {
      color: --rgba(dust);
      width: 40%;
    }

    .copy {
      cursor: pointer;

      svg {
        display: inline-block;
      }
    }
  }
}
</style>
