<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.parentsOfDeceased') }}
    </Headline>
    <CremationsFormInstructions />
    <FormWrapper @submit="saveUpdatedDetails('final')">
      <div v-for="(guestParent, i) in formData.guestParents" :key="i">
        <Headline :level="3">
          {{ $upperFirst($t('texts.words.parent')) }} {{ i + 1 }}
        </Headline>
        <div class="grid ru:form__fieldset">
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="guestParentsFirstName"
              v-model="formData.guestParents[i].firstName"
              :required="false"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.firstName')"
              :pattern="FIRST_NAME_PATTERN.regex"
              :title="$t(FIRST_NAME_PATTERN.description)"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="guestParentsOtherGivenNames"
              v-model="formData.guestParents[i].otherGivenNames"
              :required="false"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.otherGivenNames')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="guestParentsLastName"
              v-model="formData.guestParents[i].lastName"
              :required="false"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.lastName')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="guestParentsLastNameAtBirth"
              v-model="formData.guestParents[i].lastNameAtBirth"
              :required="false"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.lastNameAtBirth')"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="guestParentsSex"
              v-model="formData.guestParents[i].sex"
              :required="false"
              :disabled="loading"
              type="select"
              :text="$t('forms.labels.sex')"
              :options="SEX_OPTIONS"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="guestParentsRelationship"
              v-model="formData.guestParents[i].relationship"
              :required="false"
              :disabled="loading"
              type="select"
              :text="$t('forms.labels.relationship')"
              :options="PARENT_OPTIONS"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--lg">
            <FormControl
              id="guestParentsOccupation"
              v-model="formData.guestParents[i].occupation"
              :required="false"
              :disabled="loading"
              type="text"
              :text="$t('forms.labels.occupationDuringWorkingLife')"
            />
          </div>
        </div>
      </div>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="toggleConfirmModal">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton
          background="wine"
          color="white"
          @click="saveUpdatedDetails('final')"
        >
          {{ $t('forms.labels.accept') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import CremationsFormInstructions from '@/components/snowflakes/CremationsFormInstructions';

import { FIRST_NAME_PATTERN } from '@/utilities/cremations/form-constants';

export default {
  name: 'PagesAffiliateCremationsAtNeedParentsId',
  components: {
    BaseModal,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    FormControl,
    CremationsFormInstructions,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      loading: true,
      minLengths: {
        guestParents: 2,
      },
      formFields: {
        guestParents: {
          firstName: null,
          otherGivenNames: null,
          lastName: null,
          lastNameAtBirth: null,
          sex: null,
          relationship: null,
          occupation: null,
        },
      },
      formData: {
        guestPartners: [],
        guestParents: [],
        guestMaritalStatusAtDeath: null,
      },
      FIRST_NAME_PATTERN,
    };
  },
  computed: {
    nextPage() {
      return this.localePath(
        `/cremations/at-need/${
          this.showRelationships ? 'relationships' : 'children'
        }/${this.cremationRequestId}`
      );
    },
    prevPage() {
      return this.localePath(
        `/cremations/at-need/life/${this.cremationRequestId}`
      );
    },
  },
};
</script>
