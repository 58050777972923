<template>
  <div class="ru:fieldset">
    <div class="grid">
      <div class="cell cell__12/12">
        <FormControl
          v-if="!isQuestionLayout"
          id="name"
          v-model="formData.data.name"
          text="Name"
          rules="required|max:100"
        />
      </div>
    </div>
    <template v-if="isFieldsetVisible('LICENCE')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            rules="max:100"
            text="Type"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="licenceNumber"
            v-model="formData.data.licenceNumber"
            rules="max:100"
            text="Licence number"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="issueCountry"
            v-model="formData.data.issueCountry"
            text="Country of issue"
            type="select"
            :options="$countries"
            :required="false"
          />
        </div>
        <div
          v-if="
            formData.data.issueCountry &&
            formData.data.issueCountry !== 'Australia'
          "
          class="cell cell__12/12"
        >
          <FormControl
            id="issueLocation"
            v-model="formData.data.issueLocation"
            rules="max:100"
            text="Location of issue"
          />
        </div>
        <div v-else class="cell cell__12/12">
          <FormControl
            id="issueLocation"
            v-model="formData.data.issueLocation"
            text="State of issue"
            type="select"
            :options="$auStates"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="expirationDate"
            v-model="formData.data.expirationDate"
            text="Expiration date"
            type="date"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('PASSPORT')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="passportNumber"
            v-model="formData.data.passportNumber"
            rules="max:100"
            text="Passport number"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="issueCountry"
            v-model="formData.data.issueCountry"
            text="Country of issue"
            type="select"
            :options="$countries"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="expirationDate"
            v-model="formData.data.expirationDate"
            text="Expiration date"
            type="date"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('BIRTH_CERTIFICATE')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="registrationNumber"
            v-model="formData.data.registrationNumber"
            rules="max:100"
            text="Registration number"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="registrationDate"
            v-model="formData.data.registrationDate"
            text="Registration date"
            type="date"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('MEDICARE')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="cardNumber"
            v-model="formData.data.cardNumber"
            rules="max:12"
            text="Card number"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="individualReferenceNumber"
            v-model="formData.data.individualReferenceNumber"
            rules="max:1"
            text="Individual reference number"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('TAXATION')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="tfn"
            v-model="formData.data.tfn"
            rules="max:11"
            text="Tax file number"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('RESIDENCE')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="country"
            v-model="formData.data.country"
            text="Country"
            type="select"
            :options="$countries"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="identifier"
            v-model="formData.data.identifier"
            rules="max:100"
            text="Identifier"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('GOVERNMENT_CONCESSION')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="GOVERNMENT_CONCESSION_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="identifier"
            v-model="formData.data.identifier"
            rules="max:100"
            text="Identifier"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('RELATIONSHIP')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="relationshipType"
            v-model="formData.data.relationshipType"
            text="Relationship type"
            type="select"
            :options="RELATIONSHIP_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="partnerLegalName"
            v-model="formData.data.partnerLegalName"
            rules="max:100"
            text="Legal name of partner"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="identifier"
            v-model="formData.data.identifier"
            rules="max:100"
            text="Identifier"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('WILL')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="provider"
            v-model="formData.data.provider"
            rules="max:100"
            text="Provider"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="locationOfDocument"
            v-model="formData.data.locationOfDocument"
            rules="max:100"
            text="Location of physical document"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('POWER_OF_ATTORNEY')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="POA_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="provider"
            v-model="formData.data.provider"
            rules="max:100"
            text="Provider"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="locationOfDocument"
            v-model="formData.data.locationOfDocument"
            rules="max:100"
            text="Location of physical document"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('GUARDIANSHIP')" />
    <template v-else-if="isFieldsetVisible('BANK_ACCOUNT')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="BANK_ACCOUNT_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="institution"
            v-model="institution"
            text="Institution"
            type="select"
            :options="institutionOptions"
            :required="false"
            :allow-other="true"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            v-if="institution === 'OTHER'"
            id="otherInstitutionName"
            v-model="formData.data.otherInstitutionName"
            rules="max:100"
            text="Other institution"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('PROPERTY')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="PROPERTY_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="addressLine1"
            v-model="formData.data.addressLine1"
            rules="max:50"
            text="Address line 1"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="addressLine2"
            v-model="formData.data.addressLine2"
            rules="max:50"
            text="Address line 2"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="addressSuburb"
            v-model="formData.data.addressSuburb"
            rules="max:50"
            text="Suburb"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="addressState"
            v-model="formData.data.addressState"
            text="State"
            type="select"
            :options="$auStates"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="addressPostcode"
            v-model="formData.data.addressPostcode"
            rules="max:10"
            text="Postcode"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="addressCountry"
            v-model="formData.data.addressCountry"
            text="Country"
            type="select"
            :options="$countries"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="ownershipType"
            v-model="formData.data.ownershipType"
            text="Ownership type"
            type="select"
            :options="PROPERTY_OWNERSHIP_OPTIONS"
            :required="false"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('BUSINESS')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="BUSINESS_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="businessName"
            v-model="formData.data.businessName"
            rules="max:100"
            text="Business name"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('TRUST')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="trustName"
            v-model="formData.data.trustName"
            rules="max:100"
            text="Trust name"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="identifier"
            v-model="formData.data.identifier"
            rules="max:100"
            text="Identifier"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="role"
            v-model="formData.data.role"
            text="Are you a Trustee or Director of a Trustee?"
            type="select"
            :options="ROLE_IN_TRUST_OPTIONS"
            :required="false"
          />
        </div>
        <div>
          {{ $t('components.endOfLifeFieldset.isBeneficiaryQuestion') }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="isBeneficiary"
              v-model="formData.data.isBeneficiary"
              text="Yes"
              type="radio"
              format="boolean"
              :option="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="isBeneficiary"
              v-model="formData.data.isBeneficiary"
              text="No"
              type="radio"
              format="boolean"
              :option="false"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('FINANCIAL_ASSETS')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="FINANCIAL_ASSET_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="identifier"
            v-model="formData.data.identifier"
            rules="max:100"
            text="Identifier"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('SUPERANNUATION')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="institution"
            v-model="institution"
            text="Institution"
            type="select"
            :options="institutionOptions"
            :required="false"
            :allow-other="true"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            v-if="institution === 'OTHER'"
            id="otherInstitutionName"
            v-model="formData.data.otherInstitutionName"
            rules="max:100"
            text="Other institution"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="memberNumber"
            v-model="formData.data.memberNumber"
            rules="max:100"
            text="Member number"
          />
        </div>
        <div>
          {{
            $t(
              'components.endOfLifeFieldset.hasMadeBindingDeathNominationQuestion'
            )
          }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasMadeBindingDeathNomination"
              v-model="formData.data.hasMadeBindingDeathNomination"
              text="Yes"
              type="radio"
              format="boolean"
              :value="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasMadeBindingDeathNomination"
              v-model="formData.data.hasMadeBindingDeathNomination"
              text="No"
              type="radio"
              format="boolean"
              :value="false"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('INSURANCE')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="INSURANCE_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="institution"
            v-model="institution"
            text="Institution"
            type="select"
            :options="institutionOptions"
            :required="false"
            :allow-other="true"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            v-if="institution === 'OTHER'"
            id="otherInstitutionName"
            v-model="formData.data.otherInstitutionName"
            rules="max:100"
            text="Other institution"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="policyNumber"
            v-model="formData.data.policyNumber"
            rules="max:100"
            text="Policy number"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('VEHICLE')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="makeOrModel"
            v-model="formData.data.makeOrModel"
            rules="max:100"
            text="Make or model"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="numberPlate"
            v-model="formData.data.numberPlate"
            rules="max:100"
            text="Number plate"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('CREDIT_CARD')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="accountName"
            v-model="formData.data.accountName"
            rules="max:100"
            text="Account name"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="institution"
            v-model="institution"
            text="Institution"
            type="select"
            :options="institutionOptions"
            :required="false"
            :allow-other="true"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            v-if="institution === 'OTHER'"
            id="otherInstitutionName"
            v-model="formData.data.otherInstitutionName"
            rules="max:100"
            text="Other institution"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('LOAN')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="LOAN_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="accountName"
            v-model="formData.data.accountName"
            rules="max:100"
            text="Account name"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="institution"
            v-model="institution"
            text="Institution"
            type="select"
            :options="institutionOptions"
            :required="false"
            :allow-other="true"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            v-if="institution === 'OTHER'"
            id="otherInstitutionName"
            v-model="formData.data.otherInstitutionName"
            rules="max:100"
            text="Other institution"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('UTILITY')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="UTILITY_OPTIONS"
            :required="false"
            @input="updateCurrentInstitution"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="institution"
            v-model="institution"
            text="Institution"
            type="select"
            :options="institutionOptions"
            :required="false"
            :allow-other="true"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            v-if="institution === 'OTHER'"
            id="otherInstitutionName"
            v-model="formData.data.otherInstitutionName"
            rules="max:100"
            text="Other institution"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="amount"
            v-model="formData.data.amount"
            text="Amount"
            type="number"
            rules="max:10"
            format="float"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="frequency"
            v-model="formData.data.frequency"
            text="Frequency"
            type="select"
            :options="FREQUENCY_OPTIONS"
            :required="false"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('SUBSCRIPTION')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="institution"
            v-model="institution"
            text="Provider name"
            type="select"
            :options="institutionOptions"
            :required="false"
            :allow-other="true"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            v-if="institution === 'OTHER'"
            id="otherInstitutionName"
            v-model="formData.data.otherInstitutionName"
            rules="max:100"
            text="Other institution"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="amount"
            v-model="formData.data.amount"
            rules="max:10"
            text="Amount"
            type="number"
            format="float"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="frequency"
            v-model="formData.data.frequency"
            text="Frequency"
            type="select"
            :options="FREQUENCY_OPTIONS"
            :required="false"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('DONATION')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="institutionId"
            v-model="formData.data.institutionId"
            rules="max:100"
            text="Charity/Institution name"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="amount"
            v-model="formData.data.amount"
            rules="max:10"
            text="Amount"
            type="number"
            format="float"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="frequency"
            v-model="formData.data.frequency"
            text="Frequency"
            type="select"
            :options="FREQUENCY_OPTIONS"
            :required="false"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('GOVERNMENT_BENEFIT')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type of benefit"
            type="select"
            :options="GOVERNMENT_BENEFIT_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="institution"
            v-model="institution"
            text="Institution"
            type="select"
            :options="institutionOptions"
            :required="false"
            :allow-other="true"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            v-if="institution === 'OTHER'"
            id="otherInstitutionName"
            v-model="formData.data.otherInstitutionName"
            rules="max:100"
            text="Other institution"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="amount"
            v-model="formData.data.amount"
            rules="max:10"
            text="Amount"
            type="number"
            format="float"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="frequency"
            v-model="formData.data.frequency"
            text="Frequency"
            type="select"
            :options="FREQUENCY_OPTIONS"
            :required="false"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('EMPLOYMENT')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="employer"
            v-model="formData.data.employer"
            rules="max:100"
            text="Employer"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="type"
            v-model="formData.data.type"
            text="Type"
            type="select"
            :options="EMPLOYMENT_TYPE_OPTIONS"
            :required="false"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('ORGAN_DONATION')">
      <div class="grid">
        <div>
          {{ $t('components.endOfLifeFieldset.isOrganDonorQuestion') }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="isOrganDonor"
              v-model="formData.data.isOrganDonor"
              text="Yes"
              type="radio"
              format="boolean"
              :option="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="isOrganDonor"
              v-model="formData.data.isOrganDonor"
              text="No"
              type="radio"
              format="boolean"
              :option="false"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('MEDICAL_DEVICE')">
      <div class="grid">
        <div>
          {{ $t('components.endOfLifeFieldset.hasPacemakerQuestion') }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasPacemaker"
              v-model="formData.data.hasPacemaker"
              text="Yes"
              type="radio"
              format="boolean"
              :value="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasPacemaker"
              v-model="formData.data.hasPacemaker"
              text="No"
              type="radio"
              format="boolean"
              :value="false"
            />
          </div>
        </div>
        <div>
          {{ $t('components.endOfLifeFieldset.hasDefibrillatorQuestion') }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasDefibrillator"
              v-model="formData.data.hasDefibrillator"
              text="Yes"
              type="radio"
              format="boolean"
              :value="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasDefibrillator"
              v-model="formData.data.hasDefibrillator"
              text="No"
              type="radio"
              format="boolean"
              :value="false"
            />
          </div>
        </div>
        <div>
          {{
            $t(
              'components.endOfLifeFieldset.hasDefibrillatorDrugInfusionQuestion'
            )
          }}
        </div>
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasDefibrillatorDrugInfusion"
              v-model="formData.data.hasDefibrillatorDrugInfusion"
              text="Yes"
              type="radio"
              format="boolean"
              :value="true"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="hasDefibrillatorDrugInfusion"
              v-model="formData.data.hasDefibrillatorDrugInfusion"
              text="No"
              type="radio"
              format="boolean"
              :value="false"
            />
          </div>
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="hasOtherEquipment"
            v-model="formData.data.hasOtherEquipment"
            rules="max:3000"
            text="Do you have any other equipment/devices?"
            type="textarea"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isQuestionLayout">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            v-show="false"
            id="name"
            v-model="formData.data.name"
            rules="required|max:100"
            :value="question.name"
            text="Name"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            v-show="false"
            id="key"
            v-model="formData.data.key"
            :value="question.key"
            text="Name"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="value"
            v-model="formData.data.value"
            rules="max:3000"
            :text="question.name"
            :type="question.type"
            :options="question.options"
            @change="$emit('change', formData.data.key, $event)"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('EMOTIONAL_MESSAGE')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="recipientName"
            v-model="formData.data.recipientName"
            rules="max:152"
            text="Recipient name"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="recipientEmail"
            v-model="formData.data.recipientEmail"
            rules="email"
            text="Email"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="phoneNumber"
            v-model="formData.data.phoneNumber"
            rules="max:15"
            text="Phone number"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="modeOfDelivery"
            v-model="formData.data.modeOfDelivery"
            text="How do you want your message delivered to the recipient?"
            type="select"
            :options="EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="message"
            v-model="formData.data.message"
            rules="max:10000"
            text="Message"
            type="textarea"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('EMOTIONAL_STORY')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="recipientName"
            v-model="formData.data.recipientName"
            rules="max:152"
            text="Recipient name"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="recipientEmail"
            v-model="formData.data.recipientEmail"
            rules="email"
            text="Email"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="phoneNumber"
            v-model="formData.data.phoneNumber"
            rules="max:15"
            text="Phone number"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="modeOfDelivery"
            v-model="formData.data.modeOfDelivery"
            text="How do you want your story delivered to the recipient?"
            type="select"
            :options="EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS"
            :required="false"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="story"
            v-model="formData.data.story"
            rules="max:10000"
            text="Story"
            type="textarea"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isFieldsetVisible('CONTACT')">
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="contact"
            v-model="formData.data.contact"
            rules="max:300"
            text="Contact details"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import FormControl from '@/components/molecules/FormControl';
import institutions from '@/mixins/institutions';
import {
  GOVERNMENT_CONCESSION_OPTIONS,
  RELATIONSHIP_OPTIONS,
  POA_OPTIONS,
  BANK_ACCOUNT_OPTIONS,
  PROPERTY_OPTIONS,
  PROPERTY_OWNERSHIP_OPTIONS,
  BUSINESS_OPTIONS,
  FINANCIAL_ASSET_OPTIONS,
  INSURANCE_OPTIONS,
  LOAN_OPTIONS,
  UTILITY_OPTIONS,
  FREQUENCY_OPTIONS,
  GOVERNMENT_BENEFIT_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  FUNERAL_TYPE_OPTIONS,
  FUNERAL_DIRECTOR_OPTIONS,
  FUNERAL_PREPAID_TYPE_OPTIONS,
  EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS,
  ROLE_IN_TRUST_OPTIONS,
} from '@/utilities/end-of-life/form-constants';

export default {
  name: 'ComponentsTemplatesEndOfLifeFieldset',
  components: {
    FormControl,
  },
  mixins: [institutions],
  props: {
    layout: {
      type: String,
      default: 'DEFAULT',
    },
    institutionType: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      required: true,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentInstitutionType: null,
      institution: this.value.data.otherInstitutionName
        ? 'OTHER'
        : this.value.data.institutionId,
      institutionOptions: [],
      formData: this.value,
      GOVERNMENT_CONCESSION_OPTIONS,
      RELATIONSHIP_OPTIONS,
      POA_OPTIONS,
      BANK_ACCOUNT_OPTIONS,
      PROPERTY_OPTIONS,
      PROPERTY_OWNERSHIP_OPTIONS,
      BUSINESS_OPTIONS,
      FINANCIAL_ASSET_OPTIONS,
      INSURANCE_OPTIONS,
      LOAN_OPTIONS,
      UTILITY_OPTIONS,
      FREQUENCY_OPTIONS,
      GOVERNMENT_BENEFIT_OPTIONS,
      EMPLOYMENT_TYPE_OPTIONS,
      FUNERAL_TYPE_OPTIONS,
      FUNERAL_DIRECTOR_OPTIONS,
      FUNERAL_PREPAID_TYPE_OPTIONS,
      EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS,
      ROLE_IN_TRUST_OPTIONS,
    };
  },
  computed: {
    isQuestionLayout() {
      return ['FUNERAL_PREFERENCE', 'FUNERAL_PREPAID_POLICY'].includes(
        this.layout
      );
    },
  },
  watch: {
    currentInstitutionType() {
      this.getAndSetInstitutionOptions();
    },
    institution(value) {
      if (value === 'OTHER') {
        this.formData.data.institutionId = null;
      } else {
        this.formData.data.institutionId = value;
        this.formData.data.otherInstitutionName = null;
      }
    },
    formData: {
      handler(newData) {
        this.$emit('input', newData);
      },
      deep: true,
    },
    value: {
      handler(newData) {
        this.formData = newData;
      },
      deep: true,
    },
  },
  mounted() {
    this.updateCurrentInstitution();
  },
  created() {
    if (this.isQuestionLayout) {
      this.formData.data.name = this.question.name;
      this.formData.data.key = this.question.key;
    }
  },
  methods: {
    updateCurrentInstitution() {
      if (this.isFieldsetVisible('UTILITY')) {
        this.currentInstitutionType = this.getCurrentInstitutionForSubscription(
          this.formData.data.type
        );
      } else {
        this.currentInstitutionType = this.institutionType;
      }
    },
    getCurrentInstitutionForSubscription(current) {
      switch (current) {
        case 'Mobile':
          return 'Mobile';
        case 'Internet':
          return 'Internet';
        default:
          return 'Utilities';
      }
    },
    getCurrentInstitutionForLoan(current) {
      if (current === 'Mortgage') {
        return 'Mortgage';
      }
      return 'Personal Loan';
    },
    async getAndSetInstitutionOptions() {
      const institutions = await this.getInstitutionsByType(
        this.currentInstitutionType
      );

      this.institutionOptions = institutions.map(({ id, name }) => ({
        text: name,
        value: id,
      }));
    },
    isFieldsetVisible(fieldset) {
      return fieldset === this.layout;
    },
  },
};
</script>
