<template>
  <div class="ru:admin-card" :class="tight ? '--tight' : ''">
    <Box background="white" border="iron">
      <Headline v-if="title" :level="3">{{ title }}</Headline>
      <slot />
    </Box>
  </div>
</template>

<script>
import Box from '@/components/atoms/Box';
import Headline from '@/components/atoms/Headline';
export default {
  name: 'ComponentsAdminCard',
  components: {
    Box,
    Headline,
  },
  props: {
    title: {
      default: null,
      type: String,
    },
    tight: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &admin-card {
    position: relative;

    &#{$mf}tight {
      #{$ru}box {
        padding: 0;
      }
      #{$ru}headline {
        margin-left: var(--base-margin);
        margin-top: var(--base-margin);
      }
    }

    #{$ru}box {
      .grid {
        margin: var(--base-margin-small) var(--base-margin-small) 0;
        width: calc(100% - var(--base-margin-small) * 2);
      }
    }

    .admin-card__buttons {
      @apply absolute top-0 right-0 mt-2 mr-1 flex flex-no-wrap;
    }

    .admin-card__buttons.mr-4 {
      @apply mr-4;
    }

    .admin-card__button-content {
      @apply flex flex-no-wrap items-center font-bold;
    }

    .admin-card__button {
      @apply p-2 text-white rounded mr-2 text-sm;
    }

    .admin-card__button--save {
      @apply bg-blue-200;
    }

    .admin-card__button--copy {
      @apply bg-orange-300;
    }

    .admin-card__button--top {
      @apply bg-slate-100;
    }

    .admin-card__button--add {
      @apply bg-green-100;
    }

    .admin-card__button--remove {
      @apply bg-red-400;
    }

    .admin-card__button--override {
      @apply bg-red-500;
    }
  }
}
</style>
