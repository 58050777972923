<template>
  <ModuleStep :index="2" prev-step="/poa/about-yourself">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="done"
    >
      <Headline :level="1">
        {{ $t('pages.poa.questions.currResAddress') }}
      </Headline>
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="address_street"
            v-model="willMeta.address_street"
            :disabled="loading"
            :text="$t('forms.labels.streetAddress')"
            rules="required|max:50"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="address_suburb"
            v-model="willMeta.address_suburb"
            :disabled="loading"
            :text="$t('forms.labels.suburb')"
            rules="required|max:50"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md">
          <FormControl
            v-if="willMeta.address_country === 'AU'"
            id="address_state"
            v-model="willMeta.address_state"
            :disabled="loading"
            :text="$t('forms.labels.state')"
            type="select"
            :options="$auStates"
            rules="required"
          />
          <FormControl
            v-else
            id="address_state"
            v-model="willMeta.address_state"
            :disabled="loading"
            :text="$t('forms.labels.region')"
            rules="max:50"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md">
          <FormControl
            id="address_postcode"
            v-model="willMeta.address_postcode"
            :disabled="loading"
            :text="$t('forms.labels.postCode')"
            rules="required|postcode"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md">
          <FormControl
            id="address_country"
            v-model="willMeta.address_country"
            type="select"
            rules="required"
            :disabled="true"
            :text="$t('forms.labels.country')"
            :options="$countriesOptions"
            searchable
          />
        </div>
      </div>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Headline from '@/components/atoms/Headline';

import { getUserFullAddress } from '@/utilities';
import { beneficiaries, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaAboutYourselfAddress',
  components: {
    AdiForm,
    ModuleStep,
    FormControl,
    Headline,
  },
  mixins: [beneficiaries, user, will],
  computed: {
    isComplete() {
      if (
        this.willMeta.address_country === 'AU' &&
        !this.willMeta.address_state
      ) {
        return false;
      }
      return !!(
        this.willMeta.address_street &&
        this.willMeta.address_suburb &&
        this.willMeta.address_postcode
      );
    },
  },
  watch: {
    'willMeta.address_state': {
      handler(newValue) {
        if (newValue === '') {
          this.willMeta.address_state = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    done() {
      const willMeta = this.willMeta;
      this.$nuxt.$emit('sendTrackingAttributes', {
        address: getUserFullAddress(willMeta),
      });

      this.$router.push({
        path: this.localePath('/poa/about-yourself/date-of-birth'),
      });
    },
  },
};
</script>
