<template>
  <BaseModal v-if="showTransferWillModal" @close="close">
    <h2 class="mb-4 text-4xl leading-tight">
      Transfer Submission {{ willData.hashId }}
    </h2>
    <h3>
      Transferring a submission will generate the appropriate file and assign an
      affiliate to this will.
    </h3>
    <br />
    <ValidationObserver ref="transferWillModalObserver">
      <h3>Partnership</h3>
      <SelectInput
        id="transferAffiliateId"
        v-model="transferAffiliateId"
        class="rounded"
        :options="partnershipOptions"
        compact
      />
    </ValidationObserver>
    <br />
    <Buttons layout="stretch">
      <RuButton :disabled="isLoading" @click="close"> Cancel </RuButton>
      <RuButton :disabled="isLoading" @click="submit"> Confirm </RuButton>
    </Buttons>
    <Alert v-if="error" theme="error" level="medium">
      {{ error }}
    </Alert>
  </BaseModal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { mapGetters } from 'vuex';
import RuButton from '@/components/atoms/Button';
import Alert from '@/components/molecules/Alert';
import Buttons from '@/components/atoms/Buttons';
import SelectInput from '@/components/SelectInput';

import BaseModal from '@/components/BaseModal';
import { formatError, getSubordinateRoleOptionsByRole } from '@/utilities';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import UPDATE_WILL_MUTATION from '@/graphql/mutations/UpdateWill';

export default {
  name: 'ComponentsTransferWillModal',
  components: {
    BaseModal,
    ValidationObserver,
    RuButton,
    Alert,
    Buttons,
    SelectInput,
  },
  props: {
    showTransferWillModal: {
      default: false,
      type: Boolean,
    },
    willData: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    transferAffiliateId: null,
    isLoading: false,
    error: null,
  }),
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      update: (data) => data && data.getPartnerships,
    },
  },
  computed: {
    ...mapGetters(['role']),
    partnershipOptions() {
      if (!this.partnerships) return [];

      const partnershipOptions = this.partnerships
        .filter((partnership) => partnership.affiliateEnabled)
        .map((partnership) => {
          return {
            text: partnership.name,
            value: partnership.id,
          };
        });

      const nullOption = {
        text: 'None',
        value: null,
      };

      return [...partnershipOptions, nullOption];
    },
  },
  mounted() {
    this.transferAffiliateId = this.willData.transferAffiliateId;
  },
  methods: {
    getSubordinateRoleOptionsByRole,
    async submit() {
      this.error = null;
      const isValid =
        this.$refs.transferWillModalObserver &&
        (await this.$refs.transferWillModalObserver.validate());
      if (!isValid) {
        return;
      }

      try {
        this.isLoading = true;
        await this.$apollo.mutate({
          mutation: UPDATE_WILL_MUTATION,
          variables: {
            id: this.willData.id,
            meta: [],
            transferAffiliateId: this.transferAffiliateId,
          },
        });
        this.$emit('submit', this.transferAffiliateId);
        this.close();
      } catch (e) {
        this.error = formatError(e.message);
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.user = {};
      this.$refs.transferWillModalObserver.reset();
      this.$emit('close');
    },
  },
};
</script>
