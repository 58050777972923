<template>
  <nuxt :nuxt-child-key="$route.fullPath" />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PagesWill',
  layout: 'ruach',
  mounted() {
    this.setShowCompletionPage(true);
  },
  methods: {
    ...mapActions('will', ['setShowCompletionPage']),
  },
};
</script>
