<template>
  <AdiForm
    v-slot="{ loading }"
    :disabled="!name || !address"
    :mutation="mutation"
    :variables="variables"
    :submit-label="$t('components.addCustomCharity.submit')"
    @update="update"
    @done="close"
  >
    <FormControl
      id="name"
      v-model="name"
      :disabled="loading"
      :text="$t('components.addCustomCharity.inputs.name.label')"
      rules="required|max:100"
    />
    <Tip>
      {{ $t('components.addCustomCharity.tips.name') }}
    </Tip>
    <FormControl
      id="abn"
      v-model="abn"
      :disabled="loading"
      :text="$t('components.addCustomCharity.inputs.abn.label')"
      :placeholder="$t('components.addCustomCharity.inputs.abn.placeholder')"
      rules="abn"
    />
    <Tip>
      {{ $t('components.addCustomCharity.tips.abn') }}
    </Tip>
    <FormControl
      id="address"
      v-model="address"
      :disabled="loading"
      :text="$t('components.addCustomCharity.inputs.address.label')"
      rules="required|max:150"
    />
  </AdiForm>
</template>

<script>
import { metaArrayToObject } from '../utilities';
import AdiForm from '@/components/Form';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';

import { charities, user } from '@/mixins/apollo';
import { objectToMetaArray } from '@/utilities';

export default {
  name: 'ComponentsAddCustomCharity',
  components: {
    AdiForm,
    FormControl,
    Tip,
  },
  mixins: [charities, user],
  data() {
    return {
      name: '',
      abn: '',
      address: '',
    };
  },
  computed: {
    mutation() {
      return this.ADD_CHARITY_MUTATION;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray({
          name: this.name,
          abn: this.abn.trim(),
          address: this.address,
        }),
      };
      variables.willId = this.willId;
      return variables;
    },
  },
  methods: {
    close() {
      this.$emit('successfullyAddedCharity');
    },
    update(store, { data: { addCharity } }) {
      if (addCharity) {
        const addedCharity = metaArrayToObject(addCharity?.charity?.meta);
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'charity_added',
          props: {
            charity_name: addedCharity.name,
          },
        });
        const data = store.readQuery(this.getCharitiesQuery);
        data.getCharities.charities.push(addCharity.charity);
        store.writeQuery({
          ...this.getCharitiesQuery,
          data,
        });
        this.$nuxt.$emit('addCharity', addCharity.charity);
      }
    },
  },
};
</script>
