<template>
  <div class="ru:form__control--with-unknown">
    <FormControl
      v-bind="$attrs"
      :id="`${identifier}`"
      v-model="modelValue"
      :type="type"
      :options="optionsWithUnknown"
      :disabled="valueIsUnknown"
    />
    <div
      v-if="checkboxIsVisible"
      class="ru:form__control ru:form__control--inline"
    >
      <FormControl
        :id="`${identifier}IsUnknown`"
        type="checkbox"
        format="boolean"
        :value="valueIsUnknown"
        :required="false"
        :text="$t('components.formControlWithUnknown.unknown')"
        @input="toggleUnknown"
      />
    </div>
  </div>
</template>

<script>
import {
  UNKNOWN_DATE,
  UNKNOWN_NUMBER,
  UNKNOWN_TEXT,
} from '@/utilities/cremations/form-constants';

import FormControl from '@/components/molecules/FormControl';

export default {
  name: 'ComponentsOrganismsFormControlWithUnknown',
  components: { FormControl },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      default: '',
      type: [String, Boolean, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: null,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    valueIsUnknown() {
      const dateIsUnknown =
        this.type === 'date' && this.modelValue === UNKNOWN_DATE;
      const numberIsUnknown =
        this.type === 'number' && this.modelValue === UNKNOWN_NUMBER;
      const textIsUnknown = this.modelValue === UNKNOWN_TEXT;
      return dateIsUnknown || numberIsUnknown || textIsUnknown;
    },
    checkboxIsVisible() {
      return !['select', 'radio', 'checkbox'].includes(this.type);
    },
  },
  created() {
    this.identifier = this.id + '__' + Math.random().toString(36).slice(2);
    if (this.valueIsUnknown && this.checkboxIsVisible) {
      this.modelValue = null;
    }
    if (this.options) {
      if (typeof this.options[0] === 'string') {
        this.optionsWithUnknown = [...this.options, UNKNOWN_TEXT];
      } else {
        this.optionsWithUnknown = [
          ...this.options,
          {
            value: UNKNOWN_TEXT,
            text: UNKNOWN_TEXT.charAt(0) + UNKNOWN_TEXT.slice(1).toLowerCase(),
          },
        ];
      }
    } else {
      this.optionsWithUnknown = null;
    }
  },
  methods: {
    toggleUnknown(checked) {
      if (checked) {
        switch (this.type) {
          case 'number':
            this.modelValue = UNKNOWN_NUMBER;
            break;
          case 'date':
            this.modelValue = UNKNOWN_DATE;
            break;
          default:
            this.modelValue = UNKNOWN_TEXT;
            break;
        }
      } else {
        this.modelValue = null;
      }
    },
  },
};
</script>
