<template>
  <ModuleStep :index="4" prev-step="/poa/about-yourself/date-of-birth">
    <AdiForm
      v-if="willMeta"
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :submit-label="submitLabel"
      @done="onDone"
    >
      <Headline :level="1">
        {{ $t('pages.poa.questions.phoneNumber') }}
      </Headline>
      <Tip>
        {{ $t('pages.poa.tips.optionalSecurityFeature') }}
      </Tip>
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="phone"
            v-model="willMeta.phone_number"
            :disabled="loading"
            :text="$t('forms.placeholders.yourPhoneNumber')"
            rules="phone"
          />
        </div>
      </div>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaAboutYourselfPhone',
  components: {
    AdiForm,
    ModuleStep,
    FormControl,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    submitLabel() {
      return this.willMeta.phone_number ? 'Next' : 'Skip';
    },
  },
  methods: {
    onDone() {
      if (this.willMeta.phone_number) {
        this.$nuxt.$emit('sendTrackingAttributes', {
          phone: `${this.willMeta.phone_number}`,
        });
      }

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 1 (About Yourself)',
      });

      this.$router.push({
        path: this.localePath('/poa'),
      });
    },
  },
};
</script>
