<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ headlineSmallText }}</small>
      {{ headlineBigText }}
    </Headline>
    <Slider v-if="!showThanks">
      <SliderItem>
        <GenericModuleCard
          :title="$t('texts.sentences.aboutYourself')"
          :description="$t('texts.sentences.confirmAboutYourself')"
          :duration="[3, 5]"
          :number="1"
          :loading="false"
          :progress="aboutYourselfComplete ? 1 : 0"
          :ready="!aboutYourselfComplete"
          :editable="true"
          :to="
            localePath(`/cremations/at-need/informant/${cremationRequestId}`)
          "
        />
      </SliderItem>
      <SliderItem>
        <GenericModuleCard
          :title="$t('texts.sentences.yourLovedOne')"
          :description="$t('pages.cremations.confirmDetailsAboutLovedOne')"
          :duration="[3, 5]"
          :number="2"
          :loading="false"
          :progress="yourLovedOneComplete ? 1 : 0"
          :ready="aboutYourselfComplete && !yourLovedOneComplete"
          :editable="true"
          :to="localePath(`/cremations/at-need/deceased/${cremationRequestId}`)"
          :locked="!aboutYourselfComplete"
          :locked-message="
            $t('pages.cremations.confirmDetailsAboutYourselfFirst')
          "
        />
      </SliderItem>
      <SliderItem>
        <GenericModuleCard
          :title="$upperFirst($t('texts.words.documents'))"
          :description="$t('pages.cremations.reviewAndSignDocuments')"
          :duration="[8, 10]"
          :number="3"
          :loading="false"
          :progress="documentsComplete ? 1 : 0"
          :ready="
            aboutYourselfComplete && yourLovedOneComplete && !documentsComplete
          "
          :editable="true"
          :to="
            localePath(
              `/cremations/at-need/documentation/initial/${cremationRequestId}`
            )
          "
          :locked="!aboutYourselfComplete || !yourLovedOneComplete"
          :locked-message="
            $t('pages.cremations.confirmDetailsAboutLovedOneFirst')
          "
        />
      </SliderItem>
      <SliderItem>
        <GenericModuleCard
          :title="$t('texts.sentences.authorisation')"
          :description="
            $t('pages.cremations.completeDetailsToAuthoriseCremation')
          "
          :duration="[3, 5]"
          :number="4"
          :loading="false"
          :progress="authorisationComplete ? 1 : 0"
          :ready="
            aboutYourselfComplete &&
            yourLovedOneComplete &&
            documentsComplete &&
            !authorisationComplete
          "
          :editable="true"
          :to="
            localePath(
              `/cremations/at-need/authorisation/${cremationRequestId}`
            )
          "
          :locked="
            !aboutYourselfComplete ||
            !yourLovedOneComplete ||
            !documentsComplete
          "
          :locked-message="$t('pages.cremations.reviewAndSignDocumentsFirst')"
        />
      </SliderItem>
      <SliderItem>
        <GenericModuleCard
          :title="$upperFirst($t('texts.words.finalise'))"
          :description="
            $t('pages.cremations.completeRequiredInfoForDeathCertificate')
          "
          :duration="[10]"
          :number="5"
          :loading="false"
          :progress="finaliseComplete ? 1 : 0"
          :ready="
            aboutYourselfComplete &&
            yourLovedOneComplete &&
            documentsComplete &&
            authorisationComplete &&
            !finaliseComplete
          "
          :editable="true"
          :to="localePath(`/cremations/at-need/life/${cremationRequestId}`)"
          :locked="
            !aboutYourselfComplete ||
            !yourLovedOneComplete ||
            !documentsComplete ||
            !authorisationComplete
          "
          :locked-message="
            $t('pages.cremations.completeDetailsToAuthoriseCremationFirst')
          "
        />
      </SliderItem>
    </Slider>
    <div v-else class="grid">
      <div class="cell cell__12/12 cell__6/12--md">
        <p>
          {{ $t('pages.cremations.thankYouForCompletingRequiredDocuments') }}
        </p>
        <div class="grid">
          <div class="cell cell__10/12 cell__6/12--md cell__5/12--lg">
            <RuButton
              :aria-label="$t('texts.sentences.reviewDetails')"
              button-type="submit"
              color="wine"
              border="wine"
              background="white"
              wide
              @click="toggleReviewDetails"
            >
              <span>{{ $t('texts.sentences.reviewDetails') }}</span>
            </RuButton>
          </div>
          <div class="cell cell__10/12 cell__6/12--md cell__5/12--lg">
            <RuButton
              :href="localePath('/cremations/at-need')"
              :aria-label="$t('texts.sentences.goToDashboard')"
              button-type="submit"
              background="wine"
              color="white"
              wide
            >
              <span>{{ $t('texts.sentences.goToDashboard') }}</span>
            </RuButton>
          </div>
        </div>
      </div>
      <div class="cell cell__12/12 cell__6/12--md">
        <img
          :src="require('assets/svg/gateway__cremations.svg')"
          style="max-height: 25rem; margin: 0 auto"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import RuButton from '@/components/atoms/Button';
import Headline from '@/components/atoms/Headline';
import GenericModuleCard from '@/components/GenericModuleCard';
import Slider from '@/components/Slider';
import SliderItem from '@/components/SliderItem';

export default {
  name: 'PagesAffiliateCremationsAtNeedId',
  components: {
    RuButton,
    Headline,
    GenericModuleCard,
    Slider,
    SliderItem,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      reviewDetails: false,
      formData: {
        guestFirstName: null,
        guestLastName: null,
        authorityToActSignature: null,
        servicesAgreementSignature: null,
        transferAuthoritySignature: null,
        authorityToPostSignature: null,
        deathRegistrationSignature: null,
        authorityToReleaseSignature: null,
        guestHasWill: null,
        informantIsWillExecutor: null,
        informantIsNextOfKin: null,
        executorOrKinAddressCountry: null,
        hasRelativeExpressedObjection: null,
        relativeReasonForObjection: null,
        gpFirstName: null,
        guestOccupation: null,
        guestMaritalStatusAtDeath: null,
        isReportableToCoroner: null,
      },
    };
  },
  computed: {
    cremationRequestId() {
      return this.$route.params.id;
    },
    headlineSmallText() {
      return this.showThanks
        ? this.$t('pages.cremations.cremationService')
        : this.$t('pages.cremations.cremationServiceFor');
    },
    headlineBigText() {
      return this.showThanks
        ? this.$t('texts.sentences.thankyou')
        : this.guestIdentifier;
    },
    guestIdentifier() {
      if (this.formData.guestFirstName && this.formData.guestLastName) {
        return `${this.formData.guestFirstName} ${this.formData.guestLastName}`;
      }
      return this.$t('texts.sentences.yourLovedOne');
    },
    showThanks() {
      return this.finaliseComplete && !this.reviewDetails;
    },
    aboutYourselfComplete() {
      return (
        (this.formData.guestHasWill === true &&
          this.formData.informantIsWillExecutor === true) ||
        (this.formData.guestHasWill === false &&
          this.formData.informantIsNextOfKin === true) ||
        this.formData.executorOrKinAddressCountry !== null
      );
    },
    yourLovedOneComplete() {
      return (
        this.formData.hasRelativeExpressedObjection === false ||
        (this.formData.hasRelativeExpressedObjection === true &&
          this.formData.relativeReasonForObjection !== null)
      );
    },
    documentsComplete() {
      return (
        this.isSigned(this.formData.servicesAgreementSignature) &&
        this.isSigned(this.formData.transferAuthoritySignature)
      );
    },
    authorisationComplete() {
      return (
        (this.formData.isReportableToCoroner || this.formData.gpFirstName) &&
        this.formData.guestOccupation &&
        this.formData.guestMaritalStatusAtDeath
      );
    },
    finaliseComplete() {
      return (
        this.isSigned(this.formData.authorityToPostSignature) &&
        this.isSigned(this.formData.deathRegistrationSignature) &&
        this.isSigned(this.formData.authorityToReleaseSignature)
      );
    },
  },
  methods: {
    toggleReviewDetails() {
      this.reviewDetails = !this.reviewDetails;
    },
  },
};
</script>
