<template>
  <ValidationObserver
    ref="validationObserver"
    tag="form"
    @submit.prevent="onSubmit"
  >
    <slot />
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
export default {
  name: 'ComponentsOrganismsForm',
  components: {
    ValidationObserver,
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.validationObserver.errors;
      },
      this.validateAndEmit,
      { immediate: true }
    );
  },
  methods: {
    validateAndEmit() {
      this.$emit('input', this.$refs.validationObserver.flags.invalid);
    },
    onSubmit() {
      if (!this.$refs.validationObserver.flags.invalid) {
        this.$emit('submit');
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &form {
    margin-bottom: var(--base-margin);

    & > &__control {
      margin-bottom: var(--base-margin);
    }

    & > &__control--inline + &__control--inline {
      margin-top: #{calc(var(--base-margin-small) * -1)};
    }
  }
}
</style>
