<template>
  <ModuleStep :index="4" prev-step="/will/about-yourself/date-of-birth">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="done"
    >
      <Headline :level="1">
        {{ $t('pages.will.aboutYourSelf.questions.currentResidentialAddress') }}
      </Headline>
      <Tip>
        {{ $t('pages.will.aboutYourSelf.addressTip') }}
      </Tip>
      <div class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="address_street"
            v-model="willMeta.address_street"
            :disabled="loading"
            :text="$t('forms.labels.streetAddress')"
            rules="required|max:50"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="address_suburb"
            v-model="willMeta.address_suburb"
            :disabled="loading"
            :text="$t('forms.labels.suburb')"
            rules="required|max:50"
          />
        </div>
        <div class="cell cell__6/12">
          <FormControl
            v-if="willMeta.address_country === 'AU'"
            id="address_state"
            v-model="willMeta.address_state"
            type="select"
            rules="required"
            :disabled="loading"
            :options="$auStates"
            :text="$t('forms.labels.state')"
          />
          <FormControl
            v-else
            id="address_state"
            v-model="willMeta.address_state"
            :disabled="loading"
            :text="$t('forms.labels.region')"
            rules="max:50"
          />
        </div>
        <div class="cell cell__6/12">
          <FormControl
            id="address_postcode"
            v-model="willMeta.address_postcode"
            :disabled="loading"
            :text="$t('forms.labels.postCode')"
            rules="required|postcode"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="address_country"
            v-model="willMeta.address_country"
            type="select"
            rules="required"
            :disabled="true"
            :text="$t('forms.labels.country')"
            :options="$countriesOptions"
          />
        </div>
      </div>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { getUserFullAddress } from '@/utilities';
import { beneficiaries, user, will, charities } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillAboutYourselfAddress',
  components: {
    AdiForm,
    ModuleStep,
    FormControl,
    Tip,
    Headline,
  },
  mixins: [beneficiaries, user, will, charities],
  computed: {
    ...mapFields('signup', ['meta', 'meta.referral_charity']),
    isComplete() {
      if (
        this.willMeta.address_country === 'AU' &&
        !this.willMeta.address_state
      ) {
        return false;
      }
      return !!(
        this.willMeta.address_street &&
        this.willMeta.address_suburb &&
        this.willMeta.address_postcode
      );
    },
  },
  // to allow the user to delete the state
  watch: {
    'willMeta.address_state': {
      handler(newValue) {
        if (newValue === '') {
          this.willMeta.address_state = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async done() {
      if (!this.hasBeneficiaries && this.meta.address_state) {
        await this.replaceNationalCharitiesWithRegionalCharities();
      }

      const willMeta = this.willMeta;
      this.$nuxt.$emit('sendTrackingAttributes', {
        address: getUserFullAddress(willMeta),
      });

      // Some feature flags depend on the user's address, so we should refetch them when this changes.
      this.$ff.refreshFeatureFlags();

      this.$router.push({
        path: this.localePath('/will/about-yourself/partner'),
      });
    },
  },
};
</script>
