var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":2,"prev-step":"/will/estate"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"disabled":!_vm.isComplete},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.estate.leaveEstateCharityQuestion'))+"\n    ")]),_vm._v(" "),_c('p',[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.estate.leaveEstateCharityMessage'))+"\n    ")]),_vm._v(" "),_c('Tip',[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.estate.leaveMonetaryGiftMessage'))+"\n    ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasPartner","options":[
        {
          label: _vm.$t('forms.labels.yes'),
          value: true,
        },
        {
          label: _vm.$t('forms.labels.no'),
          value: false,
        },
      ]},model:{value:(_vm.willMeta.charity_in_estate),callback:function ($$v) {_vm.$set(_vm.willMeta, "charity_in_estate", $$v)},expression:"willMeta.charity_in_estate"}}),_vm._v(" "),(_vm.willMeta.charity_in_estate)?_c('div',[_c('Headline',{attrs:{"level":3}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.estate.charitiesAndNotForProfit'))+"\n      ")]),_vm._v(" "),(_vm.showSuggestedCharities)?_c('CharitySuggester',{attrs:{"loading":loading,"selected-suggested-charities":_vm.selectedSuggestedCharities},model:{value:(_vm.selectedSuggestedCharities),callback:function ($$v) {_vm.selectedSuggestedCharities=$$v},expression:"selectedSuggestedCharities"}}):_vm._e(),_vm._v(" "),_c('CharitySelector',{attrs:{"loading":loading},model:{value:(_vm.selectedBeneficiaryIDs),callback:function ($$v) {_vm.selectedBeneficiaryIDs=$$v},expression:"selectedBeneficiaryIDs"}}),_vm._v(" "),_c('Buttons',{attrs:{"layout":"stretch"}},[_c('RuButton',{attrs:{"size":"large","level":"secondary"},on:{"click":_vm.addCharity}},[_vm._v("\n          + "+_vm._s(_vm.$t('forms.labels.addCharity'))+"\n        ")])],1),_vm._v(" "),_c('CharityPanel')],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }