<template>
  <div class="ru: ru:admin-navigation">
    <div class="ru:admin-navigation__intro">
      <nuxt-link to="/admin">
        <Logo variant="mark" />
      </nuxt-link>
      <Headline :level="2">
        {{ $t('components.navigationAdmin.title') }}
      </Headline>
    </div>
    <div class="ru:admin-navigation__links">
      <div class="ru:admin-navigation__links--site">
        <NavigationItem
          v-if="isAdminOrHigher"
          :text="$t('components.navigationAdmin.coupons')"
          to="/admin/coupons"
        />
        <NavigationItem
          v-if="isCremationsAdminOrHigher"
          :text="$t('components.navigationAdmin.cremations')"
          to="/admin/cremations"
        />
        <NavigationItem
          v-if="isAdminOrHigher"
          :text="$t('components.navigationAdmin.endOfLifeCategories')"
          to="/admin/end-of-life-categories"
        />
        <NavigationItem
          v-if="isCremationsAdminOrHigher"
          :text="$t('components.navigationAdmin.genericCheckout')"
          to="/admin/generic-checkout"
        />
        <NavigationItem
          v-if="isAdminOrHigher"
          :text="$t('components.navigationAdmin.institutions')"
          to="/admin/institutions"
        />
        <NavigationItem
          v-if="isPartnershipsAdminOrHigher"
          :text="$t('components.navigationAdmin.partnerships')"
          to="/admin/partnerships"
        />
        <NavigationItem
          v-if="
            isSuperAdmin ||
            isCremationsAdminOrHigher ||
            isPartnershipsAdminOrHigher
          "
          :text="$t('components.navigationAdmin.reporting')"
          to="/admin/reporting"
        />
        <NavigationItem
          :text="$t('components.navigationAdmin.submissions')"
          to="/admin/submissions"
        />
        <NavigationItem
          v-if="isAdminOrHigher"
          :text="$t('components.navigationAdmin.users')"
          to="/admin/users"
        />
      </div>
      <div class="ru:admin-navigation__links--account">
        <div class="ru:admin-navigation__user">
          {{ splitEmail }}
        </div>
        <NavigationItem
          :text="$t('components.navigationAdmin.logout')"
          to="/log-out?redirect=/admin"
          icon="logout"
          icon-theme="error"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NavigationItem from '@/components/molecules/NavigationItem';
import Logo from '@/components/atoms/Logo';
import Headline from '@/components/atoms/Headline';

import {
  isAdminOrHigher,
  isCremationsAdminOrHigher,
  isPartnershipsAdminOrHigher,
  isSuperAdmin,
} from '@/utilities';

export default {
  name: 'ComponentsOrganismsNavigationAdmin',
  components: {
    NavigationItem,
    Logo,
    Headline,
  },
  computed: {
    ...mapGetters(['email', 'role']),
    isAdminOrHigher() {
      return isAdminOrHigher(this.role);
    },
    isCremationsAdminOrHigher() {
      return isCremationsAdminOrHigher(this.role);
    },
    isPartnershipsAdminOrHigher() {
      return isPartnershipsAdminOrHigher(this.role);
    },
    isSuperAdmin() {
      return isSuperAdmin(this.role);
    },
    splitEmail() {
      return this.email.replaceAll('@', ' @​');
    },
  },
};
</script>
<style lang="scss">
#{$ru} {
  &admin-navigation {
    background: --rgba(white);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15rem;
    padding: var(--base-margin-big) var(--base-margin);
    overflow: auto;

    &__intro {
      padding: 0 1.25rem 1.25rem;

      --app-logo-height: 3rem;
    }

    &__user {
      padding: 0 1.25rem;
      color: --rgba(dust);
    }

    #{$ru}logo {
      margin-bottom: var(--base-margin);
    }

    #{$ru}navigation__item {
      --link-padding: 0.75rem;
    }

    &__links {
      &,
      &--site,
      &--account {
        display: flex;
        flex-direction: column;
      }

      &--site,
      &--account {
        gap: var(--base-margin-small);
        position: relative;
      }

      &--account {
        &::before {
          display: block;
          content: '';
          height: 1px;
          background: --rgba(iron);
          margin: var(--base-margin-big) var(--base-margin-small);
        }
      }
    }
  }
}
</style>
