<template>
  <div class="ru:container ru:container--tight">
    <Checkout
      is-update-card-details
      :checkout-items="checkoutItems"
      @orderCreated="ordersCreationSuccessfulHandler"
    >
      <template #PAYMENT>
        <Headline :level="1">
          {{ $t('pages.checkout.updateCreditCard') }}
        </Headline>
        <p>
          {{ $t('pages.checkout.updateCreditCardDesc') }}
        </p>
      </template>
    </Checkout>
  </div>
</template>

<script>
import Checkout from '@/components/templates/Checkout';
import Headline from '@/components/atoms/Headline';

import { user } from '@/mixins/apollo';

const checkoutItems = [
  {
    product: 'UPDATE_CREDIT_CARD_DETAILS',
    finalPrice: 1500,
    type: 'DEFAULT',
  },
];

export default {
  name: 'PagesAffiliateCheckoutUpdateCardIndex',
  components: {
    Checkout,
    Headline,
  },
  mixins: [user],
  data() {
    return {
      success: false,
      checkoutItems,
    };
  },
  methods: {
    ordersCreationSuccessfulHandler() {
      this.$router.push({
        path: this.localePath('/checkout/update-card/thanks'),
      });
    },
  },
};
</script>
