var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/will"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"disabled":!_vm.isComplete},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.assets.asset.title'))+"\n    ")]),_vm._v(" "),_c('p',[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.assets.asset.desc'))+"\n    ")]),_vm._v(" "),_c('Tip',[_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.asset.tip.assetsGuide'))+"\n      ")]),_vm._v(" "),(_vm.userIsAustralian)?[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.assets.asset.tip.superannuationInsurance'))+"\n        ")]),_vm._v(" "),_c('Buttons',{attrs:{"layout":"start"}},[_c('RuButton',{attrs:{"data-test":"super-death-explanation","theme":"secondary","size":"small","href":"https://safewill.com/blog/posts/what-happens-to-your-superannuation-when-you-die"}},[_c('Icon',{attrs:{"id":"info"}}),_vm._v("\n            "+_vm._s(_vm.$t('pages.will.assets.asset.superDeathExplanation'))+"\n          ")],1)],1)]:_vm._e()],2),_vm._v(" "),_c('Headline',{attrs:{"level":2}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.assets.asset.question'))+"\n    ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasAssets","options":[
        {
          label: _vm.$t('forms.labels.yes'),
          value: true,
        },
        {
          label: _vm.$t('forms.labels.no'),
          value: false,
        },
      ]},model:{value:(_vm.willMeta.has_assets),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_assets", $$v)},expression:"willMeta.has_assets"}}),_vm._v(" "),(_vm.willMeta.has_assets)?[_c('Headline',{attrs:{"level":2}},[_vm._v(" "+_vm._s(_vm.$t('texts.sentences.yourAssets'))+" ")]),_vm._v(" "),(!_vm.selectedAssets.length)?_c('Box',{attrs:{"background":"sand"}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.assets.asset.noAssets'))+"\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.selectedAssets),function(asset,index){return _c('AssetInputs',{key:index,attrs:{"id":`asset_${asset.id || index}`,"disabled":loading},on:{"add":_vm.addAssetInput,"remove":function($event){return _vm.removeAssetInput(asset)}},model:{value:(asset.meta),callback:function ($$v) {_vm.$set(asset, "meta", $$v)},expression:"asset.meta"}})}),_vm._v(" "),_c('Buttons',{attrs:{"layout":"end"}},[_c('RuButton',{attrs:{"size":"large","level":"secondary"},on:{"click":_vm.addAssetInput}},[_vm._v("\n          + "+_vm._s(_vm.$t('forms.labels.add'))+"\n        ")])],1)]:_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }