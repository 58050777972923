<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :style="`--dimensions: ${dimensions}`"
    :class="[
      'ru:icon',
      theme ? `--themed --${theme}` : '',
      shadow ? '--shadow' : '',
      alignRight ? '--right' : '',
    ]"
  >
    <use
      v-bind="{
        'xlink:href': `${require('~/assets/svg/ru-icons.svg')}#${id}`,
      }"
    />
  </svg>
</template>

<script>
export default {
  name: 'ComponentsAtomsIcon',
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      default: null,
      type: String,
    },
    theme: {
      default: null,
      type: String,
    },
    shadow: {
      default: false,
      type: Boolean,
    },
    alignRight: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    dimensions() {
      switch (this.size) {
        case 'huge':
          return 2.5;
        case 'large':
          return 2;
        case 'small':
          return 1.25;
        case 'tiny':
          return 0.75;
        default:
          return 1.5;
      }
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &icon {
    stroke: none;
    fill: currentcolor;
    transition: --in();
    width: #{calc(var(--dimensions) * 1rem)};
    height: #{calc(var(--dimensions) * 1rem)};

    &#{$mf}themed {
      color: --rgba(theme);
    }

    &#{$mf}shadow {
      color: --rgba(theme);
      filter: drop-shadow(1px 1px 0 --rgba(lighter))
        drop-shadow(2px 2px 0 --rgba(lighter));
    }

    &#{$mf}right {
      margin-left: auto;
    }
  }
}
</style>
