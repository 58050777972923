<template>
  <ModuleStep :index="0" :prev-step="previous">
    <AdiForm
      v-slot="{ loading }"
      :disabled="!isComplete"
      @submit.prevent="onSubmit"
    >
      <Headline :level="1">
        {{ $t('pages.poa.financial.index.title') }}
      </Headline>
      <p class="mb-4">
        {{ $t('pages.poa.financial.index.description') }}
      </p>
      <p class="mb-4">
        {{ $t('pages.poa.financial.index.responsibilitiesListTitle') }}
      </p>
      <ul>
        <li
          v-for="(item, index) in $t(
            'pages.poa.financial.index.responsibilitiesList'
          )"
          :key="`responsibilitiesItem-${index}`"
        >
          {{ item }}
        </li>
      </ul>
      <p class="mb-4">
        {{ $t('pages.poa.financial.index.exclusions') }}
      </p>
      <Tip>
        {{ $t('pages.poa.financial.index.tip') }}
      </Tip>
      <LargeRadioButtons
        id="has_attorney"
        v-model="poaFinancialMeta.has_attorney"
        layout="column"
        :options="[
          {
            label: $t('pages.poa.financial.index.options.yes'),
            value: true,
          },
          {
            label: $t('pages.poa.financial.index.options.no'),
            value: false,
          },
        ]"
        @input="onSubmit"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';
import Tip from '@/components/molecules/Tip';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'PagesAffiliatePoaFinancialIndex',
  components: {
    Tip,
    AdiForm,
    ModuleStep,
    LargeRadioButtons,
    Headline,
  },
  apollo: {
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      poaFinancialMeta: {
        has_attorney: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
      });
    },
    isComplete() {
      return (
        this.poaFinancialMeta &&
        (this.poaFinancialMeta.has_attorney === true ||
          this.poaFinancialMeta.has_attorney === false)
      );
    },
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      await this.$apollo.mutate(
        mutations.updatePoaMeta(this.poaId, {
          key: 'has_attorney',
          value: this.poaFinancialMeta.has_attorney ? 'true' : 'false',
          category: 'FINANCIAL',
        })
      );

      if (!this.poaFinancialMeta.has_attorney) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: '✅ Module POA financial skipped',
        });
      }

      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
</script>
