<template>
  <BaseModal v-if="showNewPartnershipModal" @close="close">
    <div ref="container">
      <h2 class="mb-4 text-4xl leading-tight">
        {{ $t('components.newPartnershipModal.title') }}
      </h2>
      <AdiForm
        :mutation="ADD_PARTNERSHIP_MUTATION"
        :variables="variables"
        submit-wide
        :submit-label="$t('components.newPartnershipModal.addPartnership')"
        @update="update"
        @done="close"
      >
        <FormRow>
          <TextInput
            id="name"
            v-model="formData.name"
            :label="$t('components.newPartnershipModal.name')"
            rules="required"
            compact
          />
        </FormRow>
        <FormRow>
          <TextInput
            id="slug"
            v-model="formData.slug"
            :label="$t('components.newPartnershipModal.slug')"
            rules="required"
            compact
          />
        </FormRow>
      </AdiForm>
    </div>
  </BaseModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { charityCategories } from '@/modules/charityData';
import ADD_PARTNERSHIP_MUTATION from '@/graphql/mutations/AddPartnership.gql';

import BaseModal from '@/components/BaseModal';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import TextInput from '@/components/TextInput';

export default {
  name: 'ComponentsAdminNewPartnershipModal',
  components: {
    BaseModal,
    AdiForm,
    FormRow,
    TextInput,
  },
  data: () => ({
    ADD_PARTNERSHIP_MUTATION,
    availableCharityCategories: charityCategories,
    formData: {
      name: null,
      abn: null,
      address: null,
      categories: [],
    },
  }),
  computed: {
    ...mapState('partnership', ['showNewPartnershipModal']),
    variables() {
      return {
        partnership: {
          ...this.formData,
          abn: '',
          address: '',
          logo: '',
          landingPages: [],
          altNames: [],
          utmPrefixes: [],
          featuredFive: false,
          dashboardEnabled: false,
          bequestEnabled: false,
          affiliateEnabled: false,
          active: false,
          archived: false,
          region: null,
          relatedPartnershipIds: [],
          contacts: [],
        },
      };
    },
  },
  methods: {
    ...mapMutations('partnership', ['setShowNewPartnershipModal']),
    close() {
      this.setShowNewPartnershipModal(false);
    },
    async update(store, res) {
      const createdPartnership = res.data.createPartnership;
      this.$emit('partnershipAdded');
      await this.$router.push(
        this.localePath(`/admin/partnerships/${createdPartnership.id}`)
      );
    },
  },
};
</script>
