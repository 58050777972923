<template>
  <BaseModal v-if="showCouponModal" @close="close">
    <div ref="container">
      <div>
        <h2 class="mb-4 text-4xl leading-tight">
          {{ $t('components.couponModal.title') }}
        </h2>
        <AdiForm
          :mutation="ADD_COUPON_MUTATION"
          :variables="variables"
          submit-wide
          :submit-label="$t('components.couponModal.addCoupon')"
          @update="update"
          @done="close"
        >
          <FormRow half-width>
            <TextInput
              id="code"
              v-model="code"
              :disabled="loading"
              :label="$t('components.couponModal.codeInput.label')"
              classes="uppercase"
              :placeholder="$t('components.couponModal.codeInput.placeholder')"
              rules="required"
            />
          </FormRow>
          <FormRow>
            <TextInput
              id="description"
              v-model="description"
              :disabled="loading"
              :label="$t('components.couponModal.descriptionInput.label')"
              :placeholder="
                $t('components.couponModal.descriptionInput.placeholder')
              "
              rules="required"
            />
          </FormRow>
          <FormRow>
            <BaseRadio
              id="coupon_type"
              v-model="type"
              :label="$t('components.couponModal.typeInput.label')"
              :options="[
                {
                  text: $t(
                    'components.couponModal.typeInput.options.percentage'
                  ),
                  value: 'PERCENTAGE',
                },
                {
                  text: $t('components.couponModal.typeInput.options.fixed'),
                  value: 'FIXED',
                },
              ]"
            />
          </FormRow>
          <FormRow half-width>
            <MoneyInput
              v-if="type === 'FIXED'"
              id="factor"
              v-model="factor"
              :disabled="loading"
              :label="$t('components.couponModal.value')"
              placeholder="00"
              :rules="`required`"
            />
            <PercentageInput
              v-if="type === 'PERCENTAGE'"
              id="factor"
              v-model="factor"
              :disabled="loading"
              :label="$t('components.couponModal.value')"
              placeholder="00"
              :rules="`required|min_value:0|max_value:100|integer`"
            />
          </FormRow>
          <label class="input-label">
            {{ $t('components.couponModal.products') }}
          </label>
          <FormRow>
            <CheckboxInput
              v-for="(value, key) in checkboxProductsMap"
              :id="key"
              :key="key"
              :value="value"
              class="mt-4 md:mt-6"
              @input="(checked) => handleChangeCheckboxProducts(key, checked)"
            >
              {{ key }}
            </CheckboxInput>
          </FormRow>
        </AdiForm>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { createHelpers } from 'vuex-map-fields';

import BaseRadio from '@/components/BaseRadio';
import BaseModal from '@/components/BaseModal';
import AdiForm from '@/components/Form';
import FormRow from '@/components/FormRow';
import TextInput from '@/components/TextInput';
import MoneyInput from '@/components/MoneyInput';
import PercentageInput from '@/components/PercentageInput';
import CheckboxInput from '@/components/CheckboxInput';
import { user, coupons } from '@/mixins/apollo';

const { mapFields } = createHelpers({
  getterType: 'getCouponField',
  mutationType: 'updateCouponField',
});

export default {
  name: 'ComponentsAdminCouponModal',
  components: {
    BaseModal,
    AdiForm,
    FormRow,
    TextInput,
    BaseRadio,
    MoneyInput,
    PercentageInput,
    CheckboxInput,
  },
  mixins: [user, coupons],
  data: () => ({
    loading: false,
    checkboxProductsMap: {
      WILL: false,
      POA: false,
      PARTNER_WILL: false,
    },
  }),
  computed: {
    ...mapFields('admin/coupon', [
      'fields.code',
      'fields.type',
      'fields.description',
      'fields.used',
      'fields.factor',
      'fields.active',
      'fields.products',
    ]),
    ...mapGetters('admin/coupon', ['fields', 'showCouponModal']),
    ...mapGetters('admin', ['couponsSortOrder']),
    variables() {
      return { ...this.fields };
    },
    listProductCheckbox() {
      return Object.keys(this.checkboxProductsMap);
    },
  },
  methods: {
    ...mapActions('admin/coupon', ['setShowCouponModal']),
    close() {
      this.setShowCouponModal(false);
    },
    update() {
      this.$emit('addedCoupon');
    },
    handleChangeCheckboxProducts(key, value) {
      this.checkboxProductsMap[key] = value;
      const productEntries = Object.entries(this.checkboxProductsMap);
      this.products = productEntries.filter((el) => el[1]).map((el) => el[0]);
    },
  },
};
</script>
