<template>
  <div v-if="!isLoadingData" class="ru:subscription-status-alerts">
    <Alert
      v-if="showSubscriptionExpiredDownloadWillAlert"
      level="major"
      theme="error"
    >
      <span>
        {{
          $t(
            'components.subscriptionStatusAlert.subscriptionExpiredCanDownloadWill'
          )
        }}
        <nuxt-link :to="localePath('/checkout/unlock')">
          {{ $t('components.subscriptionStatusAlert.unlockYourAccount') }}
        </nuxt-link>
        {{ $t('components.subscriptionStatusAlert.toEditWillAgain') }}
      </span>
    </Alert>
    <Alert
      v-else-if="showSubscriptionExpiredWillUnlockAlert"
      level="major"
      theme="error"
    >
      <span>
        {{ $t('components.subscriptionStatusAlert.subscriptionExpired') }}
        <nuxt-link :to="localePath('/checkout/unlock')">
          {{ $t('components.subscriptionStatusAlert.unlockYourAccount') }}
        </nuxt-link>
        {{ $t('components.subscriptionStatusAlert.toEditWillAgain') }}
      </span>
    </Alert>
    <Alert
      v-else-if="showSubscriptionExpiringAlert"
      level="medium"
      theme="warning"
    >
      <span>
        {{ $t('components.subscriptionStatusAlert.subscriptionIsSetToExpire') }}
        <span> {{ `${formattedExpiryDate}. ` }}</span>
        {{ $t('components.subscriptionStatusAlert.notToRenewSubscription') }}
        <nuxt-link
          v-if="!hasValidPaymentMethod"
          :to="localePath('/checkout/update-card')"
          class="text-green-300 underline"
        >
          {{ $t('components.subscriptionStatusAlert.updateCardDetails') }}
        </nuxt-link>
        <nuxt-link
          v-else
          :to="{
            path: localePath('/dashboard/account-settings'),
            hash: '#billing',
          }"
          class="text-green-300 underline"
        >
          {{ $t('components.subscriptionStatusAlert.enableAutoRenew') }}
        </nuxt-link>
        {{ $t('components.subscriptionStatusAlert.keepSubscriptionActive') }}
      </span>
    </Alert>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { purchasedProducts, subscription } from '@/mixins/apollo';
import Alert from '@/components/molecules/Alert';

export default {
  name: 'ComponentsOrganismsSubscriptionStatusAlert',
  components: { Alert },
  mixins: [subscription, purchasedProducts],
  layout: 'ruach',
  props: {
    productPage: {
      default: null,
      type: String,
      validator(value) {
        return ['will'].includes(value);
      },
    },
  },
  computed: {
    ...mapGetters(['willStatus']),
    isLoadingData() {
      return (
        this.subscription === null ||
        this.isPoaPurchased === null ||
        this.isWillPurchased === null
      );
    },
    showSubscriptionExpiringAlert() {
      return (
        ((this.productPage === 'will' && this.willStatus === 'APPROVED') ||
          (this.productPage !== 'will' && this.isWillOrPoaPurchased)) &&
        this.showUpdateSubscriptionBanner
      );
    },
    showSubscriptionExpiredWillUnlockAlert() {
      return this.hasExpiredSubscription && this.productPage === 'will';
    },
    showSubscriptionExpiredDownloadWillAlert() {
      return (
        this.productPage === 'will' &&
        this.willStatus === 'APPROVED' &&
        this.hasExpiredSubscription
      );
    },
  },
};
</script>
