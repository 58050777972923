<template>
  <div class="ru: ru:onboarding-layout">
    <App />
    <Nuxt />
  </div>
</template>

<script>
import App from '@/components/organisms/App';

export default {
  name: 'LayoutOnboarding',
  components: {
    App,
  },
  middleware: [
    'redirect-if-not-logged-in.middleware',
    'userOnboardingPageRequired',
  ],
};
</script>

<style lang="scss">
#{$ru} {
  &onboarding-layout {
    --onboarding-margin: var(--base-margin-small);
    padding: calc(var(--app-height) + var(--onboarding-margin) * 3) 0;
    height: 100%;

    @include mq('min-md') {
      --onboarding-margin: var(--base-margin);
    }
  }
}
</style>
