var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":0,"prev-step":_vm.previous}},[_c('AdiForm',{attrs:{"disabled":!_vm.isComplete},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.index.title'))+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.index.description'))+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.index.responsibilitiesListTitle'))+"\n    ")]),_vm._v(" "),_c('ul',_vm._l((_vm.$t(
          'pages.poa.financial.index.responsibilitiesList'
        )),function(item,index){return _c('li',{key:`responsibilitiesItem-${index}`},[_vm._v("\n        "+_vm._s(item)+"\n      ")])}),0),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.index.exclusions'))+"\n    ")]),_vm._v(" "),_c('Tip',[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.index.tip'))+"\n    ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_attorney","layout":"column","options":[
        {
          label: _vm.$t('pages.poa.financial.index.options.yes'),
          value: true,
        },
        {
          label: _vm.$t('pages.poa.financial.index.options.no'),
          value: false,
        },
      ]},on:{"input":_vm.onSubmit},model:{value:(_vm.poaFinancialMeta.has_attorney),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "has_attorney", $$v)},expression:"poaFinancialMeta.has_attorney"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }