<template>
  <div class="grid">
    <div class="cell cell__12/12">
      <div class="grid grid--middle">
        <div class="cell cell__fill">
          <Headline flush :level="1"> Coupons </Headline>
        </div>
        <div class="cell">
          <RuButton size="small" level="primary" @click="showCouponModal">
            + Add Coupon
          </RuButton>
        </div>
        <div class="cell cell__12/12">
          <input
            id="search"
            class="input input-compact mr-8"
            :v-model="couponSearch"
            placeholder="Search..."
            :compact="true"
            @keydown="search"
          />
        </div>
      </div>
      <AdminCard tight>
        <AdminDataTable
          v-if="coupons"
          :columns="columns"
          :loading="$apollo.loading"
          :row-count="coupons.length"
          :sortable-columns="sortableColumns"
          :current-sorting-column="currentSortingColumn"
          @sortColumnClicked="changeSortColumnAndResetPageIndex"
        >
          <template #rows>
            <AdminDataTableRow
              v-for="(coupon, index) in coupons"
              :key="index"
              :to="localePath(`/admin/coupons/${coupon.id}`)"
              class="group"
            >
              <AdminDataTableCell>
                <span
                  class="admin-id px-2 py-1 border rounded font-mono font-medium text-sm leading-none"
                >
                  {{ coupon.code.toUpperCase() }}
                </span>
              </AdminDataTableCell>
              <AdminDataTableCell>
                {{ getDiscount(coupon) }}
              </AdminDataTableCell>
              <AdminDataTableCell full>
                {{ coupon.description }}
              </AdminDataTableCell>
              <AdminDataTableCell truncate>
                {{ $formatDate(coupon.createdAt) }}
              </AdminDataTableCell>
              <AdminDataTableCell>
                <BaseSwitch
                  :value="coupon.active"
                  @change="setCouponStatus(coupon)"
                />
              </AdminDataTableCell>
            </AdminDataTableRow>
          </template>
          <template #pagination>
            <AdminDataTableButton
              :disabled="couponsPageIndex === 0"
              :loading="$apollo.loading"
              @click="prevPage"
            >
              <BaseIcon id="arrow-left" />
            </AdminDataTableButton>
            <AdminDataTableButton
              :disabled="coupons.length !== pageSize"
              :loading="$apollo.loading"
              @click="nextPage"
            >
              <BaseIcon id="arrow-right" />
            </AdminDataTableButton>
          </template>
        </AdminDataTable>
      </AdminCard>
    </div>
    <AdminCouponModal @addedCoupon="addedCoupon" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

import GET_COUPONS_QUERY from '@/graphql/queries/GetCoupons';
import AdminCard from '@/components/admin/Card';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableButton from '@/components/admin/DataTableButton';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';
import AdminCouponModal from '@/components/admin/CouponModal';
import BaseIcon from '@/components/BaseIcon';
import RuButton from '@/components/atoms/Button';
import BaseSwitch from '@/components/BaseSwitch';
import Headline from '@/components/atoms/Headline';
import { coupons, coupon } from '@/mixins/apollo';
import dialog from '@/mixins/message-box';
import sorting from '@/mixins/sorting';

export default {
  name: 'PagesAffiliateAdminCouponsIndex',
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableButton,
    AdminDataTableRow,
    AdminDataTableCell,
    AdminCouponModal,
    RuButton,
    BaseIcon,
    BaseSwitch,
    Headline,
  },
  mixins: [coupons, coupon, dialog, sorting],
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  apollo: {
    coupons: {
      query: GET_COUPONS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          limit: this.pageSize,
          offset: this.couponsPageIndex * this.pageSize,
          searchQuery: this.couponSearch,
          customOrder: this.customOrder,
        };
      },
      update: (data) => data.getCoupons && data.getCoupons.coupons,
    },
  },
  data() {
    return {
      columns: ['Code', 'Discount', 'Description', 'Created At', 'Active'],
      pageSize: 10,
      coupons: [],
      sortableFields: {
        name: {
          field: 'code',
          columnName: 'Code',
          defaultDirection: 'ASC',
        },
        createdAt: {
          field: 'createdAt',
          columnName: 'Created At',
          defaultDirection: 'DESC',
        },
        discount: {
          field: 'factor',
          columnName: 'Discount',
          defaultDirection: 'ASC',
        },
        description: {
          field: 'description',
          columnName: 'Description',
          defaultDirection: 'ASC',
        },
      },
      currentSortingColumn: ['Created At', 'DESC'],
    };
  },
  computed: {
    ...mapGetters('admin', ['couponsPageIndex', 'couponSearch']),
  },
  mounted() {
    this.setCouponSearch('');
  },
  methods: {
    ...mapActions('admin', [
      'nextCouponsPage',
      'prevCouponsPage',
      'resetCouponsPageIndex',
      'setCouponSearch',
    ]),
    ...mapActions('admin/coupon', ['setShowCouponModal']),
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextCouponsPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevCouponsPage();
      }
    },
    getDiscount(coupon) {
      return coupon.type === 'PERCENTAGE'
        ? `${coupon.factor}%`
        : `$${coupon.factor}`;
    },
    showCouponModal() {
      this.setShowCouponModal(true);
    },
    search: debounce(300, function (event) {
      this.resetCouponsPageIndex();
      this.setCouponSearch(event.target.value.trim());
    }),
    async addedCoupon() {
      await this.$apollo.queries.coupons.refetch();
    },
    setCouponStatus(coupon) {
      const title = coupon.active
        ? `Deactivate ${coupon.code.toUpperCase()}`
        : `Activate ${coupon.code.toUpperCase()}`;
      const message = coupon.active
        ? `Are you sure you want to deactivate this coupon?`
        : `Are you sure you want to activate this coupon?`;

      this.$confirm({ title, message }, (action) => {
        if (action) {
          coupon.active = !coupon.active;
          this.updateCoupon(coupon);
        }
      });
    },
    changeSortColumnAndResetPageIndex(columnName) {
      this.changeSortColumn(columnName);
      this.resetCouponsPageIndex();
    },
  },
};
</script>
