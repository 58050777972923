<template>
  <div class="ru:container ru:container--snug ru:account-settings">
    <Headline :level="1">{{ $t('pages.account.headline') }}</Headline>
    <p>{{ $t('pages.account.manageAccountDetails') }}</p>
    <Tip>
      {{ $t('pages.account.startLiveChat') }}
    </Tip>
    <Headline :level="2">
      {{ $t('forms.labels.yourEmail') }}
    </Headline>
    <Box background="white">
      <FormWrapper
        v-model="validEmail"
        @submit="setShowConfirmPasswordModal(true)"
      >
        {{ invalid }}
        <div class="grid">
          <div class="cell cell__12/12 cell__9/12--md">
            <FormControl
              id="email"
              v-model="email"
              inputmode="email"
              rules="required|email"
              :text="$t('forms.labels.email')"
            />
          </div>
          <div class="cell cell__12/12 cell__3/12--md">
            <Buttons layout="stretch" label-sibling>
              <RuButton
                level="secondary"
                :aria-label="$t('components.sendInvite.send')"
                :disabled="validEmail"
                button-type="submit"
              >
                <Icon id="check" />
                {{ $t('forms.labels.updateEmail') }}
              </RuButton>
            </Buttons>
          </div>
        </div>
      </FormWrapper>
      <Alert
        :theme="verified ? 'info' : 'warning'"
        :icon="verified ? 'check' : 'question'"
        level="medium"
      >
        {{
          verified
            ? $upperFirst($t('texts.words.verified'))
            : $upperFirst($t('texts.words.unverified'))
        }}:
        <Buttons v-if="!verified" theme="default" layout="inline">
          <RuButton level="plain" theme="warning" @click="resendWelcomeEmail">
            {{
              resendTimeout
                ? $t('texts.sentences.sent')
                : $t('texts.sentences.resendVerificationEmail')
            }}
          </RuButton>
        </Buttons>
      </Alert>

      <div v-if="resendLoading" class="ml-2">
        <LoadingRing />
      </div>
    </Box>
    <Headline :level="2"> {{ $t('pages.account.yourDetails') }} </Headline>
    <Box background="white">
      <AdiForm
        v-slot="{ loading }"
        submit-icon="check"
        :mutation="UPDATE_WILL_MUTATION"
        :variables="UPDATE_WILL_VARS"
        submit-classes="w-full max-w-sm"
        :submit-label="$t('forms.labels.saveChanges')"
        @done="updateDetails"
      >
        <div class="grid">
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="name_first"
              v-model="willMeta.name_first"
              rules="required|max:50"
              :disabled="loading"
              :text="$t('forms.labels.firstName')"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="name_middle"
              v-model="willMeta.name_middle"
              rules="max:50"
              :disabled="loading"
              :text="$t('forms.labels.middleName')"
              placeholder="None"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="name_last"
              v-model="willMeta.name_last"
              rules="required|max:50"
              :disabled="loading"
              :text="$t('forms.labels.lastName')"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="phone"
              v-model="willMeta.phone_number"
              :disabled="loading"
              :text="$t('forms.labels.phoneNumber')"
              rules="phone"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="address_street"
              v-model="willMeta.address_street"
              rules="required|max:50"
              :disabled="loading"
              :text="$t('forms.labels.streetAddress')"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="address_suburb"
              v-model="willMeta.address_suburb"
              rules="required|max:50"
              :disabled="loading"
              :text="$t('forms.labels.suburb')"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              v-if="willMeta.address_country === 'AU'"
              id="address_state"
              v-model="willMeta.address_state"
              :options="$auStates"
              :disabled="loading"
              type="select"
              rules="required"
              :text="$t('forms.labels.state')"
            />
            <FormControl
              v-else
              id="address_state"
              v-model="willMeta.address_state"
              :disabled="loading"
              :text="$t('forms.labels.region')"
              rules="max:50"
            />
          </div>
          <div class="cell cell__12/12 cell__6/12--md">
            <FormControl
              id="address_postcode"
              v-model="willMeta.address_postcode"
              rules="required|integer|max:4"
              :disabled="loading"
              :text="$t('forms.labels.postCode')"
            />
          </div>
          <div class="cell cell__12/12">
            <FormControl
              id="address_country"
              v-model="willMeta.address_country"
              type="select"
              :disabled="true"
              :text="$t('forms.labels.country')"
              rules="required"
              :options="$countriesOptions"
            />
          </div>
        </div>
      </AdiForm>
    </Box>
    <Headline :level="2">{{ $t('pages.account.changePassword') }}</Headline>
    <Box background="white">
      <ChangePassword />
    </Box>
    <Headline :level="2">{{ $t('pages.account.updateBilling') }}</Headline>
    <Box background="white">
      <SubscriptionStatusCard />
    </Box>
    <template v-if="showNotifyCharities">
      <Headline :level="2">{{
        $t('pages.account.charityContactConsent')
      }}</Headline>
      <Box background="white">
        <AdiForm
          v-slot="{ loading }"
          :auto-focus="false"
          submit-icon="check"
          :mutation="UPDATE_WILL_NOTIFY_CHARITY_MUTATION"
          :variables="UPDATE_WILL_NOTIFY_CHARITY_VARS"
          submit-classes="w-full max-w-sm"
          submit-label="Save changes"
          @done="updateWills"
        >
          <Tip>
            {{ $t('pages.account.tipShareDetails') }}
          </Tip>
          <LargeRadioButtons
            id="notifyCharities"
            v-model="willMeta.notify_charities"
            :options="[
              {
                label: $t('forms.labels.yes'),
                value: true,
              },
              {
                label: $t('forms.labels.no'),
                value: false,
              },
            ]"
          />
        </AdiForm>
      </Box>
    </template>
    <template v-if="showExecutorTermsAndConditions">
      <Headline :level="2">{{
        $t('pages.account.executorTermsAndConditions')
      }}</Headline>
      <Box background="white">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="ru:account-settings__executor-terms"
          v-html="$t('pages.will.executors.professionalTerms.termsConditions')"
        />
        <!--eslint-enable-->
      </Box>
    </template>
    <BaseModal v-if="showConfirmPasswordModal" @close="closeModal">
      <div
        class="flex justify-center items-center w-10 h-10 rounded-full mb-4 bg-teal-300 text-white"
      >
        <Icon id="lock" />
      </div>
      <p class="max-w-xs mb-4 text-3xl text-teal-300">
        {{ $t('pages.account.modalConfirmPassword') }}
      </p>
      <AdiForm
        v-slot="{ loading }"
        submit-icon="check"
        :auto-focus="false"
        :mutation="UPDATE_EMAIL_MUTATION"
        :variables="updateEmailVariables"
        :submit-label="$t('forms.labels.confirmPassword')"
        @done="updateToken"
      >
        <PasswordInput
          id="confirmPassword"
          v-model="confirmPassword"
          :disabled="loading"
          :label="$t('forms.labels.password')"
          :placeholder="$t('forms.placeholders.enterPassword')"
        />
      </AdiForm>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import RESEND_WELCOME_EMAIL from '@/graphql/mutations/ResendWelcomeEmail';
import UPDATE_EMAIL_MUTATION from '@/graphql/mutations/UpdateEmail';
import UPDATE_WILL_NOTIFY_CHARITY_MUTATION from '@/graphql/mutations/UpdateNotifyCharityOfWill';

import BaseModal from '@/components/BaseModal';
import AdiForm from '@/components/Form';
import LoadingRing from '@/components/LoadingRing';
import PasswordInput from '@/components/PasswordInput';
import SubscriptionStatusCard from '@/components/SubscriptionStatusCard';
import Tip from '@/components/molecules/Tip';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import Headline from '@/components/atoms/Headline';
import Box from '@/components/atoms/Box';
import ChangePassword from '@/components/organisms/ChangePassword';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import Alert from '@/components/molecules/Alert';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateDashboardAccountSettings',
  components: {
    BaseModal,
    AdiForm,
    LoadingRing,
    PasswordInput,
    SubscriptionStatusCard,
    Tip,
    LargeRadioButtons,
    Headline,
    Box,
    ChangePassword,
    Buttons,
    RuButton,
    Icon,
    FormWrapper,
    FormControl,
    Alert,
  },
  mixins: [user, will],
  data() {
    return {
      UPDATE_WILL_NOTIFY_CHARITY_MUTATION,
      UPDATE_EMAIL_MUTATION,
      confirmPassword: '',
      email: this.$store.state.email,
      resendEmailText: 'Resend verification email',
      resendLoading: false,
      resendTimeout: null,
      validEmail: false,
      validPassword: false,
      welcomeEmailSent: false,
      hasChangedPassword: false,
    };
  },
  computed: {
    ...mapGetters(['verified']),
    ...mapGetters('ui', ['showConfirmPasswordModal']),
    showNotifyCharities() {
      return this.willMeta.notify_charities !== null;
    },
    showExecutorTermsAndConditions() {
      return (
        ['professional', 'friendsFamilyAndProfessional'].includes(
          this.willMeta.executors_option
        ) &&
        this.willMeta.professional_executor_payment_check &&
        this.willMeta.professional_executor_court_check &&
        this.willMeta.professional_executor_terms_check
      );
    },
    updateEmailVariables() {
      return {
        currentPassword: this.confirmPassword,
        newEmail: this.email,
      };
    },
    UPDATE_WILL_NOTIFY_CHARITY_VARS() {
      return {
        userId: this.userId,
        value: this.willMeta.notify_charities,
      };
    },
  },
  watch: {
    'willMeta.address_state': {
      handler(newValue) {
        if (newValue === '') {
          this.willMeta.address_state = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['setEmail', 'setToken']),
    ...mapActions('ui', ['setShowConfirmPasswordModal']),
    closeModal() {
      this.setShowConfirmPasswordModal(false);
      this.confirmPassword = '';
      this.$nuxt.$emit('unlockForm');
    },
    async resendWelcomeEmail() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✉️ Resend Verification',
      });

      this.resendLoading = true;

      await this.$apollo
        .mutate({
          mutation: RESEND_WELCOME_EMAIL,
          variables: {
            email: this.email,
          },
        })
        .then(({ data }) => {
          this.resendLoading = false;

          if (data.resendWelcomeEmail?.success) {
            this.welcomeEmailSent = true;

            this.resendTimeout = window.setTimeout(() => {
              this.resendTimeout = null;
            }, 3000);
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    updateWills() {
      this.refetchWill();
      this.$nuxt.$emit('unlockForm');
      this.$nuxt.$emit('clearErrors');
    },
    updateDetails() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        name: `${this.willMeta.name_first} ${this.willMeta.name_last}`,
      });

      this.$ff.refreshFeatureFlags();

      this.$nuxt.$emit('unlockForm');
    },
    async updateToken({
      data: {
        updateEmail: { token, user },
      },
    }) {
      await this.$apolloHelpers
        .onLogin(token)
        .then(() => {
          this.setEmail(user.email);
          this.setToken(token);
          this.$ff.refreshFeatureFlags();
        })
        .catch((e) => {
          console.error(e.message);
        });

      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &account-settings {
    &__executor-terms {
      height: 21rem;
      overflow-y: scroll;
      padding-right: var(--base-margin);
    }
  }
}
</style>
