<template>
  <ModuleStep :index="6" prev-step="/will/guardians/pet-guardians">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      :submit-label="submitLabel"
      @done="onDone"
    >
      <Headline :level="1">
        {{ $t('pages.will.guardians.leavePetCareFund') }}
      </Headline>
      <Tip>
        {{ $t('pages.will.guardians.petCareFundExplanation') }}
      </Tip>
      <FormControl
        id="pet_care_fund"
        v-model="willMeta.pet_care_fund"
        :disabled="loading"
        rules="numeric|max_value:9999999999|max:10"
        placeholder="$"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import FormControl from '@/components/molecules/FormControl';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillGuardiansPetCareFund',
  components: {
    FormControl,
    AdiForm,
    ModuleStep,
    Tip,
    Headline,
  },
  mixins: [user, will],
  computed: {
    submitLabel() {
      return this.willMeta.pet_care_fund
        ? this.$t('forms.labels.next')
        : this.$t('forms.labels.skip');
    },
    isComplete() {
      return (
        !isNaN(this.willMeta.pet_care_fund) || !this.willMeta.pet_care_fund
      );
    },
  },
  methods: {
    onDone() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module 2 (Families)',
      });
      this.$router.push({
        path: this.localePath('/will'),
      });
    },
  },
};
</script>
