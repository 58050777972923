<template>
  <AdminCard tight>
    <AdminDataTable
      :columns="columns"
      :loading="$apollo.loading"
      :row-count="cremationRequestsStatusReportData.length"
    >
      <template #rows>
        <AdminDataTableRow
          v-for="(cremationRequest, index) in cremationRequestsStatusReportData"
          :key="index"
          class="group"
        >
          <AdminDataTableCell>
            {{ toTitleCase(cremationRequest.status) }}
          </AdminDataTableCell>
          <AdminDataTableCell>
            {{ cremationRequest.count }}
          </AdminDataTableCell>
        </AdminDataTableRow>
      </template>
    </AdminDataTable>
  </AdminCard>
</template>

<script>
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import GET_CREMATIONS_REQUESTS_STATUS_REPORT_QUERY from '@/graphql/queries/GetCremationRequestsStatusReportData';

import AdminCard from '@/components/admin/Card';
import AdminDataTable from '@/components/admin/DataTable';
import AdminDataTableRow from '@/components/admin/DataTableRow';
import AdminDataTableCell from '@/components/admin/DataTableCell';

import { productToDisplayNameMapping } from '@/utilities';

export default {
  name: 'ComponentsCremationRequestsStatusReportTable',
  components: {
    AdminCard,
    AdminDataTable,
    AdminDataTableRow,
    AdminDataTableCell,
  },
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  props: {
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    cremationRequestsStatusReportData: {
      query: GET_CREMATIONS_REQUESTS_STATUS_REPORT_QUERY,
      variables() {
        return {
          startDate: this.dateFrom,
          endDate: this.dateTo,
        };
      },
      update: ({ getCremationRequestsStatusReportData }) =>
        getCremationRequestsStatusReportData,
    },
  },
  data() {
    return {
      columns: ['Status', 'Count'],
      pageSize: 10,
      cremationRequestsStatusReportData: [],
      productToDisplayNameMapping,
    };
  },
  methods: {
    toTitleCase(str) {
      return startCase(toLower(str));
    },
  },
};
</script>
