<template>
  <div class="ru:container">
    <div class="relative z-10 mb-8 md:mb-16">
      <div
        class="flex flex-col md:flex-row justify-between items-center md:items-top"
      >
        <Headline big :level="1">
          <small>{{ fullName }}</small>
          <template v-if="hasCompletedAllModules">
            {{ $t('texts.sentences.yourPOA') }}
            <PoaStatus
              product="POA"
              :status="!!poaStatus ? poaStatus : ''"
              :ready="hasCompletedAllModules"
            />.
          </template>
          <template v-else>
            <i18n path="texts.templates.appointYour">
              <DecoratedText>{{ $t('titles.powersOfAttorney') }}</DecoratedText>
            </i18n>
          </template>
        </Headline>

        <div
          class="fixed md:static left-0 right-0 bottom-0 flex-shrink-0 py-2 pl-5 zendesk-offset md:p-0 bg-white md:bg-transparent shadow-md md:shadow-none"
        >
          <div class="w-full">
            <Alert v-if="errorMessage" level="medium" theme="warning">
              {{ errorMessage }}
            </Alert>
            <PoaDownloadComboButton
              v-else
              :complete="hasCompletedAllModules"
              :approved="poaApproved"
              :loading="submittingPoa || !poaStatus"
              :show-financial="showFinancialDownloadBtn"
              :show-medical="showMedicalDownloadBtn"
              :show-update-poa-button="showUpdatePoaButton"
              :disable-download-button="disableDownloadButton || submittingPoa"
              :display-submit-button="displaySubmitButton"
              :user-id="userId"
              @click="click()"
              @unlockPoa="unlockPoa()"
            />
          </div>
        </div>
      </div>
    </div>
    <Slider :slide="currentSlide">
      <SliderItem
        v-for="(module, moduleIndex) in poaModules"
        :key="moduleIndex"
      >
        <GenericModuleCard
          :title="module.displayName || module.name"
          :description="module.description"
          :duration="module.duration"
          :number="moduleIndex + 1"
          :loading="$apollo.queries[module.name].loading"
          :progress="progress[module.name]"
          :ready="true"
          :to="to[module.name]"
          :editable="valuesEditable"
          :status="poaStatus"
        >
        </GenericModuleCard>
      </SliderItem>
    </Slider>
  </div>
</template>

<script>
import kebabCase from 'lodash/kebabCase';
import { mapGetters } from 'vuex';
import { queries } from '@/modules/apollo-queries/poa-meta';

import GenericModuleCard from '@/components/GenericModuleCard';
import Slider from '@/components/Slider';
import SliderItem from '@/components/SliderItem';
import DecoratedText from '@/components/atoms/DecoratedText';
import PoaStatus from '@/components/organisms/PoaStatus';
import PoaDownloadComboButton from '@/components/PoaDownloadComboButton';
import Alert from '@/components/molecules/Alert';
import Headline from '@/components/atoms/Headline';

import { poa, people, user, will, error } from '@/mixins/apollo';
import poaProgress from '@/mixins/poa-progress';
import GET_WILL_QUERY from '@/graphql/queries/GetWill';
import SUBMIT_POA from '@/graphql/queries/SubmitPoa';
import GET_POA_STATUS from '@/graphql/queries/GetPoaStatus';
import VERSION_POA from '@/graphql/queries/VersionPoa';

export default {
  name: 'PagesAffiliatePoaIndex',
  components: {
    GenericModuleCard,
    Slider,
    SliderItem,
    DecoratedText,
    PoaStatus,
    PoaDownloadComboButton,
    Alert,
    Headline,
  },
  mixins: [poa, people, user, will, poaProgress],
  apollo: {
    'About Yourself': {
      query: GET_WILL_QUERY,
      variables() {
        return {
          id: this.willId,
        };
      },
    },
    Financial: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    Medical: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    poaStatus: {
      query: GET_POA_STATUS,
      fetchPolicy: 'network-only',
      update: (data) => {
        return data.poa !== null ? data.poa.status : null;
      },
      variables() {
        return { id: this.poaId };
      },
      result({ data }) {
        if (data?.poa?.status === 'AWAITING_APPROVAL') {
          if (!this.poaStatusPollTimer) {
            this.$apollo.queries.poaStatus.startPolling(2000);
            this.poaStatusPollTimer = setTimeout(() => {
              this.$apollo.queries.poaStatus.stopPolling();
              this.displayErrorMessage();
              this.$nuxt.$emit('sendTrackingEvent', {
                event: '❌ POA PDF generation timeout',
                props: {
                  error:
                    'Waiting for POA PDF generation timeout after 30 seconds',
                },
              });
            }, 30000);
          }
        } else {
          clearTimeout(this.poaStatusPollTimer);
          this.poaStatusPollTimer = null;
          this.$apollo.queries.poaStatus.stopPolling();
        }
      },
      error,
    },
  },
  data() {
    return {
      poaStatus: null,
      submittingPoa: false,
      Financial: {
        has_attorney: undefined,
        has_limitations: undefined,
        limitations: undefined,
      },
      Medical: {
        has_attorney: undefined,
        has_preferences: undefined,
        preferences: undefined,
      },
      errorMessage: null,
      poaStatusPollTimer: null,
    };
  },
  computed: {
    ...mapGetters('modules', ['poaModules']),
    ...mapGetters(['userId']),
    ...mapGetters('poa', ['poaId']),
    hasCompletedAllModules() {
      return (
        this.hasCompletedAboutYourself &&
        this.hasCompletedFinancial &&
        this.hasCompletedMedical
      );
    },
    poaApproved() {
      return ['AWAITING_APPROVAL', 'APPROVED'].includes(this.poaStatus);
    },
    currentSlide() {
      return this.hasCompletedFinancial ? 1 : 0;
    },
    to() {
      const to = {};
      this.poaModules.forEach((module) => {
        let uri = `/poa/${kebabCase(module.name)}`;
        const step = module.steps[0];
        if (step?.length) {
          uri += `/${step}`;
        }
        to[module.name] = this.localePath(uri);
      });
      return to;
    },
    fullName() {
      let name = '';
      if ('name_first' in this.willMeta) {
        name = this.willMeta.name_first.trim();
        if (this.willMeta.name_middle) {
          name = `${name} ${this.willMeta.name_middle.trim()}`;
        }
        name = `${name} ${this.willMeta.name_last.trim()}`;
      }
      return name;
    },
    valuesEditable() {
      return this.poaStatus === 'IN_PROGRESS';
    },
    showUpdatePoaButton() {
      return (
        this.poaStatus === 'APPROVED' || this.poaStatus === 'AWAITING_APPROVAL'
      );
    },
    disableDownloadButton() {
      return this.poaStatus !== 'APPROVED';
    },
    showMedicalDownloadBtn() {
      return this.Medical.has_attorney === true;
    },
    showFinancialDownloadBtn() {
      return this.Financial.has_attorney === true;
    },
    displaySubmitButton() {
      return (
        this.Medical.has_attorney === true ||
        this.Financial.has_attorney === true
      );
    },
  },
  methods: {
    displayErrorMessage() {
      this.errorMessage = this.$t('texts.sentences.somethingWentWrong');
    },
    click() {
      this.submitPoa();
    },
    async submitPoa() {
      this.submittingPoa = true;
      try {
        await this.$apollo.mutate({
          mutation: SUBMIT_POA,
          variables: {
            id: this.poaId,
          },
          error,
        });
      } catch (error) {
        this.displayErrorMessage();
      }
      this.submittingPoa = false;
    },
    async unlockPoa() {
      this.submittingPoa = true;
      await this.$apollo.mutate({
        mutation: VERSION_POA,
        variables: {
          id: this.poaId,
        },
        error,
      });
      this.submittingPoa = false;
    },
  },
};
</script>
