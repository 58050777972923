<template>
  <BaseModal v-if="showSubscriptionExpiredModal" @close="close">
    <div
      class="flex justify-center items-center w-10 h-10 rounded-full mb-4 bg-red-300 text-white"
    >
      <BaseGlyph id="exclamation" />
    </div>
    <h4 class="max-w-xs mb-4 text-3xl text-red-300">
      {{ $t('components.subscriptionExpiredModal.title') }}
    </h4>
    <div class="mb-4 text-xl">
      <p>
        {{ $t('components.subscriptionExpiredModal.messsage') }}
      </p>
    </div>
    <AdiForm submit-label="Unlock Account" submit-wide @submit="submit" />
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { subscription } from '@/mixins/apollo';

import BaseGlyph from '@/components/BaseGlyph';
import BaseModal from '@/components/BaseModal';
import AdiForm from '@/components/Form';

export default {
  name: 'ComponentsSubscriptionExpiredModal',
  components: {
    BaseGlyph,
    BaseModal,
    AdiForm,
  },
  mixins: [subscription],
  computed: {
    ...mapGetters('ui', ['showSubscriptionExpiredModal']),
    variables() {
      return {
        id: this.willId,
      };
    },
  },
  methods: {
    ...mapActions('ui', ['setShowSubscriptionExpiredModal']),
    close() {
      this.setShowSubscriptionExpiredModal(false);
    },
    submit() {
      this.close();
      this.$router.push({ path: this.localePath(`/checkout/unlock`) });
    },
  },
};
</script>
