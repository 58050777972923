<template>
  <div class="ru:onboarding">
    <p>{{ currentStep }} of {{ totalSteps }}</p>
    <ProgressBar class="ru:onboarding__progress-bar" :progress="progress" />
    <transition name="ru:wiggle-horizontal-left">
      <div v-if="currentStep === 1" :key="currentStep" class="grid">
        <div class="cell cell__12/12 cell__6/12--md">
          <div class="ru:onboarding__image">
            <RuImage :src="require('@/assets/svg/onboarding__step-1.svg')" />
          </div>
        </div>
        <div class="cell cell__12/12 cell__6/12--md">
          <div class="ru:onboarding__form">
            <Headline :level="2" big>{{
              $t('pages.onboarding.newUser.page1.header')
            }}</Headline>
            <p>
              {{ $t('pages.onboarding.newUser.page1.description') }}
            </p>
            <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
              <FormControl
                id="first_name"
                v-model="form.firstName"
                data-test="first-name-input"
                :text="$t('forms.labels.firstName')"
                :placeholder="$t('forms.placeholders.yourFirstName')"
                name="first_name"
                type="text"
                rules="required|max:50"
                full
              />
              <FormControl
                id="middle_name"
                v-model="form.middleName"
                data-test="middle-name-input"
                :text="$t('forms.labels.middleName')"
                :placeholder="$t('forms.placeholders.yourMiddleName')"
                name="middle_name"
                type="text"
                rules="max:50"
                :required="false"
              />
              <FormControl
                id="last_name"
                v-model="form.lastName"
                data-test="last-name-input"
                :text="$t('forms.labels.lastName')"
                :placeholder="$t('forms.placeholders.yourLastName')"
                name="last_name"
                type="text"
                rules="required|max:50"
              />
              <Buttons layout="stretch" class="ru:onboarding__button">
                <RuButton
                  id="onboarding-next__1"
                  level="primary"
                  :disabled="!form.firstName || !form.lastName"
                  @click="nextStep"
                >
                  {{ $t('forms.buttons.next') }}
                  <Icon id="arrow-right" />
                </RuButton>
              </Buttons>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div v-if="currentStep === 2" :key="currentStep" class="grid">
        <div class="cell cell__12/12 cell__6/12--md">
          <div class="ru:onboarding__image">
            <RuImage :src="require('@/assets/svg/onboarding__step-2.svg')" />
          </div>
        </div>
        <div class="cell cell__12/12 cell__6/12--md">
          <div class="ru:onboarding__form">
            <Headline :level="2" big>
              {{ $t('pages.onboarding.newUser.page2.header') }}
            </Headline>
            <p>{{ $t('pages.onboarding.newUser.page2.description') }}</p>
            <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
              <FormControl
                id="date_of_birth"
                v-model="form.dateOfBirth"
                text="Date of Birth"
                rules="required|over18|after1900"
                type="date"
              />
              <FormControl
                v-if="features.affiliateUserIdEnabled"
                id="union_number"
                v-model="form.affiliateUserId"
                :text="features.affiliateUserIdFieldName"
                :placeholder="`Your ${features.affiliateUserIdFieldName}`"
                name="union_number"
                type="text"
                rules="required|max:50"
              />
              <FormControl
                v-if="features.couponCodeEnabled"
                id="coupon_code"
                v-model="form.couponCode"
                :text="features.couponCodeFieldName"
                :placeholder="`Your ${features.couponCodeFieldName}`"
                name="coupon_code"
                type="text"
                rules="required|max:50"
              />
              <Buttons layout="stretch" class="ru:onboarding__button">
                <RuButton
                  id="onboarding-next__2"
                  level="primary"
                  :disabled="
                    !form.dateOfBirth ||
                    (features.affiliateUserIdEnabled &&
                      !form.affiliateUserId) ||
                    (features.couponCodeEnabled && !form.couponCode)
                  "
                  @click="nextStep"
                >
                  {{
                    totalSteps === 2
                      ? $t('forms.buttons.finish')
                      : $t('forms.buttons.next')
                  }}
                  <Icon id="arrow-right" />
                </RuButton>
              </Buttons>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div v-if="currentStep === 3" :key="currentStep" class="grid">
        <div class="cell cell__12/12 cell__6/12--md">
          <div class="ru:onboarding__image">
            <RuImage :src="require('@/assets/svg/onboarding__step-3.svg')" />
          </div>
        </div>
        <div class="cell cell__12/12 cell__6/12--md">
          <div class="ru:onboarding__form">
            <Headline :level="2" big>
              {{ $t('pages.onboarding.newUser.page3.header') }}
            </Headline>
            <p>
              {{ $t('pages.onboarding.newUser.page3.description') }}
            </p>

            <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
              <LargeRadioButtons
                id="countries"
                v-model="form.country"
                :options="countries"
                layout="column"
                text-alignment="left"
              />
              <Buttons layout="stretch" class="ru:onboarding__button">
                <RuButton
                  id="onboarding-next__3"
                  level="primary"
                  :disabled="!form.country"
                  @click="nextStep"
                >
                  {{ $t('forms.buttons.finish') }}
                  <Icon id="arrow-right" />
                </RuButton>
              </Buttons>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import ProgressBar from '@/components/atoms/ProgressBar';
import RuImage from '@/components/atoms/Image';
import Headline from '@/components/atoms/Headline';
import Buttons from '~/components/atoms/Buttons';
import RuButton from '~/components/atoms/Button';
import Icon from '~/components/atoms/Icon';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import FormControl from '@/components/molecules/FormControl';

import UPDATE_WILL_MUTATION from '@/graphql/mutations/UpdateWill';
import UPDATE_USER from '@/graphql/mutations/UpdateUser';
import { convertIsoDateToLegacyDate, objectToMetaArray } from '@/utilities';

export default {
  name: 'PagesAffiliateOnboardingIndex',
  components: {
    ProgressBar,
    RuImage,
    Headline,
    Buttons,
    RuButton,
    Icon,
    LargeRadioButtons,
    ValidationObserver,
    FormControl,
  },
  layout: 'onboarding',
  data() {
    return {
      currentStep: 1,
      form: {
        firstName: null,
        middleName: null,
        lastName: null,
        dateOfBirth: null,
        country: null,
        affiliateUserId: null,
        couponCode: null,
      },
      countries: [
        {
          label: 'Australia',
          value: 'AU',
        },
        {
          label: 'New Zealand',
          value: 'NZ',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['willId', 'userId', 'affiliateUserId']),
    ...mapGetters('affiliate', ['features', 'slug']),
    ...mapGetters('directory-person', ['userDirectoryPerson']),
    ...mapGetters('coupon', ['couponLatestReferral']),
    progress() {
      return (this.currentStep / this.totalSteps) * 100;
    },
    totalSteps() {
      return this.$ff.showOnboardingCountryQuestion() ? 3 : 2;
    },
  },
  watch: {
    userDirectoryPerson(person) {
      this.form.firstName = person.firstName;
      this.form.middleName = person.middleName;
      this.form.lastName = person.lastName;
      this.form.dateOfBirth = person.dateOfBirth;
      this.form.country = person.residentialAddress.country;
    },
  },
  mounted() {
    this.form.firstName = this.userDirectoryPerson.firstName;
    this.form.middleName = this.userDirectoryPerson.middleName;
    this.form.lastName = this.userDirectoryPerson.lastName;
    this.form.dateOfBirth = this.userDirectoryPerson.dateOfBirth;
    this.form.country = this.userDirectoryPerson.residentialAddress.country;
    this.form.affiliateUserId = this.affiliateUserId;
    this.form.couponCode = this.couponLatestReferral;
  },
  methods: {
    ...mapActions(['setAffiliateUserId']),
    ...mapActions('directory-person', ['updateUserDirectoryPerson']),
    ...mapActions('coupon', ['getCouponLatestReferral']),
    async nextStep() {
      if (!(await this.isFormValid())) {
        return;
      }

      await Promise.all([
        this.$apollo.mutate({
          mutation: UPDATE_WILL_MUTATION,
          variables: {
            id: this.willId,
            meta: objectToMetaArray({
              name_first: this.form.firstName,
              name_middle: this.form.middleName,
              name_last: this.form.lastName,
              date_of_birth: convertIsoDateToLegacyDate(this.form.dateOfBirth),
              address_country: this.form.country,
            }),
          },
        }),
        (this.form.affiliateUserId || this.form.couponCode) &&
          this.$apollo.mutate({
            mutation: UPDATE_USER,
            variables: {
              userId: this.userId,
              affiliateUserId: this.form.affiliateUserId || undefined,
              couponCode: this.form.couponCode || undefined,
            },
          }),
        this.updateUserDirectoryPerson({
          firstName: this.form.firstName,
          middleName: this.form.middleName,
          lastName: this.form.lastName,
          dateOfBirth: this.form.dateOfBirth,
          country: this.form.country,
        }),
      ]);

      if (this.form.affiliateUserId) {
        this.setAffiliateUserId(this.form.affiliateUserId);
      }

      if (this.form.couponCode) {
        await this.getCouponLatestReferral();
      }

      if (this.currentStep === this.totalSteps) {
        if (this.form.country === 'NZ') {
          this.$router.push({
            path: `/nz/${this.slug}`,
          });
        } else {
          this.$router.push({
            path: `/`,
          });
        }
        return;
      }
      this.currentStep += 1;
    },
    async isFormValid() {
      const observer = this.$refs.observer;
      return observer && (await observer.validate());
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &onboarding {
    --progress-bar-width: calc(100% - 2 * var(--base-margin));
    --image-margin: 1.25rem;
    --form-margin: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq('min-md') {
      --progress-bar-width: calc(100% * 560 / 1440);
      --image-margin: 5.3125rem;
      --form-margin: 5.3125rem 5.3125rem 0 0;
    }

    &__progress-bar {
      width: var(--progress-bar-width);
      margin-top: var(--base-margin);
    }

    &__image {
      margin: var(--image-margin);
    }

    &__form {
      margin: var(--form-margin);
    }

    &__button {
      margin-top: var(--base-margin-big);
    }

    &__tip {
      display: flex;
      flex-direction: row;

      svg {
        margin-right: var(--base-margin-small);
      }
    }
  }
}
</style>
