<template>
  <ModuleStep :index="1" :prev-step="previous">
    <Alert v-if="errorMessage" level="medium" theme="warning">
      {{ errorMessage }}
    </Alert>
    <AdiForm
      v-slot="{ loading }"
      :disabled="!isComplete"
      @submit.prevent="onSubmit"
    >
      <Headline :level="1">
        {{ $t('pages.poa.questions.financialDecisionMakerPeople') }}
      </Headline>
      <p class="mb-4">
        {{
          $t('pages.poa.attorneysAllowedSelectMessage', {
            attorneysAllowed: attorneysAllowed[userState],
          })
        }}
      </p>
      <PersonSelector
        v-model="selectedPrimaryAttorneyPersonIDs"
        :loading="loading"
        :people="filteredPeople"
        :max="attorneysAllowed[userState]"
        :will-beneficiary-selector="false"
        :identifier-requirements="identifierRequirements"
        type="attorney"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import * as poaAttorneys from '@/modules/apollo-queries/poa-attorneys';
import * as peopleQueries from '@/modules/apollo-queries/people';
import { nextStep, prevStep } from '@/modules/poa-flow/financial';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Alert from '@/components/molecules/Alert';
import Headline from '@/components/atoms/Headline';

import SET_ATTORNEYS from '@/graphql/mutations/SetAttorneys';

import { will } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

export default {
  name: 'PagesAffiliatePoaFinancialPrimaryDecisionMakers',
  components: {
    AdiForm,
    ModuleStep,
    PersonSelector,
    Alert,
    Headline,
  },
  mixins: [will],
  apollo: {
    people: {
      ...peopleQueries.queries.getPeopleOfAccount(),
      variables() {
        return {
          userId: this.userId,
        };
      },
    },
    attorneys: {
      ...poaAttorneys.queries.getAttorneys({
        power: 'FINANCIAL',
      }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      selectedPrimaryAttorneyPersonIDs: [],
      attorneysAllowed: {
        act: 4,
        nsw: 4,
        nt: 4,
        qld: 3,
        sa: 4,
        tas: 4,
        vic: 4,
        wa: 2,
      },
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('poa', ['poaId']),

    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        numPrimaryAttorneysSelected:
          this.selectedPrimaryAttorneyPersonIDs.length,
        currentUrl: window.location.pathname,
        addressState: this.userState,
      });
    },
    isComplete() {
      return (
        this.selectedPrimaryAttorneyPersonIDs &&
        this.selectedPrimaryAttorneyPersonIDs.length > 0
      );
    },
    filteredPeople() {
      return this.people?.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        if (this.secondaryAttorneyPeopleIDs === undefined) return false;
        const personIsAlreadyPrimaryFinancialAttorney =
          this.selectedPrimaryAttorneyPersonIDs.includes(person.id);
        return (
          personIsAlreadyPrimaryFinancialAttorney ||
          (meta.is_over_18 &&
            meta.identifier === 'address' &&
            !this.secondaryAttorneyPeopleIDs?.includes(person.id))
        );
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    identifierRequirements() {
      return ['address', ...(this.userState === 'vic' ? ['dob'] : [])];
    },
    secondaryAttorneyPeopleIDs() {
      return this.attorneys
        ?.filter((attorney) => attorney.role === 'SECONDARY')
        ?.map((attorney) => attorney.person.id);
    },
    oldPrimaryAttorneys() {
      return this.attorneys?.filter((attorney) => attorney.role === 'PRIMARY');
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length && !this.selectedPrimaryAttorneyPersonIDs.length) {
        this.selectedPrimaryAttorneyPersonIDs = attorneys
          .filter((attorney) => attorney.role === 'PRIMARY')
          .map((attorney) => attorney.person.id);
      }
    },
  },
  methods: {
    async onSubmit() {
      this.errorMessage = '';
      try {
        await this.$apollo.mutate({
          mutation: SET_ATTORNEYS,
          variables: {
            personIds: this.selectedPrimaryAttorneyPersonIDs,
            power: 'FINANCIAL',
            role: 'PRIMARY',
            poaId: this.poaId,
          },
        });
        await this.$apollo.queries.attorneys.refetch();
      } catch (error) {
        await this.$apollo.queries.people.refetch();
        await this.$apollo.queries.attorneys.refetch();
        this.selectedPrimaryAttorneyPersonIDs = [];
        this.$nuxt.$emit('unlockForm');
        this.errorMessage = this.$t('texts.sentences.duplicateAttorneyError');
        return;
      }

      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
</script>
