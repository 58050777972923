<template>
  <div />
</template>

<script>
export default {
  name: 'PagesAffiliateLogOut',
  layout: 'minimal',
  async beforeMount() {
    await this.$auth.logout();
  },
};
</script>
