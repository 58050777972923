<template>
  <div class="ru:send-notification">
    <template v-if="!executorNotifications">
      <p class="flex items-center flush">
        <LoadingRing :size="24" class="mr-4" />
        {{ $t('components.sendNotification.loading') }}
      </p>
    </template>
    <template v-for="(executor, index) in executorNotifications">
      <FormWrapper
        :key="`executorNotifications${index}`"
        @submit="sendExecutorNotification(index)"
      >
        <div class="grid grid--top">
          <div class="cell cell__12/12 cell__8/12--sm">
            <FormControl
              id="couponInput"
              v-model="executorNotifications[index].email"
              type="email"
              :text="`${executor.name}'s contact details`"
              placeholder="name@example.com"
              rules="required|email"
            />
          </div>
          <div class="cell cell__12/12 cell__4/12--sm">
            <Buttons layout="stretch" label-sibling>
              <RuButton
                v-if="executorNotifications[index].loading"
                level="secondary"
                disabled
              >
                <LoadingRing :size="24" />
                {{ $t('components.sendNotification.sending') }}
              </RuButton>
              <RuButton
                v-else-if="executor.notified"
                level="secondary"
                disabled
              >
                <Icon id="check" />
                {{ $t('components.sendNotification.sent') }}
              </RuButton>
              <RuButton v-else type="submit" level="secondary">
                <Icon id="email" />
                {{ $t('components.sendNotification.send') }}
              </RuButton>
            </Buttons>
          </div>
        </div>
      </FormWrapper>
    </template>
    <Snackbar
      v-if="snackbarIsVisible"
      :type="snackbarType"
      :text="snackbarText"
      @closeSnackbar="closeSnackbar"
    />
  </div>
</template>

<script>
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';
import LoadingRing from '@/components/LoadingRing';
import Icon from '@/components/atoms/Icon';
import Snackbar from '@/components/molecules/Snackbar';

import { user, executors } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';

import NOTIFY_EXECUTOR_MUTATION from '@/graphql/mutations/NotifyExecutor';

export default {
  name: 'ComponentsOrganismsSendNotification',
  components: {
    Buttons,
    RuButton,
    Icon,
    FormWrapper,
    FormControl,
    LoadingRing,
    Snackbar,
  },
  mixins: [user, executors],
  data() {
    return {
      executorNotifications: [],
      snackbarIsVisible: false,
      snackbarType: null,
      snackbarText: null,
    };
  },
  watch: {
    executors() {
      this.executorNotifications = this.getExecutorNotifications();
    },
  },
  methods: {
    getExecutorNotifications() {
      return this.executors.map((executor) => {
        return {
          id: executor.id,
          notified: metaArrayToObject(executor.meta).notified,
          email: metaArrayToObject(executor.person.meta).email,
          name: metaArrayToObject(executor.person.meta).full_name,
          loading: false,
        };
      });
    },
    async sendExecutorNotification(index) {
      const executor = { ...this.executorNotifications[index] };
      this.executorNotifications[index].loading = true;
      try {
        await this.$apollo.mutate({
          mutation: NOTIFY_EXECUTOR_MUTATION,
          variables: {
            id: executor.id,
            email: executor.email,
            willId: this.willId,
          },
          refetchQueries: [this.getExecutorsQuery],
        });

        this.$nuxt.$emit('sendTrackingEvent', {
          event: '🔔 Notified Executor',
          props: { executor_email: executor.email },
        });
        this.snackbarText = this.$t(
          'components.sendNotification.executorNotified'
        );
        this.openSnackbar('success');
      } catch (error) {
        console.error(error);
      } finally {
        this.executorNotifications[index].loading = false;
      }
    },
    openSnackbar(type) {
      this.snackbarType = type;
      this.snackbarIsVisible = true;
    },
    closeSnackbar() {
      this.snackbarIsVisible = false;
    },
  },
};
</script>
