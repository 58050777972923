<template>
  <Panel v-if="showPersonPanel" :title="panelTitle" @close="close">
    <AdiForm
      v-slot="{ loading }"
      :mutation="mutation"
      :variables="variables"
      :submit-label="buttonText"
      :disabled="mustBeOver18 ? !is_over_18 : false"
      @update="update"
      @done="close"
    >
      <div class="grid">
        <div key="full_name" class="cell cell__12/12">
          <FormControl
            id="full_name"
            v-model="full_name"
            :disabled="loading"
            :text="$t('components.personPanel.fullNameInput.label')"
            rules="required|max:152"
          />
        </div>
        <template v-if="!hasIdentifierRequirements">
          <div key="identifier" class="cell cell__12/12">
            <LargeRadioButtons
              id="identifier"
              v-model="identifier"
              :label="$t('components.personPanel.identifyingInformation')"
              horizontal
              :options="[
                {
                  label: $t('components.personPanel.identifierOptions.email'),
                  value: 'email',
                },
                {
                  label: $t(
                    'components.personPanel.identifierOptions.dateOfBirth'
                  ),
                  value: 'dob',
                },
                {
                  label: $t('components.personPanel.identifierOptions.address'),
                  value: 'address',
                },
              ]"
            >
              <template #label>
                <Tooltip
                  inverted
                  :tooltip="$t('components.personPanel.identifyRequired')"
                >
                  <Badge color="azure" background="white" size="small">
                    <Icon id="question" />
                  </Badge>
                </Tooltip>
              </template>
            </LargeRadioButtons>
          </div>
        </template>
        <template
          v-if="
            identifier === 'dob' || availableIdentifierOptions.includes('dob')
          "
        >
          <div key="date_of_birth" class="cell cell__12/12">
            <FormControl
              id="date_of_birth"
              v-model="date_of_birth"
              :text="$t('components.personPanel.dobInput.label')"
              :rules="dobRules"
              type="date"
              format="legacy"
            />
          </div>
        </template>
        <template v-if="identifier === 'address'">
          <div key="address_street" class="cell cell__12/12">
            <FormControl
              id="address_street"
              v-model="address_street"
              :disabled="loading"
              :text="$t('components.personPanel.address.street.label')"
              rules="required|max:50"
            >
              <template #label>
                <Buttons v-if="showAddressHelper" layout="inline">
                  <RuButton theme="plain" @click="prefillAddressFields">
                    {{ $t('components.personPanel.useMyAddress') }}
                  </RuButton>
                </Buttons>
              </template>
            </FormControl>
          </div>
          <div key="address_suburb" class="cell cell__12/12">
            <FormControl
              id="address_suburb"
              v-model="address_suburb"
              :disabled="loading"
              :text="$t('components.personPanel.address.suburb.label')"
              rules="required|max:50"
            />
          </div>
          <template v-if="address_country && address_country !== 'Australia'">
            <div key="address_region" class="cell cell__12/12">
              <FormControl
                id="address_region"
                v-model="address_state"
                :disabled="loading"
                :text="$t('components.personPanel.address.region.label')"
                rules="max:50"
              />
            </div>
          </template>
          <template v-else>
            <div key="address_state" class="cell cell__12/12">
              <FormControl
                id="address_state"
                v-model="address_state"
                :disabled="loading"
                :text="$t('components.personPanel.address.state.label')"
                :options="$auStatesOptions"
                type="select"
                rules="required"
              />
            </div>
          </template>
          <div key="address_postcode" class="cell cell__12/12">
            <FormControl
              id="address_postcode"
              v-model="address_postcode"
              :disabled="loading"
              :text="$t('components.personPanel.address.postcode.label')"
              :rules="postcodeRules"
            />
          </div>
          <div key="address_country" class="cell cell__12/12">
            <FormControl
              id="address_country"
              v-model="address_country"
              :disabled="loading"
              :text="$t('components.personPanel.address.country.label')"
              type="select"
              rules="required"
              :options="$countries"
            >
              <template #label>
                <Tooltip inverted :tooltip="countryTooltip">
                  <Badge color="azure" background="white" size="small">
                    <Icon id="question" />
                  </Badge>
                </Tooltip>
              </template>
            </FormControl>
          </div>
        </template>
        <div key="email" class="cell cell__12/12">
          <FormControl
            id="email"
            v-model="email"
            :disabled="loading"
            :text="`${$t(
              'components.personPanel.emailInput.theirEmailAddress'
            )} ${
              !isEmailRequired
                ? `(${$t('components.personPanel.emailInput.optional')})`
                : ''
            }`"
            :rules="emailRules"
          />
        </div>
        <template v-if="!availableIdentifierOptions.includes('dob')">
          <div key="is_over_18" class="cell cell__12/12">
            <FormControl
              id="is_over_18"
              v-model="is_over_18"
              :text="$t('components.personPanel.over18')"
              type="radio"
              format="boolean"
              :option="true"
              :required="mustBeOver18"
            />
          </div>
          <div key="is_under_18" class="cell cell__12/12">
            <FormControl
              id="is_under_18"
              v-model="is_over_18"
              :text="$t('components.personPanel.under18')"
              type="radio"
              format="boolean"
              :option="false"
              :required="false"
            />
            <div
              v-if="mustBeOver18 && !is_over_18"
              class="ru:form__invalid-message d-block"
            >
              {{ $t('forms.errors.over18') }}
            </div>
          </div>
        </template>
        <div key="notify" class="cell cell__12/12">
          <FormControl
            id="notify"
            v-model="notify"
            type="checkbox"
            format="boolean"
            :text="notifyCheckboxText"
            :disabled="!isNotifyPersonEnabled"
            :required="false"
          >
            <template #label>
              <Tooltip
                inverted
                :tooltip="
                  !is_over_18
                    ? $t('components.personPanel.notifyPerson.under18')
                    : $t('components.personPanel.notifyPerson.tooltip')
                "
              >
                <Badge color="azure" background="white" size="small">
                  <Icon id="question" />
                </Badge>
              </Tooltip>
            </template>
          </FormControl>
        </div>
      </div>
    </AdiForm>
  </Panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { getName } from 'i18n-iso-countries';
import { queries } from '@/modules/apollo-queries/people';

import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import Panel from '@/components/organisms/Panel';
import AdiForm from '@/components/Form';
import FormControl from '@/components/molecules/FormControl';
import Tooltip from '@/components/atoms/Tooltip';
import Icon from '@/components/atoms/Icon';
import Badge from '@/components/atoms/Badge';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { people, user, will, invites } from '@/mixins/apollo';
import { objectToMetaArray, isOver18, is24HoursFromNow } from '@/utilities';

export default {
  name: 'ComponentsPersonPanel',
  components: {
    Panel,
    AdiForm,
    FormControl,
    Tooltip,
    LargeRadioButtons,
    Icon,
    Badge,
    Buttons,
    RuButton,
  },
  mixins: [people, user, will, invites],
  props: {
    willBeneficiary: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      notify: false,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapFields('person', [
      'fields.full_name',
      'fields.address_street',
      'fields.address_suburb',
      'fields.address_state',
      'fields.address_postcode',
      'fields.address_country',
      'fields.date_of_birth',
      'fields.email',
      'fields.is_over_18',
      'fields.identifier',
    ]),
    ...mapGetters('person', [
      'id',
      'type',
      'identifierRequirements',
      'fields',
      'showPersonPanel',
    ]),
    countryTooltip() {
      if (this.type === 'attorney') {
        return this.$t(
          'components.personPanel.countryTooltip.withAttorneyType'
        );
      }

      return this.$t('components.personPanel.countryTooltip.default');
    },
    isEmailRequired() {
      return this.identifier === 'email' || this.notify;
    },
    dobRules() {
      const rules = [];
      if (
        this.identifier === 'dob' ||
        this.availableIdentifierOptions.includes('dob')
      ) {
        rules.push('required');
      }
      if (this.mustBeOver18) {
        rules.push('over18');
      }
      return rules.join('|');
    },
    postcodeRules() {
      return this.address_country === 'Australia'
        ? 'required|postcode'
        : 'required';
    },
    emailRules() {
      return this.isEmailRequired ? 'email|required' : 'email';
    },
    mustBeOver18() {
      return ['executor', 'guardian', 'attorney'].includes(this.type);
    },
    buttonText() {
      return this.id
        ? this.$t('components.personPanel.updatePerson')
        : `${this.$t('components.personPanel.save')} ${this.capitalisedType}`;
    },
    capitalisedType() {
      return this.$upperFirst(this.type);
    },
    mutation() {
      return this.id ? this.UPDATE_PERSON_MUTATION : this.ADD_PERSON_MUTATION;
    },
    showAddressHelper() {
      return (
        this.availableIdentifierOptions.includes('address') &&
        ['child', 'partner'].includes(this.type)
      );
    },
    panelTitle() {
      return this.id
        ? this.$t('components.personPanel.editPerson')
        : `${this.$t('components.personPanel.addANew')} ${this.type}`;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
        notify: this.notify,
      };
      if (this.id) {
        variables.id = this.id;
      } else {
        if (this.willBeneficiary) {
          variables.willId = this.willId;
        } else {
          variables.userId = this.userId;
        }
        variables.category = 'none';
      }
      return variables;
    },
    hasIdentifierRequirements() {
      return this.identifierRequirements !== null;
    },
    availableIdentifierOptions() {
      if (this.hasIdentifierRequirements) {
        return this.identifierRequirements;
      }
      return this.identifier || 'address';
    },
    notifyCheckboxText() {
      return this.$t('components.personPanel.notifyPerson.label', {
        type: this.type,
      });
    },
    isNotifyPersonEnabled() {
      if (this.loading || !this.is_over_18) {
        return false;
      }
      if (this.id && this.email) {
        const invite = this.getInviteByEmail(this.email);
        if (invite) {
          return !is24HoursFromNow(invite.remindedAt);
        }
      }
      return true;
    },
  },
  watch: {
    address_country() {
      this.address_state = '';
    },
    date_of_birth(newValue) {
      this.is_over_18 = isOver18(newValue);
    },
    is_over_18(newValue) {
      this.notify = newValue;
    },
    hasIdentifierRequirements() {
      if (this.hasIdentifierRequirements) {
        this.identifier = this.identifierRequirements[0];
      } else {
        this.identifier = 'address';
      }
    },
    id(newValue) {
      if (!newValue) return;
      const invite = this.getInviteByEmail(this.email);
      if (invite) {
        this.notify = is24HoursFromNow(invite.remindedAt);
      }
    },
    identifier(newValue) {
      if (newValue === 'address' && !this.address_country) {
        this.address_country = getName(this.willMeta.address_country, 'en');
      }
    },
  },
  methods: {
    ...mapActions('person', [
      'setShowPersonPanel',
      'setIdentifierRequirements',
    ]),
    close() {
      this.setShowPersonPanel(false);
      this.setIdentifierRequirements(null);
    },
    altText(alt) {
      return alt === 'dob' ? 'date of birth' : alt;
    },
    prefillAddressFields() {
      this.address_street = this.willMeta.address_street;
      this.address_suburb = this.willMeta.address_suburb;
      this.address_state = this.willMeta.address_state;
      this.address_postcode = this.willMeta.address_postcode;
      this.address_country = getName(this.willMeta.address_country, 'en');
    },
    update(store, { data: { addPerson } }) {
      if (addPerson) {
        if (this.willBeneficiary) {
          const data = store.readQuery(this.getPeopleQuery);
          data.getPeople.people.push(addPerson.person);
          store.writeQuery({
            ...this.getPeopleQuery,
            data,
          });
        } else {
          const getPeopleOfAccountQuery = {
            variables: this.userId && { userId: this.userId },
            ...queries.getPeopleOfAccount(),
          };
          const data = store.readQuery(getPeopleOfAccountQuery);
          data.getPeopleOfAccount.people.push(addPerson.person);
          store.writeQuery({
            ...getPeopleOfAccountQuery,
            data,
          });
        }
        this.$nuxt.$emit('addPerson', addPerson.person);
      }
      if (this.notify) {
        this.$apollo.queries.invites.refetch();
      }
    },
    getInviteByEmail(email) {
      return this.invites.find((invite) => invite.email === email);
    },
  },
};
</script>
