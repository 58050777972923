<template>
  <ModuleStep :index="1" prev-step="/poa">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      @done="onDone"
    >
      <Headline :level="1">
        {{ $t('pages.poa.confirmDetails') }}
      </Headline>
      <Headline :level="2">{{ $t('pages.poa.questions.fullName') }}</Headline>
      <div class="grid grid--small">
        <div class="cell cell__12/12">
          <FormControl
            id="name_first"
            v-model="willMeta.name_first"
            :disabled="loading"
            :text="$t('forms.placeholders.yourFirstName')"
            rules="required|max:50"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="name_middle"
            v-model="willMeta.name_middle"
            :disabled="loading"
            :required="false"
            :text="$t('forms.placeholders.yourMiddleName')"
            rules="max:50"
          />
        </div>
        <div class="cell cell__12/12">
          <FormControl
            id="name_last"
            v-model="willMeta.name_last"
            :disabled="loading"
            :text="$t('forms.placeholders.yourLastName')"
            rules="required|max:50"
          />
        </div>
      </div>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import Headline from '@/components/atoms/Headline';
import FormControl from '@/components/molecules/FormControl';

import { will } from '@/mixins/apollo';
import { getUserFullName } from '@/utilities';

export default {
  name: 'PagesAffiliatePoaAboutYourselfIndex',
  components: {
    AdiForm,
    ModuleStep,
    Headline,
    FormControl,
  },
  mixins: [will],
  computed: {
    isComplete() {
      return !!(this.willMeta.name_first && this.willMeta.name_last);
    },
  },
  methods: {
    onDone() {
      const willMeta = this.willMeta;

      this.$nuxt.$emit('sendTrackingAttributes', {
        name: getUserFullName(willMeta),
      });

      this.$router.push({
        path: this.localePath('/poa/about-yourself/address'),
      });
    },
  },
};
</script>
