<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.initialDocumentation') }}
    </Headline>
    <p>
      {{ $t('pages.cremations.authoriseTransfer') }}
    </p>
    <FormWrapper v-if="!loading" @submit="saveUpdatedDetails">
      <Box stretch background="lilac">
        <div class="grid grid--around">
          <div
            v-if="showAuthorityToAct"
            class="cell cell__7/12 cell__5/12--md cell__3/12--lg text-center"
          >
            <DocumentCover
              :signed="isAuthorityToActSigned"
              :cover="require('assets/img/cover__AuthorityToAct.png')"
              title="Authority To Act: "
              @click="toggleDocumentReader('AuthorityToAct')"
            />
            <RuButton
              size="small"
              rounded
              background="cornflower"
              @click="openPDF('AuthorityToAct')"
            >
              <BaseIcon id="pencil" size="small" classes="mr-2" />
              {{ $t('forms.labels.downloadAndSign') }}
            </RuButton>
            <DocumentReader
              v-if="documents.AuthorityToAct.visible"
              v-model="formData.authorityToActSignature"
              :name="informantName"
              :images="documents.AuthorityToAct.images"
              @close="toggleDocumentReader('AuthorityToAct')"
            >
              <template #bottom>
                <p>
                  {{ $t('pages.cremations.executorOrNextOfKinAuthorisation') }}
                </p>
                <p>
                  {{ $t('pages.cremations.downloadAndEmailForm') }}
                </p>
                <RuButton
                  background="wine"
                  color="white"
                  @click="openPDF('AuthorityToAct')"
                >
                  <BaseIcon id="download" size="small" classes="mr-2" />
                  {{ $t('forms.labels.download') }}
                </RuButton>
                <RuButton
                  color="wine"
                  border="wine"
                  background="white"
                  @click="openPDF('AuthorityToAct')"
                >
                  <BaseIcon id="email" size="small" classes="mr-2" />
                  {{ $t('forms.labels.email') }}
                </RuButton>
              </template>
            </DocumentReader>
          </div>
          <div
            class="cell cell__7/12 cell__5/12--md cell__3/12--lg text-center"
          >
            <DocumentCover
              :signed="isServicesAgreementSigned"
              :cover="
                require('assets/img/cover__CremationServiceAgreement.png')
              "
              title="Cremation Services Agreement"
              @click="toggleDocumentReader('CremationServiceAgreement')"
            />
            <RuButton
              size="small"
              rounded
              :background="isSignedButtonClass(isServicesAgreementSigned)"
              @click="
                isSignedButtonEvent(
                  isServicesAgreementSigned,
                  'CremationServiceAgreement'
                )
              "
            >
              <BaseIcon
                :id="isSignedButtonIcon(isServicesAgreementSigned)"
                size="small"
                classes="mr-2"
              />
              {{ isSignedButtonText(isServicesAgreementSigned) }}
            </RuButton>
            <DocumentReader
              v-if="documents.CremationServiceAgreement.visible"
              v-model="formData.servicesAgreementSignature"
              :name="informantName"
              :images="documents.CremationServiceAgreement.images"
              :signable="
                !isSigned(cremationRequestData.servicesAgreementSignature)
              "
              @close="toggleDocumentReader('CremationServiceAgreement')"
            />
          </div>
          <div
            v-if="showNswTransferAuthorityMetroCoroner"
            class="cell cell__7/12 cell__5/12--md cell__3/12--lg text-center"
          >
            <DocumentCover
              :signed="isTransferAuthoritySigned"
              :cover="
                require('assets/img/cover__NSWTransferAuthorityCoroner.png')
              "
              title="NSW Transfer Authority Coroner"
              @click="
                toggleDocumentReader('NSWTransferAuthorityLidcombeCoroner')
              "
            />
            <RuButton
              size="small"
              rounded
              :background="isSignedButtonClass(isTransferAuthoritySigned)"
              @click="
                isSignedButtonEvent(
                  isTransferAuthoritySigned,
                  'NSWTransferAuthorityLidcombeCoroner'
                )
              "
            >
              <BaseIcon
                :id="isSignedButtonIcon(isTransferAuthoritySigned)"
                size="small"
                classes="mr-2"
              />
              {{ isSignedButtonText(isTransferAuthoritySigned) }}
            </RuButton>
            <DocumentReader
              v-if="documents.NSWTransferAuthorityLidcombeCoroner.visible"
              v-model="formData.transferAuthoritySignature"
              :name="informantName"
              :images="documents.NSWTransferAuthorityLidcombeCoroner.images"
              :signable="
                !isSigned(cremationRequestData.transferAuthoritySignature)
              "
              @close="
                toggleDocumentReader('NSWTransferAuthorityLidcombeCoroner')
              "
            />
          </div>
          <div
            v-if="showTransferAuthorityPrivate"
            class="cell cell__7/12 cell__5/12--md cell__3/12--lg text-center"
          >
            <DocumentCover
              :signed="isTransferAuthoritySigned"
              :cover="require('assets/img/cover__TransferAuthorityPrivate.png')"
              title="Transfer Authority"
              @click="toggleDocumentReader('TransferAuthorityPrivate')"
            />
            <RuButton
              size="small"
              rounded
              :background="isSignedButtonClass(isTransferAuthoritySigned)"
              @click="toggleDocumentReader('TransferAuthorityPrivate')"
            >
              <BaseIcon
                :id="isSignedButtonIcon(isTransferAuthoritySigned)"
                size="small"
                classes="mr-2"
              />
              {{ isSignedButtonText(isTransferAuthoritySigned) }}
            </RuButton>
            <DocumentReader
              v-if="documents.TransferAuthorityPrivate.visible"
              v-model="formData.transferAuthoritySignature"
              :name="informantName"
              :images="documents.TransferAuthorityPrivate.images"
              signable
              @close="toggleDocumentReader('TransferAuthorityPrivate')"
            />
          </div>
          <div
            v-if="showNswTransferAuthorityRegionalCoroner"
            class="cell cell__7/12 cell__5/12--md cell__3/12--lg text-center"
          >
            <DocumentCover
              :signed="isTransferAuthoritySigned"
              :cover="
                require('assets/img/cover__NSWTransferAuthorityHospitalAndRegionalCoroner.png')
              "
              title="NSW Transfer Authority Coroner"
              @click="
                toggleDocumentReader(
                  'NSWTransferAuthorityHospitalAndRegionalCoroner'
                )
              "
            />
            <RuButton
              size="small"
              rounded
              :background="isSignedButtonClass(isTransferAuthoritySigned)"
              @click="
                isSignedButtonEvent(
                  isTransferAuthoritySigned,
                  'NSWTransferAuthorityHospitalAndRegionalCoroner'
                )
              "
            >
              <BaseIcon
                :id="isSignedButtonIcon(isTransferAuthoritySigned)"
                size="small"
                classes="mr-2"
              />
              {{ isSignedButtonText(isTransferAuthoritySigned) }}
            </RuButton>
            <DocumentReader
              v-if="
                documents.NSWTransferAuthorityHospitalAndRegionalCoroner.visible
              "
              v-model="formData.transferAuthoritySignature"
              :name="informantName"
              :images="
                documents.NSWTransferAuthorityHospitalAndRegionalCoroner.images
              "
              :signable="
                !isSigned(cremationRequestData.transferAuthoritySignature)
              "
              @close="
                toggleDocumentReader(
                  'NSWTransferAuthorityHospitalAndRegionalCoroner'
                )
              "
            />
          </div>
        </div>
      </Box>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseIcon from '@/components/BaseIcon';
import Box from '@/components/atoms/Box';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import DocumentReader from '@/components/organisms/DocumentReader';
import DocumentCover from '@/components/atoms/DocumentCover';

export default {
  name: 'PagesAffiliateCremationsAtNeedDocumentationInitialId',
  components: {
    BaseIcon,
    Box,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    DocumentReader,
    DocumentCover,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      loading: true,
      formData: {
        informantFirstName: null,
        informantLastName: null,
        isReportableToCoroner: null,
        placeOfDeathLocation: null,
        coronerLocation: null,
        guestHasWill: null,
        informantIsWillExecutor: null,
        informantIsNextOfKin: null,
        authorityToActSignature: null,
        servicesAgreementSignature: null,
        transferAuthoritySignature: null,
      },
      documents: {
        AuthorityToAct: {
          images: [],
          visible: false,
        },
        CremationServiceAgreement: {
          images: [],
          visible: false,
        },
        NSWTransferAuthorityLidcombeCoroner: {
          images: [],
          visible: false,
        },
        TransferAuthorityPrivate: {
          images: [],
          visible: false,
        },
        NSWTransferAuthorityHospitalAndRegionalCoroner: {
          images: [],
          visible: false,
        },
      },
    };
  },
  computed: {
    informantName() {
      return `${this.formData.informantFirstName} ${this.formData.informantLastName}`;
    },
    nextPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
    prevPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
    isAuthorityToActSigned() {
      return this.isSigned(this.formData.authorityToActSignature);
    },
    isServicesAgreementSigned() {
      return this.isSigned(this.formData.servicesAgreementSignature);
    },
    isTransferAuthoritySigned() {
      return this.isSigned(this.formData.transferAuthoritySignature);
    },
  },
  methods: {
    async toggleDocumentReader(documentType) {
      this.documents[documentType].visible =
        !this.documents[documentType].visible;
      if (
        this.documents[documentType].visible &&
        !this.documents[documentType].images.length
      ) {
        this.documents[documentType].images = await this.requestImageUrls(
          documentType
        );
      }
    },
  },
};
</script>
