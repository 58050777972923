<template>
  <div
    :class="[
      `ru:buttons --${layout}`,
      theme ? `--${theme}` : '',
      labelSibling ? `--label-offset` : '',
    ]"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ComponentsAtomsButtons',
  props: {
    theme: {
      default: null,
      type: String,
    },
    labelSibling: {
      default: null,
      type: Boolean,
    },
    layout: {
      default: 'end',
      type: String,
    },
  },
};
</script>

<style lang="scss">
$buttons: '.ru\\:buttons';
$button: '.ru\\:button';
#{$ru} {
  &buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    gap: var(--base-margin-small);

    #{$button} {
      width: 100%;
    }

    @include mq('min-md') {
      gap: 1rem;
    }

    &:not(:last-child) {
      margin-bottom: var(--base-margin);
    }

    &#{$mf}label-offset {
      padding-top: #{calc(var(--base-line-height) + var(--base-margin-tiny))};
    }

    &#{$mf}between {
      justify-content: space-between;
      width: 100%;
    }

    &#{$mf}column {
      flex-direction: column;
      align-content: stretch;

      #{$button} {
        width: 100%;
      }
    }

    &#{$mf}start #{$button} {
      &#{$mf}plain {
        margin-left: 0;
      }
    }

    @include mq('min-sm') {
      flex-direction: row;
      gap: var(--base-margin);

      #{$button} {
        width: auto;
      }

      &#{$mf}column #{$button} {
        width: 100%;
      }

      @at-root &#{$mf}inline {
        display: inline-flex;
        margin-right: var(--base-margin);
        margin-bottom: 0;
      }

      @at-root &#{$mf}stretch #{$button} {
        flex: 1;
      }

      @at-root &#{$mf}center #{$button} {
        margin: 0 auto;
      }

      @at-root &#{$mf}start #{$button}:is(:last-child) {
        margin-left: 0;
        margin-right: auto;
      }

      @at-root &#{$mf}end #{$button}:is(:first-child) {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}
</style>
