var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":7,"prev-step":_vm.previous}},[_c('AdiForm',{attrs:{"disabled":!_vm.isComplete},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.limitations.title'))+"\n    ")]),_vm._v(" "),(['nsw'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.limitations.descNSW'))+"\n    ")]):(['nt'].includes(_vm.userState))?_c('p',{staticClass:"mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.limitations.descNT'))+"\n    ")]):_c('p',{staticClass:"mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t('pages.poa.financial.limitations.exLimitAssets'))+"\n    ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"has_limitations","options":[
        {
          label: _vm.$t('forms.labels.yes'),
          value: true,
        },
        {
          label: _vm.$t('forms.labels.no'),
          value: false,
        },
      ]},on:{"input":_vm.onChange},model:{value:(_vm.poaFinancialMeta.has_limitations),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "has_limitations", $$v)},expression:"poaFinancialMeta.has_limitations"}}),_vm._v(" "),(_vm.poaFinancialMeta.has_limitations)?_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell cell__12/12"},[_c('FormControl',{attrs:{"id":"poa_financial_limitations","disabled":loading,"type":"textarea","rules":"required|max:3000","placeholder":_vm.$t('forms.placeholders.itIsMyInstruction')},model:{value:(_vm.poaFinancialMeta.limitations),callback:function ($$v) {_vm.$set(_vm.poaFinancialMeta, "limitations", $$v)},expression:"poaFinancialMeta.limitations"}})],1)]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }