<template>
  <div class="ru:maintenance">
    <a href="https://safewill.com">
      <Logo />
    </a>
    <div class="ru:maintenance-content">
      <Headline :level="1" centered>{{
        $t('pages.maintenance.title')
      }}</Headline>
      <RuImage
        :src="require('~/assets/svg/gateway__vault.svg')"
        :width="225"
        :height="150"
      />
      <p class="ru:maintenance-message">
        {{ $t('pages.maintenance.message') }}
      </p>
      <Buttons layout="center">
        <RuButton level="primary" href="https://safewill.com">
          <Icon id="arrow-left" />
          {{ $t('pages.maintenance.cta') }}
        </RuButton>
      </Buttons>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/atoms/Logo';
import RuImage from '@/components/atoms/Image';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'PagesAffiliateMaintenance',
  components: {
    Logo,
    RuImage,
    Buttons,
    RuButton,
    Icon,
    Headline,
  },
  layout: 'minimal',
};
</script>

<style lang="scss">
#{$ru} {
  &maintenance {
    padding: var(--base-margin-huge);
    display: flex;
    flex-direction: column;

    &-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--base-margin);
      max-width: 50rem;
      align-self: center;
      color: --rgba(basalt);
      text-align: center;
      padding: var(--base-margin) 0;
    }
  }
}
</style>
