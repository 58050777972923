<template>
  <ModuleStep v-if="willMeta" :index="2" prev-step="/will/estate">
    <AdiForm
      v-slot="{ loading }"
      :mutation="UPDATE_WILL_MUTATION"
      :variables="UPDATE_WILL_VARS"
      :disabled="!isComplete"
      :auto-submit="willMeta.estate_split && willMeta.estate_split !== 'custom'"
      @done="done"
    >
      <template v-if="hasFamily">
        <Headline :level="1">
          {{ $t('pages.will.estate.inheritEstateQuestion') }}
        </Headline>
        <Tip>
          {{ $t('pages.will.estate.backupBeneficiaryNote') }}
        </Tip>
        <EstateSelector v-model="willMeta.estate_split" />
      </template>
      <template v-if="willMeta.estate_split === 'custom'">
        <Headline :level="1">
          {{ $t('pages.will.estate.inheritYourEstateQuestion') }}
        </Headline>
        <p class="mb-6">
          {{ $t('pages.will.estate.addBeneficiaryNote') }}
        </p>
        <PersonSelector
          v-model="selectedBeneficiaryIDs"
          label
          :loading="loading"
          :people="people"
          type="beneficiary"
        />
        <Headline :level="2">
          {{ $t('pages.will.estate.charitiesAndNotForProfit') }}
        </Headline>
        <CharitySuggester
          v-if="showSuggestedCharities"
          v-model="selectedSuggestedCharities"
          :loading="loading"
        />
        <CharitySelector v-model="selectedBeneficiaryIDs" :loading="loading" />
        <Buttons layout="stretch">
          <RuButton size="large" level="secondary" @click="addCharity">
            + {{ $t('forms.labels.addCharity') }}
          </RuButton>
        </Buttons>
        <CharityPanel />
      </template>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapActions } from 'vuex';

import { metaArrayToObject, calculateTotalDistribution } from '@/utilities';
import { isSameCharity } from '@/utilities/charity';

import CharitySelector from '@/components/CharitySelector';
import CharitySuggester from '@/components/CharitySuggester';
import EstateSelector from '@/components/EstateSelector';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import PersonSelector from '@/components/PersonSelector';
import Tip from '@/components/molecules/Tip';
import Headline from '@/components/atoms/Headline';
import CharityPanel from '@/components/CharityPanel';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { beneficiaries, charities, people, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillEstateBeneficiaries',
  components: {
    CharitySelector,
    CharitySuggester,
    EstateSelector,
    AdiForm,
    ModuleStep,
    PersonSelector,
    Tip,
    Headline,
    CharityPanel,
    Buttons,
    RuButton,
  },
  mixins: [beneficiaries, charities, people, user, will],
  data() {
    return {
      selectedBeneficiaryIDs: [],
      selectedSuggestedCharities: [],
      hasFamily: false,
    };
  },
  computed: {
    showSuggestedCharities() {
      const hasReferral = !!this.willMeta.referral_charity;
      const hasAddedSuggestedCharity = this.charities.some((charity) => {
        const charityMeta = metaArrayToObject(charity.meta);
        return !!charityMeta.suggestionLocation;
      });
      return !hasReferral && !hasAddedSuggestedCharity;
    },
    isComplete() {
      return !!(
        this.willMeta.estate_split &&
        (this.willMeta.estate_split !== 'custom' ||
          this.selectedBeneficiaryIDs.length ||
          this.selectedSuggestedCharities.length)
      );
    },
    possibleBeneficiaries() {
      return [this.beneficiaries, this.people, this.charities];
    },
  },
  watch: {
    possibleBeneficiaries() {
      if (
        this.willMeta.estate_split === 'custom' &&
        this.beneficiaries.length &&
        !this.selectedBeneficiaryIDs.length
      ) {
        const peopleIDs = this.people
          .filter((person) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.person && beneficiary.person.id === person.id
            );
          })
          .map((person) => person.id);

        const charityIDs = this.charities
          .filter((charity) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.charity && beneficiary.charity.id === charity.id
            );
          })
          .map((charity) => charity.id);

        this.selectedBeneficiaryIDs = peopleIDs.concat(charityIDs);
      }
    },
    willMeta() {
      this.hasFamily = this.willMeta.has_partner || this.willMeta.has_children;
      if (!this.hasFamily) {
        this.willMeta.estate_split = 'custom';
      }
    },
  },
  mounted() {
    this.willMeta.estate_backup_index = 0;
    this.updateWillMeta();
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    async done() {
      const availableDistribution = 100;
      let defaultDistribution;

      if (this.selectedSuggestedCharities.length) {
        await Promise.all(
          this.selectedSuggestedCharities.map((charity) => {
            return this.addPartnerCharity(charity);
          })
        );
        await this.refetchCharities();
        this.charities.forEach((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          const exists = this.selectedSuggestedCharities.find(
            (selectedSuggestedCharity) => {
              return isSameCharity(selectedSuggestedCharity, charityMeta);
            }
          );
          if (exists) {
            this.selectedBeneficiaryIDs.push(charity.id);
          }
        });
      }

      switch (this.willMeta.estate_split) {
        case 'partner':
        case 'children':
          for (const charity of this.charities) {
            await this.removeBeneficiaryByCharity(charity);
          }

          for (const person of this.people) {
            if (!this.isChild(person) && !this.isPartner(person)) {
              await this.removeBeneficiaryByPerson(person);
            }
          }

          switch (this.willMeta.estate_split) {
            case 'partner':
              defaultDistribution = availableDistribution;

              for (const child of this.children) {
                await this.removeBeneficiaryByPerson(child);
              }

              for (const partner of this.partners) {
                const beneficiary = this.getBeneficiaryByPerson(partner);

                if (beneficiary) {
                  await this.updateBeneficiaryDistribution(
                    beneficiary,
                    defaultDistribution
                  );
                } else {
                  await this.addBeneficiary(partner, defaultDistribution);
                }
              }

              break;
            case 'children':
              for (const partner of this.partners) {
                await this.removeBeneficiaryByPerson(partner);
              }

              for (const child of this.children) {
                const beneficiary = this.getBeneficiaryByPerson(child);

                if (!beneficiary) {
                  await this.addBeneficiary(child, null);
                }
              }

              break;
            default:
              break;
          }

          break;
        case 'custom': {
          for (const person of this.people) {
            const beneficiary = this.getBeneficiaryByPerson(person);
            const isSelected = this.selectedBeneficiaryIDs.includes(person.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBeneficiary(person, null);
              }
            } else if (beneficiary) {
              await this.removeBeneficiary(beneficiary);
            }
          }

          for (const charity of this.charities) {
            const beneficiary = this.getBeneficiaryByCharity(charity);
            const isSelected = this.selectedBeneficiaryIDs.includes(charity.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBeneficiary(charity, null);
              }
            } else if (beneficiary) {
              await this.removeBeneficiary(beneficiary);
            }
          }

          break;
        }
        default:
          break;
      }

      const noCharity =
        this.willMeta.estate_split !== 'custom' ||
        !this.charities.find((charity) =>
          this.selectedBeneficiaryIDs.includes(charity.id)
        );

      if (noCharity) {
        return this.$router.push({
          path: this.localePath('/will/estate/include-charity'),
        });
      } else {
        this.willMeta.charity_in_estate = 'true';
        await this.updateWillMeta();
        this.$nuxt.$emit('sendTrackingAttributes', {
          has_charity_gift: true,
        });

        await this.refetchBeneficiaries();
        const currentPrimaryTotal = calculateTotalDistribution(
          this.beneficiaries.map((beneficiary) => beneficiary.distribution)
        );

        const mustRedistribute =
          this.willMeta.estate_split === 'custom' ||
          currentPrimaryTotal !== defaultDistribution;

        this.sendBeneficiariesAddedEvent();

        if (mustRedistribute) {
          await this.$router.push(this.localePath('/will/estate/distribution'));
        } else {
          await this.updateWillIsPrimaryEstateSplitEvenly(false);
          await this.$router.push(
            this.localePath(
              `/will/estate/backup-beneficiaries/${this.backupQueue[0].id}`
            )
          );
        }
      }
    },
  },
};
</script>
