<template>
  <ModuleStep :index="4" :prev-step="previous">
    <AdiForm
      v-slot="{ loading }"
      :disabled="!isComplete"
      @submit.prevent="onSubmit"
    >
      <Headline :level="1">
        {{
          $t('pages.poa.financial.substitutes.headline', {
            substitute: canHaveMultipleSubstitutes
              ? $t('texts.sentences.substituteDecisionMakers')
              : $t('texts.sentences.aSubstituteDecisionMaker'),
          })
        }}
      </Headline>
      <template v-if="['nsw'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.financial.substitutes.stateDescriptionNSW') }}
        </p>
      </template>
      <template v-else-if="['vic', 'wa'].includes(userState)">
        <p class="mb-4">
          {{ $t('pages.poa.financial.substitutes.stateDescriptionVICWA') }}
        </p>
        <Tip v-if="['wa'].includes(userState)">
          {{ $t('pages.poa.financial.substitutes.stateDescriptionWA') }}
        </Tip>
      </template>
      <template v-else-if="['qld', 'act', 'nt'].includes(userState)">
        <p class="mb-4">
          {{
            $t('pages.poa.financial.substitutes.stateDescriptionQLDACTNT', {
              marker: canHaveMultipleSubstitutes ? 'makers' : 'maker',
            })
          }}
        </p>
      </template>
      <LargeRadioButtons
        id="has_substitute"
        v-model="poaFinancialMeta.has_substitute"
        layout="column"
        :options="[
          {
            label: $t('pages.poa.financial.substitutes.options.yes', {
              substitute: canHaveMultipleSubstitutes
                ? $t('texts.sentences.substituteDecisionMakers')
                : $t('texts.sentences.aSubstituteDecisionMaker'),
            }),
            value: true,
          },
          {
            label: $t('pages.poa.financial.substitutes.options.no', {
              substitute: canHaveMultipleSubstitutes
                ? $t('texts.sentences.anySubstituteDecisionMakers')
                : $t('texts.sentences.aSubstituteDecisionMaker'),
            }),
            value: false,
          },
        ]"
        @input="onSubmit"
      />
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaFinancialSubstitutes',
  components: {
    AdiForm,
    ModuleStep,
    LargeRadioButtons,
    Headline,
  },
  mixins: [user, will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'FINANCIAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      attorneys: undefined,
      poaFinancialMeta: {
        has_substitute: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        numPrimaryAttorneysSelected: this.attorneys?.filter(
          (attorney) => attorney.role === 'PRIMARY'
        ).length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
        addressState: this.userState,
      });
    },
    canHaveMultipleSubstitutes() {
      return ['act'].includes(this.userState);
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      return (
        this.poaFinancialMeta &&
        (this.poaFinancialMeta.has_substitute === true ||
          this.poaFinancialMeta.has_substitute === false)
      );
    },
  },
  methods: {
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMeta(this.poaId, {
          key: 'has_substitute',
          value: this.poaFinancialMeta.has_substitute ? 'true' : 'false',
          category: 'FINANCIAL',
        })
      );
      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
</script>
