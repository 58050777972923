<template>
  <ModuleStep :index="7" :prev-step="previous">
    <AdiForm
      v-slot="{ loading }"
      :disabled="!isComplete"
      @submit.prevent="onSubmit"
    >
      <Headline :level="1">
        {{ $t('pages.poa.financial.limitations.title') }}
      </Headline>
      <p v-if="['nsw'].includes(userState)" class="mb-4">
        {{ $t('pages.poa.financial.limitations.descNSW') }}
      </p>
      <p v-else-if="['nt'].includes(userState)" class="mb-4">
        {{ $t('pages.poa.financial.limitations.descNT') }}
      </p>
      <p v-else class="mb-4">
        {{ $t('pages.poa.financial.limitations.exLimitAssets') }}
      </p>
      <LargeRadioButtons
        id="has_limitations"
        v-model="poaFinancialMeta.has_limitations"
        :options="[
          {
            label: $t('forms.labels.yes'),
            value: true,
          },
          {
            label: $t('forms.labels.no'),
            value: false,
          },
        ]"
        @input="onChange"
      />
      <div v-if="poaFinancialMeta.has_limitations" class="grid">
        <div class="cell cell__12/12">
          <FormControl
            id="poa_financial_limitations"
            v-model="poaFinancialMeta.limitations"
            :disabled="loading"
            type="textarea"
            rules="required|max:3000"
            :placeholder="$t('forms.placeholders.itIsMyInstruction')"
          />
        </div>
      </div>
    </AdiForm>
  </ModuleStep>
</template>

<script>
import { mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';
import AdiForm from '@/components/Form';
import ModuleStep from '@/components/templates/ModuleStep';
import LargeRadioButtons from '@/components/molecules/LargeRadioButtons';
import FormControl from '@/components/molecules/FormControl';
import Headline from '@/components/atoms/Headline';

import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaFinancialLimitations',
  components: {
    AdiForm,
    ModuleStep,
    LargeRadioButtons,
    FormControl,
    Headline,
  },
  mixins: [user, will],
  apollo: {
    poaFinancialMeta: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
  },
  data() {
    return {
      poaFinancialMeta: {
        has_limitations: undefined,
        limitations: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    previous() {
      return prevStep({
        addressState: this.userState,
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
        numPrimaryAttorneysSelected: this.attorneys?.filter(
          (attorney) => attorney.role === 'PRIMARY'
        ).length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    isComplete() {
      return (
        this.poaFinancialMeta.has_limitations === false ||
        (this.poaFinancialMeta.has_limitations === true &&
          this.poaFinancialMeta.limitations?.length > 0)
      );
    },
  },
  methods: {
    onChange(value) {
      if (value === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'has_limitations',
            value: this.poaFinancialMeta.has_limitations ? 'true' : 'false',
            category: 'FINANCIAL',
          },
          {
            key: 'limitations',
            value: this.poaFinancialMeta.has_limitations
              ? this.poaFinancialMeta.limitations
              : null,
            category: 'FINANCIAL',
          },
        ])
      );

      this.$nuxt.$emit('sendTrackingEvent', {
        event: '✅ Module POA financial completed',
      });

      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
</script>
