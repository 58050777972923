var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{class:[
    'ru:icon',
    _vm.theme ? `--themed --${_vm.theme}` : '',
    _vm.shadow ? '--shadow' : '',
    _vm.alignRight ? '--right' : '',
  ],style:(`--dimensions: ${_vm.dimensions}`),attrs:{"xmlns":"http://www.w3.org/2000/svg"}},[_c('use',_vm._b({},'use',{
      'xlink:href': `${require('~/assets/svg/ru-icons.svg')}#${_vm.id}`,
    },false))])
}
var staticRenderFns = []

export { render, staticRenderFns }