<template>
  <Panel
    v-if="showCharityPanel"
    class="charity-panel ru:charity-panel"
    title="Add a new charity"
    @close="close"
  >
    <template v-if="!showAddCustomCharity">
      <div
        class="-mt-10 py-8 px-10 -mx-6 mb-6 bg-ice-100 text-base text-slate-300 flex"
      >
        <div
          class="bg-ice-300 rounded-full w-12 h-12 flex p-2 -ml-2 mr-2 justify-center items-center flex-shrink-0"
        >
          <BaseIcon id="charity" />
        </div>
        <p class="flush">
          {{ $t('components.charityPanel.browseOrSearch') }}
        </p>
      </div>
      <div class="mb-6">
        <button
          v-for="categoryKey in charityCategoryKeys"
          :key="`featuredCharities_${categoryKey}`"
          type="button"
          class="border-2 border-solid border-slate-300 rounded-full px-3 mr-2 mb-2 leading-relaxed"
          :class="categoryIsSelected(categoryKey)"
          @click="selectCategory(categoryKey)"
        >
          {{ charityCategories[categoryKey] }}
        </button>
      </div>
      <FormRow class="mb-6">
        <TextInput
          id="searchTerm"
          v-model="searchTerm"
          placeholder="Search for a charity"
        />
      </FormRow>
      <div class="ru:charity-panel__list">
        <Selectable
          v-for="charity in filteredCharitySource"
          :id="charity.id"
          :key="charity.id"
          :is-selected="selectedCharitySourceItemIds.includes(charity.id)"
          :data="charity"
          @selectionChange="onChange(charity.id)"
        >
          <template #content>
            <img
              v-if="charity.logo"
              class="ru:charity-panel__logo"
              :src="$makePartnerLogoUrl(charity.logo)"
              :alt="charity.displayName || charity.name"
            />
            <Headline :level="4">
              {{ charity.displayName || charity.name }}
            </Headline>
          </template>
          <template #actions>
            <div class="d-flex-end">
              <Badge
                :color="
                  selectedCharitySourceItemIds.includes(charity.id)
                    ? 'white'
                    : 'silver'
                "
                :background="
                  selectedCharitySourceItemIds.includes(charity.id)
                    ? 'theme'
                    : 'iron'
                "
              >
                <Icon id="check" />
              </Badge>
            </div>
          </template>
        </Selectable>
        <div v-if="!filteredCharitySource.length">
          <p>{{ $t('components.charityPanel.canNotFindCharity') }}</p>
          <Buttons layout="start">
            <RuButton
              size="large"
              level="primary"
              :disabled="savingCharity"
              @click="toggleShowAddCustomCharity"
            >
              {{ $t('components.charityPanel.addACharity') }}
            </RuButton>
          </Buttons>
        </div>
      </div>
      <div
        v-if="selectedCharitySourceItemIds.length"
        class="ru:panel__footer bottom-0 right-0 bg-white pl-6 py-4 md:py-6"
      >
        <Buttons layout="start">
          <RuButton
            size="large"
            level="primary"
            :disabled="savingCharity"
            @click="saveCharities"
          >
            {{ $t('components.charityPanel.saveCharity') }}
          </RuButton>
        </Buttons>
      </div>
    </template>
    <template v-else>
      <AddCustomCharity @successfullyAddedCharity="successfullyAddedCharity" />
    </template>
  </Panel>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import shuffle from 'lodash/shuffle';
import { deburr } from 'lodash/string';

import { charityCategories } from '@/modules/charityData';
import AddCustomCharity from '@/components/AddCustomCharity';
import Panel from '@/components/organisms/Panel';
import BaseIcon from '@/components/BaseIcon';
import Headline from '@/components/atoms/Headline';
import Icon from '@/components/atoms/Icon';
import Badge from '@/components/atoms/Badge';
import FormRow from '@/components/FormRow';
import TextInput from '@/components/TextInput';
import Selectable from '@/components/molecules/Selectable';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';

import { charities, will, user, beneficiaries } from '@/mixins/apollo';

export default {
  name: 'ComponentsCharityPanel',
  components: {
    AddCustomCharity,
    Panel,
    BaseIcon,
    Headline,
    Icon,
    Badge,
    FormRow,
    TextInput,
    Selectable,
    Buttons,
    RuButton,
  },
  mixins: [charities, will, user, beneficiaries],
  data() {
    return {
      charityCategories: {
        'All charities': 'All charities',
        ...charityCategories,
      },
      savingCharity: false,
      searchTerm: '',
      selectedCategory: 'All charities',
      selectedCharitySourceItemIds: [],
      showAddCustomCharity: false,
    };
  },
  computed: {
    ...mapFields('charity', ['fields.name', 'fields.address']),
    ...mapGetters('charity', ['id', 'fields', 'showCharityPanel']),
    ...mapGetters(['featuredFives']),
    ...mapState(['charitySource']),
    userState() {
      return this.willMeta.address_state?.toLowerCase();
    },
    charityCategoryKeys() {
      const categories = Object.keys(charityCategories);

      return ['All charities', ...categories.sort((a, b) => a - b)];
    },
    shuffledCharitySource() {
      const featuredFiveIds = this.featuredFives.map((charitySourceItem) => {
        return charitySourceItem.id;
      });

      const unfeaturedCharityArray = this.charitySource.filter(
        (charitySourceItem) => {
          return featuredFiveIds.includes(charitySourceItem.id) === false;
        }
      );

      return shuffle(this.featuredFives).concat(
        shuffle(unfeaturedCharityArray)
      );
    },
    filteredCharitySource() {
      const hasCategory = this.selectedCategory !== 'All charities';
      const hasSearchTerm = !!this.searchTerm;

      return this.shuffledCharitySource.filter((charitySourceItem) => {
        const sanitisedCharityName = deburr(charitySourceItem.name)
          .replaceAll('’', "'")
          .toUpperCase();
        const sanitisedCharityDisplayName = deburr(
          charitySourceItem.displayName
        )
          .replaceAll('’', "'")
          .toUpperCase();
        const sanitisedSearchTerm = hasSearchTerm
          ? deburr(this.searchTerm).replaceAll('’', "'").toUpperCase()
          : '';

        const isSearchTermMatched = hasSearchTerm
          ? sanitisedCharityDisplayName.includes(sanitisedSearchTerm) ||
            sanitisedCharityName.includes(sanitisedSearchTerm)
          : true;

        return (
          (hasCategory
            ? charitySourceItem.categories?.includes(this.selectedCategory)
            : true) && isSearchTermMatched
        );
      });
    },
  },
  methods: {
    ...mapActions('charity', ['setShowCharityPanel']),
    successfullyAddedCharity() {
      this.close();
    },
    toggleShowAddCustomCharity() {
      this.showAddCustomCharity = !this.showAddCustomCharity;
    },
    onChange(charityId) {
      if (this.selectedCharitySourceItemIds.includes(charityId)) {
        this.selectedCharitySourceItemIds =
          this.selectedCharitySourceItemIds.filter((id) => {
            return id !== charityId;
          });
      } else {
        this.selectedCharitySourceItemIds.push(charityId);
      }
    },
    async saveCharities() {
      if (this.selectedCharitySourceItemIds.length) {
        this.savingCharity = true;
        const addedCharities = [];
        await Promise.all(
          this.selectedCharitySourceItemIds.map((id) => {
            const charitySourceItem = this.charitySource.find((c) => {
              return c.id === id;
            });

            const charityData = {
              ...charitySourceItem,
              searchLocation: window.location.pathname,
              searchFeature: this.featuredFives.some((c) => {
                return c.id === id;
              }),
            };

            addedCharities.push(charityData);
            return this.addPartnerCharity(charityData);
          })
        );

        await this.refetchCharities();
        this.savingCharity = false;
        this.$nuxt.$emit('addCharities', addedCharities);
        for (const addedCharity of addedCharities) {
          this.$nuxt.$emit('sendTrackingEvent', {
            event: 'charity_added',
            props: {
              charity_name: addedCharity.displayName,
            },
          });
        }
        this.close();
      }
    },
    categoryIsSelected(category) {
      return this.selectedCategory === category
        ? 'bg-slate-300 text-white'
        : 'bg-white text-slate-300';
    },
    close() {
      this.searchTerm = '';
      this.selectedCategory = 'All charities';
      this.selectedCharitySourceItemIds = [];
      this.showAddCustomCharity = false;
      this.setShowCharityPanel(false);
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
    primaryText() {
      return !this.hideName ? this.charityMeta.name : null;
    },
    secondaryText() {
      if (this.descriptionIsHidden) {
        return null;
      }
      return this.partnerCharity
        ? this.charityMeta.description
        : this.charityMeta.address;
    },
    tooltipText() {
      return this.showTooltip ? this.charityMeta.description : null;
    },
    classes() {
      return {
        card: {
          'border-2 border-teal-200': this.selected,
          'border-2 border-white': !this.selected,
        },
      };
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &charity-panel {
    &__list {
      --margin-y: var(--base-margin);
      margin-bottom: 11rem;
    }

    &__logo {
      background: --rgba(white);
      margin-bottom: var(--base-margin);
      max-height: 4rem;
      max-width: 100%;
      outline: 0.5rem solid --rgba(white);
      box-shadow: 0.5rem 0 0 0.5rem --rgba(white);
      border-radius: var(--border-radius-small);
    }
  }
}
</style>
