<template>
  <div class="ru:container">
    <template v-if="isLoading">
      <Headline :level="1">
        <small>{{ $t('titles.endOfLifePlanning') }}</small>
      </Headline>
      <Loading />
    </template>
    <template v-else>
      <Headline :level="1">
        <small v-if="rootCategory.name">{{
          $t('titles.endOfLifePlanning')
        }}</small>
        {{ rootCategory.name || $t('titles.endOfLifePlanning') }}
      </Headline>
      <Loading
        v-if="!showCategoriesTabs && !showSubcategoriesTabs"
        theme="light"
      />
      <transition appear name="ru:wiggle-fade" mode="out-in">
        <Tabs
          v-if="showCategoriesTabs"
          v-model="categoryTabIndex"
          :tabs="
            displayCategoryTree.map((category) => {
              return {
                name: category.slug,
                title: category.name,
                subtitle: endOfLifeCategoryItemCounts[category.id]
                  ? { ...endOfLifeCategoryItemCounts }[category.id]
                  : null,
              };
            })
          "
          variant="underline"
          @tabSelected="categoryTabSelected($event, 0)"
        >
          <template
            v-for="(category, i) in displayCategoryTree"
            #[category.slug]
          >
            <PortalTarget
              v-if="!showSubcategoriesTabs"
              :key="`portal_${i}`"
              name="end-of-life-items"
            />
            <Tabs
              v-if="showSubcategoriesTabs"
              :key="`tab_${i}`"
              v-model="subcategoryTabIndex"
              :tabs="
                category.subcategories.map((subcategory) => {
                  return {
                    name: subcategory.slug,
                    title: subcategory.name,
                    subtitle: endOfLifeCategoryItemCounts[subcategory.id]
                      ? { ...endOfLifeCategoryItemCounts }[subcategory.id]
                      : '0',
                  };
                })
              "
              variant="pill"
              @tabSelected="categoryTabSelected(i, $event)"
            >
              <template
                v-for="(subcategory, j) in category.subcategories"
                #[subcategory.slug]
              >
                <PortalTarget :key="`portal_${j}`" name="end-of-life-items" />
              </template>
            </Tabs>
          </template>
        </Tabs>
      </transition>
    </template>
    <Portal to="end-of-life-items">
      <transition appear name="ru:wiggle-fade" mode="out-in">
        <div v-if="activeCategory" class="ru:end-of-life__items">
          <Loading v-if="loading" theme="light" />
          <template v-else>
            <ItemList
              v-if="[...endOfLifeItemsByCategory[activeCategory.id]].length > 0"
              key="itemList"
              :items="[...endOfLifeItemsByCategory[activeCategory.id]]"
              :layout="activeDataLayout"
              :institution-type="institutionType"
              @editItem="editItem(activeCategory.id, $event)"
            />
            <EmptyCategory v-else key="EmptyCategory" />
            <div v-if="addItemButtonIsVisible" class="grid grid--center">
              <div
                class="cell__12/12 cell__8/12--sm cell__6/12--md cell__4/12--lg"
              >
                <Buttons layout="stretch">
                  <RuButton
                    :level="
                      endOfLifeItemsByCategory[activeCategory.id] &&
                      endOfLifeItemsByCategory[activeCategory.id].length > 0
                        ? 'tertiary'
                        : 'primary'
                    "
                    @click="addItem(activeCategory.id, activeCategory.name)"
                  >
                    <Icon id="add" size="small" />
                    {{ $t('forms.labels.add') }} {{ activeCategory.name }}
                  </RuButton>
                </Buttons>
              </div>
            </div>
          </template>
          <div
            v-if="fileUploadsAllowed && $ff.useEOLFileUpload()"
            class="ru:end-of-life__files-wrapper"
          >
            <Headline :level="2">
              {{ $t('pages.endOfLife.otherImportantDocuments') }}
            </Headline>
            <Loading v-if="uploadedFiles.category.loading" theme="light" />
            <Alert
              v-if="uploadedFiles.category.alert.message"
              level="medium"
              theme="error"
              icon="error"
            >
              {{ uploadedFiles.category.alert.message }}
            </Alert>
            <div class="ru:end-of-life__files">
              <div class="grid grid--stretch">
                <div
                  class="cell cell__12/12 cell__6/12--sm cell__4/12--md cell__3/12--lg d-flex"
                >
                  <div
                    v-if="!uploadedFiles.category.loading"
                    class="ru:end-of-life__upload-files"
                    @click="openUploadFileModal"
                  >
                    <Icon id="upload" size="large" />
                    {{ $t('pages.endOfLife.uploadFiles') }}
                  </div>
                </div>
                <div
                  v-for="file of uploadedFiles.category.files || []"
                  :key="file.id"
                  class="cell cell__12/12 cell__6/12--sm cell__4/12--md cell__3/12--lg"
                >
                  <div class="ru:end-of-life__file">
                    <FileThumbnail
                      :file-name="file.meta.fileName"
                      :file-id="file.id"
                      background="white"
                      border="iron"
                      unlinkable
                      @selectFile="downloadFile"
                      @unlinkFile="unlinkEndOfLifeCategoryFile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </Portal>
    <Modal
      v-if="endOfLifeItemModalIsVisible"
      id="endOfLifeItem"
      @closeModal="closeModal"
    >
      <template #header>
        {{ modalData.header }}
      </template>
      <template #body>
        <FormWrapper v-model="isInvalid">
          <EndOfLifeFieldset
            ref="eolFieldset"
            :value="modalData"
            :layout="activeDataLayout"
            :institution-type="institutionType"
            :question="currentQuestion"
            @change="setItemField"
          />
          <div
            v-if="fileUploadsAllowed && $ff.useEOLFileUpload()"
            class="ru:end-of-life__item-files"
          >
            <p
              v-if="uploadedFiles.item.loading"
              class="ru:end-of-life__files-loading"
            >
              <Loading theme="light" /> {{ $t('pages.endOfLife.loadingFiles') }}
            </p>
            <FilesList
              :files="uploadedFiles.item.files"
              unlinkable
              :unlink-confirm="false"
              @downloadFile="downloadFile"
              @unlinkFile="openUnlinkEndOfLifeItemFileModal"
            />
            <Buttons layout="start">
              <RuButton level="plain" @click="openUploadFileModal">
                <Icon id="upload" />
                {{ $t('pages.endOfLife.uploadFiles') }}
              </RuButton>
            </Buttons>
            <Tip>
              {{ $t('pages.endOfLife.recommendedDocuments') }}<br />
              {{ filesTypeString }}.
            </Tip>
          </div>
          <FormControl
            v-if="!isQuestionLayout"
            id="notes"
            v-model="modalData.data.notes"
            :required="false"
            text="Notes"
            type="textarea"
            rules="max:3000"
          />
          <Alert level="minor" theme="warning" icon="warning">
            {{ $t('pages.endOfLife.securityInfo') }}
          </Alert>
          <Buttons
            v-if="isQuestionCategory && modalData.type === 'CREATE_VAULT'"
            layout="stretch"
          >
            <RuButton level="primary" @click="saveAnswer">
              {{ questionNextText }}
              <Icon id="arrow-right" />
            </RuButton>
          </Buttons>
          <Buttons v-else layout="stretch">
            <RuButton
              v-if="modalData.type === 'update'"
              level="secondary"
              theme="error"
              @click="openDeleteEndOfLifeItemModal"
            >
              {{ $t('forms.labels.delete') }}
            </RuButton>
            <RuButton
              level="primary"
              :disabled="isInvalid || isBusy"
              @click="saveItem"
            >
              {{ $t('forms.labels.save') }}
            </RuButton>
          </Buttons>
        </FormWrapper>
      </template>
    </Modal>
    <Modal
      v-if="deleteEndOfLifeItemModalIsVisible"
      id="deleteEndOfLifeItem"
      @closeModal="closeDeleteEndOfLifeItemModal"
    >
      <template #header> {{ $t('forms.labels.deleteEntry') }} </template>
      <template #body>
        <p>
          {{ $t('pages.endOfLife.deleteConfirmation') }}<br />
          <strong>{{ modalData.data.name }}</strong>
        </p>
        <Buttons layout="stretch">
          <RuButton
            level="secondary"
            theme="default"
            @click="closeDeleteEndOfLifeItemModal"
          >
            {{ $t('forms.labels.cancel') }}
          </RuButton>
          <RuButton
            level="primary"
            theme="error"
            @click="deleteItem(modalData.categoryId, modalData.id)"
          >
            <Icon id="trash" />
            {{ $t('forms.labels.delete') }}
          </RuButton>
        </Buttons>
      </template>
    </Modal>
    <UploadFileModal
      v-if="uploadFileModalIsVisible"
      files-type="any"
      :file-types="fileTypes"
      multiple
      renameable
      @filesChange="uploadFiles"
      @closeModal="closeUploadFileModal"
    />
    <Modal
      v-if="unlinkEndOfLifeItemFileModalIsVisible"
      id="unlinkEndOfLifeItemFile"
    >
      <template #header>
        <Icon id="warning" />
        {{ $t('pages.endOfLife.unlinkFile') }}
      </template>
      <template #body>
        <p>
          {{ $t('pages.endOfLife.unlinkConfirmation') }}
        </p>
        <Buttons layout="stretch">
          <RuButton
            level="secondary"
            @click="closeUnlinkEndOfLifeItemFileModal"
          >
            {{ $t('forms.labels.cancel') }}
          </RuButton>
          <RuButton level="primary" @click="unlinkEndOfLifeItemFile">
            {{ $t('forms.labels.unlink') }}
          </RuButton>
        </Buttons>
      </template>
    </Modal>
    <Snackbar
      v-if="snackbar.isVisible"
      :type="snackbar.type"
      :text="snackbar.text"
      @closeSnackbar="closeSnackbar"
    />
  </div>
</template>

<script>
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import { mapGetters } from 'vuex';

import { Portal, PortalTarget } from 'portal-vue';
import Headline from '@/components/atoms/Headline';
import EndOfLifeFieldset from '@/components/templates/end-of-life/Fieldset';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Snackbar from '@/components/molecules/Snackbar';
import Loading from '@/components/atoms/Loading';
import Alert from '@/components/molecules/Alert';
import Modal from '@/components/molecules/Modal';
import Tabs from '@/components/molecules/Tabs';
import Icon from '@/components/atoms/Icon';
import FileThumbnail from '@/components/atoms/FileThumbnail';
import UploadFileModal from '@/components/templates/UploadFileModal';
import FilesList from '@/components/molecules/FilesList';
import EmptyCategory from '@/components/templates/end-of-life/EmptyCategory';
import ItemList from '@/components/templates/end-of-life/ItemList';
import FormControl from '@/components/molecules/FormControl';
import FormWrapper from '~/components/organisms/Form';
import Tip from '~/components/molecules/Tip';

import endOfLife from '@/mixins/end-of-life';
import files from '@/mixins/files';

import { toTitleCase } from '@/utilities';

import {
  FUNERAL_TYPE_OPTIONS,
  FUNERAL_DIRECTOR_OPTIONS,
  FUNERAL_PREPAID_TYPE_OPTIONS,
} from '@/utilities/end-of-life/form-constants';

const DEFAULT_LAYOUT = 'DEFAULT';

export default {
  name: 'PagesAffiliateEndOfLife',
  components: {
    Portal,
    PortalTarget,
    Headline,
    ItemList,
    EndOfLifeFieldset,
    RuButton,
    Buttons,
    Snackbar,
    Loading,
    Alert,
    Modal,
    Tabs,
    Icon,
    UploadFileModal,
    FilesList,
    EmptyCategory,
    FileThumbnail,
    FormControl,
    FormWrapper,
    Tip,
  },
  mixins: [endOfLife, files],
  layout: 'ruach',
  data() {
    return {
      categoryTabIndex: null,
      subcategoryTabIndex: null,
      loading: false,
      isBusy: false,
      isInvalid: false,
      modalData: null,
      endOfLifeItemsByCategory: {},
      deleteEndOfLifeItemModalIsVisible: false,
      uploadFileModalIsVisible: false,
      unlinkEndOfLifeItemFileModalIsVisible: false,
      snackbar: {
        isVisible: false,
        type: null,
        text: null,
      },
      uploadedFiles: {
        item: {
          files: [],
          loading: null,
          alert: {
            message: null,
          },
        },
        category: {
          files: [],
          loading: null,
          alert: {
            message: null,
          },
        },
      },
      selectedUnlinkFile: null,
      fileTypes: [],
      questions: {
        FUNERAL_PREFERENCE: [
          {
            key: 'funeralType',
            name: 'Preferred funeral type',
            type: 'select',
            options: FUNERAL_TYPE_OPTIONS,
          },
          {
            key: 'funeralDirectorPreference',
            name: 'Preferred funeral director',
            type: 'select',
            options: FUNERAL_DIRECTOR_OPTIONS,
          },
          {
            key: 'funeralProvider',
            name: 'Preferred funeral provider',
            type: 'textarea',
            requirement: {
              key: 'funeralDirectorPreference',
              values: FUNERAL_DIRECTOR_OPTIONS.filter(
                (option) => option !== 'Safewill Cremations'
              ),
            },
          },
          {
            key: 'restingPlacePreference',
            name: 'Final resting place',
            type: 'textarea',
          },
          {
            key: 'serviceLocation',
            name: 'Service location',
            type: 'textarea',
          },
          {
            key: 'eulogy',
            name: 'Eulogy, readings or songs',
            type: 'textarea',
          },
          {
            key: 'dressCode',
            name: 'Dress code',
            type: 'textarea',
          },
          {
            key: 'food',
            name: 'Food to be served',
            type: 'textarea',
          },
          {
            key: 'guestList',
            name: 'Guest list',
            type: 'textarea',
          },
          {
            key: 'other',
            name: 'Other notes',
            type: 'textarea',
          },
        ],
        FUNERAL_PREPAID_POLICY: [
          {
            key: 'type',
            name: 'Prepaid funeral policy type',
            type: 'select',
            options: FUNERAL_PREPAID_TYPE_OPTIONS,
          },
          {
            key: 'institution',
            name: 'Prepaid funeral policy institution',
            type: 'text',
          },
          {
            key: 'other',
            name: 'Other notes',
            type: 'textarea',
          },
        ],
      },
      FUNERAL_TYPE_OPTIONS,
      FUNERAL_DIRECTOR_OPTIONS,
      FUNERAL_PREPAID_TYPE_OPTIONS,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('end-of-life-categories', [
      'endOfLifeCategoryTree',
      'endOfLifeCategorySlugsToIds',
      'endOfLifeCategoryItemCounts',
    ]),
    isQuestionLayout() {
      return ['FUNERAL_PREFERENCE', 'FUNERAL_PREPAID_POLICY'].includes(
        this.activeDataLayout
      );
    },
    fileUploadsAllowed() {
      return ![
        'FUNERAL_PREFERENCE',
        'FUNERAL_PREPAID_POLICY',
        'CONTACT',
      ].includes(this.activeDataLayout);
    },
    isLoading() {
      return (
        !this.endOfLifeCategoryTree.length &&
        !Object.keys(this.endOfLifeCategorySlugsToIds) &&
        !Object.keys(this.endOfLifeCategoryItemCounts)
      );
    },
    rootCategory() {
      return (
        this.endOfLifeCategories[
          this.endOfLifeCategorySlugsToIds[this.rootCategorySlug]
        ] || {}
      );
    },
    rootCategorySlug() {
      return this.$route.params.rootcategory;
    },
    categorySlug() {
      return this.$route.params.category;
    },
    subcategorySlug() {
      return this.$route.params.subcategory;
    },
    displayCategoryTree() {
      const rootCategory = this.endOfLifeCategoryTree.find(
        (category) => category.slug === this.rootCategorySlug
      );
      return rootCategory?.subcategories || [];
    },
    categoryDataHasLoaded() {
      return (
        !!this.endOfLifeCategoryTree &&
        !!this.endOfLifeCategoryTree?.length &&
        !!this.displayCategoryTree.length
      );
    },
    showCategoriesTabs() {
      return (
        this.endOfLifeCategoryTree.length && this.displayCategoryTree.length
      );
    },
    showSubcategoriesTabs() {
      if (!this.endOfLifeCategoryTree.length) {
        return false;
      }
      const category = this.displayCategoryTree.find(
        (category) => category.slug === this.categorySlug
      );
      return !!category?.subcategories?.length;
    },
    endOfLifeItemModalIsVisible() {
      return (
        this.modalData &&
        !this.deleteEndOfLifeItemModalIsVisible &&
        !this.uploadFileModalIsVisible &&
        !this.unlinkEndOfLifeItemFileModalIsVisible
      );
    },
    activeSubcategoryId() {
      const subcategory =
        this.$route.params.subcategory ||
        this.endOfLifeCategoryTree[0].subcategories[0].slug;
      if (!subcategory) {
        return null;
      }
      return this.endOfLifeCategorySlugsToIds[subcategory];
    },
    slugsJoined() {
      const joinedSlugOrder = [
        this.$route.params.rootcategory,
        this.$route.params.category,
        this.$route.params.subcategory,
      ];
      return (
        joinedSlugOrder
          .filter((slug) => slug)
          .join('__')
          .replaceAll('-', '_') || null
      );
    },
    activeDataLayout() {
      const joinedSlugToLayoutMapping = {
        personal__identification__birth_certificate: 'BIRTH_CERTIFICATE',
        personal__identification__citizenship: 'RESIDENCE',
        personal__identification__government_concession:
          'GOVERNMENT_CONCESSION',
        personal__identification__licence: 'LICENCE',
        personal__identification__medicare: 'MEDICARE',
        personal__identification__passport: 'PASSPORT',
        personal__identification__proof_of_residence: 'RESIDENCE',
        personal__identification__relationship: 'RELATIONSHIP',
        personal__identification__taxation: 'TAXATION',
        personal__identification__visa: 'RESIDENCE',
        legal__estate_planning__guardianship: 'GUARDIANSHIP',
        legal__estate_planning__power_of_attorney: 'POWER_OF_ATTORNEY',
        legal__estate_planning__will: 'WILL',
        financial__assets__bank_accounts: 'BANK_ACCOUNT',
        financial__assets__business: 'BUSINESS',
        financial__assets__financial_assets: 'FINANCIAL_ASSETS',
        financial__assets__insurances: 'INSURANCE',
        financial__assets__assets_other: DEFAULT_LAYOUT,
        financial__assets__properties: 'PROPERTY',
        financial__assets__superannuation: 'SUPERANNUATION',
        financial__assets__trust: 'TRUST',
        financial__assets__valuables: 'FINANCIAL_VALUABLE',
        financial__assets__vehicles: 'VEHICLE',
        financial__income__employment: 'EMPLOYMENT',
        financial__income__government_benefits: 'GOVERNMENT_BENEFIT',
        financial__liabilities__credit_cards: 'CREDIT_CARD',
        financial__liabilities__loans: 'LOAN',
        financial__liabilities__liabilities_other: DEFAULT_LAYOUT,
        financial__recurring__donations: 'DONATION',
        financial__recurring__subscriptions: 'SUBSCRIPTION',
        financial__recurring__utilities: 'UTILITY',
        medical__medical_equipment: 'MEDICAL_DEVICE',
        medical__organ_donation: 'ORGAN_DONATION',
        funeral__funeral_other: DEFAULT_LAYOUT,
        funeral__preferences: 'FUNERAL_PREFERENCE',
        funeral__prepaid_policies: 'FUNERAL_PREPAID_POLICY',
        emotional__message: 'EMOTIONAL_MESSAGE',
        emotional__story: 'EMOTIONAL_STORY',
        key_contacts__accountants: 'CONTACT',
        key_contacts__doctors: 'CONTACT',
        key_contacts__financial_advisors: 'CONTACT',
        key_contacts__lawyers: 'CONTACT',
        key_contacts__key_contacts_other: 'CONTACT',
      };
      return joinedSlugToLayoutMapping[this.slugsJoined] || DEFAULT_LAYOUT;
    },
    institutionType() {
      const slugToInstitutionType = {
        financial__assets__bank_accounts: 'Bank',
        financial__assets__superannuation: 'Superannuation',
        financial__assets__insurances: 'Insurance',
        financial__liabilities__credit_cards: 'Credit Card',
        financial__liabilities__loans: 'Loan',
        financial__income__government_benefits: 'Government Benefits',
        financial__recurring__internet: 'Internet',
        financial__recurring__mobiles: 'Mobile',
        financial__recurring__subscriptions: 'Subscription',
        financial__recurring__utilities: 'Utilities',
        liabilities__personal_loans: 'Personal Loan',
        financial__recurring__recurring_other: 'Subscription',
      };
      return slugToInstitutionType[this.slugsJoined] || '';
    },
    activeCategory() {
      if (this.subcategorySlug) {
        return this.endOfLifeCategories[
          this.endOfLifeCategorySlugsToIds[this.subcategorySlug]
        ];
      }
      if (this.categorySlug) {
        return this.endOfLifeCategories[
          this.endOfLifeCategorySlugsToIds[this.categorySlug]
        ];
      }
      if (this.rootCategorySlug) {
        return this.endOfLifeCategories[
          this.endOfLifeCategorySlugsToIds[this.rootCategorySlug]
        ];
      }
      return null;
    },
    unansweredQuestions() {
      const answeredQuestions =
        this.endOfLifeItemsByCategory[this.activeCategory.id]?.map(
          (item) => item.data
        ) || [];
      const answeredQuestionsKeys = new Set(
        answeredQuestions.map((item) => item.key)
      );
      return (
        this.questions[this.activeDataLayout]?.filter((question) => {
          if (!answeredQuestionsKeys.has(question.key)) {
            if (!question.requirement) {
              return true;
            } else {
              const requirementAnswer = answeredQuestions.find(
                (item) => item.key === question.requirement.key
              );
              if (
                requirementAnswer &&
                question.requirement.values.includes(requirementAnswer.value)
              ) {
                return true;
              }
            }
          }
          return false;
        }) || []
      );
    },
    currentQuestion() {
      if (!this.isQuestionCategory) {
        return null;
      }
      if (this.modalData?.type === 'update') {
        return this.questions[this.activeDataLayout].find(
          ({ key }) => key === this.modalData?.data?.key
        );
      }
      return this.unansweredQuestions[0] || null;
    },
    isQuestionCategory() {
      return ['FUNERAL_PREFERENCE', 'FUNERAL_PREPAID_POLICY'].includes(
        this.activeDataLayout
      );
    },
    addItemButtonIsVisible() {
      return !this.isQuestionCategory || this.unansweredQuestions?.length > 0;
    },
    questionNextText() {
      if (this.unansweredQuestions?.length === 1) {
        if (!this.modalData?.data?.value) {
          return 'Skip and close';
        } else {
          return 'Save and close';
        }
      }
      if (!this.modalData?.data?.value) {
        return 'Skip question';
      }
      return 'Next question';
    },
    filesTypeString() {
      const filesTypeString = this.fileTypes
        .map((fileType) => {
          return startCase(camelCase(fileType.text));
        })
        .join(', ');
      const comma = ', ';
      const position = filesTypeString.lastIndexOf(comma);
      if (position === -1) {
        return filesTypeString;
      }
      return (
        filesTypeString.substring(0, position) +
        ', or ' +
        filesTypeString.substring(position + comma.length)
      );
    },
  },
  watch: {
    categoryDataHasLoaded(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setCategories();
      }
    },
    '$route.params': {
      handler: 'setCategories',
      immediate: true,
    },
  },
  methods: {
    checkAndRedirectToChildCategory() {
      if (!this.endOfLifeCategoryTree.length) {
        return;
      }
      let rootcategory = null;
      let category = null;
      let subcategory = null;
      rootcategory =
        this.endOfLifeCategoryTree.find(
          (category) => category.slug === this.rootCategorySlug
        ) || this.endOfLifeCategoryTree[0];
      if (rootcategory) {
        category =
          rootcategory.subcategories.find(
            (category) => category.slug === this.categorySlug
          ) || rootcategory?.subcategories[0];
      }
      if (category) {
        subcategory =
          category.subcategories.find(
            (category) => category.slug === this.subcategorySlug
          ) || category?.subcategories[0];
      }
      const redirectPath =
        '/end-of-life/' +
        [rootcategory?.slug, category?.slug, subcategory?.slug]
          .filter((category) => category)
          .join('/');
      this.$router.push({
        path: redirectPath,
      });
    },
    async setCategories() {
      if (!this.endOfLifeCategoryTree.length) {
        return;
      }
      this.checkAndRedirectToChildCategory();
      if (!this.rootCategorySlug) {
        this.$router.push({
          path: `/end-of-life/${this.endOfLifeCategoryTree[0].slug}`,
        });
      }
      this.loading = true;
      const categorySlug =
        this.categorySlug || this.displayCategoryTree[0].slug;
      const category = this.displayCategoryTree.find(
        (category) => category.slug === categorySlug
      );
      if (category && !category?.subcategories?.length) {
        await this.refetchCategoryItems(category.id);
      } else if (category && category?.subcategories?.length) {
        const subcategorySlug =
          this.$route.params.subcategory ||
          this.displayCategoryTree[0].subcategories[0].slug;
        const subcategoryId = this.endOfLifeCategorySlugsToIds[subcategorySlug];
        await this.refetchCategoryItems(subcategoryId);
      }
      this.getFileTypes();
      this.getAllUploadedFiles();
      this.updateTabsIndexes();
      this.loading = false;
    },
    updateTabsIndexes() {
      if (!this.rootCategorySlug || !this.categorySlug) {
        return;
      }
      const categoryIndex = this.displayCategoryTree.findIndex(
        (category) => category.slug === this.categorySlug
      );
      const subcategoryIndex = this.displayCategoryTree[
        categoryIndex
      ].subcategories.findIndex(
        (subcategory) => subcategory.slug === this.subcategorySlug
      );
      this.categoryTabIndex = categoryIndex;
      this.subcategoryTabIndex = subcategoryIndex;
    },
    categoryTabSelected(categoryIndex, subcategoryIndex) {
      let redirectPath = `/end-of-life/${this.rootCategorySlug}`;
      const categorySlug = this.displayCategoryTree[categoryIndex].slug;
      const subcategorySlug =
        this.displayCategoryTree[categoryIndex].subcategories[subcategoryIndex]
          ?.slug;
      if (subcategorySlug) {
        redirectPath += `/${categorySlug}/${subcategorySlug}`;
      } else {
        redirectPath += `/${categorySlug}`;
      }
      this.$router.push({
        path: redirectPath,
      });
    },
    async getEndOfLifeItems(categoryId, dataLayout) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      let endOfLifeItems = await this.getEndOfLifeItemsByCategoryIdAndUserId(
        categoryId,
        this.userId
      );
      endOfLifeItems = Promise.all(
        endOfLifeItems.map((item) => {
          return this.revealPrivacyVaultData(item, dataLayout);
        })
      );

      return endOfLifeItems;
    },
    revealPrivacyVaultData(endOfLifeItem, dataLayout) {
      return this.$sensitive.EndOfLifeItem[dataLayout].reveal(
        endOfLifeItem.id,
        endOfLifeItem
      );
    },
    addItem(categoryId, categoryName) {
      this.modalData = {
        type: 'CREATE_VAULT',
        header: `Add ${categoryName}`,
        categoryId,
        data: {},
        fileIds: [],
      };
    },
    closeModal() {
      this.modalData = null;
    },
    async saveItem() {
      const { categoryId } = this.modalData;
      this.isBusy = true;
      try {
        if (this.modalData.type === 'CREATE_VAULT') {
          this.snackbar.text = 'Successfully added';
          const newEndOfLifeItem =
            await this.createEndOfLifeItemWithSensitiveData({
              userId: this.userId,
              categoryId,
              dataLayout: this.activeDataLayout,
              itemData: this.modalData.data,
              fileIds: this.modalData.fileIds,
            });

          this.$set(this.endOfLifeItemsByCategory, categoryId, [
            ...(this.endOfLifeItemsByCategory[categoryId] || []),
            await this.revealPrivacyVaultData(
              newEndOfLifeItem,
              this.activeDataLayout
            ),
          ]);
        } else if (this.modalData.type === 'update') {
          this.snackbar.text = 'Successfully updated';
          const updatedItem = await this.updateEndOfLifeItemWithSensitiveData({
            itemId: this.modalData.id,
            categoryId: this.modalData.categoryId,
            dataLayout: this.activeDataLayout,
            userId: this.modalData.userId,
            itemData: this.modalData.data,
            fileIds: this.modalData.fileIds,
          });
          const currentEndOfLifeItemIndex = this.endOfLifeItemsByCategory[
            this.modalData.categoryId
          ].findIndex((item) => item.id === updatedItem.id);
          this.endOfLifeItemsByCategory[this.modalData.categoryId].splice(
            currentEndOfLifeItemIndex,
            1,
            await this.revealPrivacyVaultData(
              updatedItem,
              this.activeDataLayout
            )
          );
        }
        if (this.isQuestionCategory && this.modalData.type === 'CREATE_VAULT')
          return;
        this.isBusy = false;
        this.openSnackbar('success');
        this.closeModal();
      } catch (e) {
        this.isBusy = false;
        this.closeModal();
        this.snackbar.text = 'Something went wrong, please try again';
        this.openSnackbar('error');
      }
    },
    editItem(categoryId, itemId) {
      const item = this.endOfLifeItemsByCategory[categoryId].find(
        (item) => item.id === itemId
      );
      const data = item.data;
      this.modalData = {
        header: `Update ${data.name}`,
        id: itemId,
        categoryId,
        userId: this.userId,
        data: {
          ...data,
        },
        fileIds: item.fileIds,
        type: 'update',
      };
      this.getAllUploadedFiles();
    },
    async deleteItem(categoryId, itemId) {
      this.snackbar.text = 'Successfully deleted';
      await this.deleteEndOfLifeItem(categoryId, itemId);
      this.endOfLifeItemsByCategory[categoryId] = this.endOfLifeItemsByCategory[
        categoryId
      ].filter((item) => item.id !== itemId);
      this.closeDeleteEndOfLifeItemModal();
      this.openSnackbar('success');
      this.closeModal();
    },
    openDeleteEndOfLifeItemModal() {
      this.deleteEndOfLifeItemModalIsVisible = true;
    },
    closeDeleteEndOfLifeItemModal() {
      this.deleteEndOfLifeItemModalIsVisible = false;
    },
    openSnackbar(type) {
      this.snackbar.type = type;
      this.snackbar.isVisible = true;
    },
    closeSnackbar() {
      this.snackbar.isVisible = false;
    },
    openUploadFileModal() {
      this.uploadFileModalIsVisible = true;
    },
    closeUploadFileModal() {
      this.uploadFileModalIsVisible = false;
    },
    openUnlinkEndOfLifeItemFileModal(fileId) {
      this.unlinkEndOfLifeItemFileId = fileId;
      this.unlinkEndOfLifeItemFileModalIsVisible = true;
    },
    closeUnlinkEndOfLifeItemFileModal() {
      this.unlinkEndOfLifeItemFileModalIsVisible = false;
    },
    async uploadFiles(files, fileType, fileName) {
      if (this.modalData) {
        this.uploadedFiles.item.alert.message = null;
        this.uploadedFiles.item.loading = true;
        this.uploadEndOfLifeItemFiles({
          files,
          fileType,
          fileName,
        });
      } else {
        this.uploadedFiles.category.alert.message = null;
        this.uploadedFiles.category.loading = true;
        const { success } = await this.uploadEndOfLifeCategoryFiles({
          categoryId: this.activeSubcategoryId,
          files,
          fileType,
          fileName,
        });
        if (success) {
          await this.getAllUploadedFiles();
        } else {
          this.uploadedFiles.category.alert.message =
            'Oh no! Something went wrong while uploading your files.';
        }
        this.uploadedFiles.category.loading = false;
      }
    },
    async getAllUploadedFiles() {
      if (!this.activeSubcategoryId) {
        return;
      }
      this.uploadedFiles.category.loading = true;
      this.uploadedFiles.category.alert.message = null;
      this.uploadedFiles.category.files = [];
      try {
        this.uploadedFiles.category.files = [
          ...(await this.getEOLCategoryFiles(this.activeSubcategoryId)),
        ].reverse();
      } catch (error) {
        this.uploadedFiles.category.alert.message =
          'Oh no! Something went wrong while fetching your uploaded files.';
      } finally {
        this.uploadedFiles.category.loading = false;
      }

      this.uploadedFiles.item.loading = true;
      this.uploadedFiles.item.alert.message = null;
      this.uploadedFiles.item.files = [];
      try {
        const fileIds = this.modalData?.fileIds || [];
        const files = await this.getFilesByIds(fileIds);
        this.uploadedFiles.item.files = [
          ...files.map((file) => ({
            ...file,
            unlinkable: true,
            mutable: false,
          })),
        ].reverse();
      } catch (error) {
        this.uploadedFiles.item.alert.message =
          'Oh no! Something went wrong while fetching your uploaded files.';
      } finally {
        this.uploadedFiles.item.loading = false;
      }
    },
    async uploadEndOfLifeItemFiles({ files, fileType, fileName }) {
      this.uploadedFiles.item.loading = true;
      this.uploadedFiles.item.alert.message = null;
      const uploadedFileIds = this.modalData?.fileIds || [];
      try {
        await Promise.all(
          Object.values(files).map(async (file) => {
            const getFileUploadUrlData = await this.getFileUploadUrl(
              file,
              fileType,
              fileName
            );
            await this.uploadFile(getFileUploadUrlData.url, file);
            await this.associateUploadedFile(getFileUploadUrlData.id, 'NONE');
            uploadedFileIds.push(getFileUploadUrlData.id);
          })
        );
        this.modalData.fileIds = uploadedFileIds;
      } catch (error) {
        this.uploadedFiles.item.alert.message =
          'Oh no! Something went wrong while fetching your uploaded files.';
      } finally {
        this.uploadedFiles.item.loading = false;
      }
      this.getAllUploadedFiles();
    },
    unlinkEndOfLifeItemFile() {
      this.modalData.fileIds = this.modalData.fileIds.filter(
        (id) => !this.unlinkEndOfLifeItemFileId.includes(id)
      );
      this.uploadedFiles.item.files = this.uploadedFiles.item.files.filter(
        (file) => !this.unlinkEndOfLifeItemFileId.includes(file.id)
      );
      this.closeUnlinkEndOfLifeItemFileModal();
    },
    async getFileTypes() {
      const fileTypes = await this.getFileTypesByCategoryId(
        this.activeCategory.id
      );
      this.fileTypes = fileTypes
        .sort((a, b) => {
          if (a.key < b.key) {
            return -1;
          }
          if (a.key > b.key) {
            return 1;
          }
          return 0;
        })
        .map((fileType) => ({
          value: fileType.key,
          text: toTitleCase(fileType.key),
        }));
    },
    async unlinkEndOfLifeCategoryFile(fileId) {
      this.uploadedFiles.category.loading = true;
      this.uploadedFiles.category.alert.message = null;
      try {
        await this.deleteEOLCategoryFile({
          categoryId: this.activeSubcategoryId,
          fileId,
        });
      } catch (error) {
        this.uploadedFiles.category.alert.message =
          'Oh no! Something went wrong while deleting your uploaded file.';
      } finally {
        this.uploadedFiles.category.loading = false;
      }

      this.uploadedFiles.category.files =
        this.uploadedFiles.category.files.filter((file) => file.id !== fileId);
    },
    async refetchCategoryItems(categoryId) {
      await this.$set(
        this.endOfLifeItemsByCategory,
        categoryId,
        await this.getEndOfLifeItems(categoryId, this.activeDataLayout)
      );
    },
    async saveAnswer() {
      await this.saveItem();
      if (this.unansweredQuestions?.length === 0) {
        this.closeModal();
        return;
      }
      this.modalData = {
        type: 'CREATE_VAULT',
        header: `Add ${this.activeCategory.name}`,
        categoryId: this.activeCategory.id,
        data: {
          key: this.currentQuestion.key,
          name: this.currentQuestion.name,
          value: null,
        },
      };
    },
    setItemField(key, value) {
      this.modalData.data[key] = value;
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &end-of-life {
    &__headline {
      &-icon {
        display: none;
        @include mq('min-md') {
          display: inline;
        }
      }

      &-button {
        display: inline;
        #{$ru}icon {
          margin-right: 0;
        }
        @include mq('min-md') {
          display: none;
        }
      }
    }

    &__items {
      padding-bottom: var(--base-margin);
    }

    &__empty {
      text-align: center;
      padding-top: var(--base-margin-big);
      #{$ru}image {
        margin-top: var(--base-margin);
      }
    }

    &__files {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      gap: var(--base-margin);

      &-wrapper {
        border-top: 1px solid --rgba(iron);
        padding-top: var(--base-margin);
        margin-top: var(--base-margin);
      }

      #{$ru}file-control {
        width: 100%;
      }
    }

    &__file {
      background-color: --rgba(white);
      border-radius: var(--border-radius);
      border: 1px solid --rgba(iron);
      padding: var(--base-margin-small) 0;

      &:hover {
        background: --rgba(iron);
      }
    }

    &__upload-files {
      background-color: --rgba(white);
      border: 1px solid --rgba(iron);
      color: --rgba(rhino);
      border-radius: var(--border-radius);
      padding: var(--base-margin-small);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--base-margin-small);
      width: 100%;
    }

    &__files-loading {
      display: flex;
      flex-direction: row;
      margin-bottom: var(--base-margin);

      svg {
        margin-right: var(--base-margin);
      }
    }

    &__item-files {
      margin-bottom: var(--base-margin);

      &-upload {
        display: flex;
        flex-direction: row;
      }
    }
  }

  &tab-title {
    &__subtitle {
      color: --rgba(dust);
      display: inline-block;
      margin-left: var(--base-margin-small);
      width: var(--base-line-height);
      border-radius: 99rem;
    }

    &:is(#{$mf}selected) &__subtitle {
      background: --rgba(darker);
      color: --rgba(white);
    }
  }

  &tabs__titles {
    &:is(#{$mf}underline) {
      margin-bottom: var(--base-margin-big);

      @include mq('min-md') {
        margin-bottom: var(--base-margin);
      }
    }
  }
}
</style>
