var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":5,"prev-step":"/will/about-yourself/address"}},[_c('AdiForm',{attrs:{"mutation":_vm.UPDATE_WILL_MUTATION,"variables":_vm.UPDATE_WILL_VARS,"disabled":!_vm.isComplete,"auto-submit":_vm.isComplete && !_vm.willMeta.has_partner},on:{"done":_vm.done},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.aboutYourSelf.partner.question'))+"\n    ")]),_vm._v(" "),_c('Tip',[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.aboutYourSelf.partner.tip'))+"\n      "),_c('GlossaryTooltip',{attrs:{"term":"deFactoDomesticRelationship"}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.aboutYourSelf.partner.subTip'))+"\n      ")])],1),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"hasPartner","options":[
        {
          label: _vm.$t('forms.labels.yes'),
          value: true,
        },
        {
          label: _vm.$t('forms.labels.no'),
          value: false,
        },
      ]},model:{value:(_vm.willMeta.has_partner),callback:function ($$v) {_vm.$set(_vm.willMeta, "has_partner", $$v)},expression:"willMeta.has_partner"}}),_vm._v(" "),(_vm.willMeta.has_partner)?[(!_vm.hidePartnerDiscountTip)?_c('Box',{attrs:{"background":"white","shadow":""}},[_c('Headline',{attrs:{"level":3}},[_c('Icon',{attrs:{"id":"tag"}}),_vm._v("\n          "+_vm._s(_vm.$t('texts.sentences.partnerDiscount'))+"\n        ")],1),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('pages.will.aboutYourSelf.partner.discountNotice'))+"\n        ")])],1):_vm._e(),_vm._v(" "),_c('Headline',{attrs:{"level":2}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.will.aboutYourSelf.partner.partnerSelect.headline'))+"\n      ")]),_vm._v(" "),_c('PersonSelector',{attrs:{"loading":loading,"max":1,"people":_vm.filteredPeople,"type":"partner"},model:{value:(_vm.selectedPartners),callback:function ($$v) {_vm.selectedPartners=$$v},expression:"selectedPartners"}})]:_vm._e()]}}])}),_vm._v(" "),_c('GlossaryPanel')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }