<template>
  <nuxt-child />
</template>

<script>
export default {
  name: 'PagesAffiliateAdmin',
  layout: 'admin',
};
</script>
