var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleStep',{attrs:{"index":1,"prev-step":"/will"}},[_c('AdiForm',{on:{"submit":_vm.onSubmit}},[_c('Headline',{attrs:{"level":1}},[_vm._v("\n      "+_vm._s(_vm.$t('titles.funeral'))+"\n    ")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('Headline',{attrs:{"level":2}},[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.funeral.funeralType'))+"\n    ")]),_vm._v(" "),_c('p',[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.funeral.executorInstructions'))+"\n    ")]),_vm._v(" "),_c('Tip',[_vm._v("\n      "+_vm._s(_vm.$t('pages.will.funeral.funeralDescription'))+"\n    ")]),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"funeral_type","layout":"column","options":_vm.options[0]},on:{"input":_vm.onCremationClick},model:{value:(_vm.willMeta.funeral_type),callback:function ($$v) {_vm.$set(_vm.willMeta, "funeral_type", $$v)},expression:"willMeta.funeral_type"}}),_vm._v(" "),(_vm.showPrepaidCremationModal)?_c('PrepaidCremationModal',{attrs:{"request-quote-or-interest-text":_vm.requestQuoteOrInterestText},on:{"closeModal":_vm.closePrepaidCremationModal,"requestQuote":_vm.requestPrepaidCremationQuote}}):_vm._e(),_vm._v(" "),(_vm.userHasPrepaidCremationInterest && _vm.userHasSelectedCremation)?_c('Alert',{attrs:{"level":"medium","theme":"success","icon":"check"}},[_c('div',{staticClass:"ru:addon__success"},[_vm._v(_vm._s(_vm.successStateText))])]):(_vm.addonVisible)?_c('Box',{attrs:{"background":"white","border":"aluminium"}},[_c('div',{staticClass:"ru:addon__close"},[_c('Buttons',{attrs:{"theme":"default","layout":"end"}},[_c('RuButton',{attrs:{"level":"plain"},on:{"click":_vm.hideAddon}},[_c('Icon',{attrs:{"id":"close"}})],1)],1)],1),_vm._v(" "),_c('Headline',{staticClass:"ru:addon__headline",attrs:{"big":"","level":3,"row":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.will.funeral.components.addon.titles.prepaid')))])]),_vm._v(" "),_c('div',{staticClass:"ru:addon__content"},[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t(
              'pages.will.funeral.components.addon.text.descriptionFirstParagraph'
            ))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t(
              'pages.will.funeral.components.addon.text.descriptionSecondParagraph'
            ))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t(
              'pages.will.funeral.components.addon.text.descriptionThirdParagraph'
            ))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"ru:addon__explanation"},[_c('Buttons',{attrs:{"layout":"start"}},[_c('RuButton',{attrs:{"level":"plain","theme":"slate","underline":""},on:{"click":_vm.openPrepaidCremationModal}},[_vm._v("\n            "+_vm._s(_vm.$t('pages.will.funeral.components.addon.buttons.explanation'))+"\n          ")])],1)],1),_vm._v(" "),_c('Tooltip',{attrs:{"disabled":_vm.hasCorrectDataForPrepaidCremation,"class-name":"ru:addon__tooltip","max-width-class":"max-w-2xl","content":_vm.$t('pages.will.funeral.components.addon.tips.actionDisabled')}},[_c('Buttons',{attrs:{"layout":"start"}},[_c('RuButton',{attrs:{"level":"secondary","theme":"slate","wide":"","disabled":!_vm.hasCorrectDataForPrepaidCremation || _vm.addonLoading},on:{"click":_vm.requestPrepaidCremationQuote}},[(_vm.addonLoading)?_c('Loading'):_vm._e(),_vm._v("\n            "+_vm._s(_vm.requestQuoteOrInterestText)+"\n          ")],1)],1)],1)],1):_vm._e(),_vm._v(" "),_c('LargeRadioButtons',{attrs:{"id":"funeral_type","layout":"column","options":_vm.options[1]},on:{"input":_vm.onSubmit},model:{value:(_vm.willMeta.funeral_type),callback:function ($$v) {_vm.$set(_vm.willMeta, "funeral_type", $$v)},expression:"willMeta.funeral_type"}})],1),_vm._v(" "),(_vm.snackbarIsVisible)?_c('Snackbar',{attrs:{"type":_vm.snackbarType,"text":_vm.snackbarText},on:{"closeSnackbar":_vm.closeSnackbar}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }