<template>
  <div class="ru:container">
    <Headline :level="1">
      <small>{{ $t('pages.cremations.cremationService') }}</small>
      {{ $t('pages.cremations.yourLovedOne') }}
    </Headline>
    <p>
      {{ $t('pages.cremations.checkAboutYourselfDetails') }}
    </p>
    <FormWrapper @submit="saveUpdatedDetails('initial')">
      <Headline :level="3">{{ $t('texts.sentences.theirName') }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--md cell__3/12--lg">
          <FormControl
            id="guestFirstName"
            v-model="formData.guestFirstName"
            type="text"
            :text="$t('forms.labels.firstName')"
            :pattern="FIRST_NAME_PATTERN.regex"
            :title="$t(FIRST_NAME_PATTERN.description)"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__3/12--lg">
          <FormControl
            id="guestOtherGivenNames"
            v-model="formData.guestOtherGivenNames"
            type="text"
            :text="$t('forms.labels.otherGivenNames')"
            :required="false"
            :optional="true"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__3/12--lg">
          <FormControl
            id="guestLastName"
            v-model="formData.guestLastName"
            type="text"
            :text="$t('forms.labels.lastName')"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__3/12--lg">
          <FormControl
            id="guestLastNameAtBirth"
            v-model="formData.guestLastNameAtBirth"
            type="text"
            :text="$t('forms.labels.lastNameAtBirth')"
          />
        </div>
      </div>
      <Headline :level="3">{{ $t('texts.sentences.theirDetails') }}</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--md cell__3/12--lg">
          <FormControl
            id="gender"
            v-model="formData.guestSex"
            type="select"
            :text="$t('forms.labels.sex')"
            :options="SEX_OPTIONS"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--md cell__3/12--lg">
          <FormControl
            id="guestDateOfBirth"
            v-model="formData.guestDateOfBirth"
            type="date"
            format="date"
            :text="$t('forms.labels.dateOfBirth')"
          />
        </div>
      </div>
      <Headline :level="3">Their Address</Headline>
      <div class="grid ru:form__fieldset">
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="guestAddressLine1"
            v-model="formData.guestAddressLine1"
            type="text"
            :text="$t('forms.labels.addressLine1')"
          />
        </div>
        <div class="cell cell__12/12 cell__6/12--lg">
          <FormControl
            id="guestAddressLine2"
            v-model="formData.guestAddressLine2"
            :required="false"
            :optional="true"
            type="text"
            :text="$t('forms.labels.addressLine2')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="guestAddressSuburb"
            v-model="formData.guestAddressSuburb"
            type="text"
            :text="$t('forms.labels.suburb')"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="guestAddressState"
            v-model="formData.guestAddressState"
            type="select"
            :text="$t('forms.labels.australianState')"
            :options="$auStatesOptions"
          />
        </div>
        <div class="cell cell__12/12 cell__4/12--lg">
          <FormControl
            id="guestAddressPostcode"
            v-model="formData.guestAddressPostcode"
            type="text"
            :text="$t('forms.labels.4digitPostcode')"
            inputmode="decimal"
            min="4"
            max="4"
            pattern="[0-9]{4}"
          />
        </div>
      </div>
      <Buttons>
        <RuButton :href="prevPage" level="secondary">
          {{ $t('forms.labels.back') }}
        </RuButton>
        <RuButton aria-label="Submit" button-type="submit" level="primary">
          {{ $t('forms.labels.next') }}
        </RuButton>
      </Buttons>
    </FormWrapper>
    <BaseModal v-if="showConfirmModal" @close="toggleConfirmModal">
      <h3>{{ $t('texts.sentences.detailsUpdate') }}</h3>
      <p>{{ $t('pages.cremations.updateDetailsSuccess') }}</p>
      <div class="flex items-baseline mt-8">
        <RuButton
          background="wine"
          color="white"
          @click="saveUpdatedDetails('initial')"
        >
          {{ $t('forms.labels.accept') }}
        </RuButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AtNeedCremations from '@/mixins/at-need-cremations';

import BaseModal from '@/components/BaseModal';
import RuButton from '@/components/atoms/Button';
import Buttons from '@/components/atoms/Buttons';
import Headline from '@/components/atoms/Headline';
import FormWrapper from '@/components/organisms/Form';
import FormControl from '@/components/molecules/FormControl';

import { FIRST_NAME_PATTERN } from '@/utilities/cremations/form-constants';

export default {
  name: 'PagesAffiliateCremationsAtNeedDeceasedId',
  components: {
    BaseModal,
    RuButton,
    Buttons,
    Headline,
    FormWrapper,
    FormControl,
  },
  mixins: [AtNeedCremations],
  data() {
    return {
      formData: {
        guestFirstName: null,
        guestOtherGivenNames: null,
        guestLastName: null,
        guestLastNameAtBirth: null,
        guestDateOfBirth: null,
        guestSex: null,
        guestAddressLine1: null,
        guestAddressLine2: null,
        guestAddressSuburb: null,
        guestAddressState: null,
        guestAddressPostcode: null,
      },
      FIRST_NAME_PATTERN,
    };
  },
  computed: {
    nextPage() {
      return this.localePath(
        `/cremations/at-need/passing/${this.cremationRequestId}`
      );
    },
    prevPage() {
      return this.localePath(`/cremations/at-need/${this.cremationRequestId}`);
    },
  },
};
</script>
