<template>
  <div class="grid">
    <div class="cell cell__12/12">
      <div class="grid grid--middle">
        <div class="cell cell__fill">
          <Headline flush :level="1"> Generic Checkout </Headline>
        </div>
      </div>
      <AdminCard tight>
        <div
          v-for="(product, index) in selectedProducts"
          :key="index"
          class="grid"
        >
          <div class="cell cell__6/12">
            <FormControl
              :id="`${index}_product_name`"
              v-model="product.name"
              type="select"
              text="Product"
              :options="allCustomProducts"
            />
          </div>
          <div class="cell cell__6/12">
            <FormControl
              :id="`${index}_product_price`"
              v-model="product.price"
              text="Price (AUD)"
              type="text"
              inputmode="decimal"
              @change="(e) => setProductPrice(index, e)"
            />
          </div>
        </div>
      </AdminCard>
      <Buttons layout="start">
        <RuButton level="primary" @click="generatePaymentLink">
          Generate payment link
        </RuButton>
      </Buttons>
      <div v-if="generatedLink" class="pt-4">
        <Alert theme="success" level="medium">
          {{ generatedLink }}
        </Alert>
        <Buttons layout="start">
          <RuButton level="primary" @click="copyLink"> Copy Link </RuButton>
        </Buttons>
        <Toast />
      </div>
    </div>
  </div>
</template>

<script>
import { encodePayloadForUrl, getBaseUrl } from '@/utilities';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import FormControl from '@/components/molecules/FormControl';
import Toast from '@/components/Toast';
import AdminCard from '@/components/admin/Card';
import Alert from '@/components/molecules/Alert';
import Headline from '@/components/atoms/Headline';

export default {
  name: 'PagesAffiliateAdminGenericCheckoutIndex',
  components: {
    Buttons,
    RuButton,
    FormControl,
    Toast,
    AdminCard,
    Alert,
    Headline,
  },
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  data() {
    return {
      allCustomProducts: [
        {
          value: 'AT_NEED_CREMATION',
          text: 'forms.labels.atNeedCremation',
        },
        {
          value: 'LEGAL_ADVICE',
          text: 'forms.labels.legalAdvice',
        },
      ],
      pricedProduct: {
        name: null,
        price: 0,
      },
      selectedProducts: [{ ...this.pricedProduct }],
      generatedLink: null,
    };
  },
  methods: {
    addProduct() {
      this.selectedProducts = [
        ...this.selectedProducts,
        { ...this.pricedProduct },
      ];
    },
    setProduct(index, event) {
      this.selectedProducts[index].product = event.target.value;
    },
    setProductPrice(index, event) {
      this.selectedProducts[index].price = event.target.value;
    },
    generatePaymentLink() {
      this.generatedLink = null;
      const allFieldsPresent = this.selectedProducts.reduce(
        (previous, current) => {
          return previous && !!current.name && !!current.price;
        },
        true
      );
      if (!allFieldsPresent) {
        this.$nuxt.$emit('toast', {
          type: 'error',
          message: 'All fields are required to generate link',
        });
        return;
      }

      const link = encodePayloadForUrl({
        customProducts: this.selectedProducts.map(({ name, price }) => ({
          name,
          price: price * 100,
        })),
      });
      this.generatedLink =
        getBaseUrl() + `/checkout/generic?checkoutData=${link}`;
    },
    async copyLink() {
      await navigator.clipboard.writeText(this.generatedLink);
      this.$nuxt.$emit('toast', {
        type: 'success',
        message: 'Link copied to clipboard',
      });
    },
  },
};
</script>
