<template>
  <div class="ru:checkout">
    <Breadcrumbs
      v-if="breadcrumbs"
      :crumbs="[
        {
          text: breadcrumbs.start,
          state: 'complete',
        },
        {
          text: $t('components.checkout.addOns'),
          state: breadcrumbState('PRODUCT_SELECTION'),
        },
        {
          text: $t('components.checkout.payment'),
          state: breadcrumbState('PAYMENT'),
        },
        {
          text: breadcrumbs.finish,
          state: breadcrumbState('PROCESSING'),
        },
      ]"
    />
    <transition-group
      tag="div"
      class="relative"
      name="ru:wiggle-horizontal"
      @after-enter="scrollToTop"
    >
      <template v-if="isCurrentAct('LOADING')">
        <div key="LOADING">
          <OneMoment />
        </div>
      </template>
      <template v-else-if="isCurrentAct('PRODUCT_SELECTION')">
        <div key="PRODUCT_SELECTION">
          <transition-group
            tag="div"
            class="relative"
            name="ru:wiggle-horizontal"
          >
            <template v-if="isCurrentScene('PRODUCTS_SUGGESTED')">
              <div key="PRODUCTS_SUGGESTED">
                <slot name="PRODUCTS_SUGGESTED" />
                <ItemSelector
                  v-model="selectedItems"
                  :items="suggestedItemsWithPrices"
                />
                <Buttons layout="column">
                  <RuButton
                    id="products_suggested__next"
                    level="primary"
                    @click="productSelectionNext('PRODUCTS_SUGGESTED')"
                  >
                    {{ $t('components.checkout.continue') }}
                    <Icon id="arrow-right" />
                  </RuButton>
                  <RuButton
                    key="checkout__later"
                    data-test="checkout__later"
                    level="plain"
                    theme="slate"
                    @click="$emit('openCheckoutModal')"
                  >
                    {{ $t('components.checkout.later') }}
                  </RuButton>
                </Buttons>
              </div>
            </template>
            <template v-if="isCurrentScene('PRODUCTS_PARTNER')">
              <div key="PRODUCTS_PARTNER">
                <Buttons v-if="showProductSuggestions" layout="start">
                  <RuButton
                    level="plain"
                    @click="setCurrentScene('PRODUCTS_SUGGESTED')"
                  >
                    <Icon id="chevron-left" />
                    {{ $t('components.checkout.back') }}
                  </RuButton>
                </Buttons>
                <slot name="PRODUCTS_PARTNER" />
                <ItemSelector
                  v-model="selectedItems"
                  :items="partnerItemsWithPrices"
                />
                <Buttons layout="column">
                  <RuButton
                    id="products_partner__next"
                    level="primary"
                    @click="productSelectionNext('PRODUCTS_PARTNER')"
                  >
                    {{ $t('components.checkout.continue') }}
                    <Icon id="arrow-right" />
                  </RuButton>
                  <RuButton
                    key="checkout__later"
                    data-test="checkout__later"
                    level="plain"
                    theme="slate"
                    @click="$emit('openCheckoutModal')"
                  >
                    {{ $t('components.checkout.later') }}
                  </RuButton>
                </Buttons>
              </div>
            </template>
            <template v-if="isCurrentScene('PRODUCTS_GIFT')">
              <div key="PRODUCTS_GIFT">
                <Buttons v-if="showProductSuggestions" layout="start">
                  <RuButton
                    level="plain"
                    @click="setCurrentScene('PRODUCTS_SUGGESTED')"
                  >
                    <Icon id="chevron-left" />
                    {{ $t('components.checkout.back') }}
                  </RuButton>
                </Buttons>
                <slot name="PRODUCTS_GIFT" />
                <ItemSelector
                  v-model="selectedItems"
                  :items="giftItemsWithPrices"
                />
                <Buttons layout="stretch">
                  <RuButton
                    id="products_gift__next"
                    level="primary"
                    @click="productSelectionNext('PRODUCTS_GIFT')"
                  >
                    {{ $t('components.checkout.continue') }}
                    <Icon id="arrow-right" />
                  </RuButton>
                </Buttons>
              </div>
            </template>
          </transition-group>
        </div>
      </template>
      <template v-else-if="isCurrentAct('PAYMENT')">
        <div key="PAYMENT">
          <Buttons v-if="showProductSelection" layout="start">
            <RuButton level="plain" @click="setCurrentAct('PRODUCT_SELECTION')">
              <Icon id="chevron-left" />
              {{ $t('components.checkout.back') }}
            </RuButton>
          </Buttons>
          <slot name="PAYMENT" />
          <CheckoutSummary
            :items="checkoutItemsWithPrices"
            :discount-data="discountData"
            @removeCoupon="applyCoupon"
          />
          <CouponInput
            v-if="couponAllowed && !couponIsValid"
            :value="discountCode"
            @applyCoupon="applyCoupon"
          >
            <Alert
              v-if="showCouponAlert"
              :theme="couponAlertTheme"
              level="medium"
            >
              {{ couponAlertMessage }}
            </Alert>
          </CouponInput>
          <div v-if="showAfterpay">
            <Headline :level="2">
              {{ $t('components.checkout.choosePaymentMethod') }}
            </Headline>
            <div class="ru:tabs ru:checkout__payment-methods">
              <Buttons layout="stretch">
                <RuButton
                  id="credit-card"
                  v-bind="tabAttributes('stripe')"
                  @click="selectPaymentMethod('stripe')"
                >
                  {{ $t('components.checkout.creditCard') }}
                  <img
                    class="ru:checkout__credit-card"
                    alt="Visa icon"
                    width="22"
                    height="16"
                    :src="require('~/assets/img/credit-cards/logo__visa.png')"
                  />
                  <img
                    class="ru:checkout__credit-card"
                    alt="Mastercard icon"
                    width="22"
                    height="16"
                    :src="
                      require('~/assets/img/credit-cards/logo__mastercard.png')
                    "
                  />
                  <img
                    class="ru:checkout__credit-card"
                    alt="American Express icon"
                    width="22"
                    height="16"
                    :src="
                      require('~/assets/img/credit-cards/logo__american-express.png')
                    "
                  />
                </RuButton>
                <RuButton
                  id="afterpay"
                  v-bind="tabAttributes('afterpay')"
                  @click="selectPaymentMethod('afterpay')"
                >
                  {{ $t('components.checkout.afterpay') }}
                  <img
                    class="ru:checkout__afterpay"
                    alt="Afterpay icon"
                    width="74"
                    height="16"
                    :src="
                      require('~/assets/img/credit-cards/logo__afterpay.png')
                    "
                  />
                </RuButton>
              </Buttons>
            </div>
          </div>
          <transition-group
            tag="div"
            class="relative"
            name="ru:wiggle-horizontal"
          >
            <template v-if="isSelectedTab('stripe')">
              <StripePayment
                key="stripe"
                ref="stripeComponent"
                :action-type="stripeActionType"
                :items="checkoutItemsWithPrices"
                :expected-cost-in-cents="expectedCostInCents"
                :discount-code="discountCode"
                :products-with-custom-prices-token="
                  productsWithCustomPricesToken
                "
                @paymentProcessingChanged="setPaymentProcessing"
                @paymentSucceeded="createOrder"
              />
            </template>
            <template v-if="isSelectedTab('afterpay')">
              <AfterpayPayment
                v-if="showAfterpay"
                key="afterpay"
                ref="afterpayComponent"
                :items="checkoutItemsWithPrices"
                :expected-cost-in-cents="expectedCostInCents"
                :discount-code="discountCode"
                :billing-address="billingAddress"
                :products-with-custom-prices-token="
                  productsWithCustomPricesToken
                "
                @paymentProcessingChanged="setPaymentProcessing"
                @paymentSucceeded="createOrder"
              />
            </template>
          </transition-group>
          <Buttons layout="stretch">
            <RuButton
              id="submit"
              level="primary"
              :disabled="submitDisabled"
              @click="submitPayment"
            >
              {{ $t('components.checkout.submit') }}
              <Icon id="arrow-right" />
            </RuButton>
          </Buttons>
        </div>
      </template>
      <template v-if="isCurrentAct('PROCESSING')">
        <div key="PROCESSING">
          <OneMoment text="We’re processing your order…" />
        </div>
      </template>
      <template v-else-if="isCurrentAct('ERROR')">
        <div key="ERROR">
          <OhDear />
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import OneMoment from '@/components/templates/OneMoment';
import OhDear from '@/components/templates/OhDear';
import Alert from '@/components/molecules/Alert';
import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import StripePayment from '@/components/organisms/StripePayment';
import AfterpayPayment from '@/components/organisms/AfterpayPayment';
import CheckoutSummary from '@/components/organisms/CheckoutSummary';
import CouponInput from '@/components/molecules/CouponInput';
import Headline from '@/components/atoms/Headline';
import ItemSelector from '@/components/organisms/ItemSelector';
import Breadcrumbs from '@/components/molecules/Breadcrumbs';

import GET_PRICES_QUERY from '@/graphql/queries/getPrices';
import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';
import CURRENT_USER_QUERY from '@/graphql/queries/CurrentUser';
import GET_ORDERS from '@/graphql/queries/GetOrders';
import GET_ACTIVE_INVITES_FOR_RECIPIENT from '@/graphql/queries/GetActiveInvitesForRecipient';

import { user, will } from '@/mixins/apollo';
import { testUntilTrue } from '@/utilities';

export default {
  name: 'ComponentsTemplatesCheckout',
  components: {
    OneMoment,
    OhDear,
    Alert,
    Buttons,
    RuButton,
    StripePayment,
    AfterpayPayment,
    CheckoutSummary,
    Icon,
    CouponInput,
    Headline,
    ItemSelector,
    Breadcrumbs,
  },
  mixins: [user, will],
  props: {
    checkoutItems: {
      type: Array,
      default: () => [],
    },
    isGeneric: {
      type: Boolean,
      default: false,
    },
    productsWithCustomPricesToken: {
      type: String,
      default: null,
    },
    customProducts: {
      type: Array,
      default: () => [],
    },
    isUpdateCardDetails: {
      type: Boolean,
      default: false,
    },
    isUnlockAccount: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      acts: [
        'LOADING',
        'PRODUCT_SELECTION',
        'PAYMENT',
        'PROCESSING',
        'ERROR',
        'CHECKOUT',
      ],
      currentAct: null,
      currentScene: null,
      selectedItems: [],
      allItemsWithPrices: [],
      paymentProcessing: false,
      discountCode: null,
      discountId: null,
      discountData: null,
      paymentMethod: null,
      finalPrice: null,
      couponAllowed: false,
      stripeActionType: '',
      activePaidInvites: [],
      defaultItems: [],
      suggestedItems: [],
      partnerItems: [],
      giftItems: [],
      purchasedProducts: [],
    };
  },
  apollo: {
    getPrices: {
      query: GET_PRICES_QUERY,
      variables() {
        return {
          products: [...this.checkoutItems.map((product) => product.product)],
          discountCode: this.discountCode,
        };
      },
      result({ data, error }) {
        if (error) {
          this.setCurrentAct('ERROR');
        } else {
          this.allItemsWithPrices = data.getPrices.pricedItems;
          this.discountData = data.getPrices.discount;
          this.finalPrice = data.getPrices.finalPrice;
        }
      },
      skip() {
        return this.isGeneric || this.isUpdateCardDetails;
      },
    },
    purchasedProducts: {
      query: GET_PURCHASED_PRODUCTS_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          userId: this.userId,
        };
      },
      skip() {
        return (
          !this.defaultItems.length ||
          Boolean(this.hasAfterpayPaymentIntentClientSecret)
        );
      },
      update(data) {
        const purchasedProducts = data?.getPurchasedProducts?.products || [];

        const hasAlreadyPurchasedAllDefaultItems = this.defaultItems.every(
          (defaultItem) => {
            return purchasedProducts.includes(defaultItem.product);
          }
        );
        if (
          !this.hasAfterpayPaymentIntentClientSecret &&
          !this.isUnlockAccount &&
          hasAlreadyPurchasedAllDefaultItems
        ) {
          console.error('User has already purchased all default items.');
          this.setCurrentAct('ERROR');
        }

        return purchasedProducts;
      },
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
      result({ data }) {
        if (!this.activePaidInvites.length) {
          this.discountCode = data.currentUser.user.latest_referral_coupon;
        }
      },
    },
    activePaidInvites: {
      query: GET_ACTIVE_INVITES_FOR_RECIPIENT,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          userId: this.userId,
        };
      },
      update({ getActiveInvitesForRecipient }) {
        const invites = getActiveInvitesForRecipient?.invites || [];
        const activePaidInvites = invites.filter(
          (invite) => invite.type === 'PAID'
        );

        this.updateSuggestedItems(activePaidInvites);

        const applicableInvite = this.findApplicapleInvite(activePaidInvites);
        if (applicableInvite) {
          this.discountId = applicableInvite.id;
          this.discountCode = applicableInvite.code;
        }

        return activePaidInvites;
      },
      skip() {
        return !this.userId;
      },
    },
  },
  computed: {
    ...mapGetters('directory-person', ['userIsAustralian']),
    ...mapGetters('coupon', ['couponLatestReferral']),
    showProductSelection() {
      return (
        this.showProductSuggestions ||
        this.showPartnerSelection ||
        this.showGiftSelection
      );
    },
    showProductSuggestions() {
      return this.suggestedItems.length > 0;
    },
    showPartnerSelection() {
      return this.partnerItems.length > 0;
    },
    showGiftSelection() {
      return this.giftItems.length > 0;
    },
    selectedItemsWithPrices() {
      if (this.allItemsWithPrices) {
        return this.selectedItems.map((selectedItem) => {
          return this.allItemsWithPrices.find((pricedItem) => {
            return pricedItem.product === selectedItem.product;
          });
        });
      }
      return [];
    },
    defaultItemsWithPrices() {
      if (this.allItemsWithPrices) {
        return this.defaultItems.map((defaultItem) => ({
          ...this.allItemsWithPrices.find(
            (item) => item.product === defaultItem.product
          ),
          ...defaultItem,
        }));
      }
      return [];
    },
    suggestedItemsWithPrices() {
      if (!this.allItemsWithPrices) {
        return [];
      }

      return this.suggestedItems
        .filter(
          (suggestedItem) =>
            !this.purchasedProducts.includes(suggestedItem.product)
        )
        .map((suggestedItem) => {
          return {
            ...this.allItemsWithPrices.find((pricedItem) => {
              return pricedItem.product === suggestedItem.product;
            }),
            ...suggestedItem,
          };
        });
    },
    partnerItemsWithPrices() {
      if (!this.allItemsWithPrices) {
        return [];
      }

      return this.partnerItems
        .filter(
          (partnerItem) => !this.purchasedProducts.includes(partnerItem.product)
        )
        .map((partnerItem) => {
          return {
            ...this.allItemsWithPrices.find((pricedItem) => {
              return pricedItem.product === partnerItem.product;
            }),
            ...partnerItem,
          };
        });
    },
    giftItemsWithPrices() {
      if (!this.allItemsWithPrices) {
        return [];
      }
      return this.giftItems.map((giftItem) => {
        return {
          ...this.allItemsWithPrices.find((pricedItem) => {
            return pricedItem.product === giftItem.product;
          }),
          ...giftItem,
        };
      });
    },
    checkoutItemsWithPrices() {
      return [...this.defaultItemsWithPrices, ...this.selectedItemsWithPrices];
    },
    expectedCostInCents() {
      return this.checkoutItemsWithPrices.reduce((total, current) => {
        return total + current.finalPrice;
      }, 0);
    },
    showCouponAlert() {
      return Boolean(
        this.discountCode && !this.$apollo.queries.getPrices.loading
      );
    },
    couponIsValid() {
      return !!this.discountData;
    },
    couponAlertTheme() {
      return this.couponIsValid ? 'success' : 'error';
    },
    couponAlertMessage() {
      return this.couponIsValid
        ? this.$t('components.checkout.couponCodeApplied', {
            code: this.discountCode?.toUpperCase(),
          })
        : this.$t('components.checkout.invalidCouponCode', {
            code: this.discountCode?.toUpperCase(),
          });
    },
    billingAddress() {
      if (this.willMeta) {
        return {
          street: this.willMeta.address_street,
          suburb: this.willMeta.address_suburb,
          state: this.willMeta.address_state,
          postcode: this.willMeta.address_postcode,
          country: this.willMeta.address_country,
        };
      }
      return {};
    },
    showAfterpay() {
      if (this.isUpdateCardDetails || !this.userIsAustralian) {
        return false;
      } else {
        return this.expectedCostInCents / 100 >= 40;
      }
    },
    submitDisabled() {
      return !this.paymentMethod || this.paymentProcessing;
    },
  },
  watch: {
    finalPrice() {
      if (!this.showAfterpay) {
        this.paymentMethod = 'stripe';
      }
    },
  },
  created() {
    this.discountCode = this.couponLatestReferral;

    this.currentAct = 'LOADING';

    this.defaultItems = this.checkoutItems.filter(
      (checkoutItem) => checkoutItem.type === 'DEFAULT'
    );

    this.giftedOnlyItems = this.checkoutItems.filter(
      (checkoutItem) => checkoutItem.type === 'GIFTED_ONLY'
    );

    this.suggestedItems = this.checkoutItems
      .filter((checkoutItem) => checkoutItem.type === 'SUGGESTED')
      .map((item) => ({ ...item, isGifted: false }));
    this.partnerItems = this.checkoutItems.filter(
      (checkoutItem) => checkoutItem.type === 'PARTNER'
    );
    this.giftItems = this.checkoutItems.filter(
      (checkoutItem) => checkoutItem.type === 'GIFT'
    );

    this.couponAllowed =
      !this.isGeneric && !this.isUpdateCardDetails && !this.isUnlockAccount;

    this.stripeActionType = this.isUpdateCardDetails ? 'update' : 'checkout';

    this.hasAfterpayPaymentIntentClientSecret =
      !!this.$route.query.payment_intent_client_secret;

    if (this.hasAfterpayPaymentIntentClientSecret) {
      this.setCurrentAct('PAYMENT');
      this.paymentMethod = 'afterpay';
    } else if (this.showProductSelection) {
      this.setCurrentAct('PRODUCT_SELECTION');
      if (this.showProductSuggestions) {
        this.setCurrentScene('PRODUCTS_SUGGESTED');
      } else if (this.showPartnerSelection) {
        this.setCurrentScene('PRODUCTS_PARTNER');
      } else if (this.showGiftSelection) {
        this.setCurrentScene('PRODUCTS_GIFT');
      }
    } else {
      this.setCurrentAct('PAYMENT');
      if (this.isUpdateCardDetails || this.isUnlockAccount) {
        this.paymentMethod = 'stripe';
      }
    }
    if (this.isGeneric || this.isUpdateCardDetails) {
      this.finalPrice = this.defaultItems.reduce(
        (total, product) => total + product.finalPrice,
        0
      );
    }
  },
  methods: {
    isWillCheckout() {
      return !!this.checkoutItems.find(
        (item) => item.product === 'WILL' && item.type === 'DEFAULT'
      );
    },
    isPoaCheckout() {
      return !!this.checkoutItems.find(
        (item) => item.product === 'POA' && item.type === 'DEFAULT'
      );
    },
    userHasSeparatePoaAndWillInvites(activePaidInvites) {
      return (
        activePaidInvites.length >= 2 &&
        activePaidInvites.some((invite) => invite.products.includes('POA')) &&
        activePaidInvites.some((invite) => invite.products.includes('WILL'))
      );
    },
    userHasPoaInvite(activePaidInvites) {
      return activePaidInvites.some((invite) =>
        invite.products.includes('POA')
      );
    },
    userHasWillInvite(activePaidInvites) {
      return activePaidInvites.some((invite) =>
        invite.products.includes('WILL')
      );
    },
    userHasPurchasedWill() {
      return this.purchasedProducts.includes('WILL');
    },
    userHasPurchasedPoa() {
      return this.purchasedProducts.includes('POA');
    },
    updateSuggestedItems(activePaidInvites) {
      if (this.userHasSeparatePoaAndWillInvites(activePaidInvites)) {
        this.suggestedItems = this.suggestedItems.filter(
          (item) =>
            (this.isWillCheckout() && item.product !== 'POA') ||
            (this.isPoaCheckout() && item.product !== 'WILL')
        );
        this.giftedOnlyItems = this.giftedOnlyItems.filter(
          (item) =>
            (this.isWillCheckout() && item.product !== 'POA') ||
            (this.isPoaCheckout() && item.product !== 'WILL')
        );
      }

      const possibleGifts = [...this.suggestedItems, ...this.giftedOnlyItems];

      if (possibleGifts.length) {
        possibleGifts.forEach((item, index) => {
          if (
            (item.product === 'POA' &&
              this.userHasPoaInvite(activePaidInvites) &&
              !this.userHasPurchasedPoa()) ||
            (item.product === 'WILL' &&
              this.userHasWillInvite(activePaidInvites) &&
              !this.userHasPurchasedWill())
          ) {
            possibleGifts[index].isGifted = true;
            this.selectedItems.push(possibleGifts[index]);
          }
        });
      } else if (this.currentScene === 'PRODUCTS_SUGGESTED') {
        this.setCurrentAct('PAYMENT');
      }
    },
    findApplicapleInvite(activePaidInvites) {
      let applicableInvite = null;
      const willInvite = activePaidInvites.find(({ products }) =>
        products.includes('WILL')
      );
      const poaInvite = activePaidInvites.find(({ products }) =>
        products.includes('POA')
      );
      const legalInvite = activePaidInvites.find(({ products }) =>
        products.includes('LEGAL')
      );
      if (this.isWillCheckout()) {
        if (willInvite) {
          applicableInvite = willInvite;
        } else if (this.selectedItems.find((item) => item.product === 'POA')) {
          applicableInvite = poaInvite;
        } else if (
          this.selectedItems.find((item) => item.product === 'LEGAL_ADVICE')
        ) {
          applicableInvite = legalInvite;
        }
      } else if (this.isPoaCheckout()) {
        applicableInvite = poaInvite;
        if (poaInvite) {
          applicableInvite = poaInvite;
        } else if (this.selectedItems.find((item) => item.product === 'WILL')) {
          applicableInvite = willInvite;
        } else if (
          this.selectedItems.find((item) => item.product === 'LEGAL_ADVICE')
        ) {
          applicableInvite = legalInvite;
        }
      }
      return applicableInvite;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    setCurrentAct(act) {
      this.currentAct = act;
    },
    isCurrentAct(act) {
      return this.currentAct === act;
    },
    productSelectionNext(currentScene) {
      switch (currentScene) {
        case 'PRODUCTS_SUGGESTED': {
          if (this.showPartnerSelection) {
            this.setCurrentScene('PRODUCTS_PARTNER');
          } else if (this.showGiftSelection) {
            this.setCurrentScene('PRODUCTS_GIFT');
          } else {
            this.currentAct = 'PAYMENT';
          }
          break;
        }
        case 'PRODUCTS_PARTNER': {
          if (this.showGiftSelection) {
            this.setCurrentScene('PRODUCTS_GIFT');
          } else {
            this.currentAct = 'PAYMENT';
          }
          break;
        }
        case 'PRODUCTS_GIFT': {
          this.currentAct = 'PAYMENT';
          break;
        }
      }
    },
    setCurrentScene(scene) {
      this.currentScene = scene;
    },
    isCurrentScene(scene) {
      return this.currentScene === scene;
    },
    applyCoupon(couponCode) {
      this.discountCode = couponCode;
    },
    breadcrumbState(act) {
      const actIndex = this.acts.indexOf(act);
      const currentActIndex = this.acts.indexOf(this.currentAct);
      if (actIndex === currentActIndex) {
        return 'active';
      } else if (actIndex < currentActIndex) {
        return 'complete';
      }
      return null;
    },
    selectPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    isSelectedTab(paymentMethod) {
      return this.paymentMethod === paymentMethod;
    },
    tabAttributes(paymentMethod) {
      return {
        level: this.isSelectedTab(paymentMethod) ? 'primary' : 'secondary',
      };
    },
    setPaymentProcessing(isProcessing) {
      this.paymentProcessing = isProcessing;
    },
    submitPayment() {
      switch (this.paymentMethod) {
        case 'stripe': {
          this.$refs.stripeComponent.submitPayment();
          break;
        }
        case 'afterpay': {
          this.$refs.afterpayComponent.submitPayment();
          break;
        }
      }
    },
    async createOrder() {
      this.setCurrentAct('PROCESSING');
      if (!this.isUpdateCardDetails) {
        const isOrderCreationSuccessful = await this.checkPurchasedItems();
        if (isOrderCreationSuccessful) {
          this.$nuxt.$emit('sendTrackingEvent', {
            event: `Order Completed - Frontend`,
          });
          await this.sendPurchasedProductsTrackingEvents();
        }
      }
      this.$emit('orderCreated');
    },
    checkPurchasedItems() {
      const userId = this.userId;
      const purchasedItems = this.checkoutItemsWithPrices.map(
        (item) => item.product
      );
      return testUntilTrue(
        async () => {
          const { data } = await this.$apollo.query({
            query: GET_PURCHASED_PRODUCTS_QUERY,
            fetchPolicy: 'no-cache',
            variables: {
              userId,
            },
            skip() {
              return !userId;
            },
          });
          const { products = [] } = data.getPurchasedProducts;
          return purchasedItems.every((purchasedItem) =>
            products.includes(purchasedItem)
          );
        },
        30,
        200
      );
    },
    async getLatestOrder() {
      const userId = this.userId;

      const { data } = await this.$apollo.query({
        query: GET_ORDERS,
        fetchPolicy: 'no-cache',
        variables: {
          userId,
        },
        skip() {
          return !userId;
        },
      });

      return data.getOrders.sort(function (x, y) {
        return Number(y.createdAt) - Number(x.createdAt);
      })[0];
    },
    async sendPurchasedProductsTrackingEvents() {
      const latestOrder = await this.getLatestOrder();
      const items = [];
      for (const orderItem of latestOrder.orderItems) {
        const props = {
          transaction_id: latestOrder.externalPaymentId,
          currency: this.$t('texts.words.currency'),
          value: orderItem.valueInCents / 100,
          items: [
            {
              item_name: startCase(camelCase(orderItem.product)),
              price: orderItem.valueInCents / 100,
              quantity: 1,
            },
          ],
        };
        items.push(props.items[0]);
        if (this.activePaidInvites.length) {
          props.invite_applied = true;
          props.invite_id = this.discountId;
        }
        this.$nuxt.$emit('sendTrackingEvent', {
          event: `${orderItem.product} purchased - Frontend`,
          props,
        });
      }
      this.$nuxt.$emit('sendTrackingEvent', {
        event: `purchase`,
        props: {
          transaction_id: latestOrder.id,
          items,
        },
      });
    },
  },
};
</script>

<style lang="scss">
#{$ru} {
  &checkout {
    &__payment-methods {
      img {
        display: inline-block;
      }
    }
    #{$mf}primary &__credit-card {
      border-radius: var(--border-radius-small);
      outline: 1px solid --rgba(white);
    }
    #{$mf}primary &__afterpay {
      filter: invert(1);
    }
  }
}
</style>
