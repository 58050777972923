<template>
  <Buttons layout="stretch">
    <RuButton :href="action.link" level="primary">
      {{ action.text }}
      <Icon id="arrow-right" />
    </RuButton>
  </Buttons>
</template>

<script>
import { mapGetters } from 'vuex';

import Buttons from '@/components/atoms/Buttons';
import RuButton from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';

import { purchasedProducts, will } from '@/mixins/apollo';
import { queries } from '@/modules/apollo-queries/poa-status';
import willProgress from '@/mixins/will-progress';
import poaProgress from '@/mixins/poa-progress';

export default {
  name: 'ComponentsOrganismsRecommendedAction',
  components: {
    Buttons,
    RuButton,
    Icon,
  },
  mixins: [purchasedProducts, will, willProgress, poaProgress],
  data() {
    return {
      buttonActionsWithConditions: [
        {
          condition: () =>
            this.willStatus === 'IN_PROGRESS' &&
            this.completedWillStepsAsPercentage >= 100,
          isProductEnabled: () => this.features.willEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.submitWill'),
            link: this.localePath('/will'),
          },
        },
        {
          condition: () => this.willStatus === 'IN_PROGRESS',
          isProductEnabled: () => this.features.willEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.completeWill'),
            link: this.localePath('/will'),
          },
        },
        {
          condition: () =>
            this.willStatus === 'AWAITING_APPROVAL' &&
            !this.isPoaPurchased &&
            this.userIsAustralian,
          isProductEnabled: () => this.features.powerOfAttorneyEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.completePOA'),
            link: this.localePath('/poa'),
          },
        },
        {
          condition: () => this.willStatus === 'FLAGGED',
          isProductEnabled: () => this.features.willEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.editWill'),
            link: this.localePath('/will'),
          },
        },
        {
          condition: () => !this.isPoaPurchased && this.userIsAustralian,
          isProductEnabled: () => this.features.powerOfAttorneyEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.purchasePOA'),
            link: this.localePath('/poa'),
          },
        },
        {
          condition: () =>
            this.poaProgressAsPercentage < 80 && this.userIsAustralian,
          isProductEnabled: () => this.features.powerOfAttorneyEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.completePOA'),
            link: this.localePath('/poa'),
          },
        },
        {
          condition: () =>
            this.poaProgressAsPercentage < 100 && this.userIsAustralian,
          isProductEnabled: () => this.features.powerOfAttorneyEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.submitPOA'),
            link: this.localePath('/poa'),
          },
        },
        {
          condition: () => !this.hasEndOfLifeItems,
          isProductEnabled: () => this.features.endOfLifeEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.startVault'),
            link: this.localePath('/end-of-life'),
          },
        },
        {
          condition: () => true,
          isProductEnabled: () => this.features.endOfLifeEnabled,
          buttonActions: {
            text: this.$t('components.recommendedAction.continueVault'),
            link: this.localePath('/end-of-life'),
          },
        },
      ],
    };
  },
  apollo: {
    poaStatus: {
      ...queries.getPoaStatus(),
    },
  },
  computed: {
    ...mapGetters('affiliate', ['features']),
    ...mapGetters('end-of-life-categories', ['endOfLifeCategoryItemCounts']),
    ...mapGetters('directory-person', ['userIsAustralian']),
    action() {
      const possibleActions = this.buttonActionsWithConditions.filter(
        (action) => {
          return (
            action.condition.call(this) && action.isProductEnabled.call(this)
          );
        }
      );
      return possibleActions[0]?.buttonActions || null;
    },
    hasEndOfLifeItems() {
      return Object.values(this.endOfLifeCategoryItemCounts).some(
        (categoryCount) => categoryCount > 0
      );
    },
  },
};
</script>
